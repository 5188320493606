<template>
	<div ref="visitCreate" class="full-container">
		<div class="full-top-wrap">
			Visit Create
			
		</div>
		<div class="full-center-wrap">
			<form @submit.prevent="doValidation()">
				<p class="txt-crud-title">
					Visit Form
				</p>
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>Visit Name.</th>
							<td colspan="3">
								<input
									type="text"
									name="visitName"
									class="input-crud"
									:class="(errors.has('visitName') || isDuplicate ? 'input-crud-error' : '')"
									:placeholder="(errors.has('visitName') ? '* ' + errors.first('visitName') : 'Please enter the Visit Name.')"
									v-model="item.visitName"
									v-validate="'required'"
									data-vv-as="Visit Name"
									@input="item.visitName = $event.target.value"
									@keyup="doDuplicateCheck()"
								/>
								<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;">
								* Visit Name is duplicated.
								</p>
							</td>
						</tr>
						<tr>
							<th>Sort Order.</th>
							<td>
								<input
									type="number"
									min="1"
									max="100"
									v-model="visitSortOrderVO"
									class="input-crud"
									:class="(errors.has('visitSortOrder') ? 'input-crud-error' : '')"
									name="visitSortOrder"
									v-validate="'required'"
									data-vv-as="Sort Order"
									:placeholder="(errors.has('visitSortOrder') ? '* ' + errors.first('visitSortOrder') : 'Please enter the Sort Order.')"
								/>
							</td>
							<th>
								Site
							</th>
							<td>
								<select
									class="select-crud"
									:class="(errors.has('siteCode') ? 'input-crud-error' : '')"
									name="siteCode"
									v-model="item.siteCode"
									v-validate="'required'"
									data-vv-as="Site"
									style="width: 100%;"
									disabled
								>
									<option value="" selected disabled>--- select ---</option>
									<option v-for="(siteListItem, index) in siteList" :key="index" :value="siteListItem.siteCode">
										{{ siteListItem.siteName }}
									</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>Register.</th>
							<td>{{ userInfo.userName }}({{ userInfo.userId }})</td>
						</tr>
						<tr>
							<th>Register Date.</th>
							<td>
								{{ item.firstRegistDate }}
							</td>
						</tr>
						
						<tr>
							<th>Use Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="Y"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="N"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
						<!-- <tr>
							<th>UnSchedule Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="Y"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="N"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									No
								</label>
							</td>
						</tr> -->
						<tr>
							<th>Page Name.</th>
							<td>
								<div v-for="visitPageItem in visitPageList" :key="visitPageItem.pageNo">
									<label class="checkbox-crud">
										<input
											type="checkbox"
											name='selectVisit'
											:value="visitPageItem.pageNo"
											v-model="selectedPageNo"
											v-validate="'required'"
											data-vv-as="Select PAGE NAME"
										/>
										<span class="checkbox"></span>
										{{ visitPageItem.pageName }}
									</label>
								</div>
								<p class="txt-validation" v-if="errors.has('selectVisit')">
									* {{ errors.first("selectVisit") }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div class="full-bottom-wrap">
			
			<button v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')" type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
				Save
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">
				Cancel
			</button>
		</div>
	</div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import EventBus from "@/script/EventBus.js";

export default {
	name: "VisitCreate",
	created() {
		this.logger.debug(this, "created()");
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.useYn = "Y";
		this.item.unscheduleYn = "N";
		
		this.selectUserInfo();
		this.selectSiteList();
		this.selectVisitPageList();		
		this.getAccessList('VISIT');
		this.getProjectStateCd();
	},
	props: {},
	data() {
		return {
			item: {},
			visitPageList: [],
			selectedPageNo: [],
			visitPageItem: [],
			visitSortOrderVO: [],
			siteList: [],
			isDuplicate: false,
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		selectUserInfo() {
			this.logger.debug(this, "selectUserInfo()");
			axios
				.post(this.config.contextRoot + "/visit/selectUserInfo.do", {userId: this.userInfo.userId})
				.then((response) => {
					console.log(response);
					let siteCode = response.data.userVo.siteCode;
					this.$set(this.item, "siteCode", siteCode);

					this.selectSortOrder();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");
			axios
				.post(this.config.contextRoot + "/site/selectList.do", {})
				.then((response) => {
					console.log(response);
					this.siteList = response.data.siteList;					
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectVisitPageList() {
			this.logger.debug(this, "selectVisitPageList()");

			axios
				.post(this.config.contextRoot + "/visit/selectVisigPageList.do", {projectNo: this.$route.params.projectNo, visittNo: this.$route.params.visittNo, unscheduleYn: "N"})
				.then((response) => {
					console.log(response);
					this.visitPageList = response.data.visitPageList;

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSortOrder() {
            this.logger.debug(this, "selectSortOrder()");
            this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/visit/selectSortOrder.do", this.item)
				.then((response) => {
					console.log(response);
                    this.visitSortOrderVO = response.data.item;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if(result && !this.isDuplicate) {
						this.$confirm("Do you want to register the current Visit?", "Question", "question").then(() => {
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			this.item.projectNo = this.$route.params.projectNo;
			this.item.visitPageList  = this.selectedPageNo;
			this.item.visitSortOrder = this.visitSortOrderVO;

			axios
				.post(this.config.contextRoot + "/visit/insert.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						EventBus.$emit("project-loading", true);
						
						var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/visit";
						
						if (this.$route.path !== url)
						{
							this.$router.push(url);
						}
					}
					else
					{
						this.$alert(response, "Error", "error");
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the visit registration?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.visitCreate.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/visit"
				if (this.$route.path !== url)
				{
					EventBus.$emit("project-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 410);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/visit/duplicateCheck.do", this.item)
				.then((response) => {
					console.log(response);
					if(response.data.visitExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>
<style scoped>

.btn-cancel {
	margin-right: 10px;
}
</style>