<template>
	<div>
		<p class="txt-list-title">Auth Privilege List</p>
		<ul class="sub-menu">
			<li @click="moveMenu('/auth')">Auth</li>
			<li @click="moveMenu('/auth/privilege')">Privilege</li>
			<li class="clicked" @click="moveMenu('/auth/authPrivilege')">Auth Privilege</li>
		</ul>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the privilege category name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button type="button" class="btn-new" title="New Data" @click="doSave()">Save</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="15%" />
				<col width="15%" />
				<col v-for="authItem in authList" :key="authItem.authNo" :width="65/authList.length + '%'"/>
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Category</th>
					<th>Privilege</th>
					<th v-for="authItem in authList" :key="authItem.authNo">{{ authItem.authName }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(privilegeItem, index) in privilegeList" :key="privilegeItem.privilegeNo">
					<td>{{ index + 1 }}</td>
					<td>
						{{ privilegeItem.privilegeCategory }}
					</td>
					<td>
						{{ privilegeItem.privilegeName }}
					</td>
					<td v-for="authItem in authList" :key="authItem.authNo">
						<input
							type="checkbox"
							:checked="targetValue(authItem, privilegeItem)"
							@change="setTargetValue(authItem, privilegeItem)"
							:authNo="authItem.authNo"
							:privilegeNo="privilegeItem.privilegeNo"
							:disabled="disableAccess(privilegeItem, authItem)==true"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- <div class="pagination-wrap">
			<pagination :data="pagination" :show-disabled="true" :limit="4" @pagination-change-page="doSearchPage">
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
        </div> -->
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "AuthPrivilegeList",
	components: {},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectList();
	},
	data() {
		return {
			item: {},
			authList: [],
			privilegeList: [],
			authPrivilegeList: [],
			searchText: "",
			isTyping: false,
		};
	},
	watch: {
		searchText: function(val) {
			this.item.privilegeCategory = val;
			this.item.pageNum = 1;
		}
	},
	computed: {},
	methods: {
		targetValue(authItem, privilegeItem) {
			let result = false;

			this.authPrivilegeList.forEach((obj) => {
				if (obj.authNo == authItem.authNo && obj.privilegeNo == privilegeItem.privilegeNo) {
					result = true;
				}
			});

			return result;
		},
		setTargetValue(authItem, privilegeItem) {
			this.logger.debug(this, "setTargetValue()");

			let result = false;
			let newPrivilegeList = [];
			let lastPrivilegeList = [];

			this.authPrivilegeList.forEach((obj) => {
				if (obj.authNo == authItem.authNo && obj.privilegeNo == privilegeItem.privilegeNo) {
					result = true;
				} else {
					newPrivilegeList.push(obj);
				}
			});

			if (!result) {
				newPrivilegeList.push({authNo: authItem.authNo, privilegeNo: privilegeItem.privilegeNo});
				this.authPrivilegeList = newPrivilegeList;
			}
			else{
				let getPrivilege = [];
				
				if(privilegeItem.privilegeName == 'ACCESS' && privilegeItem.privilegeCategory != 'SETTING'){
					// 같은 category 찾기
					this.privilegeList.forEach((obj) => {
						if(obj.privilegeCategory == privilegeItem.privilegeCategory){
							getPrivilege.push(obj.privilegeNo);
						}
					});
				}

				for(let i = 0; i < newPrivilegeList.length; i++){
					if(newPrivilegeList[i].authNo == authItem.authNo && getPrivilege.includes(newPrivilegeList[i].privilegeNo)){
						result = true;
					} else {
						lastPrivilegeList.push(newPrivilegeList[i]);
					}
				}
				this.authPrivilegeList = lastPrivilegeList;
			}
		},
		selectList() {
			this.logger.debug(this, "selectList()");

			this.item.privilegeTypeCd = '1';

			axios.post(this.config.contextRoot + "/auth/selectAuthPrivilegeList.do", this.item).then((response) => {
				console.log(response);
				this.authList = response.data.authList;
				this.privilegeList = response.data.privilegeList;
				this.authPrivilegeList = response.data.authPrivilegeList;
			});

			this.$nextTick(function () {
				setTimeout(
					function () {
						EventBus.$emit("setting-loading", false);
					}.bind(this),
					500
				);
			});
		},
		doSave() {
			this.logger.debug(this, "doSave()");

			let param = {
				authPrivilegeList: this.authPrivilegeList,
			};

			this.$confirm("Do you want to save your Auth Privileges?", "Question", "question").then(() => {
				axios
					.post(this.config.contextRoot + "/auth/saveAuthPrivilege.do", param)
					.then((response) => {
						if (response.status == 200) {
							console.log(response);
							this.$alert("It's saved.", "Info", "info").then(() => {
								location.reload();
							});
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");

			EventBus.$emit("setting-loading", true);
			let url = this.config.contextRoot + "/setting" + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		disableAccess(privilegeItem, authItem){
			let getAcsPrivilege = "";
			
			// access 이면서 같은 category 찾기
			if(privilegeItem.privilegeName != 'ACCESS'){
				this.privilegeList.forEach((obj) => {
					if(obj.privilegeCategory == privilegeItem.privilegeCategory && obj.privilegeName == 'ACCESS'){
						getAcsPrivilege = obj;
					}
				});
			}
			let disableBool = true;
			
			if(this.targetValue(authItem, getAcsPrivilege)){
				
				disableBool = false;
			}
			
			if(privilegeItem.privilegeName == 'ACCESS' || privilegeItem.privilegeCategory == 'NOTICE'){
				disableBool = false;
			}

			
			if(privilegeItem.privilegeCategory == 'SETTING'){
				disableBool = false;
			}
			
			if(authItem.authNo == '1'){
				disableBool = true;
			}
			
			return disableBool;
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("setting-loading", true);
				this.privilegeList = new Array();
				this.selectList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
	},
};
</script>

<style scoped>
.project-in-balloon {
    display: inline-block;
    position: absolute;
    top: 90px;
    left: 480px;
    width: 120px;
    height: 50px;
    animation: dropBalloon 0.3s ease-out both;
    z-index: 55;
}
</style>
