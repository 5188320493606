<template>
	<div ref="scheduleDetail" class="dialog-container" :style="pdfYn == 'Y' ? 'display: none;' : ''">
		<div class="dialog" style="max-height: 720px; max-width: 1250px; transform: translate(0, -360px);">
			<form @submit.stop="doValidation()" style="height: 97%;">
				<p class="txt-crud-title">
					Procedure View
					<!-- <button
						@click.stop="htmlPdfDown()"
						type="button"
					>
					pdf
					</button> -->
				</p>
				<div class="center">
					<!-- 스크리닝 정보 -->
					<div class="screening-contents-container" ref="pdftable">
						<table
							v-for="(table, index) in surveyItemList"
							:key="index"
							class="procedure-table"
							tabindex="0"
						>
							<colgroup>
								<col
									v-for="col in Number(table.tableCol)"
									:key="col"
									:width="setColumnStyle(index, String.fromCharCode(64 + col))"
								/>
							</colgroup>
							<thead>
								<tr>
									<th 
										:colspan="Number(table.tableCol + 1)"
										class="th-col"
									>
										{{ table.procName }}
									</th> 
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="row in Number(table.tableRow)"
									:key="row"
									:style="setRowStyle(index, row)"
								>
									<td
										v-for="col in Number(table.tableCol)"
										:key="col"
										:id="'td_' + index + '_' + (String.fromCharCode(64 + col) + row)"
										class="th-col"
										:data-id="String.fromCharCode(64 + col)"
										:colspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandCol"
										:rowspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandRow"
										:style="cellStyle(index, String.fromCharCode(64 + col)+ row)"
									>
										<button
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot == tableDataList[index][String.fromCharCode(64 + col) + row].cellName"
											class="repeat-Btn"
											type="button"
											:style="pdfYn == 'Y' ? 'display: none;' : ''"
										>
											+
										</button>

										<!-- cell Text -->
										<div
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											class="unfocus-text"
										>
											{{ tableDataList[index][String.fromCharCode(64 + col) + row].text }}
										</div>
										<!-- cell Data -->
										<div
											v-if="!tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											:id="'tableHD_' + index + '_' + (String.fromCharCode(64 + col) + row)"
											:class="
												tableDataList[index][String.fromCharCode(64 + col) + row].isHeader ?
												'table-head' :
												'table-data'
											"
										>
											<!-- cell Header -->
											<template
												v-if="
													tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													)
												"
											>
												
												{{ tableDataList[index][String.fromCharCode(64 + col) + row].field.questionName }}
												
											</template>
											<!-- cell Field -->
											<template
												v-if="
													(!tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													))
												"
											>
												<ul style="display: block; width: 100%;">
													<li
														v-for="(field, fieldIndex) in getFieldList(index, String.fromCharCode(64 + col) + row)"
														:key="fieldIndex"
														:id="'surveyFieldList_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex"
														class="survey-field-list"
													>
														{{ field.prefixName }}
														<div
															:class="field.fieldLayoutCd == '1' ? 'survey-field-vtype' : 'survey-field-htype'"
															:style="getMargin(field)"
														>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '01'">
																<input
																	type='text'
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	:name="'procFieldNo1' + field.fieldNo"
																	disabled
																/>
															</template>
															<!-- TextArea type -->
															<template v-if="field.fieldTypeCd == '02'">
																<textarea
																	:rows="field.textareaRows"
																	:cols="field.textareaCols"
																	:name="'procFieldNo2' + field.fieldNo"
																	style="max-width: 100%; width: 41rem;"
																	disabled
																/>
															</template>
															<!-- Radio type -->
															<template v-if="field.fieldTypeCd == '03'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.radioOptionsLabel.split(';').length, field.radioCols)"
																>
																	<label
																		v-for="(text, radioIndex) in field.radioOptionsLabel.split(';')"
																		:key="radioIndex"
																	>
																		<input
																			type="radio"
																			:name="'procFieldNo3' + field.fieldNo + row + col"
																			disabled
																		/><span class="radio"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Checkbox type -->
															<template v-if="field.fieldTypeCd == '04'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.checkboxOptionsLabel.split(';').length, field.checkboxCols)"
																>
																	<label
																		v-for="(text, checkboxIndex) in field.checkboxOptionsLabel.split(';')"
																		:key="checkboxIndex"
																	>
																		<input
																			type="checkbox"
																			:name="'procFieldNo4' + field.fieldNo + row + col"
																			disabled
																		/><span class="checkbox"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Select type -->
															<template v-if="field.fieldTypeCd == '05'">
																<select
																	class="select-crud"
																	:name="'procFieldNo5' + field.fieldNo + row + col"
																	disabled
																>
																	<option value="">-- Select --</option>
																	<option
																		v-for="(selectItem, selectIndex) in field.selectOptionsLabel.split(';')"
																		:key="selectIndex"
																		:value="field.selectOptionsValue.split(';')[selectIndex]"
																	>
																			{{ selectItem }}
																	</option>
																</select>
															</template>
															<!-- Image type -->
															<template v-if="field.fieldTypeCd == '06'">
																<div v-show="field.isLoad">
																	<img v-if="field.fileList && field.fileList.length > 0"
																		:src="
																			config.contextRoot +
																				'/common/file/printImage.do?fileNo=' +
																				field.fileList[0].fileNo +
																				'&fileGroupNo=' +
																				field.fileList[0].fileGroupNo +
																				'&serverFileSaveName=' +
																				field.fileList[0].serverFileSaveName
																		"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																	/>
																</div>
																<div v-if="!field.isLoad">
																	<svg 
																		v-if="field.fileList && field.imageWidth"
																		xmlns="http://www.w3.org/2000/svg"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																		:viewBox="'0 0 ' + field.imageWidth + ' ' + field.imageHeight">
																		<rect fill="#ddd" 
																			:width="field.imageWidth"
																			:height="field.imageHeight"
																		/>
																		<text fill="#666" font-family="sans-serif" font-size="20" dy="10.5" font-weight="normal" x="50%" y="50%" text-anchor="middle">
																			{{field.imageWidth}}x{{field.imageHeight}}
																		</text>
																	</svg>
																	<p v-else-if="!field.fileList">
																		There is no image.
																	</p>
																</div>
															</template>
															<!-- File Type -->
															<template v-if="field.fieldTypeCd == '07'">
																<site-file
																	:fileExtension="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileExtension"
																	:fileMaxSize="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileMaxSize"
																	:disabled="true"
																/>
															</template>
															<!-- Date Start -->
															<template v-if="field.fieldTypeCd == '08'">
																<label 
																	class="label-date-picker label-date-picker-disabled"
																	:style="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue == '' ? 'color: #ccc' : 'color: black'"
																>
																	{{
																		(
																			tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat
																		) 
																	}}
																</label>
															</template>
															<!-- Calc Type -->
															<template v-if="field.fieldTypeCd == '09'">
																<input
																	type="text"
																	class="calc"
																	:name="'calculate' + field.fieldNo"
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	disabled
																/>
																<button
																	type="button"
																	class="btn-calc"
																	title="calculate"
																>
																	Calculate
																</button>
															</template>
															<!-- FileDownload Type -->
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																	style="display: inline-block; margin: 0 10px 0 0;"
																/>
																<button
																	v-if="['pdf', 'PDF'].includes(field.fileList[0].fileExtensionName)"
																	type="button"
																	class="download-preview"
																	title="Preview"
																	@click="showPreview(field.fileList)"
																>
																	Preview
																</button>
															</template>
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length < 1">
																<p class="txt-validation">
																	File is not exist!
																</p>
															</template>
															<!-- FileDownload Type -->
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																/>
															</template>
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length < 1">
																<p class="txt-validation">
																	Video is not exist!
																</p>
															</template>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '12'">

																{{tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].selectDefault}}
															</template>
														</div>
														{{ field.suffixName }}
													</li>
												</ul>
											</template>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</form>
			<button type="button" class="btn-cancel fright" title="Close" style="position: inherit;" @click="doCancel()">
				Close
			</button>
		</div>
	</div>
	
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";
import SiteFile from "@/components/common/SiteFile";

export default {
	name: "scheduleDetail",
	created() {
		this.logger.debug(this, "created()");

		this.items.visitNo = this.param.visitNo;
		this.items.pageNo = this.param.pageNo;
		this.pdfYn = this.param.pdfYn;
		
		this.select();
	},
	updated(){
		this.$nextTick(function() {
			if(this.param.pdfYn == 'Y'){
				this.htmlPdfDown();
			}
        })
	},
	components: {
		CommonFile,
		SiteFile,
	},
	props: {
		param: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: {},
			procFieldList: [],
			surveyItemList: [],
			repeatList: [],
			tableRow: 0,
			tableCol: 0,
			tableDataList: [],
			columnInfoList: [],
			rowInfoList: [],
			isViewer : false,
			viewerUrl : "",
			viewerControl : ['print','rotate','zoom','catalog','switchPage'],
			pdfYn : "N"
		};
	},
	methods: {
		select() {
			this.logger.debug(this, "select()");
			axios
				.post(this.config.contextRoot + "/screening/selectProcList.do", this.items)
				.then((response) => {
					console.log(response);
					this.items.lastModifyUserId = response.data.registerVo.lastModifyUserId;
					this.items.lastModifyDate = response.data.registerVo.lastModifyDate;
					this.surveyItemList = response.data.itemList;
					this.tableDataList = response.data.tableDataList;
					this.columnInfoList = response.data.columnInfoList;
					this.rowInfoList = response.data.rowInfoList;

					for(let i = 0; i < this.tableDataList.length; i++) {
						let tableDataKeys = Object.keys(this.tableDataList[i]);
						for(let j = 0 ; j < tableDataKeys.length ; j++)
						{
							this.$set(this.tableDataList[i][tableDataKeys[j]], "isCopy", false);
							if(this.tableDataList[i][tableDataKeys[j]].field == null)
							{
								this.$set(this.tableDataList[i][tableDataKeys[j]], "field", {});
							}
							else{
								for(let k = 0; k < this.tableDataList[i][tableDataKeys[j]].field.fieldList.length; k++){
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldTypeCd == "04" && this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue == ""){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue = [];
									}
								}
							}
						}
					}
					for(let i = 0; i < this.surveyItemList.length; i++)
					{
						for(let j = 0; j < this.surveyItemList[i].procQuestionList.length; j++)
						{
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [];
									}
								}
							}
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldRepeatList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldTypeCd == "04") {
									console.log("indexof : " + this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.indexOf(";"));
									if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [];
									}
								}
							}
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				
		},
		setRowStyle(index, row){
			let rowInfo = this.rowInfoList[index];
			let height = rowInfo[row].height;
			let rowName = rowInfo[row].rowName;
			return {
				height: height,
				rowName : rowName
			};
		},
		setColumnStyle(index, col){
			let columnInfo = this.columnInfoList[index];
			let width = columnInfo[col].width;
			return width;
		},
		cellStyle(index, id) {
			let style = {};
			let cell = this.tableDataList[index][id];

			if(cell.isExpand && cell.expandCol < 2 && cell.expandRow < 2)
			{
				style.display = "none";
			}
			if(cell.isHeader)
			{
				style.backgroundColor = "#f5f5f5";
				style.fontWeight = "600";
			}

			style.textAlign = cell.align;
			style.justifyContent = cell.align;

			return style;
		},
		getFieldList(index, id) {
			if(Object.keys(this.tableDataList[index][id].field).length !== 0)
			{
				return this.tableDataList[index][id].field.fieldList;
			}
			else
			{
				return [];
			}
		},
		getMargin(field) {
			var prefixName = field.prefixName;
			var suffixName = field.suffixName;
			var fieldLayout = field.fieldLayoutCd;

			var topMargin = 0;
			var bottomMargin = 0;
			var leftMargin = 0;
			var rightMargin = 0;

			if (fieldLayout == "1")
			{
				if (prefixName != null && prefixName != "") topMargin = 5;
				if (suffixName != null && suffixName != "") bottomMargin = 5;
			}
			else
			{
				if (prefixName != null && prefixName != "") leftMargin = 10;
				if (suffixName != null && suffixName != "") rightMargin = 10;
			}

			return {
				marginTop: topMargin + "px",
				marginBottom: bottomMargin + "px",
				marginLeft: leftMargin + "px",
				marginRight: rightMargin + "px",
			};
		},
		getGridRowCol(length, cols) {
			var value = parseInt(Number(length) / Number(cols));
			var etc = Number(length) % Number(cols);

			var row = 0;
			var col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(36px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		loadedImage(field) {
			this.$set(field, 'isLoad', true);
		},
		getProcQuestionIndex(index, cellId) {
			let tableData = this.tableDataList[index];
			let procQuestionNo = tableData[cellId].field.procQuestionNo;
			let procQuestionList = this.surveyItemList[index].procQuestionList.map((obj) => {
				let endname = obj.procQuestionNo;
				return endname;
			});
			let getIndex = procQuestionList.indexOf(procQuestionNo);
			console.log("cellId = " + cellId + ", getIndex : " + getIndex);
			return Number(getIndex);
		},
		showPreview(fileList) {
			EventBus.$emit("project-loading", true);
			this.closePreview();
			let pdfUrl = fileList[0].serverFileSavePath + "\\" +fileList[0].serverFileSaveName;
			let base64 = "";

			axios
				.post(this.config.contextRoot + "/common/file/selectPdfViewer.do", {pdfUrl : pdfUrl})
				.then((response) => {
					console.log(response);
					base64 = response.data.base64Url;
					
					this.viewerUrl = base64;
					this.isViewer = true;
					this.showDualView();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		showDualView() {
			this.$refs.dialog.style.transform = "translate(0, 0)";
			this.$refs.dialog.style.position = "fixed";
			this.$refs.dialog.style.top = "0";
			this.$refs.dialog.style.right = "0";
			this.$refs.dialog.style.width = "50%";
			this.$refs.dialog.style.height = "100%";
			this.$refs.dialog.style.maxHeight = "100%";
			this.$refs.dialog.style.animation = "unset";
			EventBus.$emit("project-loading", false);
		},
		cancelDualView() {
			this.$refs.dialog.style.transform = "translate(0px, -400px)";
			this.$refs.dialog.style.position = "";
			this.$refs.dialog.style.top = "";
			this.$refs.dialog.style.right = "";
			this.$refs.dialog.style.width = "";
			this.$refs.dialog.style.height = "";
			this.$refs.dialog.style.maxHeight = "800px";
			this.$refs.dialog.style.animation = "";
		},
		closePreview() {
			this.isViewer = false;
			this.viewerUrl = "";
			this.cancelDualView();
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.doClose();
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.scheduleDetail.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.scheduleDetail.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isScheduleDetail");
			}.bind(this), 410);
		},
		htmlPdfDown(){
			let procSize = this.$refs.pdftable.childElementCount;
			for(let i = 0; i < procSize; i++){
				var name = this.param.pageName;
				name += '_';
				name += this.surveyItemList[i].procName;
				this.$htmlToPdf(this.$refs.pdftable.childNodes[i], name);
				name = "";
			}
			this.doClose();

			if(this.param.pdfEnd == 'Y'){
				this.$nextTick(function () {
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
					}.bind(this), 200);
				});
			}
		}
	}
};
</script>
<style scoped>
.screening-contents-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
	
    grid-auto-flow: row;
    row-gap: 25px;
    position: absolute;
    width: 95%;
    height: calc(100% - 160px);
    padding: 10px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 50;
}

@media ( max-width: 1200px) {
    .screening-contents-container {
        grid-template-columns: 100% !important;
        left: calc(35% + 60px) !important;
        width: calc(65% - 90px) !important;
        transition: left 0.4s linear !important;
    }
}

.screening-contents-container::-webkit-scrollbar {
    width: 18px;
}

.screening-contents-container::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to top, #9ecc5a, #2bade0);
    background-clip: padding-box;
}

.screening-contents-container::-webkit-scrollbar-track {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 20px;
    background: #eee;
    background-clip: padding-box;
}

.screening-contents-container::after {
    content: "";
    display: block;
    width: 100%;
    min-height: 1px;
}

.unfocus-text,
.table-head {
	display: flex;
	justify-content: inherit;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
	z-index: 15;
}

.table-data {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
}

.survey-field-list {
	width: 100%;
	font-weight: 600 !important;
	font-size: 10pt !important;
	color: #666;
	overflow-y: visible;
}

.survey-field-vtype,
.survey-field-htype {
    max-width: 100%;
	font-weight: 400 !important;
}

.dinput-info {
	display: flex;
	align-items: center;
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.dinput-info > .item {
	width: 25%;
	border-left: 1px solid #ccc;
}

.dinput-info > .item:first-child {
	border-left: none;
}

.dinput-info > .item > .title {
	padding: 8px 0 8px 15px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
	border-bottom: 1px solid #ccc;
	background-color: #eee;
	text-align: left;
}

.dinput-info > .item > .content {
	padding: 15px 0 15px 15px;
	font-size: 10.5pt;
	color: #999;
	text-align: left;
}

.procedure-input-wrap {
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
}

.procedure-title {
	padding: 8px 0 8px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #e3f7ff;
	font-weight: 600;
	font-size: 10.5pt;
	color: #000;
	text-align: left;
}

.procedure-field {
	display: flex;
}

.procedure-question {
	display: flex;
	align-items: center;
	width: 20%;
	padding: 20px 0 20px 15px;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #333;
	text-align: left;
}

.procedure-form {
	width: 80%;
	padding: 20px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
	font-weight: 400;
	font-size: 10.5pt;
	color: #666;
	text-align: left;
}

.procedure-form > .procedure-item:last-child {
	margin: 0;
}

.procedure-item {
	display: block;
	margin: 0 0 15px 0;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
}

.procedure-item > .vertical {
	display: block;
}

.procedure-item > .horizon {
	display: inline-block;
}

.input-crud {
	max-width: 100%;
}

.textarea-crud {
	height: unset !important;
}

.radio-crud,
.checkbox-crud {
	font-weight: 400;
	font-size: 10pt;
}

.select-crud {
	width: auto !important;
	max-width: 100% !important;
}

.txt-crud-title {
	justify-content: space-between;
}


.procedure-table {
	width: 100%;
	border-collapse: collapse;
}

.procedure-table:focus {
	outline: none;
}

.procedure-table tr {
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead th {
	position: relative;
	min-width: 30px;
	height: 35px;
	overflow-x: auto;
	resize: horizontal;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead tr th:first-child {
	width: 35px;
	resize: none;
}

.procedure-table th {
	border: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10pt;
	color: #000;
}

.procedure-table td {
	position: relative;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	word-break: keep-all;
}

.procedure-table td > input[type=text] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	border: none;
	background-color: unset;
	font-weight: inherit;
	font-size: inherit;
	text-align: inherit;
}

.procedure-table .focus-celltype {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	font-family: cursive;
	font-weight: 500;
	font-size: 9pt;
	text-align: right;
	overflow: hidden;
	z-index: 5;
	opacity: 0.6;
}

.repeat-Btn {
	width: 20px;
	height: 20px;
	font-size: 20px;
	position: absolute;
	margin: 5px 5px;
	display: flex;
    align-items: center;
    justify-content: center;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
	z-index: 20;
	top: 0;
	left: 1175px;
}

.procedure-table .focus-celltype .celltype-header {
	display: inline-block;
	margin: 0 5px;
	padding: 2px 5px;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-text {
	display: inline-block;
	padding: 2px 5px;
	background-color: #9ecc5a;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-data {
	display: inline-block;
	padding: 2px 5px;
	background-color: #cc5a5a;
	border-radius: 3px;
	color: #fff;
}

.download-preview {
	color: rgb(0, 162, 255);
}

.download-preview:hover {
	text-decoration: underline;
}

.btn-preview-close {
    display: inline-block;
    position: fixed;
    top: 10px;
    left: calc(50% - 140px);
    width: 36px;
    height: 36px;
    border-radius: 36px;
    z-index: 5555;
    font-weight: 600;
    font-size: 10pt;
    color: #fff;
}

.btn-preview-close:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.btn-calc {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 10px;
	padding: 3px 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	font-weight: 600;
	font-size: 9.5pt;
	color: #999;
}

.btn-calc:hover {
	border: 1px solid #aaa;
	color: #666;
}
		
</style>