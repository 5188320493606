<template>
	<div id="" class="layout-container">
		<Menu :menuName="menuName"/>
		<Topbar ref="topbar" :menuName="menuName" />
		<div class="contents-container" :class="isLoading ? 'loading' : ''">
			<router-view ref="router" :class="isLoading ? 'blur' : ''" />
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import Menu from "@/components/left/Menu.vue";
import Topbar from "@/components/left/Topbar.vue";

export default {
	name: "DataDictionaryLayout",
	components: {
		Menu,
		Topbar
	},
	created() {
		this.logger.debug(this, "created()");

		// 로딩바 닫기
		EventBus.$on("dictionary-loading", (isLoading) => {
			this.logger.debug(this, "[EventBus] loading(isLoading)");
			this.isLoading = isLoading;
		});
	},
	beforeDestroy() {
		EventBus.$off('dictionary-loading'); 
	},
	data() {
		return {
			menuName: "Data Dictionary",
			isLoading: true
		};
	}
};
</script>

<style scoped>
@import "../../assets/css/dataDictionary/dataDictionary.css";
</style>