<template>
	<div id="logLayout" class="layout-container">
		<Menu :menuName="menuName" />
		<Topbar ref="topbar" :menuName="menuName" />
		<div class="contents-container" :class="isLoading ? 'loading' : ''">
			<router-view ref="router" :class="isLoading ? 'blur' : ''" />
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import Menu from "@/components/left/Menu.vue";
import Topbar from "@/components/left/Topbar.vue";

export default {
	name: "logLayout",
	components: {
		Menu,
		Topbar
	},
	created() {
		this.logger.debug(this, "created()");

		// 로딩바 닫기
		EventBus.$on("log-loading", (isLoading) => {
			this.logger.debug(this, "[EventBus] loading(isLoading)");
			this.isLoading = isLoading;
		});
	},
	beforeDestroy() {
		EventBus.$off('log-loading');
	},
	data() {
		return {
			menuName: "System Log",
			isLoading: true
		};
	}
};
</script>

<style scoped>
@import "../../assets/css/log/log.css";
</style>