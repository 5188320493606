<template>
	<form @submit.prevent="doValidation()">
		<div class="sign-agree-container">
			<div class="sign-top-wrap">
				회원가입
				<div class="sign-cnt">1</div>
				<span></span>
				<div class="sign-cnt" style="background-color: #DEE2E6; border:none;">2</div>
			</div>
			<!-- <div> -->
				<div>
					<p class="sign-title">Terms and Conditions</p>
					<div class="policy">
						<h1>EDC 이용 약관</h1>
						<h2>제1조(목적)</h2>
						<h3>
							이 약관은 ARBO가 제공하는 임상연구 데이터 제공 서비스(이하 "서비스"라 한다)(이하 "플랫폼"이라 한다)를
							이용함에 있어 충북대학교병원과 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
						</h3>
					</div>
					<input
						class="agree-input-check"
						id="chk-1"
						type="checkbox"
						name="termsAndConditions"
						v-model="agree.terms"
						v-validate="'required'"
						data-vv-as="Terms and Conditions"
					/>
					<label class="agree-check no-drag" for="chk-1">
						<!-- <span>I agree to the above terms and conditions.</span> -->
						<span>위 이용약관에 동의합니다.</span>
					</label>
					<p class="txt-validation" v-if="errors.has('termsAndConditions')">
						* {{ errors.first("termsAndConditions") }}
					</p>
				</div>
				<div>
					<p class="sign-title">Privacy Policy</p>
					<div class="policy">
						<h1>ARBO 개인 정보 보호 정책</h1>
						<h2>제1조(개인정보의 처리 목적)</h2>
						<h3>
							충북대학교병원은 개인정보를 다음의 목적을 위해 처리합니다. 
							처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 
							이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
						</h3>
						<h2>제2조(목적)</h2>
						<h3>
							본 진료정책은 충북대학교병원에서 운영하는 ARBO(이하 '플랫폼')에서 제공하는 임상연구 데이터를 제공하기 위해 제작되었습니다.
						</h3>
					</div>
					<input
							class="agree-input-check"
							id="chk-2"
							type="checkbox"
							name="privacyPolicy"
							v-model="agree.privacy"
							v-validate="'required'"
							data-vv-as="Privacy Policy"
					/>
					<label class="agree-check no-drag" for="chk-2">
						<!-- <span>I agree to the above security policy.</span> -->
						<span>위 이용약관에 동의합니다.</span>
					</label>
					<p class="txt-validation" v-if="errors.has('privacyPolicy')">
						* {{ errors.first("privacyPolicy") }}
					</p>
				</div>
			<!-- </div> -->
			<div>
				<button type="button" class="sign-btn sign-cancel" title="Previous" @click.stop="doCancel()">Previous</button>
				<button type="submit" class="sign-btn sign-submit" title="Next">Next</button>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	name: "SignAgree",
	created() {
		this.logger.debug(this, "created()");
	},
	data() {
		return {
			agree: {}
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result) {
						this.$router.push(this.config.contextRoot + "/login/sign/up");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$router.push(this.config.contextRoot + "/login");
		}
	}
};
</script>
<style>
.txt-validation{
	margin: 0;
	font-size: 0.63vmax;
}
</style>