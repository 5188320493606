import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/login/Login.vue';
import SignAgree from '@/components/login/SignAgree.vue';
import SignUp from '@/components/login/SignUp.vue';
import FindAccountId from '@/components/login/FindAccountId.vue';
import FindAccountPassword from '@/components/login/FindAccountPassword.vue';
import NoticeList from '@/components/notice/NoticeList.vue';
//preRegist
import preRegistration from '@/components/preRegistration/preRegistration.vue';
import preRegistrationEnd from '@/components/preRegistration/preRegistrationEnd.vue';
import prePamphlet from '@/components/preRegistration/prePamphlet.vue';
import preScreeningCreate from '@/components/preRegistration/preScreeningCreate.vue';
import preFindUser from '@/components/preRegistration/preFindUser.vue';
import preScreeningEntryList from '@/components/preRegistration/preScreeningEntryList.vue';
import preScreeningEntryListEnd from '@/components/preRegistration/preScreeningEntryListEnd.vue';
import preEndSurvey from '@/components/preRegistration/preEndSurvey.vue';
// project
import ProjectList from '@/components/project/project/ProjectList.vue';
// page
import PageList from '@/components/project/page/PageList.vue';
// procedure
import ProcedureList from '@/components/project/procedure/ProcedureList.vue';
import ProcedureCreate from '@/components/project/procedure/ProcedureCreate.vue';
import ProcedureEdit from '@/components/project/procedure/ProcedureEdit.vue';
//projectDictionary
import ProjectDictionaryList from '@/components/project/projectDictionary/ProjectDictionaryList.vue';
//schedule
import ScheduleList from '@/components/project/schedule/ScheduleList.vue';
//visit
import VisitList from '@/components/project/visit/VisitList.vue';
import VisitCreate from '@/components/project/visit/VisitCreate.vue';
import VisitEdit from '@/components/project/visit/VisitEdit.vue';
//screening
import ScreeningList from '@/components/project/screening/ScreeningList.vue';
import ScreeningCreate from '@/components/project/screening/ScreeningCreate.vue';
import ScreeningEdit from '@/components/project/screening/ScreeningEdit.vue';
import ScreeningEntryList from '@/components/project/screening/ScreeningEntryList.vue';
//testScreening
import TestScreeningList from '@/components/project/testScreening/TestScreeningList.vue';
import TestScreeningCreate from '@/components/project/testScreening/TestScreeningCreate.vue';
import TestScreeningEdit from '@/components/project/testScreening/TestScreeningEdit.vue';
import TestScreeningEntryList from '@/components/project/testScreening/TestScreeningEntryList.vue';
//query
import QueryResolutionList from '@/components/project/queryResolution/QueryResolutionList.vue';
//test query
import TestQueryResolutionList from '@/components/project/testQueryResolution/TestQueryResolutionList.vue';
// field
import FieldList from '@/components/field/FieldList.vue';
import FieldCreate from '@/components/field/FieldCreate.vue';
import FieldEdit from '@/components/field/FieldEdit.vue';
import FieldPopup from '@/components/field/FieldPopup.vue';
//validation
import ValidationList from '@/components/project/validation/ValidationList.vue';
import ValidationCreate from '@/components/project/validation/ValidationCreate.vue';
import ValidationEdit from '@/components/project/validation/ValidationEdit.vue';
//validation
import CalculationList from '@/components/project/calculation/CalculationList.vue';
import CalculationCreate from '@/components/project/calculation/CalculationCreate.vue';
import CalculationEdit from '@/components/project/calculation/CalculationEdit.vue';
// setting
import SiteList from '@/components/setting/site/SiteList.vue';
import AuthList from '@/components/setting/auth/AuthList.vue';
import AdminPrivilegeList from '@/components/setting/auth/AdminPrivilegeList.vue';
import AuthPrivilegeList from '@/components/setting/auth/AuthPrivilegeList.vue';
import EmrColumnManageList from '@/components/setting/emrColumnManage/EmrColumnManageList.vue';
import EmrDataList from '@/components/setting/emrConnector/EmrDataList.vue';
import UserAuthList from '@/components/setting/userAuth/UserAuthList.vue';
import LogList from '@/components/log/LogList.vue';
// DataDictionay
import DataDictionaryList from '@/components/dataDictionary/DataDictionaryList.vue';
import moment from 'moment';
// Freezing
import FreezingTotalList from '@/components/project/freezing/FreezingTotalList.vue';
import FreezingEachList from '@/components/project/freezing/FreezingEachList.vue';
// Role
import RoleList from '@/components/project/role/RoleList.vue';
import PrivilegeList from '@/components/project/role/PrivilegeList.vue';
import RolePrivilegeList from '@/components/project/role/RolePrivilegeList.vue';
// User
import UserList from '@/components/project/user/UserList.vue';
import UserEdit from '@/components/project/user/UserEdit.vue';
// Project Site
import ProjectUserList from '@/components/project/site/ProjectSiteList.vue';

Vue.use(VueRouter);

//Config
Vue.prototype.config = {
    contextRoot: '',
    proxyServerUrl: window.location.protocol +
        '//' +
        window.location.host +
        ':8081',
};

const routes = [{
        path: Vue.prototype.config.contextRoot + '/login',
        component: Login
    },
    {
        path: Vue.prototype.config.contextRoot + '/login/sign/agree',
        component: SignAgree,
    },
    {
        path: Vue.prototype.config.contextRoot + '/login/sign/up',
        component: SignUp,
    },
    {
        path: Vue.prototype.config.contextRoot + '/login/find/account/id',
        component: FindAccountId,
    },
    {
        path: Vue.prototype.config.contextRoot + '/login/find/account/password',
        component: FindAccountPassword,
    },
    {
        path: Vue.prototype.config.contextRoot + '/notice/list',
        component: NoticeList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/page',
        component: PageList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project',
        component: ProjectList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/procedure',
        component: ProcedureList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/procedure/create/:pageNo',
        component: ProcedureCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/procedure/edit/:procNo',
        component: ProcedureEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/projectDictionary',
        component: ProjectDictionaryList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/visit',
        component: VisitList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/visit/create',
        component: VisitCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/visit/edit/:visitNo',
        component: VisitEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/schedule',
        component: ScheduleList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/screening',
        component: ScreeningList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/screening/create',
        component: ScreeningCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/screening/edit/:screeningNo',
        component: ScreeningEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/screening/:screeningNo',
        component: ScreeningEntryList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/testScreening',
        component: TestScreeningList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/testScreening/create',
        component: TestScreeningCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/testScreening/edit/:testScreeningNo',
        component: TestScreeningEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/testScreening/:testScreeningNo',
        component: TestScreeningEntryList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/queryResolution',
        component: QueryResolutionList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/testQueryResolution',
        component: TestQueryResolutionList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/field',
        component: FieldList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/field/create/:questionGroupNo',
        component: FieldCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + "/field/create/:questionGroupNo/popup",
        name: 'test',
        component: FieldPopup,
    },
    {
        path: Vue.prototype.config.contextRoot + '/field/edit/:questionGroupNo/:questionNo',
        component: FieldEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/validation',
        component: ValidationList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/validation/create',
        component: ValidationCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/validation/edit/:validNo',
        component: ValidationEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/calculation',
        component: CalculationList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/calculation/create',
        component: CalculationCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/calculation/edit/:calculateNo',
        component: CalculationEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/site',
        component: SiteList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/auth',
        component: AuthList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/auth/privilege',
        component: AdminPrivilegeList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/auth/authPrivilege',
        component: AuthPrivilegeList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/emr_column_manage',
        component: EmrColumnManageList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/emr_data_manage',
        component: EmrDataList
    },
    {
        path: Vue.prototype.config.contextRoot + '/setting/user_Auth',
        component: UserAuthList
    },
    {
        path: Vue.prototype.config.contextRoot + '/system_log',
        component: LogList
    },
    {
        path: Vue.prototype.config.contextRoot + '/data_dictionary',
        component: DataDictionaryList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/freezing',
        component: FreezingTotalList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/freezing/each',
        component: FreezingEachList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/role',
        component: RoleList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/role/privilege',
        component: PrivilegeList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/role/rolePrivilege',
        component: RolePrivilegeList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/user',
        component: UserList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/user/edit/:projectUserNo',
        component: UserEdit,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/site',
        component: ProjectUserList,
    },
    //사전등록
    {
        path: Vue.prototype.config.contextRoot + '/preRegistration',
        component: preRegistration
    },
    {
        path: Vue.prototype.config.contextRoot + '/preRegistrationEnd',
        component: preRegistrationEnd
    },
    {
        path: Vue.prototype.config.contextRoot + '/preRegistrationEndSurvey',
        component: preEndSurvey
    },
    {
        path: Vue.prototype.config.contextRoot + '/prePamphlet',
        component: prePamphlet
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/preCreate',
        component: preScreeningCreate,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/preFindUser',
        component: preFindUser,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/preEntry/:screeningNo',
        component: preScreeningEntryList,
    },
    {
        path: Vue.prototype.config.contextRoot + '/project/:projectNo/preEntryEndSurvey/:screeningNo',
        component: preScreeningEntryListEnd,
    },
    {
        path: Vue.prototype.config.contextRoot + '*',
        redirect: Vue.prototype.config.contextRoot + '/login',
    },
];

//UserInfo
Vue.prototype.userInfo = {
    userId: sessionStorage.getItem('userId'),
    userName: sessionStorage.getItem('userName'),
    roleCd: sessionStorage.getItem('roleCd'),
    isAdmin: function() {
        return sessionStorage.getItem('roleCd') !== undefined &&
            sessionStorage.getItem('roleCd').indexOf('ROLE_ADMIN') >= 0 ?
            true :
            false;
    },
};

//LogLevel
Vue.prototype.logLevel = 'debug'; //debug, warn, error, none

//Logger
Vue.prototype.logger = {
    debug: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    warn: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug' || Vue.prototype.logLevel == 'warn') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    error: function(obj, args) {
        if (
            Vue.prototype.logLevel == 'debug' ||
            Vue.prototype.logLevel == 'warn' ||
            Vue.prototype.logLevel == 'error'
        ) {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
};

//Utils
Vue.prototype.utils = {
    isNull: function(args) {
        if (args === undefined || args == null || args == '') {
            return true;
        } else {
            return false;
        }
    },
    isEmpty: function(args) {
        return this.isNull(args);
    },
    formatDate(value) {
        return moment(value).format('YYYY-MM-DD');
    },
    deepCopy: function(args) {
        return JSON.parse(JSON.stringify(args));
    },
    getEventPath: function(event) {
        let path = event.path;
        if (event.path === undefined || event.path == null) {
            path = event.composedPath;
            if (event.path === undefined || event.path == null) {
                path = event.composedPath();
            }
        }
        return path;
    }
};

//AXIOS ExceptionHandler
Vue.prototype.exceptionHandler = function(obj, errors) {
    Vue.prototype.logger.debug(obj, errors);
    console.log(errors.response);

    if (!Vue.prototype.utils.isEmpty(errors.response.status)) {
        let statusCode = errors.response.status;
        let errorData = Vue.prototype.utils.isEmpty(errors.response.data.error) ?
            errors.response.status :
            errors.response.data.error;
        let message = Vue.prototype.utils.isEmpty(errors.response.data.message) ?
            errors.response.statusText :
            errors.response.data.message;

        if (statusCode == 403) {
            alert('[' + errorData + ']' + message);

            sessionStorage.setItem('userId', '');
            sessionStorage.setItem('userName', '');
            sessionStorage.setItem('roleCd', '');
            Vue.prototype.userInfo = {};
            this.$router.push(Vue.prototype.config.contextRoot);
        } else {
            alert('[' + errorData + ']' + message);

            //this.$router.push(Vue.prototype.config.contextRoot);
        }
    }
};

console.log(Vue.prototype.config.proxyServerUrl);
console.log(Vue.prototype.config.contextRoot);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    console.log(to.fullPath);
    console.log('[router.js]path:' + from.path + ' --> ' + to.path);
    console.log('this.userInfo.userId:' + Vue.prototype.userInfo.userId);
    if (
        to.path.indexOf('/preRegistration') == -1 &&
        to.path.indexOf('/prePamphlet') == -1 &&
        to.path.indexOf('/login') == -1 &&
        (Vue.prototype.utils.isNull(sessionStorage.getItem('roleCd')) ||
            sessionStorage.getItem('roleCd') == 'ROLE_ANONYMOUS')
    ) 
    {
        Vue.prototype.userInfo = {};
        sessionStorage.setItem('roleCd', '');
        sessionStorage.setItem('userId', '');
        sessionStorage.setItem('userName', '');
        if(to.fullPath.indexOf('preEntry') == -1){
            next(Vue.prototype.config.contextRoot + '/login');
        }
        else{
            next(Vue.prototype.config.contextRoot + '/preRegistration?ctcProjectId=105&osType=mobile');
        }
    }
    else 
    {
        next();
    }
});

export default router;