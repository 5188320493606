<template>
	<div class="menu-container no-drag">
		<button type="button" class="btn-home" title="Home(notice)" @click="movePage(noticeUrl)"></button>
		<ul class="menu-wrap">
			<li
				v-for="menu in menus"
				v-bind:key="menu.id"
				:title="menu.name"
				:style="menuName === menu.name ? {'background-image':'url(' + menu.imgSrcOn +')'} : {'background-image':'url(' + menu.imgSrc +')'}"
				tabindex="0"
				@click.stop="movePage(menu.url)"
				@keyup.enter="movePage(menu.url)"
			></li>
		</ul>
		<button type="button" class="btn-logout" title="Logout" @click="doLogout()"></button>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import axios from "axios";

export default {
	name: "Menu",
	created() {
		this.logger.debug(this, "created()");
	},
	beforeDestroy() {
		EventBus.$off('access-check');
	},
	props: {
		menuName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			noticeUrl: this.config.contextRoot + "/notice/list",
			menus: [
				{
					id: 1,
					name: "Project",
					imgSrc: require("@/assets/images/left/menu01_off.png"),
					imgSrcOn: require("@/assets/images/left/menu01_on.png"),
					url: this.config.contextRoot + "/project",
				},
				{
					id: 2,
					name: "Field",
					imgSrc: require("@/assets/images/left/menu02_off.png"),
					imgSrcOn: require("@/assets/images/left/menu02_on.png"),
					url: this.config.contextRoot + "/field",
				},
				{
					id: 3,
					name: "Data Dictionary",
					imgSrc: require("@/assets/images/left/menu03_off.png"),
					imgSrcOn: require("@/assets/images/left/menu03_on.png"),
					url: this.config.contextRoot + "/data_dictionary",
				},
				{
					id: 4,
					name: "System Log",
					imgSrc: require("@/assets/images/left/menu04_off.png"),
					imgSrcOn: require("@/assets/images/left/menu04_on.png"),
					url: this.config.contextRoot + "/system_log",
				},
				{
					id: 5,
					name: "Setting",
					imgSrc: require("@/assets/images/left/menu05_off.png"),
					imgSrcOn: require("@/assets/images/left/menu05_on.png"),
					url: this.config.contextRoot + "/setting/site"
				}
			]
		};
	},
	methods: {
		movePage(url) {
			this.logger.debug(this, "movePage(url)");
			if(url != this.config.contextRoot + "/setting/site"){
				if (this.$route.path !== url) this.$router.push(url);
			}
			else{
				// let settingUrl = this.config.contextRoot + "/setting/" + 
				if (this.$route.path !== url){
					axios
						.post(this.config.contextRoot + "/access/selectSettingAccessUrl.do", {userId : sessionStorage.getItem("userId")})
						.then((response) => {
							console.log(response.data.url);
							if(response.data.url == 'false'){
								this.$alert("You are not authorized.","Warning","warning");
							}
							else{
								// this.$alert(window.location.href ,"Warning","warning");
								let url = this.config.contextRoot + "/setting/" + this.getProjectName(response.data.url);
								if (this.$route.path !== url)
								{
									EventBus.$emit("project-loading", true);
									// this.$router.push(url);
									location.href = this.config.contextRoot + "/setting" + this.getProjectName(response.data.url);
								}
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						})
				}
			}
		},
		getProjectName(obj){
			let url = "";

			if(obj == 'ACCESS'){
				url = "/site";
			}
			else if(obj == 'EMR COLUMN MANAGE ACCESS'){
				url = "/emr_column_manage"
			}
			else if(obj == 'EMR DATA MANAGE ACCESS'){
				url = "/emr_data_manage"
			}
			return url;
		},
		doLogout() {
			this.logger.debug(this, "doLogout()");
			if (confirm("Would you like to log out?"))
			{
				this.$Auth.logout(
					function(logoutResult) {
						if (logoutResult){
							this.$router.push(this.config.contextRoot+"/");
						}
						else
							this.logger.debug(this, "logoutResult:" + logoutResult);
					}.bind(this)
				);
			}
		}
	}
};
</script>

<style>
@import "../../assets/css/common/menu.css";
</style>