<template>
	<div>
		<p class="txt-list-title">Visit List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the visit name to search."
				@keyup="searchVisit($event.target.value)"
			/>
			<button
				v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')"
				type="button"
				class="btn-new"
				title="New Visit"
				@click="doCreate()"
			>
				<img src="@/assets/images/common/btn_new.png" alt="" />
				New Visit
			</button>
		</div>
		<table class="tb-list-vertical" style="table-layout: fixed;">
			<colgroup>
				<col width="5%" />
				<col width="13%" />
				<col width="auto" />
				<col width="9%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
				<col width="7%" />
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Visit Name</th>
					<th>Page Name(Page Label)</th>
					<th>Site Name</th>
					<th>Use</th>
					<th>UnSchedule</th>
					<th>Sort Order</th>
					<th>Delete</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(visit, index) in visitList" :key="index" tabindex="0" @click.stop="doEdit(visit)">
					<td>
						{{ index + 1}}
					</td>
					<td>
						{{ visit.visitName }}
					</td>
					<td class="ellipsis" :title="visit.pageName">
						{{ visit.pageName }}
					</td>
					<td>
						{{ visit.siteName }}
					</td>
					<td>
						{{ visit.useYn == "Y" ? 'Yes' : 'No' }}
					</td>
					<td >
						{{ visit.unscheduleYn == "Y" ? 'Yes' : 'No'}}
					</td>
					<td>
						{{ visit.visitSortOrder }}
					</td>
					<td>
						<button
							v-if="workAccessCheck('DELETE') && !(projectStateCd == '4' || projectStateCd == '3')"
							type="button"
							class="btn-remove"
							title="Remove Procedure"
							@click.stop="selectScreening(visit)"
						></button>
					</td>
				</tr>
				<tr v-if="visitList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered visit, Please register the visit.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "VisitList",
	components: {
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "VISIT",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.item.projectNo = this.$route.params.projectNo;
		this.selectVisitList();
		this.getAccessList('VISIT');
		this.getProjectStateCd();
	},
	data() {
		return {
			item: {},
			visitList: [],
			pagination: {},
			screeningList: [],
			testScreeningList: [],
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		selectVisitList() {
			this.logger.debug(this, "selectList()");
			this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/visit/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");

			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectVisitList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");

			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/visit/create";
			if (this.$route.path !== url) {
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(visit) {
			this.logger.debug(this, "doEdit(visit)");

			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/visit/edit/" + visit.visitNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		selectScreening(visit) {
			this.logger.debug(this, "selectScreening()");

			axios
				.post(this.config.contextRoot + "/visit/selectScreening.do", {visitNo: visit.visitNo})
				.then((response) => {
					console.log(response);
					this.screeningList = response.data.screeningList;
					this.testScreeningList = response.data.testScreeningList;

					if(this.screeningList.length > 0 || this.testScreeningList.length > 0)
					{
						this.$alert("I can't delete the data because there's a screening.", "Warn", "warning");
						return;
					}
					else if(this.screeningList.length == 0)
					{
						this.doDelete(visit);
					}
					// test entry 사용자일시
					// if(this.testScreeningList.length > 0)
					// {
					// 	this.$alert("I can't delete the data because there's a testScreening.", "Warn", "warning");
					// 	return;
					// }
					// else if(this.testScreeningList.length == 0)
					// {
					// 	this.doDelete(visit);
					// }
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doDelete(visit) {
			this.logger.debug(this, "doDelete(visit)");

			let msg = "Are you sure you want to delete the visit?";
			this.$confirm(msg, "Question", "question").then(() => {
				EventBus.$emit("project-loading", true);

				var param = {projectNo: this.item.projectNo, visitNo: visit.visitNo};
				axios
					.post(this.config.contextRoot + "/visit/delete.do", param)
					.then((response) => {
						console.log(response);
						location.reload();
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		searchVisit(value) {
			this.logger.debug(this, "searchVisit(value)");
			this.item.visitName = value;
			this.selectVisitList();
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>