<template>
	<div>
		<p class="txt-list-title">Freezing</p>
		<ul class="sub-menu">
			<li class="clicked" @click="moveMenu('/freezing')">Total List</li>
			<li @click="moveMenu('/freezing/each')">Each List</li>
		</ul>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the Data No to search."
			/>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="20%" />
				<col width="15%" />
				<col width="10%" />
				<col width="20%" />
				<col width="15%" />
				<col width="15%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						Data No
					</th>
					<th>
						visit
					</th>
					<th>
						page
					</th>
					<th>
						Procedure
					</th>
					<th>
						status
					</th>
					<th>
						Register
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="7" class="no-list">
						There is no registered Freezing, Please register the Freezing.
					</td>
				</tr>
			</tbody>
		</table>
		<!-- <div class="pagination-wrap">
			<pagination :data="pagination" :show-disabled="true" :limit="4" @pagination-change-page="doSearchPage">
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
        </div> -->
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "FreezingEachList",
	components: {
	},
	created() {
		this.logger.debug(this, "created()");

        this.$nextTick(function () {
			setTimeout(function() {
				EventBus.$emit("project-loading", false);
			}.bind(this), 500);
		});
	},
	data() {
		return {
            item: {},
			FreezingList: {},
            pagination: {}
		};
	},
	watch: {
		searchText: function(val) {
			this.item.pageName = val;
			this.item.pageNum = 1;
			//this.selectList();
		}
	},
	methods: {
		selectList() {
            this.logger.debug(this, "selectList()");
            this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/page/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
					this.pagination = response.data.pagination;

                    this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
        },	
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
	}
};
</script>