<template>
	<div ref="myPage" class="dialog-container">
		<div class="dialog" style="max-height: 480px; transform: translate(0, -240px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
					<p class="txt-crud-title">
						MyPage
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="15%" />
							<col width="85%" />
						</colgroup>
						<tbody>
							<tr>
								<th>User Id.</th>
								<td>{{ item.userId }}</td>
							</tr>
							<tr>
								<th>Site Name.</th>
								<td>{{ item.siteCodeName }}</td>
							</tr>
							<tr>
								<th>
									Name.
									<span class="fcolor-red">*</span>
								</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="userName"
										v-model="item.userName"
										v-validate="'required'"
										data-vv-as="User Name"
									/>
									<p class="txt-validation" v-if="errors.has('userName')">
										* {{ errors.first("userName") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>E-mail.</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="emailFront"
										v-model="item.emailFront"
										style="width: calc(50% - 16px);"
									/>
									<span style="margin: 0 10px;">@</span>
									<input
										type="text"
										class="input-crud"
										name="emailEnd"
										v-model="item.emailEnd"
										style="width: calc(50% - 16px);"
									/>
								</td>
							</tr>
							<tr>
								<th>Mobile Tel.</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="emailFront"
										v-model="item.phoneFront"
										maxlength="3"
										style="width: calc(33.3% - 16.3px);"
									/>
									<span style="margin: 0 10px;">-</span>
									<input
										type="text"
										class="input-crud"
										name="phoneCenter"
										v-model="item.phoneCenter"
										maxlength="4"
										style="width: calc(33.3% - 16.3px);"
									/>
									<span style="margin: 0 10px;">-</span>
									<input
										type="text"
										class="input-crud"
										name="phoneEnd"
										v-model="item.phoneEnd"
										maxlength="4"
										style="width: calc(33.3% - 16.3px);"
									/>
								</td>
							</tr>
							<tr v-if="!isChangePassword">
								<th>User Password.</th>
								<td>
									<button type="button" class="btn-table" title="Change" @click="doEditPassWord()">
										Change
									</button>
								</td>
							</tr>
							<tr v-if="isChangePassword">
								<th rowspan="3">User Password.</th>
								<td>
									<label class="label-ps">
										Current password. <span class="fcolor-red">*</span>
									</label>
									<input
										type="password"
										class="input-crud"
										name="password"
										v-model="item.password"
										v-validate="'required'"
										data-vv-as="Current Password"
										style="width: 80%;"
										@keyup="doCheckPassword()"
									/>
									<p class="txt-validation" v-if="errors.has('password')">
										* {{ errors.first("password") }}
									</p>
									<p class="txt-validation" v-if="isCurrentPassword">
										* It doesn't match your current password!
									</p>
								</td>
							</tr>
							<tr v-if="isChangePassword">
								<td>
									<label class="label-ps">
										Change password. <span class="fcolor-red">*</span>
									</label>
									<input
										ref="changePassword"
										type="password"
										class="input-crud"
										name="changePassword"
										v-model="item.changePassword"
										v-validate="'required'"
										data-vv-as="Change Password"
										style="width: 80%;"
									/>
									<p class="txt-validation" v-if="errors.has('changePassword')">
										* {{ errors.first("changePassword") }}
									</p>
								</td>
							</tr>
							<tr v-if="isChangePassword">
								<td>
									<label class="label-ps">
										Confirm password. <span class="fcolor-red">*</span>
									</label>
									<input
										type="password"
										class="input-crud"
										name="confirmPassword"
										v-model="item.confirmPassword"
										v-validate="'required|confirmed:changePassword'"
										data-vv-as="Confirm Password"
										style="width: 80%;"
									/>
									<p class="txt-validation" v-if="errors.has('confirmPassword')">
										* {{ errors.first("confirmPassword") }}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "MyPage",
	created() {
		this.logger.debug(this, "created()");
		this.doSelect();
	},
	data() {
		return {
			item: {},
			isChangePassword: false,
			isCurrentPassword: false
		};
	},
	methods: {
		doSelect() {
			this.logger.debug(this, "doSelect()");
			axios
				.post(this.config.contextRoot + "/user/myPage.do", {userId: sessionStorage.userId})
				.then((response) => {
					console.log(response);
					this.item = response.data.item;

					//Email Split
					this.item.emailFront = this.item.emailAddress.split("@")[0];
					this.item.emailEnd = this.item.emailAddress.split("@")[1];
					
					//MobileTelNo Split
					this.item.phoneFront = this.item.mobileTelno.split("-")[0];
					this.item.phoneCenter = this.item.mobileTelno.split("-")[1];
					this.item.phoneEnd = this.item.mobileTelno.split("-")[2];
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
			});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if(this.isChangePassword && this.isCurrentPassword)
					{
						return;
					}
					this.$confirm("Do you want to modify the user info?", "Question", "question").then(() => {
						this.doUpdate();
					});
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			// 패스워드가 Edit 모드였으면 선 Update
			if(this.isChangePassword && !this.isCurrentPassword)
			{
				this.item.password = this.item.changePassword;
				axios
					.post(this.config.contextRoot + "/user/updatePassWord.do", this.item)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
			// Mobile Phone과 Mail 데이터 병합
			this.item.mobileTelno = this.item.phoneFront + "-" + this.item.phoneCenter + "-" +this.item.phoneEnd;
			this.item.emailAddress = this.item.emailFront + "@" + this.item.emailEnd;
			// User Data 수정
			axios
				.post(this.config.contextRoot + "/user/update.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						this.doClose();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
			});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel user info editing?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.myPage.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.myPage.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("mypage-cancel");
			}.bind(this), 410);
		},
		doEditPassWord() {
			this.logger.debug(this, "doEditPassWord()");
			this.$refs.myPage.children[0].style.transition = "all 0.4s ease-in-out";
			this.$refs.myPage.children[0].style.maxHeight = "600px";
			this.$refs.myPage.children[0].style.transform = "translate(0, -300px)";
			setTimeout(function() {
				this.isChangePassword = true;
			}.bind(this), 410);
		},
		doCheckPassword() {
			this.logger.debug(this, "doCheckPassword()");
			axios
				.post(this.config.contextRoot + "/user/checkPassWord.do", this.item)
				.then((response) => {
					this.isCurrentPassword = !response.data.check;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		}
	}
};
</script>

<style scoped>
.label-ps {
	display: inline-block;
	width: 20%;
	font-weight: 600;
	color: #454545;
	text-align: center;
}
</style>