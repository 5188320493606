import { render, staticRenderFns } from "./ProcedureSort.vue?vue&type=template&id=26c72854&scoped=true&"
import script from "./ProcedureSort.vue?vue&type=script&lang=js&"
export * from "./ProcedureSort.vue?vue&type=script&lang=js&"
import style0 from "./ProcedureSort.vue?vue&type=style&index=0&id=26c72854&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26c72854",
  null
  
)

export default component.exports