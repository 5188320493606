<template>
	<div>
		<p class="txt-list-title">Auth List</p>
		<ul class="sub-menu">
			<li class="clicked" @click="moveMenu('/auth')">Auth</li>
			<li @click="moveMenu('/auth/privilege')">Privilege</li>
			<li @click="moveMenu('/auth/authPrivilege')">Auth Privilege</li>
		</ul>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the auth name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button
				type="button"
				class="btn-new"
				title="New Auth"
				@click="doCreate()"
			>
				<img src="@/assets/images/common/btn_new.png" alt="" />
				New Auth
			</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="20%" />
				<col width="20%" />
				<col width="25%" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Auth Code</th>
					<th>Name</th>
					<th>Etc</th>
					<th>Register</th>
					<th>Regist Date</th>
					<th>Remove</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(auth, index) in authList"
					:key="index"
					tabindex="0"
					@click.stop="doEdit(auth)"
				>
					<td>
						{{ auth.rowNumber }}
					</td>
					<td class="ellipsis underline" :title="auth.authCode">
						{{ auth.authCode }}
					</td>
					<td class="ellipsis underline" :title="auth.authName">
						{{ auth.authName }}
					</td>
					<td class="ellipsis" :title="auth.authEtc">
						{{ (auth.authEtc.length == 0 ? "-" : auth.authEtc) }}
					</td>
					<td>
						{{ auth.authRegistUserId }}
					</td>
					<td>
						{{ auth.authRegistDt }}
					</td>
					<td>
						<button
							v-if="auth.authNo != '1'"
							type="button"
							class="btn-remove"
							title="Remove Auth"
							@click.stop="doDelete(auth)"
						></button>
					</td>
				</tr>
				<tr v-if="authList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered auth, Please register the auth.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<AuthCreate v-if="isCreate" />
		<AuthEdit v-if="isEdit" :authNo="selectAuthNo" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import AuthCreate from "@/components/setting/auth/AuthCreate.vue";
import AuthEdit from "@/components/setting/auth/AuthEdit.vue";

export default {
	name: "AuthList",
	components: {
		AuthCreate,
		AuthEdit
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");

		this.selectAuthList();
	},
	data() {
		return {
			item: {},
			authList: [],
			pagination: {},
			searchText: "",
			isCreate: false,
			isEdit: false,
			selectAuthNo: "",
			isTyping: false,
		};
	},
	watch: {
		searchText: function(val) {
			this.item.authName = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectAuthList() {
			this.logger.debug(this, "selectAuthList()");
			axios
				.post(this.config.contextRoot + "/auth/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.authList = response.data.authList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");

			EventBus.$emit("setting-loading", true);
			let url = this.config.contextRoot + "/setting" + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectAuthList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		doEdit(auth) {
			this.logger.debug(this, "doEdit(agency)");
			if(auth.authNo != '1'){
				this.selectAuthNo = auth.authNo;
				this.isEdit = true;
			}
			else{
				this.$alert("Super admins cannot be edited.", "Warn", "warning");
			}
		},
		doDelete(auth) {
			this.logger.debug(this, "doDelete(agency)");
			this.$confirm("Are you sure you want to delete " + auth.authName +"?", "Question", "question").then(() => {
				var param = {authNo: auth.authNo};
				
				axios
					.post(this.config.contextRoot + "/auth/delete.do", param)
					.then((response) => {
						console.log(response);
						if(response.data.totCnt > 0)
						{
							this.$alert("The user with the auth exists and cannot be deleted.", "Warn", "warning");
						}
						else
						{
							EventBus.$emit("setting-loading", true);
							location.reload();
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("setting-loading", true);
				this.authList = new Array();
				this.selectAuthList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
	},
};
</script>
<style scoped>
.project-in-balloon {
    display: inline-block;
    position: absolute;
    top: 90px;
    left: 480px;
    width: 120px;
    height: 50px;
    animation: dropBalloon 0.3s ease-out both;
    z-index: 55;
}
</style>