<template>
	<div>
		<p class="txt-list-title">Role & Privilege List</p>
		<ul class="sub-menu">
			<li @click="moveMenu('/role')">Role</li>
			<li @click="moveMenu('/role/privilege')">Privilege</li>
			<li class="clicked" @click="moveMenu('/role/rolePrivilege')">Role Privilege</li>
		</ul>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the privilege category name to search."
				v-model="searchText"
			/>
			<button v-if="workAccessCheck('ROLE & PRIVILEGE SAVE')" type="button" class="btn-new" title="New Data" @click="doSave()">Save</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="15%" />
				<col width="15%" />
				<col v-for="roleItem in roleList" :key="roleItem.roleNo" :width="65/roleList.length + '%'"/>
			</colgroup>
			<thead>
				<tr>
					<th>No1</th>
					<th>Category</th>
					<th>Privilege</th>
					<th v-for="roleItem in roleList" :key="roleItem.projectRoleNo">{{ roleItem.roleName }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(privilegeItem, index) in privilegeList" :key="privilegeItem.projectPrivilegeNo">
					<td>{{ index + 1 }}</td>
					<td>
						{{ privilegeItem.privilegeCategory }}
					</td>
					<td>
						{{ privilegeItem.privilegeName }}
					</td>
					<td v-for="roleItem in roleList" :key="roleItem.projectRoleNo">
						<input
							type="checkbox"
							:checked="targetValue(roleItem, privilegeItem)"
							@change="setTargetValue(roleItem, privilegeItem)"
							:projectRoleNo="roleItem.projectRoleNo"
							:projectPrivilegeNo="privilegeItem.projectPrivilegeNo"
							:disabled="disableAccess(privilegeItem, roleItem)==true"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- <div class="pagination-wrap">
			<pagination :data="pagination" :show-disabled="true" :limit="4" @pagination-change-page="doSearchPage">
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
        </div> -->
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "RoleList",
	components: {},
	created() {
		this.logger.debug(this, "created()");
		this.selectList();
		this.getAccessList('ROLE');
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "ROLE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		EventBus.$emit('access-check', item);
	},
	data() {
		return {
			item: {},
			roleList: [],
			privilegeList: [],
			rolePrivilegeList: [],
			searchText: "",
			accessList: [],
		};
	},
	watch: {
		searchText: function(val) {
			this.item.privilegeCategory = val;
			this.item.pageNum = 1;
			this.selectList();
		}
	},
	computed: {},
	methods: {
		targetValue(roleItem, privilegeItem) {
			let result = false;

			this.rolePrivilegeList.forEach((obj) => {
				if (obj.projectRoleNo == roleItem.projectRoleNo && obj.projectPrivilegeNo == privilegeItem.projectPrivilegeNo) {
					result = true;
				}
			});

			return result;
		},
		setTargetValue(roleItem, privilegeItem) {
			this.logger.debug(this, "setTargetValue()");

			let result = false;
			let newPrivilegeList = [];
			let lastPrivilegeList = [];

			this.rolePrivilegeList.forEach((obj) => {
				if (obj.projectRoleNo == roleItem.projectRoleNo && obj.projectPrivilegeNo == privilegeItem.projectPrivilegeNo) {
					result = true;
				} else {
					newPrivilegeList.push(obj);
				}
			});

			if (!result) {
				newPrivilegeList.push({projectRoleNo: roleItem.projectRoleNo, projectPrivilegeNo: privilegeItem.projectPrivilegeNo});
				this.rolePrivilegeList = newPrivilegeList;
			}
			else{
				let getPrivilege = [];
				
				if(privilegeItem.privilegeName == 'ACCESS'){
					// 같은 category 찾기
					this.privilegeList.forEach((obj) => {
						if(obj.privilegeCategory == privilegeItem.privilegeCategory){
							getPrivilege.push(obj.projectPrivilegeNo);
						}
					});
				}

				for(let i = 0; i < newPrivilegeList.length; i++){
					if(newPrivilegeList[i].projectRoleNo == roleItem.projectRoleNo && getPrivilege.includes(newPrivilegeList[i].projectPrivilegeNo)){
						result = true;
					} else {
						lastPrivilegeList.push(newPrivilegeList[i]);
					}
				}
				this.rolePrivilegeList = lastPrivilegeList;
			}
		},
		selectList() {
			this.logger.debug(this, "selectList()");

			this.item.projectNo = this.$route.params.projectNo;

			axios.post(this.config.contextRoot + "/role/selectRolePrivilegeList.do", this.item).then((response) => {
				console.log(response);
				this.roleList = response.data.roleList;
				this.privilegeList = response.data.privilegeList;
				this.rolePrivilegeList = response.data.rolePrivilegeList;
			});

			this.$nextTick(function () {
				setTimeout(
					function () {
						EventBus.$emit("project-loading", false);
					}.bind(this),
					500
				);
			});
		},
		doSave() {
			this.logger.debug(this, "doSave()");

			let param = {
				projectNo: this.$route.params.projectNo,
				rolePrivilegeList: this.rolePrivilegeList,
			};

			this.$confirm("Do you want to save your Role Privileges?", "Question", "question").then(() => {
				axios
					.post(this.config.contextRoot + "/role/saveRolePrivilege.do", param)
					.then((response) => {
						if (response.status == 200) {
							console.log(response);
							this.$alert("It's saved.", "Info", "info").then(() => {
								location.reload();
							});
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");

			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		disableAccess(privilegeItem, roleItem){
			let getAcsPrivilege = "";
			
			// access 이면서 같은 category 찾기
			if(privilegeItem.privilegeName != 'ACCESS'){
				this.privilegeList.forEach((obj) => {
					if(obj.privilegeCategory == privilegeItem.privilegeCategory && obj.privilegeName == 'ACCESS'){
						getAcsPrivilege = obj;
					}
				});
			}

			let disableBool = true;
			
			if(this.targetValue(roleItem, getAcsPrivilege)){
				disableBool = false;
			}

			if(privilegeItem.privilegeName == 'ACCESS'){
				disableBool = false;
			}

			if(roleItem.roleName == 'ADMIN'){
				disableBool = true;
			}

			return disableBool;
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	},
};
</script>

<style></style>
