<template>
	<div v-if="isOpen" class="no-drag" style="padding-bottom: 50px;">
		<ul class="visit-list2" tabindex="0" @blur="blurDropList()">
			<li class="visit-item2 visit-default2">Visit</li>
			<li
				v-for="(visit, visitIndex) in validVisitList"
				:key="visitIndex"
				class="visit-item2"
				:class="endDepth == 'VISIT' ? 'no-after' : ''"
				:title="visit.visitName"
				@click.stop="enterVisitItem(visit, $event)"
			>
				{{ visit.visitName }}
				<ul
					v-if="selectVisitNo == visit.visitNo && endDepth != 'VISIT'"
					class="page-list"
				>
					<li class="page-item page-default" style="pointer-events: none;">Page</li>
					<li
						v-for="(page, pageIndex) in visit.projectDictionaryList"
						:key="pageIndex"
						class="page-item"
						:class="endDepth == 'PAGE' ? 'no-after' : ''"
						:title="page.pageName"
						@click.stop="enterPageItem(visit, page, $event)"
					>
						{{ page.pageName }}
						<ul
							v-if="selectPageNo == page.pageNo && endDepth != 'PAGE'"
							class="proc-list"
						>
							<li class="proc-item proc-default" style="pointer-events: none;">Procedure</li>
							<li
								v-for="(proc, procIndex) in page.procedureList"
								:key="procIndex"
								class="proc-item"
								:class="endDepth == 'PROC' ? 'no-after' : ''"
								:title="proc.procName"
								@click.stop="enterProcItem(visit, page, proc, $event)"
							>
								{{ proc.procName }}
								<ul
									v-if="selectProcNo == proc.procNo && endDepth != 'PROC'"
									class="question-list"
								>
									<li class="question-item question-default" style="pointer-events: none;">Field Name | Label | Field Type | Data Type</li>
									<li
										v-for="(question, questionIndex) in proc.procQuestionList"
										:key="questionIndex"
										class="question-item"
										:class="!['7','8', '9', '11', '12', '13'].includes(question.fieldList[0].dataTypeCd) || endDepth == 'QUESTION' ? 'no-after' : ''"
										:title="question.questionFieldName + ' | ' + question.questionName + ' | ' + getFieldTypeName(question.fieldList[0].fieldTypeCd) + ' | ' + dataTypeName(question.fieldList[0].dataTypeCd) + isRepeatName(question.isRepeat)"
										@click.stop="!['7','8', '9', '11', '12', '13'].includes(question.fieldList[0].dataTypeCd) ? setValue(visit, page, proc, question, question.fieldList[0]) : enterQuestionItem(visit, page, proc, question, $event)"
									>
										{{ question.questionFieldName }} | {{question.questionName}} | {{ getFieldTypeName(question.fieldList[0].fieldTypeCd) }} | {{dataTypeName(question.fieldList[0].dataTypeCd) + isRepeatName(question.isRepeat)}}

										<ul
											v-if="selectProcQuestionNo == question.procQuestionNo && endDepth != 'QUESTION'"
											class="field-list"
										>
											<li class="field-item field-default" style="pointer-events: none;">List Type</li>
											<li
												v-for="(listType, listTypeIndex) in question.listTypeList"
												:key="listTypeIndex"
												class="field-item"
												@click.stop="setValue(visit, page, proc, listType, listType.fieldList[0], question.listTypeList)"
											>
											{{ listType.questionFieldName }} | {{listType.questionName}} | {{listType.listIndex}}
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from '../../../script/EventBus';
export default {
	name: "DropList",
	created() {
		this.selectComCodeList();
	},
	data() {
		return {
			codeList: {},
			isOpen: true,
			selectVisitNo: "",
			selectPageNo: "",
			selectProcNo: "",
			selectProcQuestionNo: "",
			valueText: "Please select the field",
		};
	},
	props: {
		value: {
			type: Object
		},
		validVisitList: {
			type: Array,
			required: true
		},
		// pageList: {
		// 	type: Array,
		// 	required: true
		// },
		fieldTypeList: {
			type: Array,
			required: true
		},
		endDepth: {
			type: String,
			default: "FIELD"
		},
	},
	methods: {
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["DATA_TYPE_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		blurDropList() {
			this.isOpen = false;
			EventBus.$emit("blurDropList");
		},
		enterVisitItem(visit, event) {
			if(this.endDepth != "VISIT")
			{
				this.selectVisitNo = visit.visitNo;
				this.$nextTick(() => {
					if(event.target.nodeName == "LI")
					{
						let right = event.target.children[0].offsetWidth;
						event.target.children[0].style.right = (-right) + "px";
					}
				});
			}
			else
			{
				let setValue = {};

				setValue.visitNo = visit.visitNo;
				setValue.valueText = visit.visitName;

				this.$emit("input", setValue);
				this.blurDropList();
			}
		},
		enterPageItem(visit, page, event) {
			if(this.endDepth != "PAGE")
			{
				this.selectPageNo = page.pageNo;
				this.$nextTick(() => {
					if(event.target.nodeName == "LI")
					{
						let right = event.target.children[0].offsetWidth;
						event.target.children[0].style.right = (-right) + "px";
					}
				});
			}
			else
			{
				let setValue = {};

				setValue.visitNo = visit.visitNo;
				setValue.pageNo = page.pageNo;
				setValue.valueText = visit.visitName + " > " + page.pageName;

				this.$emit("input", setValue);
				this.blurDropList();
			}
		},
		enterProcItem(visit, page, proc, event) {
			if(this.endDepth != "PROC")
			{
				this.selectProcNo = proc.procNo;
				this.$nextTick(() => {
					if(event.target.nodeName == "LI")
					{
						let right = event.target.children[0].offsetWidth;
						event.target.children[0].style.right = (-right) + "px";
					}
				});
			}
			else
			{
				let setValue = {};

				setValue.visitNo = visit.visitNo;
				setValue.pageNo = page.pageNo;
				setValue.procNo = proc.procNo;
				setValue.valueText = visit.visitName + " > " + page.pageName + " > " + proc.procName;

				this.$emit("input", setValue);
				this.blurDropList();
			}
		},
		enterQuestionItem(visit, page, proc, question, event) {
			if(this.endDepth != "QUESTION")
			{
				this.selectProcQuestionNo = question.procQuestionNo;
				this.$nextTick(() => {
					if(event.target.nodeName == "LI")
					{
						let right = event.target.children[0].offsetWidth;
						event.target.children[0].style.right = (-right) + "px";
					}
				});
			}
			else
			{
				let setValue = {};

				setValue.visitNo = visit.visitNo;
				setValue.pageNo = page.pageNo;
				setValue.procNo = proc.procNo;
				setValue.procQuestionNo = question.procQuestionNo;
				setValue.valueText = visit.visitName + " > " + page.pageName + " > " + proc.procName + " > " + question.questionFieldName + " | " + question.questionName + " | " +this.getFieldTypeName(question.fieldList[0].fieldTypeCd)+ " | " + this.dataTypeName(question.fieldList[0].dataTypeCd);

				this.$emit("input", setValue);
				this.blurDropList();
			}
		},
		getFieldTypeName(comCode) {
			let comCodeList = this.fieldTypeList.map((item) => {
				return item.comCode;
			});
			let getIndex = comCodeList.indexOf(comCode);

			return this.fieldTypeList[getIndex].comCodeName;
		},
		dataTypeName(dataTypeCd){
			let dataTypeName = "";
			this.codeList.forEach((element) => {
				if(dataTypeCd == element.comCode){
					dataTypeName = element.comCodeName;
				}
			});
			return dataTypeName;
		},
		isRepeatName(isRepeat){
			let result = "";
			if(isRepeat == 'true'){
				result = " | Repeat"
			}

			return result;
		},
		setValue(visit, page, proc, question, field, listTypeList) {
			let setValue = {};

			setValue.isRepeat = question.isRepeat;
			setValue.visitNo = visit.visitNo;
			setValue.pageNo = page.pageNo;
			setValue.procNo = proc.procNo;
			setValue.procQuestionNo = question.procQuestionNo;
			setValue.procFieldNo = field.procFieldNo;
			setValue.listFirstProcQuestionNo = "";
			setValue.listFirstProcFieldNo = "";
			setValue.fieldChildName = field.fieldChildName;
			setValue.dateFormat = field.dateFormat;
			setValue.valueText = visit.visitName + " > " + page.pageName + " > " + proc.procName + " > " + question.questionFieldName + " | " + question.questionName + " | " +this.getFieldTypeName(field.fieldTypeCd)+ " | " + this.dataTypeName(field.dataTypeCd);
			
			if(['7','8', '9', '11', '12', '13'].includes(field.dataTypeCd)){
				setValue.valueText += " | " + question.listIndex;
				setValue.listFirstProcQuestionNo = listTypeList[0].fieldList[0].procQuestionNo;
				setValue.listFirstProcFieldNo = listTypeList[0].fieldList[0].procFieldNo;
			}

			if(question.isRepeat == 'true'){
				setValue.valueText += " | Repeat";
			}

			if(this.endDepth == "FIELD")
			{
				let copyList = [];
				// let selectVisitIndex = 0; // eslint-disable-line no-unused-vars
				// let visitDeleteIndex = [];
				// let pageDeleteIndex = [];
				copyList = (JSON.parse(JSON.stringify(this.validVisitList)));

				// for(let i = 0; i < copyList.length; i++)
				// { 
				// 	if(copyList[i].visitSortOrder < visit.visitSortOrder)
				// 	{
				// 		visitDeleteIndex.push(i);
				// 	}
				// 	if(copyList[i].visitSortOrder === visit.visitSortOrder)
				// 	{
				// 		selectVisitIndex = i;
				// 	}
				// }

				// for(let i = 0; i < copyList.length; i++)
				// {
				// 	for(let j = 0; j < copyList[i].projectDictionaryList.length; j++)
				// 	{
				// 		if(copyList[i].visitSortOrder === visit.visitSortOrder && copyList[i].projectDictionaryList[j].pageSortOrder < page.pageSortOrder)
				// 		{
				// 			pageDeleteIndex.push(j);
				// 		}
				// 	}
				// }

				// if(pageDeleteIndex.length != 0)
				// {
				// 	copyList[selectVisitIndex].projectDictionaryList.splice(0, pageDeleteIndex.length);
				// }

				// if(visitDeleteIndex.length != 0)
				// {
				// 	copyList.splice(0, visitDeleteIndex.length);
				// }

				setValue.visitSortList = copyList;
			}

			this.$emit("input", setValue);
			this.blurDropList();
		}
	}
};
</script>

<style scoped>

.drop-list {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.drop-list::after {
	content: ">";
	display: inline-block;
	position: absolute;
	top: 0;
	right: 10px;
	font-weight: 600;
	font-family: cursive;
	transform: rotate(90deg);
}

/* .page-list {
	display: inline-block;
	position: relative;
	left: 0;
	bottom: 0;
	border: 1px solid #ccc;
	border-top: none;
	background-color: #fff;
	z-index: 50;
} */
.visit-list2 {
	display: inline-block;
	position: relative;
	left: 0;
	bottom: 0;
	border: 1px solid #ccc;
	border-top: none;
	background-color: #fff;
	z-index: 50;
}

/* .page-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	padding: 3px 10px;
	font-size: 10pt;
	color: #999;
	white-space: nowrap;
} */

.visit-item2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	padding: 3px 10px;
	font-size: 10pt;
	color: #999;
	white-space: nowrap;
}

/* .page-list > .page-item:last-child {
	border-bottom: none;
}

.page-list > .page-item:first-child::after {
	content: "";
} */
.visit-list2 > .visit-item2:last-child {
	border-bottom: none;
}

.visit-list2 > .visit-item2:first-child::after {
	content: "";
}

/* .page-item:hover {
	background-color: #1E90FF;
	color: #fff;
}

.page-item::after {
	content: ">";
	margin: 0 0 0 30px;
	font-family: cursive;
	font-size: 11pt;
} */
.visit-item2:hover {
	background-color: #1E90FF;
	color: #fff;
}

.visit-item2::after {
	content: ">";
	margin: 0 0 0 30px;
	font-family: cursive;
	font-size: 11pt;
}

.visit-default2 {
	background-color: #999;
	color: #fff;
}

.visit-default2:hover {
	background-color: #999;
	color: #fff;
}
/* .page-default {
	background-color: #999;
	color: #fff;
}

.page-default:hover {
	background-color: #999;
	color: #fff;
} */

.page-list,
.proc-list,
.question-list,
.field-list {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	border: 1px solid #ccc;
	background-color: #fff;
}

.page-item,
.proc-item,
.question-item,
.field-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	padding: 3px 10px;
	font-size: 10pt;
	color: #999;
	white-space: nowrap;
}

.page-list > .page-item:last-child,
.proc-list > .proc-item:last-child,
.question-list > .question-item:last-child
.field-list > .field-item:last-child {
	border-bottom: none;
}

.page-list > .page-item:first-child::after,
.proc-list > .proc-item:first-child::after,
.question-list > .question-item:first-child::after {
	content: "";
}

.page-item:hover,
.proc-item:hover,
.question-item:hover,
.field-item:hover {
	background-color: #1E90FF;
	color: #fff;
}

.page-item::after,
.proc-item::after,
.question-item::after{
	content: ">";
	margin: 0 0 0 30px;
	font-family: cursive;
	font-size: 11pt;
}

.page-default,
.proc-default,
.question-default,
.field-default {
	background-color: #999;
	color: #fff;
}

.page-default:hover,
.proc-default:hover,
.question-default:hover,
.field-default:hover {
	background-color: #999;
	color: #fff;
}

.field-item {
	padding-right: 30px;
}

.no-after::after {
	content: "" !important;
}
</style>