<template>
	<div class="popup-container" :style="[getSize(), drag.position]">
		<div
			class="popup-top-wrap"
			@dragstart="dragstart($event)"
			@drag="drag.isDrag ? dragging($event) : ''"
			@dragend="drag.isDrag ? draggend() : ''"
			draggable
		>
			<span>
				{{ popupData.title }}
			</span>
			<button type="button" @click="close()"></button>
		</div>
		<div
			class="popup-center-wrap thin-grey-scroll"
			:class="popupData.contentsType == 'previewpdf' ? 'preview-container-background' : ''"
		>

		<img :src="imageBinary" >

		</div>
		<div class="popup-bottom-wrap">
			<button
				type="button"
				v-for="(item, index) in popupData.button"
				:key="index"
				:style="{width: 100 / popupData.button.length + '%'}"
				@click.prevent="btnClick(popupData.buttonAction[index])"
			>
				{{ item }}
			</button>
		</div>

	</div>
</template>
<script>

import EventBus from "@/script/EventBus.js";
import axios from "axios";

export default {
	name: "PopUp",
	props: {
		popupData: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.logger.debug(this, "created()");
		this.returnPopupStyle();
		const item = this.popupData.parameter.dataInputItem;
		const fileGroupNo = item.fileGroupNo;
		const fileNo = item.fileNo;
		const serverFileSaveName = item.serverFileSaveName;

		axios
			.get(this.config.contextRoot + "/common/file/printImage.do", {
				responseType: 'arraybuffer' ,
				params: {
					fileGroupNo,
					fileNo,
					serverFileSaveName,
				}
			})
			.then((response) => {
				//console.log(response)
				var prefix = "data:" + response.headers["content-type"] + ";base64,";
				var img = Buffer(response.data, 'binary').toString('base64');
				this.imageBinary = prefix + img;
			})
			.catch((error) => {
				this.exceptionHandler(this, error);
			});
	},
	components: {
		
	},
	data() {
		return {
			drag: {
				isDrag: false,
				position: {
					top: "",
					left: "",
					cursor: "",
				},
			},
			imageBinary : ""
		};
	},
	computed: {},
	methods: {
		close() {
			this.logger.debug(this, "close()");
			EventBus.$emit("closeImagePopUp");
		},
		dragstart(event) {
			this.drag.offsetLeft = event.offsetX;
			this.drag.offsetTop = event.offsetY;
			this.drag.isDrag = true;
		},
		dragging(event) {
			var top = event.clientY - this.drag.offsetTop;
			var left = event.clientX - this.drag.offsetLeft;
			if (top > 0 && left > 0) {
				this.drag.position.top = top + "px";
				this.drag.position.left = left + "px";
				this.drag.position.cursor = "grab";
			}
		},
		draggend() {
			this.drag.isDrag = false;
			this.drag.position.cursor = "unset";
		},
		returnPopupStyle() {
			var widthValue = this.popupData.width == undefined ? "980px" : this.popupData.width + "px";
			var heightValue = this.popupData.height == undefined ? "680px" : this.popupData.height + "px";
			var topValue =
				this.popupData.top == undefined
					? "calc(50% - " + heightValue.replace("px", "") / 2 + "px)"
					: this.popupData.top + "%";
			var leftValue =
				this.popupData.left == undefined
					? "calc(50% - " + widthValue.replace("px", "") / 2 + "px)"
					: this.popupData.left + "%";

			this.drag.position.top = topValue;
			this.drag.position.left = leftValue;
		},
		getSize() {
			var returnMap = {};

			var widthValue = this.popupData.width == undefined ? "980px" : this.popupData.width + "px";
			var heightValue = this.popupData.height == undefined ? "680px" : this.popupData.height + "px";
			returnMap.width = widthValue;
			returnMap.height = heightValue;

			return returnMap;
		},
	},
};
</script>
