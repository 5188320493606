<template>
	<div>
		<p class="txt-list-title">Page List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the page name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>

			<div class="btn-wrap">
				<button
					v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')"
					type="button"
					class="btn-new"
					title="New Page"
					@click="doCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New Page
				</button>
				<button
					v-if="workAccessCheck('SORT CHANGE') && !(projectStateCd == '4' || projectStateCd == '3')"
					type="button"
					class="btn-grey"
					title="Sort"
					@click="procedureSort()"
				>
					Procedure Sort
				</button>
			</div>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="8%" />
				<col width="10%" />
				<col width="auto" />
				<col width="10%" />
				<col width="8%" />
				<col width="8%" />
				<col width="8%" />
				<col width="20%" />
			</colgroup>
			<thead>
				<tr class="no-drag">
					<th>
					</th>
					<th>
						Type
					</th>
					<th>
						Name
					</th>
					<th>
						Label
					</th>
					<th>
						Count
					</th>
					<th>
						Sort Order
					</th>
					<th>
						Register
					</th>
					<th>
						Regist Date
					</th>
					<th>
						Function
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(page, pageIndex) in pageList">
					<tr 
						:key="pageIndex"
						class="group-col no-drag"
						:class="(openPageList.indexOf(page.pageNo) >= 0 ? 'group-col-on' : '')"
						tabindex="0"
						@click.stop="openPage(page)"
					>
						<td style="text-align: left;">
							<i
								class="fold"
								:class="(openPageList.indexOf(page.pageNo) >= 0 ? 'fold-on' : '')"
							/>
						</td>
						<td style="font-weight: 600;">
							Page
						</td>
						<td class="ellipsis" :title="page.pageName">
							{{ page.pageName }}
						</td>
						<td class="ellipsis" :title="page.pageLabel">
							{{ page.pageLabel }}
						</td>
						<td>
							{{ page.procedureList.length }} Procedure
						</td>
						<td>
							{{ page.pageSortOrder }}
						</td>
						<td>
							{{ page.firstRegistUserId }}
						</td>
						<td>
							{{ page.firstRegistDate.substring(0, 10) }}
						</td>
						<td>
							<div>
								<button
									v-if="workAccessCheck('EDIT') && !(projectStateCd == '4' || projectStateCd == '3')"
									class="btn-table"
									title="Edit Group"
									style="margin-right: 10px;"
									@click.stop="doEdit(page)"
								>
									Edit
								</button>
								<button
									v-if="workAccessCheck('DELETE') && !(projectStateCd == '4' || projectStateCd == '3')"
									type="button"
									class="btn-table"
									title="Remove Field"
									style="margin-right: 10px;"
									@click.stop="selectProcedure(page)"
								>
									Remove
								</button>
								<button
									v-if="workAccessCheck('PROCEDURE CREATE') && !(projectStateCd == '4' || projectStateCd == '3')"
									class="btn-table"
									title="Add Field"
									@click.stop="doProcCreate(page)"
								>
									+ Add Proc
								</button>
							</div>
						</td>
					</tr>
					<template v-for="(procedure, procedureIndex) in page.procedureList">
						<tr
							v-if="openPageList.indexOf(procedure.pageNo) >= 0"
							:key="500 + procedure.procedureNo"
							class="question-col no-drag"
							tabindex="0"
							@click.stop="doProcedureEdit(procedure)"
						>
							<td>
								{{ procedureIndex + 1 }}
							</td>
							<td style="font-weight: 600;">
								Procedure
							</td>
							<td class="ellipsis" :title="procedure.procName">
								{{ procedure.procName }}
							</td>
							<td class="ellipsis" :title="procedure.procLabel">
								{{ procedure.procLabel }}
							</td>
							<td>
								{{ procedure.fieldCount }} Field
							</td>
							<td>
								{{ procedure.procSortOrder }}
							</td>
							<td>
								{{ procedure.firstRegistUserId }}
							</td>
							<td>
								{{ procedure.firstRegistDate.substring(0, 10) }}
							</td>
							<td>
								<button
									v-if="workAccessCheck('PROCEDURE DELETE') && !(projectStateCd == '4' || projectStateCd == '3')"
									type="button"
									class="btn-remove"
									title="Remove Field"
									@click.stop="doValidation(procedure)"
								></button>
							</td>
						</tr>
					</template>
					<tr
						v-if="page.procedureList.length == 0 && openPageList.indexOf(page.pageNo) >= 0"
						:key="pageIndex + 100"
					>
						<td colspan="9" class="no-list">
							There is no registered procedure, Please register the procedure.
						</td>
					</tr>
				</template>
				<tr v-if="pageList.length == 0">
					<td colspan="9" class="no-list">
						There is no registered page, Please register the page.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination :data="pagination" :show-disabled="true" :limit="4" @pagination-change-page="doSearchPage">
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<PageCreate v-if="isCreate" />
		<PageEdit v-if="isEdit" :pageNo="pageNo"/>
		<ProcedureSort v-if="isProcedureSort" :projectNo="items.projectNo" />
		<ProcedureCopy v-if="isProcedureCopy" :projectNo="items.projectNo" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import PageCreate from "@/components/project/page/PageCreate.vue";
import PageEdit from "@/components/project/page/PageEdit.vue";
import ProcedureSort from "@/components/project/procedure/ProcedureSort.vue"
import ProcedureCopy from "@/components/project/procedure/ProcedureCopy.vue"

export default {
	name: "PageList",
	components: {
		PageCreate,
		PageEdit,
		ProcedureSort,
		ProcedureCopy
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "PAGE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.selectList();
		// this.selectProcedureList();
		this.getAccessList('PAGE');
		this.getProjectStateCd();
	},
	mounted(){
		this.$nextTick(function () {
			if(this.$parent.current_page != "" && this.$parent.current_page != undefined){
				this.doSearchPage(this.$parent.current_page);
			}
		});
	},
	data() {
		return {
			item: {},
			items: {},
			pagination: {},
			isCreate: false,
			isEdit: false,
			searchText: "",
			pageList: [],
			procedureList: [],
			openPageList: [],
			pageNo: "",
			procCount: "",
			searchTextVal:"",
			isProcedureSort: false,
			isProcedurePDF: false,
			isProcedureCopy: false,
			pdfParam: {},
			isTyping: false,
			accessList: [],
			projectStateCd: ""
		};
	},
	watch: {
		searchText: function(val) {
			// this.item.pageName = val;
			this.item.searchText = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectList() {
			this.logger.debug(this, "selectList()");
			this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/page/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
					this.pagination = response.data.pagination;

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// selectProcedureList() {
		// 	this.logger.debug(this, "selectProcedureList()");
		// 	axios
		// 		.post(this.config.contextRoot + "/procedure/selectList.do", this.items)
		// 		.then((response) => {
		// 			console.log(response);
		// 			this.procedureList = response.data.procedureList;

		// 			this.$nextTick(function () {
		// 				setTimeout(function() {
		// 					EventBus.$emit("project-loading", false);
		// 					for(let j = 0; j < this.pageList.length; j++){
		// 						let procedureList = [];
		// 						for(let i = 0; i < this.procedureList.length; i++){
		// 							if(this.pageList[j].pageNo == this.procedureList[i].pageNo){
		// 								procedureList.push(this.procedureList[i]);
		// 							}
		// 						}
		// 						this.$set(this.pageList[j], "procedureList", procedureList);
		// 					}
		// 				}.bind(this), 100);
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		});
		// },
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		doProcCreate(page) {
			this.logger.debug(this, "doProcCreate(page)");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/procedure/create/" + page.pageNo + "?current_page=" + this.pagination.current_page;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
				console.log(page.pageNo);
			}
		},
		doEdit(page) {
			this.logger.debug(this, "doEdit(page)");
			this.pageNo = page.pageNo;
			
			this.isEdit = true;
		},
		doProcedureEdit(procedure) {
			this.logger.debug(this, "doProcedureEdit(procedure)");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/procedure/edit/" + procedure.procNo + "?current_page=" + this.pagination.current_page;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		selectProcedure(page){
			this.logger.debug(this, "selectProcedure(page)");
			
			axios
				.post(this.config.contextRoot + "/page/selectProcedure.do", {pageNo: page.pageNo})
				.then((response) => {
					console.log(response);
					this.procCount = response.data.procCount;

					if(this.procCount > '0')
					{
						this.$alert("I can't delete the data because there's a procedure.", "Warn", "warning");
						return;	
					}
					else if(this.procCount == '0')
					{
						this.doDelete(page);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
		},
		doDelete(page) {
			this.logger.debug(this, "doDelete(page)");
			
			this.$confirm("Are you sure you want to delete this page?", "Question", "question").then(() => {
				axios
					.post(this.config.contextRoot + "/page/delete.do", {pageNo: page.pageNo})
					.then((response) => {
						console.log(response);
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.selectList();
						});
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.selectList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		procedureSort() {
			this.logger.debug(this, "procedureSort()");
			this.isProcedureSort = true;
		},
		openPage(page) {
			this.logger.debug(this, "openPage(page)");
			let pageNo = page.pageNo;
			let index = this.openPageList.indexOf(pageNo);

			if(index >= 0) this.openPageList.splice(index, 1);
			else this.openPageList.push(pageNo);
		},
		doValidation(item) {
			this.logger.debug(this, "doValidation()");
			this.checkConditions(item).then((message) => {
				if (message) {
					this.$alert(message, "Warning", "warning");
				} else {
					this.doProcDelete(item);
				}
			});
		},
		async checkConditions(item) {
			const response1 = await axios.post(this.config.contextRoot + "/procedure/checkInsertScreening.do", { pageNo: item.pageNo });
			const response2 = await axios.post(this.config.contextRoot + "/procedure/checkInsertTestScreening.do", { pageNo: item.pageNo });
			const response3 = await axios.post(this.config.contextRoot + "/procedure/checkInsertCalculation.do", { pageNo: item.pageNo });
			const response4 = await axios.post(this.config.contextRoot + "/procedure/checkInsertValidation.do", { pageNo: item.pageNo });
			if (Number(response4.data.count) > 0) {
				return "The procedure cannot be deleted\nfor which validations have already been registered.";
			} else if (Number(response3.data.count) > 0) {
				return "The procedure cannot be deleted\nfor which calculations have already been registered.";
			} else if (Number(response2.data.count) > 0) {
				return "The procedure cannot be deleted\nfor which data has already been entered.";
			} else if (Number(response1.data.count) > 0) {
				return "The procedure cannot be deleted\nfor which data has already been entered.";
			} else {
				return null;
			}
		},
		// doValidation(item) {
		// 	this.logger.debug(this, "doValidation()");
		// 	this.isSavedVisit(item).then((isSavedVisit) => {
		// 		if(isSavedVisit)
		// 		{
		// 			this.$alert("The procedure cannot be deleted\n for which visits have already been registered.","Warning","warning")
		// 			return false;
		// 		}
		// 		else{
		// 			this.isSavedValid(item).then((isSavedValid) => {
		// 				if(isSavedValid)
		// 				{
		// 					this.$alert("The procedure cannot be deleted\n for which validations have already been registered.","Warning","warning");
		// 					return false;
		// 				}
		// 				else{
		// 					this.isSavedCalc(item).then((isSavedCalc) => {
		// 						if(isSavedCalc)
		// 						{
		// 							this.$alert("The procedure cannot be deleted\n for which calculations have already been registered.","Warning","warning");
		// 							return false;
		// 						}
		// 						else{
		// 							this.isUserTestInput(item).then((isUserTestInput) => {
		// 								if(isUserTestInput)
		// 								{
		// 									this.$alert("The procedure cannot be deleted\n for which data has already been entered.","Warning","warning");
		// 									return false;
		// 								}
		// 								else{
		// 									this.isUserInput(item).then((isUserInput) => {
		// 										if(isUserInput)
		// 										{
		// 											this.$alert("The procedure cannot be deleted\n for which data has already been entered.","Warning","warning");
		// 											return false;
		// 										}
		// 										else
		// 										{
		// 											this.doProcDelete(item);
		// 										}
		// 									});
		// 								}
		// 							});
		// 						}
		// 					});
		// 				}
		// 			})
		// 		}
		// 	});
		// },
		// async isUserInput(item) {
		// 	this.logger.debug(this, "isUserInput()");
		// 	const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertScreening.do", {pageNo: item.pageNo})
		// 	if(Number(response.data.count > 0))
		// 	{
		// 		return true;
		// 	}
		// 	else
		// 	{
		// 		return false;
		// 	}
		// },
		// async isUserTestInput(item) {
		// 	this.logger.debug(this, "isUserInput()");
		// 	const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertTestScreening.do", {pageNo: item.pageNo})
		// 	if(Number(response.data.count > 0))
		// 	{
		// 		return true;
		// 	}
		// 	else
		// 	{
		// 		return false;
		// 	}
		// },
		// async isSavedCalc(item) {
		// 	this.logger.debug(this, "isCalcExists()");
		// 	const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertCalculation.do", {pageNo: item.pageNo})
		// 	if(Number(response.data.count > 0))
		// 	{
		// 		return true;
		// 	}
		// 	else
		// 	{
		// 		return false;
		// 	}
		// },
		// async isSavedValid(item) {
		// 	this.logger.debug(this, "isValidExists()");
		// 	const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertValidation.do", {pageNo: item.pageNo})
		// 	if(Number(response.data.count > 0))
		// 	{
		// 		return true;
		// 	}
		// 	else
		// 	{
		// 		return false;
		// 	}
		// },
		// async isSavedVisit(item) {
		// 	this.logger.debug(this, "isVisitExists()");
		// 	const response = await axios.post(this.config.contextRoot + "/procedure/selectVisit.do", {pageNo: item.pageNo})
		// 	if(Number(response.data.count > 0))
		// 	{
		// 		return true;
		// 	}
		// 	else
		// 	{
		// 		return false;
		// 	}
		// },
		// selectVisit(item) {
		// 	this.logger.debug(this, "selectVisit()");

		// 	axios
		// 		.post(this.config.contextRoot + "/procedure/selectVisit.do", {pageNo: item.pageNo})
		// 		.then((response) => {
		// 			console.log(response);
		// 			this.visitList = response.data.visitList;

		// 			if(this.visitList.length > 0)
		// 			{
		// 				this.$alert("I can't delete the data\nbecause there's a visit or calculation.", "Warn", "warning");
		// 				return;
		// 			}
		// 			else if(this.visitList.length == 0)
		// 			{
		// 				this.doProcDelete(item);
		// 			}

		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		});
		// },
		doProcDelete(procedure) {
			this.logger.debug(this, "doDelete(notice)");
			this.$confirm("Are you sure you want to delete the procedure?","Question","question").then(() => {
				axios
					.post(this.config.contextRoot + "/procedure/delete.do", {procNo: procedure.procNo})
					.then((response) => {
						console.log(response);
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.selectList();
						});
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
    align-items: center;
	justify-content: center;
	min-width: 120px;
    height: 35px;
    margin: 0 0 10px 10px;
    padding: 0 25px;
    border-radius: 5px;
	border: 1px solid #dee2e6;
    background-color: #eee;
    font-weight: 600;
    font-size: 10.5pt;
    color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
    background-color: #fff;
    color: #2bade0;
}

.btn-wrap {
	display: inline-block;
	position: relative;
	float: right;
}


.tb-list-vertical thead th {
	background-color: #eee;
}


.group-col:hover td,
.group-col:focus td {
	background-color: #f6f6f6;
}

.group-col td {
	background-color: #f6f6f6;
	font-weight: 400;
	font-size: 10.5pt;
	color: #999;
	cursor: initial !important;
}

.group-col-on td {
	font-weight: 600;
	color: #2bade0;
}

.question-col > td {
	height: 45px;
	color: #999;
}

.question-col:hover > td,
.question-col:focus > td {
	background-color: #fff;
	color: #666;
	font-weight: 600;
}
.project-list-select{
	position: absolute;
	right: 0;
	top: 10px;
	width: 154px;
	height: 32px;
    padding: 0 15px;
    border: 1px solid #ccc;
    font-size: 10pt;
    color: #999;
}
</style>