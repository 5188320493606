<template>
	<div>
		<p class="txt-list-title">
			Field List
		</p>
		<p class="txt-list-total">
			Total <span >{{ totlaFieldCount }}</span> fields are in operation.
			<!-- <select 
				class="project-list-select"
				@input="selectValue($event.target.value, 'searchStateCd')"
			>
				<option v-if="!isData" value="" selected disabled>--- select ---</option>
				<option value="0">ALL</option>
				<option v-for="(item, index) in projectList" :key="index" :value="item.projectNo">
					{{ item.projectStudyTitle }}
				</option>
			</select> -->
		</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the field name or group name to search."
				@input="doSearchText($event.target.value)"
				@keyup="keyupHandler"
                @focus="infoBalloon"
                @blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="field-balloon">
                <p>Please press the Enter key. </p>
            </div>
			<div class="btn-wrap">
				<button
					v-if="workAccessCheck('GROUP CREATE')"
					type="button"
					class="btn-new"
					title="New Group"
					@click="doGroupCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New Group
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Field Sort"
					@click="doFieldSort()"
				>
					Field Sort
				</button>
			</div>
		</div>
		<table class="tb-list-vertical" style="table-layout: fixed;">
			<colgroup>
				<col width="5%" />
				<col width="8%" />
				<col width="auto" />
				<col width="15%" />
				<col width="8%" />
				<col width="8%" />
				<col width="8%" />
				<col width="20%" />
			</colgroup>
			<thead>
				<tr class="no-drag">
					<th>
					</th>
					<th>
						Type
					</th>
					<th>
						Name
					</th>
					<th>
						Count
					</th>
					<th>
						Sort Order
					</th>
					<th>
						Register
					</th>
					<th>
						Regist Date
					</th>
					<th>
						Function
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(groupItem, groupIndex) in questionGroupList">
					<tr
						:key="groupIndex"
						class="group-col no-drag"
						:class="(openGroupList.indexOf(groupItem.questionGroupNo) >= 0 ? 'group-col-on' : '')"
						tabindex="0"
						@click.stop="openGroup(groupItem)"
					>
						<td style="text-align: left;">
							<i
								class="fold"
								:class="(openGroupList.indexOf(groupItem.questionGroupNo) >= 0 ? 'fold-on' : '')"
							/>
						</td>
						<td style="font-weight: 600;">
							Group
						</td>
						<td class="ellipsis" :title="groupItem.questionGroupName">
							{{ groupItem.questionGroupName }}
						</td>
						<td>
							{{ groupItem.questionList.length }} Field
						</td>
						<td>
							{{ groupItem.questionGroupSortOrder }}
						</td>
						<td>
							{{ groupItem.firstRegistUserId }}
						</td>
						<td>
							{{ groupItem.firstRegistDate.substring(0, 10) }}
						</td>
						<td>
							<div>
								<button
									v-if="workAccessCheck('GROUP EDIT')"
									class="btn-table"
									title="Edit Group"
									style="margin-right: 10px;"
									@click.stop="doGroupEdit(groupItem)"
								>
									Edit
								</button>
								<button
									v-if="workAccessCheck('GROUP DELETE')"
									type="button"
									class="btn-table"
									title="Remove Field"
									style="margin-right: 10px;"
									@click.stop="groupRemove(groupItem)"
								>
									Remove
								</button>
								<button
									v-if="workAccessCheck('CREATE')"
									class="btn-table"
									title="Add Field"
									@click.stop="doCreate(groupItem)"
								>
									+ Add Field
								</button>
							</div>
						</td>
					</tr>
					<template v-for="(questionItem, questionIndex) in groupItem.questionList">
						<tr
							v-if="openGroupList.indexOf(questionItem.questionGroupNo) >= 0"
							:key="500 + questionItem.questionNo"
							class="question-col no-drag"
							tabindex="0"
							@click.stop="doEdit(questionItem)"
						>
							<td>
								{{ questionIndex + 1 }}
							</td>
							<td style="font-weight: 600;">
								Field
							</td>
							<td class="ellipsis" :title="questionItem.questionName">
								{{ questionItem.questionFieldName }} ({{questionItem.questionName}})
							</td>
							<td>
								{{ questionItem.itemCount }} Item
							</td>
							<td>
								{{ questionItem.questionSortOrder }}
							</td>
							<td>
								{{ groupItem.firstRegistUserId }}
							</td>
							<td>
								{{ groupItem.firstRegistDate.substring(0, 10) }}
							</td>
							<td>
								<button
									v-if="workAccessCheck('DELETE')"
									type="button"
									class="btn-remove"
									title="Remove Field"
									@click.stop="fieldChildRemove(questionItem.questionNo)"
								></button>
							</td>
						</tr>
					</template>
					<tr
						v-if="groupItem.questionList.length == 0 && openGroupList.indexOf(groupItem.questionGroupNo) >= 0"
						:key="groupIndex + 100"
					>
						<td colspan="8" class="no-list">
							There is no registered field, Please register the field.
						</td>
					</tr>
				</template>
				<tr v-if="questionGroupList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered group, Please register the group.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<GroupCreate v-if="isGroupCreate" />
		<GroupEdit v-if="isGroupEdit" :questionGroupNo="groupNo"/>
		<FieldSort v-if="isFieldSort" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import GroupCreate from "@/components/field/QuestionGroupCreate.vue";
import GroupEdit from "@/components/field/QuestionGroupEdit.vue";
import FieldSort from "@/components/field/FieldSort.vue";

export default {
	name: "FieldList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "FIELD",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectProjectList();
		this.selectQuestionGroupList();
		this.getAccessList('FIELD');
	},
	mounted(){
		this.$nextTick(function () {
			if(this.$parent.current_page != "" && this.$parent.current_page != undefined){
				this.doSearchPage(this.$parent.current_page);
			}
		});
	},
	components: {
		GroupCreate,
		GroupEdit,
		FieldSort
	},
	data() {
		return {
			items: {},
			questionGroupList: [],
			projectList: [],
			pagination: {},
			openGroupList: [],
			isGroupCreate: false,
			isGroupEdit: false,
			isFieldSort: false,
			groupNo: "",
			isTyping: false,
			isData: false, 
			accessList: [],
		};
	},
	computed: {
		totlaFieldCount: function () {
			let count = 0;
			for(let i = 0 ; i < this.questionGroupList.length ; i++)
			{
				count = count + this.questionGroupList[i].questionList.length;
			}
			return count;
		}
	},
	methods: {
		selectProjectList() {
			this.logger.debug(this, "selectProjectList()");
			this.openProjectIndex = -1;
			axios
				.post(this.config.contextRoot + "/project/selectList.do", {searchText: this.searchText})
				.then((response) => {
					console.log(response);
					this.projectList = response.data.projectList;
					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("project-loading", false);
						}.bind(this), 400);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectQuestionGroupList() {
			this.logger.debug(this, "selectQuestionGroupList()");
			this.items.userId = sessionStorage.userId;
			axios
				.post(this.config.contextRoot + "/field/selectQuestionList.do", this.items)
				.then((response) => {
					console.log(response);
					this.questionGroupList = response.data.questionList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						EventBus.$emit('field-loading', false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProjectQuestionGroupList() {
			this.logger.debug(this, "selectQuestionGroupList()");
			axios
				.post(this.config.contextRoot + "/field/selectProjectQuestionGroupList.do", this.items)
				.then((response) => {
					console.log(response);
					this.questionGroupList = response.data.questionList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						EventBus.$emit('field-loading', false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		openGroup(groupItem) {
			this.logger.debug(this, "openGroup(groupItem)");
			let questionGroupNo = groupItem.questionGroupNo;
			let index = this.openGroupList.indexOf(questionGroupNo);

			if(index >= 0) this.openGroupList.splice(index, 1);
			else this.openGroupList.push(questionGroupNo);
		},
		doSearchText(value) {
			this.$set(this.items, "searchText", value);
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage("+page+")");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.items.pageNum = page;
			this.selectQuestionGroupList();
		},
		doCreate(field) {
			this.logger.debug(this, "doCreate()");
			let url = this.config.contextRoot + "/field/create/" + field.questionGroupNo + "?current_page="+this.pagination.current_page;
			if (this.$route.path !== url)
			{
				EventBus.$emit("field-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(field) {
			this.logger.debug(this, "doEdit(field)");
			if(field.questionName == '-')
			{
				this.groupNo = field.questionGroupNo;
				this.isGroupEdit = true;
			}
			else
			{
				let url =  this.config.contextRoot + "/field/edit/" + field.questionGroupNo + "/" + field.questionNo + "?current_page="+this.pagination.current_page;
				if (this.$route.path !== url)
				{
					EventBus.$emit("field-loading", true);
					this.$router.push(url);
				}
			}
		},
		doGroupCreate() {
			this.logger.debug(this, "doGroupCreate()");
			this.isGroupCreate = true;
		},
		doGroupEdit(groupItem) {
			this.logger.debug(this, "doGroupEdit(groupItem)");
			this.groupNo = groupItem.questionGroupNo;
			this.isGroupEdit = true;
		},
		doFieldSort() {
			this.logger.debug(this, "dofieldSort()");
			this.isFieldSort = true;
		},
		fieldChildRemove(questionNo) {
			this.logger.debug(this, "fieldChildRemove()");

			this.items.questionNo = questionNo;
			axios
				.post(this.config.contextRoot + "/field/deleteFieldChildFlag.do", this.items)
				.then((response) => {
					console.log(response);
					
					if(response.data.flag)
					{
						this.$alert("The referenced item exists.", "Warn", "warning");
						return;
					}

					this.$confirm("Do you want to delete the current field child?", "Question", "question").then(() => {
						axios
						.post(this.config.contextRoot + "/field/deleteFieldChild.do", this.items)
						.then((response) => {
							console.log(response);

							if(response.status == 200)
							{
								this.$alert("delete complete.", "Success", "success").then(() => {
									// location.reload();
									this.selectProjectList();
									this.selectQuestionGroupList();
								});
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});		
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		groupRemove(groupItem) {
			this.logger.debug(this, "groupRemove()");

					
			if(groupItem.questionList.length > 0)
			{
				this.$alert("The referenced question exists.", "Warn", "warning");
				return;
			}
			else
			{
				this.$confirm("Do you want to delete the current question group?", "Question", "question").then(() => {
					axios
					.post(this.config.contextRoot + "/field/deleteQuestionGroup.do", groupItem)
					.then((response) => {
						console.log(response);

						if(response.status == 200)
						{
							this.$alert("delete complete.", "Success", "success").then(() => {
								// location.reload();
								this.selectProjectList();
								this.selectQuestionGroupList();
							});
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});		
				});
			}
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			if (event.keyCode == 13)
			{
				EventBus.$emit('field-loading', true);
				this.selectQuestionGroupList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		selectValue(value, target, bools) {
			if(target == 'searchStateCd')
			{
				if(bools == true)
				{
					this.isData = false;
				}
				else
				{
					this.isData = true;
					if(value=="0"){
						this.selectQuestionGroupList();
					}
					else{
						this.selectProjectQuestionGroupList();
					}
				}
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
    align-items: center;
	justify-content: center;
	min-width: 120px;
    height: 35px;
    margin: 0 0 10px 10px;
    padding: 0 25px;
    border-radius: 5px;
	border: 1px solid #dee2e6;
    background-color: #eee;
    font-weight: 600;
    font-size: 10.5pt;
    color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
    background-color: #fff;
    color: #2bade0;
}

.btn-wrap {
	display: inline-block;
	position: relative;
	float: right;
}


.tb-list-vertical thead th {
	background-color: #eee;
}


.group-col:hover td,
.group-col:focus td {
	background-color: #f6f6f6;
}

.group-col td {
	background-color: #f6f6f6;
	font-weight: 400;
	font-size: 10.5pt;
	color: #999;
	cursor: initial !important;
}

.group-col-on td {
	font-weight: 600;
	color: #2bade0;
}

.question-col > td {
	height: 45px;
	color: #999;
}

.question-col:hover > td,
.question-col:focus > td {
	background-color: #fff;
	color: #666;
	font-weight: 600;
}
.project-list-select{
	position: absolute;
	right: 0;
	top: 10px;
	width: 154px;
	height: 32px;
    padding: 0 15px;
    border: 1px solid #ccc;
    font-size: 10pt;
    color: #999;
}
</style>