<template>
	<div>
		<!-- 좌측 데이터 영역 -->
		<div class="screening-left-wrap">
			<p class="page-title">Query Resolution</p>
			
			<div class="title-flex">
				<p class="list-title" style="margin: 38px 0 10px;">Query List</p>
				<label class="check-label list-title">
					<input
						type="checkbox"
						@click="checkUsedQueryList($event)"
						checked
					/>
					<span class="checkbox"></span>
					Show used query
				</label>
			</div>
			<!-- 데이터 리스트 wrap -->
			<div class="list-wrap scroll">
				<!-- <div
					class="list-item no-drag"
					@click="selectData('TOTAL')"
					:class="selectedValidationNo == 'TOTAL' ? 'select' : ''"
				>
					Total
				</div> -->
				<div
					class="list-item no-drag"
					@click="selectData('REQUIRED')"
					:class="selectedValidationNo == 'REQUIRED' ? 'select' : ''"
				>
					Required
				</div>
				<div
					class="list-item no-drag"
					@click="selectData('REQUEST')"
					:class="selectedValidationNo == 'REQUEST' ? 'select' : ''"
				>
					Request
				</div>
				<div
					v-for="(item, index) in validationList"
					:key="index"
					class="list-item no-drag"
					:class="selectedValidationNo == item.validNo ? 'select' : ''"
					@click="selectData(item)"
				>
					{{ item.validationName }}
				</div>
			</div>
		</div>
		<!-- 중앙 컨테이너 wrap -->
		<div class="screening-center-wrap">
			<div class="list-control-wrap">			
			
			<button
				v-if="workAccessCheck('EXCEL DOWNLOAD')"
				type="button"
				class="btn-grey"
				title="Excel Download"
				style="float: right; margin: 25px 0 15px 0;"
				@click="doExcelDown()"
			>
				Excel
			</button>
			<button
				v-if="workAccessCheck('HISTORY')"
				type="button"
				class="btn-grey"
				style="float: right; margin: 25px 10px 15px 0;"
				@click="historyPopup()"
			>
				Query History
			</button>
		</div>
		<div class="list-wrap scroll">
			<table class="tb-list-vertical">
				<colgroup>  
					<col width="5%" />
					<col width="13%" />
					<col v-if="selectedValidationNo != 'REQUIRED' && selectedValidationNo != 'REQUEST'" width="auto" />
					<!-- <col width="8%" /> -->
					<col width="8%" />
					<col width="10%" />
					<col width="10%" />
					<col width="10%" />
					<col width="20%" />
				</colgroup>
				<thead>
					<tr>
						<th>No</th>
						<th>Subject</th>
						<th v-if="selectedValidationNo != 'REQUIRED' && selectedValidationNo != 'REQUEST'">Page Label</th>
						<!-- <th>Count</th> -->
						<th>Register</th>
						<th>Date</th>
						<th>Type</th>
						<th>Status</th>
						<th>Function</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(queryResolution, index) in queryResolutionList" :key="index" tabindex="0">
						<td>
							{{ index + 1}}
						</td>
						<td>
							<span class="underline" @click.stop="doScreening(queryResolution)">
								{{ queryResolution.screeningName }}
							</span>
						</td>
						<td v-if="selectedValidationNo != 'REQUIRED' && selectedValidationNo != 'REQUEST'">
							{{ queryResolution.pageLabel}}
						</td>
						<!-- <td >
							<b>{{ queryResolution.queryResultVoList.length }} Field</b>
						</td> -->
						<td>
							<b>{{ queryResolution.firstRegistUserId }}</b><br>
							({{ queryResolution.userName }})
						</td>
						<td>
							{{ queryResolution.lastModifyDate.substring(0, 10) }}
						</td>
						<td>
							{{ getTypeName(queryResolution.queryTypeCd) }}
						</td>
						<td
							:class="getStatusName(queryResolution)=='CLOSE' ? 'red' : getStatusName(queryResolution)=='OPEN' ? 'blue' : getStatusName(queryResolution)=='ANSWER' ? 'green' : ''"
						>
							<b class="blue">{{queryResolution.openCnt}} OPEN<br></b>
							<b class="green">{{queryResolution.answerCnt}} ANSWER<br></b>
							<b class="red">{{queryResolution.closeCnt}} CLOSE</b>
							<!-- <b class="blue" v-if="getStatusName(queryResolution)=='OPEN'" >{{queryResolution.openCnt}} OPEN<br></b>
							<b class="green" v-if="getStatusName(queryResolution)=='ANSWER'" >{{queryResolution.answerCnt}} ANSWER<br></b>
							<b class="red" v-if="getStatusName(queryResolution)=='OPEN' && queryResolution.closeCnt != 0" >{{queryResolution.closeCnt}} CLOSE</b>
							<b class="red" v-if="getStatusName(queryResolution)=='ANSWER' && queryResolution.closeCnt != 0" >{{queryResolution.closeCnt}} CLOSE</b>
							<b class="red" v-if="getStatusName(queryResolution)=='CLOSE'" >{{queryResolution.closeCnt}} CLOSE</b> -->
							<!-- <b class="blue query-underline" v-if="getStatusName(queryResolution)=='OPEN'" @click.stop="openResult(queryResolution, 1)">{{queryResolution.openCnt}} OPEN</b>
							<b class="green query-underline" v-if="getStatusName(queryResolution)=='ANSWER'" @click.stop="openResult(queryResolution, 3)">{{queryResolution.answerCnt}} ANSWER<br></b>
							<b class="red query-underline" v-if="getStatusName(queryResolution)=='ANSWER' && queryResolution.closeCnt != 0" @click.stop="openResult(queryResolution, 2)">{{queryResolution.closeCnt}} CLOSE</b>
							<b class="red query-underline" v-if="getStatusName(queryResolution)=='CLOSE'" @click.stop="openResult(queryResolution, 2)">{{queryResolution.closeCnt}} CLOSE</b> -->
						</td>
						<td>
							<button
								v-if="workAccessCheck('VALIDATION VIEW') && selectedValidationNo != 'REQUIRED' && selectedValidationNo != 'REQUEST'"
								type="button"
								class="btn-send"
								@click="viewPopup(queryResolution.validNo, queryResolution.screeningNo, queryResolution.visitNo, queryResolution.pageNo, queryResolution.screeningName, queryResolution.pageLabel)"
								style="margin-right: 5px;"
							>
							VIEW
							</button>
							<button
								v-if="workAccessCheck('QUERY OPEN') && !(projectStateCd == '4') && selectedValidationNo == 'REQUEST'"
								type="button"
								class="btn-send"
								@click="sendPopup(queryResolution)"
							>
							SEND
							</button>
						</td>
					</tr>
					<tr v-if="queryResolutionList.length == 0">
						<td colspan="9" class="no-list">
							There is no registered QueryResolution, Please register the QueryResolution.
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		</div>
		<QueryValidView v-if="isValidView" :selectViewValidNo="selectViewValidNo" :selectViewTestScreeningNo="''" :selectViewScreeningNo="selectViewScreeningNo" 
			:selectViewVisitNo="selectViewVisitNo" :selectViewPageNo="selectViewPageNo"
			:selectViewSubject="selectViewSubject" :selectViewPageName="selectViewPageName"/>
		<SendQuery v-if="isSendQuery" :selectQueryResolution="selectQueryResolution" :queryStatusList="queryStatusList"/>
		<QueryHistory v-if="isQueryHistory"  :selectedValidationNo="selectedValidationNo"/>
		<QueryMsgHistory v-if="isQueryMsgHistory" :selectQueryStatusCd="selectQueryStatusCd" :selectQueryResolution="selectQueryResolution"/>
	</div>
</template>


<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import QueryValidView from "@/components/project/queryResolution/QueryValidView"
import SendQuery from "@/components/project/queryResolution/SendQuery"
import QueryHistory from "@/components/project/queryResolution/QueryHistory"
import QueryMsgHistory from "@/components/project/queryResolution/QueryMsgHistory"

export default {
	name: "QueryResolutionList",
	components: {
		QueryValidView,
		SendQuery,
		QueryHistory,
		QueryMsgHistory
    },
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "QUERY RESOLUTION",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.selectValidationList("Y");
		this.selectComCodeList();
		this.getAccessList('QUERY RESOLUTION');
		this.getProjectStateCd();

		// 리스트 새로고침
		EventBus.$on("refresh-data-list", (selectedValidationNo) => {
			this.logger.debug(this, "[EventBus] refresh-data-list(" + selectedValidationNo + ")");
		});

		EventBus.$on("view-close", () => {
			this.logger.debug(this, "[EventBus] view-cancel");
			this.isValidView = false;
			this.isSendQuery = false;
			this.isQueryHistory = false;
			this.isQueryMsgHistory = false;
		});
	},
	data() {
		return {
			items: {},
			validationList: [],
			queryResolutionList: [],
			selectedValidationNo: "",
			selectViewValidNo: "",
			selectViewScreeningNo: "",
			selectViewVisitNo: "",
			selectViewPageNo: "",
			selectViewSubject: "",
			selectViewPageName: "",
			selectQueryResolution: {},
			selectQueryStatusCd: "",
			isValidView: false,
			isSendQuery: false,
			isQueryHistory: false,
			isQueryMsgHistory: false,
			queryTypeList: [],
			queryStatusList: [],
			accessList: [],
			projectStateCd: ""
		};
	},
	computed: {
		selectedValidationName: function () {
			let indexList = this.validationList.map((item) => {
				return item.validationNo;
			});
			let nameList = this.validationList.map((item) => {
				return item.validationName;
			});
			let selectedIndex = indexList.indexOf(this.selectedValidationNo);

			return nameList[selectedIndex];
		},
	},
	methods: {
		selectValidationList(openQueryYn) {
			this.logger.debug(this, "selectValidationList()");
			axios
				.post(this.config.contextRoot + "/queryResolution/selectValidationList.do", this.items)
				.then((response) => {
					console.log(response);
					this.validationList = [];
					if(openQueryYn == 'Y'){
						response.data.validationList.forEach(obj =>{
							if(obj.useQueryYn == 'Y'){
								this.validationList.push(obj);
							}
						});
					}
					else{
						this.validationList = response.data.validationList;
					}

					this.selectedValidationNo = "REQUIRED";
					this.selectProcedureList();

					this.$nextTick(function () {
						setTimeout(
							function () {
								EventBus.$emit("project-loading", false);
							}.bind(this),
							500
						);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		checkUsedQueryList(event){
			if(event.target.checked)
			{
				this.selectValidationList("Y");
			}
			else
			{
				this.selectValidationList("N");
			}
		},
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["QUERY_TYPE_CD", "QUERY_STATUS_CD"]})
				.then((response) => {
					let codeList = response.data.codeList;
					for (let index = 0 ; index < codeList.length ; index++)
					{
						if(codeList[index].classCode == "QUERY_TYPE_CD")
						{
							this.queryTypeList.push(codeList[index]);
						}
						if(codeList[index].classCode == "QUERY_STATUS_CD")
						{
							this.queryStatusList.push(codeList[index]);
						}
					}
					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("field-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProcedureList() {
			this.logger.debug(this, "selectProcedureList()");

			let param = {
				projectNo: this.items.projectNo,
				validNo: this.selectedValidationNo,
			}
			
			axios
				.post(this.config.contextRoot + "/queryResolution/selectList.do", param)
				.then((response) => {
					console.log(response);
					this.queryResolutionList = response.data.dataQueryResolutionList;

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectData(screening) {
			this.logger.debug(this, "selectData(screening)");

			console.log(screening.length);
			if(screening.length > 0){
				this.selectedValidationNo = screening
			}
			else{
				this.selectedValidationNo = screening.validNo;
			}

			this.selectProcedureList();
		},
		doExcelDown() {
			this.logger.debug(this, "doExcelDown()");

			this.items.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/excel/downloadExcelValidation.do", this.items, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					if(response.status == 200)
					{						
						console.log(response);
						console.log(response.headers);
						const url = window.URL.createObjectURL(
							new Blob([response.data], {
								type: response.headers["content-type"],
							})
						);						
						
						const link = document.createElement("a");
						link.href = url;
						let today = new Date();
						let year = today.getFullYear();
						let month = ('0' + (today.getMonth() +1)).slice(-2);
						let day = ('0' + (today.getDate())).slice(-2);

						today = year + "-" + month + "-" + day;

						link.setAttribute("download", "QureryResolution_"+today +".xlsx");
						
						document.body.appendChild(link);
						link.click();
						
						this.$alert("The file has been downloaded.", "Info", "info");
					}

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doScreening(queryResolution) {
			this.logger.debug(this, "doScreening()");

			var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/screening/" + queryResolution.screeningNo;
			
			if (this.$route.path !== url)
			{
				this.$router.push(url);
			}
		},
		getTypeName(comCode) {
			let comCodeList = this.queryTypeList.map((item) => {
				return item.comCode;
			});
			let getIndex = comCodeList.indexOf(comCode);

			return this.queryTypeList[getIndex].comCodeName;
		},
		getStatusName(queryResolution) {
			let openCount = "";
			let openCnt = 0;
			let answerCnt = 0;
			let closeCnt = 0;
			queryResolution.queryResultVoList.map((item) => {
				if(item.queryStatusCd == '1'){
					openCount = item.queryStatusCd;
					openCnt += 1;
				}
				if(item.queryStatusCd == '2'){
					closeCnt += 1;
				}
				if(item.queryStatusCd == '3'){
					answerCnt += 1;
				}
			});

			this.$set(queryResolution, "openCnt", openCnt);
			this.$set(queryResolution, "closeCnt", closeCnt);
			this.$set(queryResolution, "answerCnt", answerCnt);

			if(openCount == '1'){
				let comCodeList = this.queryStatusList.map((item) => {
					return item.comCode;
				});
				let getIndex = comCodeList.indexOf('1');
				return this.queryStatusList[getIndex].comCodeName;
			}
			else{
				queryResolution.queryResultVoList.map((item) => {
					if(item.queryStatusCd == '3'){
						openCount = item.queryStatusCd;
					}
				});
				if(openCount == '3'){
					let comCodeList = this.queryStatusList.map((item) => {
						return item.comCode;
					});
					let getIndex = comCodeList.indexOf('3');
					return this.queryStatusList[getIndex].comCodeName;
				}
				else{
					let comCodeList = this.queryStatusList.map((item) => {
						return item.comCode;
					});
					let getIndex = comCodeList.indexOf('2');
					return this.queryStatusList[getIndex].comCodeName;
				}
			}
		},
		viewPopup(validNo, screeningNo, visitNo, pageNo, subject, pageName){
			this.logger.debug(this, "viewPopup()");

			this.selectViewValidNo = validNo;
			this.selectViewScreeningNo = screeningNo;
			this.selectViewVisitNo = visitNo;
			this.selectViewPageNo = pageNo;
			this.selectViewSubject = subject;
			this.selectViewPageName = pageName;
			this.isValidView = true;
		},
		sendPopup(queryResolution){
			this.logger.debug(this, "sendPopup()");
			this.selectQueryResolution = queryResolution
			this.isSendQuery = true;
			// if(queryResolution.queryStatusCd == '2'){
			// }
			// else if(queryResolution.queryStatusCd == '1'){
			// 	this.$alert("Already open query!", "Warning", "warning");
			// }
		},
		historyPopup(){
			this.logger.debug(this, "historyPopup()");
			this.isQueryHistory = true;
		},
		openResult(queryResolution, statusCd){
			this.logger.debug(this, "openResult()");
			this.selectQueryStatusCd = statusCd;
			this.selectQueryResolution = queryResolution
			this.isQueryMsgHistory = true;
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	},
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-send {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	height: 35px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}
.btn-send:hover, .btn-send:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}

.btn-wrap {
	display: inline-block;
	position: relative;
	float: right;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
.query-underline{
	text-decoration: underline;
	cursor: pointer;
}
.query-underline:hover{
	color: #333;
}
.tb-list-vertical tbody tr:focus>td, .tb-list-vertical tbody tr:hover>td {
    background-color: #f8f9fa;
    cursor: auto;
}
.screening-center-wrap .list-wrap {
	width: 100%;
    display: block;
    position: relative;
    height: calc(100% - 121px);
    overflow-y: scroll;
}
.title-flex{
	display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.check-label {
    display: flex;
    font-size: 10pt;
	align-items: flex-end;
    margin: 38px 20px 10px;
}
.check-label > input[type=checkbox] {
    display: none;
}
.check-label>.checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
	cursor: pointer;
}
.check-label > input[type=checkbox]:checked+.checkbox {
    border: 2px solid #2bade0;
}
.check-label > input[type=checkbox]:checked+.checkbox::before {
    content: "\2713";
    position: absolute;
    top: -11px;
    left: 0.5px;
    width: 16px;
    height: 16px;
    font-weight: 600;
    font-size: 13pt;
    color: #2bade0;
}
</style>