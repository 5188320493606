<template>
	<form @submit.prevent="doValidation()">
		<div class="sign-up-container">
			<div class="sign-top-wrap">
				회원가입
				<div class="sign-cnt" style="background-color: #DEE2E6; border:none;">1</div>
				<span></span>
				<div class="sign-cnt" style="padding: 0;">2</div>
			</div>
			<div class="sign-scroll">
				<p class="sign-title">Member Information</p>
				<table class="tb-crud-horizon tb-crud-horizon2 login-table">
					<caption>
						<!-- * Membership information is secured in accordance with the privacy policy and is not disclosed or provided to third parties without your explicit consent. -->
						* 회원정보는 개인정보취급방침에 따라 보호되며, 귀하의 명시적인 동의 없이는 제3자에게 공개 또는 제공되지 않습니다.
					</caption>
					<colgroup>
						<col width="15%" />
						<col width="35%" />
						<col width="15%" />
						<col width="35%" />
					</colgroup>
					<tbody>
						<tr>
							<th>
								ID
								<span class="fcolor-red">*</span>
							</th>
							<td>
								<input
									type="text"
									maxlength="15"
									name="userId"
									class="input-crud input-crud2"
									v-model="item.userId"
									v-validate="'required'"
									data-vv-as="User ID"
									autocomplete="off"
									@keyup="doIdDuplicateCheck()"
								/>
								<p class="txt-validation" v-if="errors.has('userId')">
									* {{ errors.first("userId") }}
								</p>
								<p class="txt-validation" v-if="isIdDuplicate">
									* ID is duplicated.
								</p>
							</td>
							<th>
								Name
								<span class="fcolor-red">*</span>
							</th>
							<td>
								<input
									type="text"
									maxlength="50"
									name="userName"
									class="input-crud input-crud2"
									v-model="item.userName"
									v-validate="'required'"
									data-vv-as="User Name"
								/>
								<p class="txt-validation" v-if="errors.has('userName')">
									* {{ errors.first("userName") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>
								Password
								<span class="fcolor-red">*</span>
							</th>
							<td>
								<input
									type="password"
									maxlength="30"
									name="password"
									class="input-crud input-crud2"
									v-model="item.password"
									v-validate="'required'"
									data-vv-as="Password"
									ref="password"
								/>
								<p class="txt-validation" v-if="errors.has('password')">
									* {{ errors.first("password") }}
								</p>
							</td>
							<th>
								Confirm Password
								<span class="fcolor-red">*</span>
							</th>
							<td>
								<input
									type="password"
									maxlength="30"
									name="confirmPassword"
									class="input-crud input-crud2"
									v-model="item.confirmPassword"
									v-validate="'required|confirmed:password'"
									data-vv-as="Confirm Password"
								/>
								<p class="txt-validation" v-if="errors.has('confirmPassword')">
									* {{ errors.first("confirmPassword") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>
								E-mail
							</th>
							<td>
								<div class="email-wrap">
									<input type="text" class="input-crud input-crud2" v-model="item.emailFront" />
									<span>@</span>
									<input type="text" class="input-crud input-crud2" v-model="item.emailEnd" />
								</div>
							</td>

							<th>
								Phone Number
							</th>
							<td>
								<div class="phone-wrap">
									<input type="text" maxlength="3" class="input-crud input-crud2" v-model="item.phoneFront" ref="phoneFront" @keyup="phoneNext('phoneFront', 'phoneCenter')" />
									<span>-</span>
									<input type="text" maxlength="4" class="input-crud input-crud2" v-model="item.phoneCenter" ref="phoneCenter" @keyup="phoneNext('phoneCenter', 'phoneEnd')" />
									<span>-</span>
									<input type="text" maxlength="4" class="input-crud input-crud2" v-model="item.phoneEnd" ref="phoneEnd" />
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div>
				<button type="button" class="sign-btn sign-cancel" title="Previous" @click.stop="doCancel()">Previous</button>
				<button type="submit" class="sign-btn sign-submit" title="Sign Up">Sign Up</button>
			</div>
		</div>
	</form>
</template>

<script>
import axios from "axios";

export default {
	name: "SignUp",
	created() {
		this.logger.debug(this, "created()");
	},
	data() {
		return {
			item: {},
			isIdDuplicate: false,
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");

			if(this.item.emailFront && this.item.emailEnd)
			{
				var emailAddress = this.item.emailFront + "@" + this.item.emailEnd;
				this.$set(this.item, "emailAddress", emailAddress);
			}
			else if(this.item.emailFront || this.item.emailEnd)
			{
				var emailEmpty = (!this.item.emailFront ? "Email Front" : "Email End");
				this.$alert(emailEmpty + " Field is Empty!","Warning","warning");
				return ;
			}

			if(this.item.phoneFront && this.item.phoneCenter && this.item.phoneEnd)
			{
				var mobileTelno = this.item.phoneFront + "-" + this.item.phoneCenter + "-" + this.item.phoneEnd;
				this.$set(this.item, "mobileTelno", mobileTelno);
			}
			else if(this.item.phoneFront || this.item.phoneCenter || this.item.phoneEnd)
			{
				var phoneEmpty = (!this.item.phoneFront ? "Phone Front" : (!this.item.phoneCenter ? "Phone Center" : "Phone End"));
				this.$alert(phoneEmpty + " Field is Empty!","Warning","warning");
				return ;
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result && confirm("Would you like to submit the membership registration form?")) {
						this.doInsert();
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");

			axios
				.post(this.config.contextRoot + "/login/signUp.do", this.item)
				.then((response) => {
					if(response.status == 200) {
						this.$alert("Membership registration has been successfully completed!","Success","success");
						this.$router.push(this.config.contextRoot + "/login");	
					} else {
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$router.push(this.config.contextRoot + "/login/sign/agree");
		},
		doIdDuplicateCheck() {
			this.logger.debug(this, "doIdDuplicateCheck()");
			
			axios
				.post(this.config.contextRoot + "/login/confirmId.do", this.item)
				.then((response) => {
					if(response.data.count > 0) {
						this.isIdDuplicate = true;
					} else {
						this.isIdDuplicate = false;
					}
				})
					.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		phoneNext(now, next) {

			if(!this.utils.isNull(this.item.phoneFront))
			{
				if(now == "phoneFront" && this.item.phoneFront.length == 3) {
				this.$refs[next].focus();
				}
			}
						
			if(!this.utils.isNull(this.item.phoneCenter))
			{
				if(now == "phoneCenter" && this.item.phoneCenter.length == 4) {
					this.$refs[next].focus();
				}
			}
		}
	}
};
</script>
<style>
.tb-crud-horizon2 tr{	
	border-top: 0.11vh solid #ccc;
    border-bottom: 0.11vh solid #ccc;
}
.tb-crud-horizon2 th{
	padding: 0.53vh 0.83vw;
    background-color: #eee;
    font-weight: 600;
    font-size: 0.834vmax;
    color: #000;
    text-align: left;
	font-family: Pretendard;
}
.tb-crud-horizon2 td{
	padding: 0.53vh 0.83vw;
    background-color: #fff;
    font-weight: 400;
    font-size: 0.834vmax;
    color: #999;
    text-align: left;
	font-family: Pretendard;
}
.input-crud2{
	width: 100%;
    height: 3.7vmin;
    padding: 0 0.8vw;
    border: 0.11vh solid #ccc;
    font-size: 0.73vmax;
    color: #999;
	font-family: Pretendard;
}
.sign-scroll{
	overflow-y: auto;
}
.sign-scroll::-webkit-scrollbar {
        width: 2.5vmin;
    }

.sign-scroll::-webkit-scrollbar-thumb {
	border: 1vmin solid transparent;
	border-radius: 5vmin;
	background: linear-gradient(to top, #9ecc5a, #2bade0);
	background-clip: padding-box;
}

.sign-scroll::-webkit-scrollbar-track {
	border-top: 1vmin solid transparent;
	border-bottom: 1vmin solid transparent;
	border-left: 1vmin solid transparent;
	border-right: 1vmin solid transparent;
	border-radius: 5vmin;
	background: #eee;
	background-clip: padding-box;
}
</style>