import axios from 'axios';

export default {
	
	install(Vue) {
		
		Vue.prototype.$accessAuth = async function(target){
			this.logger.debug(this, "[accessAuth] function(target)");

			const response = await axios.post(this.config.contextRoot + "/access/privilegeCheck.do", target)
			if(response.data.authCheck == false){
				return false;
			}
			else{
				return true;
			}
        }

    }
}
