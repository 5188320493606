<template>

	<div>
		<p class="txt-list-title">Freezing</p>
		<ul class="sub-menu">
			<li class="clicked" @click="moveMenu('/freezing')">Total List</li>
			<li @click="moveMenu('/freezing/each')">Each List</li>
		</ul>
		
		<div class="schedule-list">
			<table class="schedule-table" v-if="visitList.length > 0 && pageList.length > 0 "> 
				<thead>
					<tr>
						<th></th>
						<th :colspan="nvCnt">
							NV
						</th>
						<th :colspan="uvCnt" v-if="uvCnt>0">
							UV
						</th>
					</tr>
					<tr>
						<th></th>
						<th v-for="(item, index) in visitList" :key="index">
							{{ item.visitName }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pageItem, pageIndex) in pageList" :key="pageIndex">
						<th>
							{{ pageItem.pageName }}
						</th>
						<td v-for="(visitItem, visitIndex) in visitList" :key="visitIndex">
							<button
								v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) >= 0"								
								class="ck-green-btn"	
								@click.stop="doEdit(pageIndex)"						
							>
							</button>
						</td>						
					</tr>
							
				</tbody>
			</table>
			<table class="schedule-table" v-if="visitList.length == 0 || pageList.length == 0 "> 
				<thead>
					<tr>
						<th>No Schedule</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="no-list">
							There is no registered visit, Please register the visit.
						</td>
					</tr>	
				</tbody>
			</table>
		</div>
		<FreezingScreening v-if="isFreezingScreening" :paramPageNo="selectPageNo"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import FreezingScreening from "@/components/project/freezing/FreezingScreening.vue";

export default {
	name: "FreezingTotalList",
	components: {
		FreezingScreening
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;

		this.selectPageList();
		this.selectDataVisitList();	
	},
	data() {
		return {
			items: {},
			visitList: [],
			pageList: [],
			nvCnt: 0,
			uvCnt: 0,
			isFreezingScreening: false,
			selectPageNo: ""
		};
	},
	methods: {
		selectDataVisitList() {
			this.logger.debug(this, "selectDataVisitList()");
			axios
				.post(this.config.contextRoot + "/freezing/dataVisitList.do", {projectNo: this.items.projectNo})
				.then((response) => {
					console.log(response);

					this.visitList = response.data.dataVisitList;		

					if(this.visitList.length > 0)
					{
						this.nvCnt = this.visitList[0].nvCnt;
						this.uvCnt = this.visitList[0].uvCnt;
					}

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/freezing/selectPageList.do", {projectNo: this.items.projectNo})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doEdit(pageIndex) {
			this.logger.debug(this, "doEdit()");
			this.selectPageNo = this.pageList[pageIndex].pageNo;

			this.isFreezingScreening = true;
		},		
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();

	
		},
	}
};
</script>

<style scoped>
</style>
