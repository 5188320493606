<template>
	<div>
		<button 
			v-if="isAccessCreate"
			type="button" class="btn-new" title="New Notice" @click="doCreate()"
		>
			<img src="@/assets/images/common/btn_new.png" alt="" />
			New Notice
		</button>
		<p class="txt-list-title">
			Hello, {{ item.userId }}
		</p>
		<p class="txt-list-total">
			<span>{{ noticeList.length }}</span>
			Notices have been registered.
		</p>
		<div class="list-grid" ref="noticeList">
			<div
				v-for="index in ((openPage * 6) < noticeList.length ? openPage * 6 : noticeList.length)"
				:key="index"
				class="item"
			>
				<div
					class="box-notice"
					:class="((index - 1) == openNoticeIndex ? 'box-notice-clicked' : '')"
					tabindex="0"
					@mouseenter="openNotice($event, (index - 1))"
					@focus="openNotice($event, (index - 1))"
				>
					<p class="title ellipsis" :title="noticeList[index - 1].noticeName">
						{{ noticeList[index - 1].noticeName }}
					</p>
					<p
						class="contents"
						v-html="
							utils.isEmpty(noticeList[index - 1].noticeContents)
							? ''
							: noticeList[index - 1].noticeContents.replace(/(?:\r\n|\r|\n)/g, '<br />')
						"
					></p>
					<p v-if="(index - 1) == openNoticeIndex" class="info">
						<span class="manager">From manager.</span><br>
						<span class="date">{{ noticeList[index - 1].lastModifyDate }}</span>
						<button v-if="workAccessCheck('EDIT')" type="button" class="btn-edit" title="Edit Notice" @click.stop="doEdit()"></button>
						<button v-if="workAccessCheck('DELETE')" type="button" class="btn-delete" title="Delete Notice" @click.stop="doDelete(noticeList[index - 1])"></button>
					</p>
				</div>
			</div>
		</div>
		<button v-if="openPage < maxPage" type="button" class="btn-more" title="More View" @click="moreView($event)">
			<img src="@/assets/images/common/btn_more.png" alt="" />
			more view
		</button>
		<NoticeCreate v-if="isCreate" />
		<NoticeEdit v-if="isEdit" :noticeNo="noticeList[openNoticeIndex].noticeNo"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import NoticeCreate from "@/components/notice/NoticeCreate.vue"
import NoticeEdit from "@/components/notice/NoticeEdit.vue"

export default {
	name: "NoticeList",
	components: {
		NoticeCreate,
		NoticeEdit
	},
	created() {
		this.logger.debug(this, "created()");
		this.item.userId = sessionStorage.getItem("userId");
		this.selectNoticeList();
		this.getAccessList('NOTICE');
	},
	updated() {
		this.logger.debug(this, "updated()");
		this.$nextTick(function () {
			setTimeout(function() {
				// 로딩바 닫기 요청
				EventBus.$emit("notice-loading", false);
			}.bind(this), 500);
		});
	},
	props: {
		searchText: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {},
			noticeList: [],
			openNoticeIndex: -1,
			openPage: 1,
			isCreate: false,
			isEdit: false,
			accessList: [],
			isAccessCreate: false,
		};
	},
	computed: {
		maxPage: function() {
			return Math.ceil(this.noticeList.length / 6);
		}
	},
	watch: {
		searchText: function() {
			this.selectNoticeList();
			this.initHeight();
		}
	},
	methods: {
		selectNoticeList() {
			this.logger.debug(this, "selectNoticeList()");
			this.openNoticeIndex = -1;
			axios
				.post(this.config.contextRoot + "/notice/selectList.do", {noticeName: this.searchText})
				.then((response) => {
					console.log(response);
					this.noticeList = response.data.noticeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		openNotice(event, index) {
			this.logger.debug(this, "openNotice(event, index)");

			let getPath = this.utils.getEventPath(event);

			// 다른 Notice 클릭
			if(index != this.openNoticeIndex) {
				// 이전 Notice Height 원상복귀
				if(this.openNoticeIndex != -1) this.initHeight();
				// Open Index 설정
				this.openNoticeIndex = index;
				// 전체 Height 적용
				let parentIndex = getPath.map(
					(item) => {
						return item.className;
					}
				).indexOf("box-notice");
				
				let totalHeight = getPath[parentIndex].children[1].clientHeight + 105;
				totalHeight = (totalHeight <= 245 ? 245 : totalHeight);
				getPath[parentIndex].style.transition = "height 0.3s ease-in-out";
				getPath[parentIndex].style.height = totalHeight + "px";
				getPath[parentIndex].blur();
			}
		},
		initHeight() {
			this.logger.debug(this, "initHeight()");
			let noticeLength = this.$refs.noticeList.children.length;
			for(let i = 0 ; i < noticeLength ; i++)
			{
				this.$refs.noticeList.children[i].children[0].style.transition = "";
				this.$refs.noticeList.children[i].children[0].style.height = "";
			}
		},
		moreView(event) {
			this.logger.debug(this, "moreView(event)");
			event.target.blur();
			if(this.openPage < this.maxPage) this.openPage++;
		},
		doEdit() {
			this.logger.debug(this, "doEdit()");
			this.isEdit = true;
		},
		doDelete(notice) {
			this.logger.debug(this, "doDelete(notice)");
			this.$confirm("Are you sure you want to delete the notice?","Question","question").then(() => {
				axios
					.post(this.config.contextRoot + "/notice/deleteNotice.do", {noticeNo: notice.noticeNo})
					.then((response) => {
						console.log(response);
						location.reload();
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
			if(obj.privilegeName == 'CREATE' && obj.isAccess == true){
				this.isAccessCreate = true;
			}
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>