<template>
	<div class="login-center-wrap">
		<!-- <div id="loginCenterLeft" class="vhalf fleft flex-center">
			<ul class="login-edc-list no-drag">
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div> -->
		<div id="loginCenterRight">
			<form class="login-form pre-login-form" @submit.prevent="doLogin">
				<img class="login-head pre-login-head" src="@/assets/images/preRegistration/survey_main.png" alt=""/>
				<!-- <label for="userId" class="login-form-label flex-center">
					<img src="@/assets/images/login/id_icon_login.png" alt="" />
					User ID
				</label> -->
				<!-- <span class="login-name pre-login-name bold">참여자 등록</span> -->
				<input
					type="hidden"
					id="userId"
					v-model="userId"
					ref="userId"
					class="input-login-style sha-focus-666 bmargin-15"
					placeholder="아이디"
					autocomplete="off"
					data-vv-name="UserID"
					style="margin-top: 4.2vh"
				/>

				<!-- <label for="password" class="login-form-label flex-center">
					<img src="@/assets/images/login/pw_icon_login.png" alt="" />
					PASSWORD
				</label> -->
				<input
					type="hidden"
					id="password"
					v-model="password"
					ref="password"
					class="input-login-style sha-focus-666"
					placeholder="비밀번호"
					autocomplete="off"
					data-vv-name="Password"
					@keydown.enter="doLogin"
					style="margin-top: 1.68vh;"
				/>

				<button type="button" class="login-button" @click="doLogin" style="margin-top: 0;">
					시작하기
				</button>

				<!-- <div class="login-form-bottom">
					<button type="button" class="button-line-white fleft" @click.prevent="signUp()">
						Sign up
					</button>
					<button type="button" class="button-line-white fright" @click.prevent="findUser()">
						Find Account
					</button>
				</div> -->

				<div class="login-sub pre-login-sub">
					<button type="button" class="pamphlet-btn" @click.prevent="viewPamphlet()">팜플렛 보기</button>
					<!-- <div>|</div> -->
					<!-- <button type="button" @click.prevent="findUser(2)">비밀번호 찾기</button>
					<div>|</div>
					<button type="button" @click.prevent="signUp()">회원가입</button> -->
				</div>

				<div class="login-call" style="width: fit-content !important; padding: 0 10px;">
					<span>Customer Service</span>
					<span style="font-weight: bold; color:#004098;">043-269-7416</span>
					<span style="font-weight: bold; color:#004098;">Korean.prms@gmail.com</span>
				</div>
				
				<div class="login-sponser">
					<img style="width: 5.37vmax;" src="@/assets/images/login/agency_img_1.png" alt=""/>
					<img style="width: 5.37vmax;" src="@/assets/images/login/agency_img_2.png" alt=""/>
					<img style="width: 4.54vmax;" src="@/assets/images/login/agency_img_3.png" alt=""/>
					<img style="width: 5.94vmax;" src="@/assets/images/login/agency_img_4.png" alt=""/>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import VeeValidator from "vee-validate";
Vue.use(VeeValidator);

export default {
	name: "preRegistration",
	created() {
		this.logger.debug(this, ".created()");
		console.log(this.userInfo.userId);
		// if (!this.utils.isEmpty(this.userInfo.userId)) {
		// 	this.$router.push(this.config.contextRoot + "/notice/list");
		// }
	},
	mounted() {
		this.logger.debug(this, ".mounted()");
	},
	data() {
		return {
			userId: "",
			password: "",
			loginResult: false,
			findId: "N",
			findPass: "N"
		};
	},
	methods: {
		doLogin() {
			this.logger.debug(this, "doLogin");
			this.$router.push(this.$route.query.redirect || this.config.contextRoot + "/preRegistration?ctcProjectId=110&osType=mobile");

			//관리자 계정 부여
			this.userId = 'ctcadmin';
			this.password = 'ctcadmin';

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						return;
					}

					this.$Auth.login(
						this.userId,
						this.password,
						function(loginResult) {
							if (loginResult) {
								this.$router.push(
									this.$route.query.redirect || this.config.contextRoot + "/project/110/preFindUser?osType=" + this.$route.query.osType
								);
							} else {
								this.logger.debug(this, "loginResult:" + loginResult);
							}
						}.bind(this)
					);
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					this.$alert(this.errors.all());
					return false;
				});
		},
		viewPamphlet(){
			this.$router.push(this.$route.query.redirect || this.config.contextRoot + "/prePamphlet")
		}
	},
};
</script>
<style>
@media ( max-width: 1200px) {
	.pre-login-name{
		margin-top: 2vh;
		color: #BBB;
		font-size: 2vh !important;
	}
	.pre-login-sub{
		position: absolute;
		top: 0;
		justify-content: right;
		margin-right: 30px;
		/* text-decoration: underline; */
	}
	.pre-login-form{
		position: relative;
	}
	.pre-login-head{
		margin-top: 4vh;
		width: 25vmax !important;
	}
}
.pamphlet-btn{
	min-width: 74px !important;
	height: 23px !important;
	border-radius: 12px !important;
	background-color: #D4F2F9 !important;
	font-family: Pretendard !important;
	color: #454545 !important;
	font-size: 11px !important;
}
</style>