<template>
	<div>
		<p class="txt-list-title">Site List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the user ID to search."
				v-model="searchText"
			/>
		</div>
		<table class="tb-list-vertical no-cursor">
			<colgroup>
				<col width="5%" />
				<col width="20%" />
				<col width="15%" />
				<col width="15%" />
				<col width="25%" />
				<col width="20%" />
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Name</th>
					<th>Code</th>
					<th>Manager</th>
					<th>Etc</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				<tr 
					v-for="(site, index) in siteList"
					:key="index"
					tabindex="0"
				>
					<td>
						{{ site.rowNumber}}
					</td>
					<td class="ellipsis underline" :title="site.siteName">
						{{ site.siteName }}
					</td>
					<td>
						{{ site.siteCode }}
					</td>
					<td>{{ site.managerName }} ({{ site.managerTelno }}) </td>
					<td class="ellipsis" :title="site.siteEtc">
						{{ site.siteEtc }}
					</td>
					<td>
						<label class="custom-check">
							<input
								:disabled="workAccessCheck('PROJECT PERMISSION') == false || site.adminSite != ''"
								type="checkbox"
								:name="'confirm' + index"
								v-model="siteList[index].approvalStatus"
								@click="userChangeApproval(site)"
							/>
							<span></span>
						</label>
					</td>
				</tr>
				<tr v-if="siteList.length == 0">
					<td colspan="6" class="no-list">There is no registered site, Please register the site.</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectSiteList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "SITE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You ar e not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectSiteList();
		this.getAccessList('SITE');
	},
	data() {
		return {
			item: {},
			siteList: [],
			pagination: {},
			searchText: "",
			isCreate: false,
			isEdit: false,
			selectSiteCode: "",
			isTyping: false,
			accessList: [],
		};
	},
	watch: {
		searchText: function(val) {
			this.item.siteName = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");

			this.item.projectNo = this.$route.params.projectNo;
			axios
				.post(
					this.config.contextRoot + "/site/selectProjectList.do", this.item)
				.then(response => {
					console.log(response);
					this.siteList = response.data.siteList;
					this.pagination = response.data.pagination;
					for(let i = 0 ; i < this.siteList.length ; i++) {
						if(this.siteList[i].projectSiteNo != null && this.siteList[i].projectSiteNo != ''){
							this.siteList[i].approvalStatus = true;
						}
						else{
							this.siteList[i].approvalStatus = false;
						}
					}
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch(error => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage(page)");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectSiteList();
		},
		userChangeApproval(site) {
			this.logger.debug(this, "userChangeApproval(index)");
			site.projectNo = this.$route.params.projectNo;
			if(site.approvalStatus == true)
			{
				site.approvalStatus = 0;
			}
			else
			{
				site.approvalStatus = 1;
			}

			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/site/UpdateSiteProjectAuth.do", site)
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						location.reload();
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>