import { render, staticRenderFns } from "./TestScreeningCreate.vue?vue&type=template&id=45c25ca9&scoped=true&"
import script from "./TestScreeningCreate.vue?vue&type=script&lang=js&"
export * from "./TestScreeningCreate.vue?vue&type=script&lang=js&"
import style0 from "./TestScreeningCreate.vue?vue&type=style&index=0&id=45c25ca9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c25ca9",
  null
  
)

export default component.exports