<template>
	<div style="height: 100%;"> 
		<p class="txt-list-title">Emr Data List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the data value to search."
				@keyup.enter="selectSearchText()"
				v-model="searchText"
			/>
			<div class="btn-wrap"> 
				<button
					type="button"
					class="btn-new"
					title="New EMR DATA"
					@click="doCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New EMR Data
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Upload EMR"
					@click="allView()"
				>
					All View
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Upload EMR"
					@click="loadRow()"
				>
					+100 More
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Upload EMR"
					@click="openUploader('EmrDataUploader')"
				>
					Upload
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Download Template"
					@click="doDownloadTemplate()"
				>
					Download Template
				</button>
			</div>
		</div>
		<!-- <div ref="scrollWrap" class="scroll-wrap scroll" style="height: calc(100% - 59px); overflow-y: scroll;"> -->
			<div class="emr-data-div scroll" style="height: calc(100% - 59px);">
				<table class="tb-list-vertical emr-data-list">
					<thead style="position: sticky; top: 0px;">
						<tr>
							<th style="border-top: none;">
								Row Type
							</th>
							<th
								v-for="(col, colIndex) in columnList"
								:key="colIndex"
								style="border-top: none;"
							>
								{{col.columnEnglishName}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(data, dataIndex) in dataList"
							:key="dataIndex"
							tabIndex="0"
						>
							<td>
								{{ data.dataType }}
							</td>
							<td
								v-for="(col, colIndex) in columnList"
								:key="colIndex"
								:style="data[col.columnName] != null ? '' : 'color: #ccc;'"
								:class="(data[col.columnName] != null ? (data[col.columnName].indexOf(searchText) >= 0 && searchText != '' ? 'yellow' : '') : '')"
							>
								{{ data[col.columnName] != null ? data[col.columnName] : '(EMPTY)' }}
							</td>
						</tr>
						<tr v-if="dataList.length == 0">
							<td :colspan="columnList.length + 1" class="no-list">
								There is no registered data, Please register the data.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		<!-- </div> -->
		<EmrDataCreate v-if="isCreate" />
		<EmrDataEdit v-if="isEmrEdit" :param="selectData" />
		<common-file
			ref="EmrDataUploader"
			v-model="uploadData.fileList"
			fileMaxSize="500"
			fileExtension="xlsx"
			style="display: none;"
		/>
		<div v-if="isMethod" class="prompt">
			<div class="wrap">
				<div class="top">
					Select Import Method
					<button type="button" class="close" @click="cancelData()">
						×
					</button>
				</div>
				<div class="bottom">
					<p>Please choose  the import method</p>
					<button type="button" class="white" @click="importData(false)">
						Override
					</button>
					<button type="button" class="blue" @click="importData(true)">
						Add
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import EmrDataCreate from "@/components/setting/emrConnector/EmrDataCreate.vue";
import EmrDataEdit from "@/components/setting/emrConnector/EmrDataEdit.vue";
import CommonFile from "@/components/common/CommonFile";

export default {
	name: "EmrDataManageList",
	components: {
		EmrDataCreate,
		EmrDataEdit,
		CommonFile
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "EMR DATA MANAGE ACCESS"
		}

		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectColumnList();
	},
	data() {
		return {
			item: {},
			columnList: [],
			dataList: [],
			isCreate: false,
			isEmrEdit: false,
			selectData: {},
			searchText: "",
			uploadData: {},
			isMethod: false,
			totalCurrentPage: 100,
		};
	},
	watch: {
		uploadData: function() {
			if(this.uploadData.fileList.length == 1)
			{
				var fileExtension = this.uploadData.fileList[0].fileExtensionName;
				if(fileExtension == "xlsx" || fileExtension == "XLSX")
				{
					this.isMethod = true;
				}
				else
				{
					this.$alert("Please upload the file in Excel format.","Warning","warning").then(() => {
						location.reload();
					});
				}
			}
		}
	},
	methods: {
		selectColumnList() {
			this.logger.debug(this, "selectColumnList()");
			axios
				.post(this.config.contextRoot + "/emr/selectColumnOneList.do", this.item)
				.then((response) => {
					console.log(response);
					this.columnList = response.data.emrColumnOneList;
					this.selectDataList();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectDataList() {
			EventBus.$emit("setting-loading", true);
			this.logger.debug(this, "selectDataList()");
			this.item.totalCurrentPage = this.totalCurrentPage;
			axios
				.post(this.config.contextRoot + "/emr/selectDataList.do", this.item)
				.then((response) => {
					console.log(response);
					this.dataList = response.data.emrDataList;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSearchText(){
			this.item.searchText = this.searchText;
			this.totalCurrentPage = 0;
			if(this.searchText == ""){
				this.totalCurrentPage = 100;
			}
			this.selectDataList();
		},
		loadRow(){
			if(this.searchText == "" && this.totalCurrentPage != 0){
				this.totalCurrentPage = this.totalCurrentPage + 100;
				this.selectDataList();
			}
		},
		allView(){
			this.totalCurrentPage = 0;
			this.item.searchText = "";
			this.selectDataList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		doEdit(data) {
			this.logger.debug(this, "doEdit(agency)");
			this.selectData = data;
			this.isEmrEdit = true;
		},
		// doDelete(auth) {
		// 	this.logger.debug(this, "doDelete(agency)");
		// 	this.$confirm("Are you sure you want to delete " + auth.authName +"?", "Question", "question").then(() => {
		// 		var param = {authNo: auth.authNo};
				
		// 		axios
		// 			.post(this.config.contextRoot + "/auth/delete.do", param)
		// 			.then((response) => {
		// 				console.log(response);
		// 				if(response.data.totCnt > 0)
		// 				{
		// 					this.$alert("The user with the auth exists and cannot be deleted.", "Warn", "warning");
		// 				}
		// 				else
		// 				{
		// 					EventBus.$emit("setting-loading", true);
		// 					location.reload();
		// 				}
		// 			})
		// 			.catch((error) => {
		// 				this.exceptionHandler(this, error);
		// 			});
		// 	});
		// },
		openUploader(refName) {
			this.logger.debug(this, "openUploader()");
			this.$refs[refName].openUploader();
		},
		cancelData() {
			this.isMethod = false;
			this.uploadData = {};
		},
		importData(isAdd) {
			this.logger.debug(this, "importData()");
			this.isMethod = false;
			this.$confirm("Do you want to import emr data?","Question","question").then(() => {
				axios
					.post(this.config.contextRoot + "/emr/excelDataUpload.do", {fileList: this.uploadData.fileList, isAdd: isAdd})
					.then((response) => {
						if(response.data.result == "Error"){
							this.$alert("Template mismatch." ,"Warn","warning");
						}
						else if(response.data.result == "Success"){
							this.$alert("The emr data has been successfully imported.","Success","success");
							location.reload();
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
						location.reload();
					});
			});
		},
		doDownloadTemplate() {
			this.logger.debug(this, "doDownloadTemplate()");
			this.$confirm("Would you like to download a template?", "Question", "question").then(() => {
				axios({
					method: 'POST',
					url: '../emr/excelDataTemplateDownload.do',
					responseType: 'blob',
					headers: {
						"Content-Type": "application/json"
					}
				})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'dataTemplate.xlsx');
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			});
		}
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}
.btn-wrap {
	display: block;
	position: relative;
	float: right;
}

.emr-data-list{
	width:100%;

}
.emr-data-list th{
	width:100px;
}

.emr-data-div{
	overflow:auto;
}

.prompt {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000066;
	z-index: 1060;
}

.prompt > .wrap {
	display: block;
	width: 512px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 2px 2px 5px 0 #666;
}

.prompt > .wrap > .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 15px 25px;
	border-radius: 10px 10px 0 0;
	background-color: #eee;
	font-weight: 600;
	font-size: 13pt;
	color: #2bade0;
}

.prompt > .wrap > .top > .close {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 5px;
	background: #fff;
	font-family: serif;
	font-weight: 600;
	color: #999;
}

.prompt > .wrap > .top > .close:hover {
	border: 1px solid #666;
	color: #666;
}

.prompt > .wrap > .bottom {
	position: relative;
	padding: 25px;
	border-radius: 0 0 10px 10px;
	background-color: #fff;
	font-weight: 600;
	font-size: 11pt;
	color: #333;
	text-align: center;
}

.prompt > .wrap > .bottom > button {
	width: 120px;
	height: 36px;
	margin: 30px 10px 0 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #979ea5;
}

.prompt > .wrap > .bottom > button:hover {
	border: 1px solid #2bade0;
	background: #2bade0;
	color: #fff;
}

.tb-list-vertical th,
.tb-list-vertical td {
	padding: 0 20px;
	white-space: nowrap;
	width:100px;
}
.yellow {
	background-color: yellow !important;
}
</style>