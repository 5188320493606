<template>
	<div>
		<p class="txt-list-title">Validation List</p>
		
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the validation name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button
				v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')"
				type="button"
				class="btn-new"
				title="New Validation"
				@click="doCreate()"
			>
				<img src="@/assets/images/common/btn_new.png" alt="" />
				New Validation
			</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="50%" />
				<col width="10%" />
				<col width="15%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						Validation Name
					</th>
					<th>
						RegistId
					</th>
					<th>
						RegistDate
					</th>
					<th>
						Use
					</th>
					<th>
						Delete
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(validation, index) in validationList"
					:key="index"
					tabindex="0"
					@click.stop="doEdit(validation)"
				>
					<td>
						{{ validation.rowNumber }}
					</td>
					<td class="ellipsis underline" :title="validation.validationName">
						{{ validation.validationName }}
					</td>
					<td class="ellipsis" :title="validation.firstRegistUserId">
						{{ validation.firstRegistUserId }}
					</td>
					<td class="ellipsis" :title="validation.firstRegistDate">
						{{ validation.firstRegistDate.substring(0, 10) }}
					</td>
					<td>
						{{ validation.useYn }}
					</td>
					<td>
						<button
							v-if="workAccessCheck('DELETE') && !(projectStateCd == '4' || projectStateCd == '3')"
							type="button"
							class="btn-remove"
							title="Remove Validation"
							@click.stop="doDelete(validation)"
						></button>
					</td>
				</tr>
				<tr v-if="validationList.length == 0">
					<td colspan="6" class="no-list">
						There is no registered validation, Please register the validation.
					</td>
				</tr>
			</tbody>
		</table>
		
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ValidationList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "VALIDATION",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.item.projectNo = this.$route.params.projectNo;
		
		this.selectValidationList();
		this.getAccessList('VALIDATION');
		this.getProjectStateCd();
	},
	data() {
		return {
			item: {},
			validationList: [],
			pagination: {},
			searchText: "",
			isTyping: false,
			accessList: [],
			projectStateCd: ""
		};
	},
	watch: {
		searchText: function(val) {
			this.item.searchText = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectValidationList() {
			this.logger.debug(this, "selectValidationList()");
			axios
				.post(this.config.contextRoot + "/valid/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.validationList = response.data.validationList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectValidationList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/validation/create"
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(validation) {
			this.logger.debug(this, "doEdit(validation)");
			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/validation/edit/" + validation.validNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		testEntryUsedCheck(validation){
			this.logger.debug(this, "testEntryUsedCheck()");
			axios
				.post(this.config.contextRoot + "/valid/testEntryUsedCheck.do", validation)
				.then((response) => {
					console.log(response);
					if(response.data.result == 'true'){
						this.$confirm("Are you sure you want to delete the validation?", "Question", "question").then(() => {
						EventBus.$emit("project-loading", true);
						var param = {projectNo: this.item.projectNo, validNo: validation.validNo}
						axios
							.post(this.config.contextRoot + "/valid/delete.do", param)
							.then((response) => {
								console.log(response);
								location.reload();
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					});
					}
					else{
						this.$alert("Used Test Entry!", "Warning", "warning");
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doDelete(validation) {
			this.logger.debug(this, "doDelete(validation)");
			if (!this.utils.isEmpty(this.item.projectNo) && !this.utils.isEmpty(validation.validNo))
			{
				if(validation.useQueryYn == 'Y'){
					this.$alert("Used Query!", "Warning", "warning");
				}
				else{
					this.testEntryUsedCheck(validation);
				}
			}
			else this.$alert("An error has occurred, Project Number or Validation Number is empty!", "Error", "error");
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.selectValidationList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>