<template>
	<div ref="repeatField" class="dialog-container" style="width: 100%; left: 0px; z-index: 100;">
		<div class="dialog" style="max-height: 600px; max-width: 600px;transform: translate(0, -360px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Repeat Fields
					</p>
				</div>
				<div class="center">
					<table
						v-for="name in fieldName" :key="name"
						class="tb-crud-horizon"
						style="margin-bottom: 25px;"
					>
						<colgroup>
							<col width="11%" />
							<col width="39%" />
						</colgroup>
						<tbody>
							<tr>
								<th>
									Field Name
								</th>
								<td colspan="3">
									<input class="input-crud" style="font-weight: 600;" type="text" :value="name" disabled>
								</td>
							</tr>
							<tr>
								<th>Field List.</th>
								<td>
									<div v-for="(field, index) in calcRepeatList" :key="index">
										<div v-if="field.fieldChildName == name || field.procFieldName == name">
											<label class="radio-crud">
												<input
													type="radio"
													:name="'selectRepeat_' + name"
													:value="field.fieldValue"
													v-validate="'required'"
													@click.stop="selectField(index)"
												/>
												<span class="radio"></span>
												{{ field.fieldValue }}
											</label>
										</div>
									</div>
									<p class="txt-validation" v-if="errors.has('selectVisit')">
										* {{ errors.first("selectVisit") }}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Confirm
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";

export default {
    name: "RepeatField",
    created () {
		this.logger.debug(this, "created()");

		this.removeDuplicate();
	},
    data() {
        return {
			fieldName: [],
		};
    },
	props: {
		calcRepeatList: {
			type: Array,
			required: true
		},
	},
    methods: {
        doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Repeat Field registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.repeatField.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.repeatField.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				this.$parent.closeRepeat('cancel');
				return false;
			}.bind(this), 410);
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the current Repeat Field?", "Question", "question").then(() => {
							EventBus.$emit("project-loading", true);

							this.$parent.setRepeatValue(this.calcRepeatList);

							this.$refs.repeatField.style.animation = "fadeOut 0.4s ease-in-out both";
							this.$refs.repeatField.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
							setTimeout(function() {
								this.$parent.closeRepeat('confirm');
								EventBus.$emit("project-loading", false);
							}.bind(this), 410);
						});
					}
					else{
						this.$alert("Please select a value!", "Warn", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
        },
		removeDuplicate() {
			console.log(this.calcRepeatList);
			var fieldName = [];
			this.calcRepeatList.forEach((item) => {
				if(!this.utils.isEmpty(item.fieldChildName))
				{
					fieldName.push(item.fieldChildName);
				}
				else
				{
					fieldName.push(item.procFieldName);
				}
				var fieldNameSet = [...new Set(fieldName)];
				this.fieldName = fieldNameSet;
			})
		},
		selectField(index) {
			this.$set(this.calcRepeatList[index], "isCalcRepeat", true);
		}
    },
}
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>