<template>
	<div ref="dataCreate" class="full-container">
		<div class="full-top-wrap">
			Subject Create
		</div>
		<div class="full-center-wrap">
			<form @submit.prevent="doValidation()">
				<p class="txt-crud-title">
					Subject Form
				</p>
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>Subject</th>
							<td colspan="3">
								<div class="flex-center" style="margin: 0 0 5px;">
									<label class="radio-crud">
										<input
											type="radio"
											name="numberingIsAuto"
											:value="true"
											v-model="numberingIsAuto"
											@click="items.screeningName = originScreeningName"
										/><div class="radio" />
										Auto
									</label>
									<label class="radio-crud">
										<input
											type="radio"
											name="numberingIsAuto"
											:value="false"
											v-model="numberingIsAuto"
											@click="items.screeningName = ''"
										/><div class="radio" />
										Typing
									</label>
									<span class="txt-info" style="margin: 0;">
										* [N3] → 001~999
									</span>
								</div>
								<input
									type="text"
									class="input-crud"
									:class="(errors.has('screeningName') || isDuplicate ? 'input-crud-error' : '')"
									name="screeningName"
									v-model="items.screeningName"
									v-validate="'required'"
									data-vv-as="Screening"
									:readonly="numberingIsAuto"
									:placeholder="(errors.has('screeningName') ? 'The Subject is required' : 'please enter the Subject')"
									style="width: 43%;"
									@keyup="doDuplicateCheck()"
								/>
								<div v-if="isDuplicate" class="duplicate-balloon" style="display: inline-block;">
									* Numbering is duplicated.
								</div>
							</td>
						</tr>
						<tr>
							<th>
								Subject No.
							</th>
							<td>
								<input
									type="text"
									class="input-crud"
									:class="(errors.has('subjectNo') || isDuplicate ? 'input-crud-error' : '')"
									name="subjectNo"
									v-model="items.subjectNo"
									data-vv-as="Screening"
									:placeholder="(errors.has('subjectNo') ? 'The Subject No. is required' : 'please enter the Subject No.')"
								/>
							</td>
							<th>
								Initial
							</th>
							<td>
								<input
									type="text"
									class="input-crud"
									:class="(errors.has('initial') || isDuplicate ? 'input-crud-error' : '')"
									name="initial"
									v-model="items.initial"
									data-vv-as="Screening"
									:placeholder="(errors.has('initial') ? 'The Initial is required' : 'please enter the Initial')"
								/>
							</td>
						</tr>
						<tr>
							<th>
								Site
							</th>
							<td>
								<select
									class="select-crud"
									:class="(errors.has('siteCode') ? 'input-crud-error' : '')"
									name="siteCode"
									v-model="items.siteCode"
									v-validate="'required'"
									data-vv-as="Site"
									style="width: 100%;"
									disabled
								>
									<option value="" selected disabled>--- select ---</option>
									<option v-for="(item, index) in siteList" :key="index" :value="item.siteCode">
										{{ item.siteName }}
									</option>
								</select>
							</td>
							<th>
								Consent Date
							</th>
							<td>
								<label 
									class="label-date-picker"
									:style="utils.isEmpty(items.informedConsentDt)? 'color: #ccc' : 'color: #999'"
								>
									{{
										(
											getDateValue()
										) 
									}} 
									<i class="mx-icon">
										<i class="mx-icon-calendar">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
													<path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z">
													</path>
												</svg>
											</i>
										<i class="mx-icon-clear"
											@click="clearDate()"
										>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
												<path d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z">
												</path>
											</svg>
										</i>
									</i>
									<date-picker
										ref="date-picker"
										v-model="items.informedConsentDtTemp"
										value-type="format"
										format="YYYY-MM-DD"
										:lang="lang"
										style="width: 0px; height: 0px; overflow: hidden;"
										v-validate="'required'"
										name="informedConsentDt"
										data-vv-as="Consent Date"
										confirm
									/>
								</label>
								<p class="txt-validation" v-if="errors.has('informedConsentDt')">
										* {{ errors.first("informedConsentDt") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>
								Register
							</th>
							<td>
								{{ items.firstRegistUserId }}
							</td>
							<th>
								Regist Date
							</th>
							<td>
								{{ items.firstRegistDate }}
							</td>
						</tr>
						<tr>
							<th>
								Note
							</th>
							<td colspan="3">
								<textarea
									class="textarea-crud"
									name="screeningNote"
									v-model="items.screeningNote"
								>
								</textarea>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div class="full-bottom-wrap">
			<button v-if="workAccessCheck('CREATE') && !(projectStateCd == '4')" type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
				Save
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()" style="margin-right: 10px;">
				Cancel
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import EventBus from "@/script/EventBus.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
	name: "ScreeningCreate",
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.items.firstRegistDate = moment().format("YYYY-MM-DD");
		this.items.firstRegistUserId = this.userInfo.userId;
		this.selectScreeningNumber();
		this.selectSiteList();
		this.getAccessList('ENTRY');
		this.getProjectStateCd();
	},
	components: {
		DatePicker
	},
	data() {
		return {
			items: {},
			numberingIsAuto: true,
			originScreeningName: "",
			isDuplicate: false,
			siteList: [],
			lang: {
				formatLocale: {
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			accessList: [],
			projectStateCd: ""
		};
	},
	watch: {},
	methods: {
		selectScreeningNumber() {
			this.logger.debug(this, "selectScreeningNumber()");
			axios
				.post(this.config.contextRoot + "/screening/selectScreeningNumber.do", {userId: this.items.firstRegistUserId})
				.then((response) => {
					console.log(response);
					let siteCode = response.data.userVo.siteCode;
					let screeningName = siteCode + "_TB_" + moment().format("YYYYMMDD") + "_[N3]";
					this.$set(this.items, "siteCode", siteCode);
					this.$set(this.items, "screeningName", screeningName);
					this.originScreeningName = screeningName;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");
			axios
				.post(this.config.contextRoot + "/site/selectList.do", {})
				.then((response) => {
					console.log(response);
					this.siteList = response.data.siteList;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		leftPad(value) {
			if (value >= 10) {
				return value;
			}
			return '0' + value;
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/screening/duplicateCheck.do", this.items)
				.then((response) => {
					console.log(response);
					if(response.data.screeningExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the current data?","Question","question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			this.$set(this.items, "informedConsentDt", this.items.informedConsentDt);
			axios
				.post(this.config.contextRoot + "/screening/insert.do", this.items)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/screening";
						if (this.$route.path !== url)
						{
							this.$router.push(url);
						}
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the data registration?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.dataCreate.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/screening"
				if (this.$route.path !== url)
				{
					EventBus.$emit("project-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 410);
		},
		getDateValue() {
			var informedConsentDtTemp = this.items.informedConsentDtTemp;
			var informedConsentDt = this.items.informedConsentDt;

			if(this.utils.isEmpty(informedConsentDtTemp))
			{
				if(this.utils.isEmpty(informedConsentDt))
				{
					return "YYYY-MM-DD"
				}
				else
				{
					return informedConsentDt;
				}
			}
			else
			{
				{
					this.$set(this.items, "informedConsentDt", informedConsentDtTemp);
				}
				return this.items.informedConsentDt;
			}
		},
		clearDate() {
			this.$set(this.items, "informedConsentDt", "");
			this.$set(this.items, "informedConsentDtTemp", "");
			setTimeout(function() {
				let popup = document.getElementsByClassName("mx-datepicker-popup")[0];
				popup.style.display = "none";
			}.bind(this), 0);
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>
<style scoped>
.label-date-picker {
	display: flex;
    align-items: center;
}
</style>