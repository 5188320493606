<template>
	<div ref="projectDataCreate" class="dialog-container">
		<div class="dialog" style="max-height: 750px; transform: translate(0, -420px);">
			<div class="top"> 
				<p class="txt-crud-title">
					Project Data Create
				</p>
			</div>
			<p class="title">-Field Data List</p>
			<div class="center field-flex" style="height: calc(100% - 131px);">
				<div class="survey-category" style="margin: 0; height: 100%;">
					<div class="dic-category-list scroll no-drag" style="margin: 0; border-top: 1px solid #ccc; height: 100%;">
						<div
							class="field-group"
						>
							<button
								type="button"
								class="group-name group-type"
								style="cursor: auto !important;"
							>
								<div class="allIcon">
									A
								</div>
								<div class="dic-list">
									All Select
								</div>
								<label class="check-label">
									<input
										type="checkbox"
										name="checkAll"
										v-model="isAllCheck"
										@click.stop="allCheck('ALL', $event)"
									/>
									<span class="checkbox"></span>
								</label>
							</button>
						</div>
						<div
							v-for="(visitItem, vindex) in fieldList"
							:key="vindex"
							class="field-group"
							:class="(visitOpenList.indexOf(visitItem.visitNo) >= 0 ? '' : 'group-off')"
						>
							<button
								type="button"
								class="group-name group-type"
								:title="visitItem.visitName"
								style="cursor: auto !important;"
							>
								<div class="groupIcon">
									V
								</div>
								<div class="dic-list">
									{{ visitItem.visitName + " "}}
									<span style="color: #bbb;">{{ (visitItem.unscheduleYn == 'Y' ? '[UnSchedule]': '') }}</span>
								</div>
								<label class="check-label">
									<input
										type="checkbox"
										v-model="checkedVisitList"
										:value="visitItem.visitNo"
										@click.stop="allCheck('VISIT', $event, visitItem)"
									/>
									<span class="checkbox"></span>
								</label>
								<div
									class="groupDropdown"
									style="cursor: pointer;"
									@click.stop="foldVisit(visitItem.visitNo)"
								>
									&gt;
								</div>
							</button>
							<div
								v-for="(pageItem, pIndex) in visitItem.pageList"
								:key="pIndex"
								class="field-group field-question"
								:class="(pageOpenList.indexOf(visitItem.visitNo+'/'+pageItem.pageNo) >= 0 ? '' : 'group-off')"
							>
								<button
									type="button"
									class="group-name field-type"
									:title="pageItem.pageLabel"
									style="cursor: auto !important;"
								>
									<div class="fieldIcon">
										Pa
									</div>
									<div class="dic-list">
										{{ pageItem.pageLabel }}
									</div>
									<label class="check-label">
									<input
										type="checkbox"
										v-model="checkedPageList"
										:value="visitItem.visitNo+'/'+pageItem.pageNo"
										@click.stop="allCheck('PAGE', $event, visitItem, pageItem)"
									/>
									<span class="checkbox"></span>
								</label>
									<div
										class="fieldDropdown"
										style="cursor: pointer;"
										@click.stop="foldPage(visitItem.visitNo, pageItem.pageNo)"
									>
										&gt;
									</div>
								</button>
								<div
									v-for="(procItem, prIndex) in pageItem.procedureList"
									:key="prIndex"
									class="field-group field-question"
									:class="(procOpenList.indexOf(visitItem.visitNo+'/'+pageItem.pageNo+'/'+procItem.procNo) >= 0 ? '' : 'group-off')"
								>
									<button
										type="button"
										class="group-name proc-type"
										:title="procItem.pageLabel"
										style="cursor: auto !important;"
									>
										<div class="procIcon">
											Pr
										</div>
										<div class="dic-list">
											{{ procItem.procLabel }}
										</div>
										<label class="check-label">
											<input
												type="checkbox"
												v-model="checkedProcList"
												:value="visitItem.visitNo+'/'+pageItem.pageNo+'/'+procItem.procNo"
												@click.stop="allCheck('PROC', $event, visitItem, pageItem, procItem)"
											/>
											<span class="checkbox"></span>
										</label>
										<div
											class="fieldDropdown"
											style="cursor: pointer;"
											@click.stop="foldProc(visitItem.visitNo, pageItem.pageNo, procItem.procNo)"
										>
											&gt;
										</div>
									</button>
									<div
										v-for="(procQuestionItem, pqIndex) in procItem.procQuestionList"
										:key="pqIndex"
										class="field-group field-question"
									>
										<button
											type="button"
											class="group-name procQuestion-type"
											:title="procQuestionItem.questionName"
											style="cursor: auto !important;"
										>
											<div class="procQuestionIcon">
												F
											</div>
											<label class="check-label">
												<input
													type="checkbox"
													v-model="checkedProcQuestionList"
													:value="visitItem.visitNo+'/'+pageItem.pageNo+'/'+procItem.procNo+'/'+procQuestionItem.procQuestionNo"
													@click.stop="allCheck('PROCQUESTION', $event, visitItem, pageItem, procItem, procQuestionItem)"
												/>
												<span class="checkbox"></span>
											</label>
											<div class="dic-list">
												{{ procQuestionItem.questionName + " [" + procQuestionItem.questionFieldName + "]" }}
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="17%" />
						<col width="83%" />
					</colgroup>
					<tbody>
						<tr>
							<th>File Encryption</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										value="Y"
										v-model="encryptYn"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										value="N"
										v-model="encryptYn"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
						<tr>
							<th>Password</th>
							<td>
								<input
									type="text"
									class="input-crud"
									name="password"
									v-model="item.password"
									v-validate="'required'"
									data-vv-as="Password"
									:disabled="this.encryptYn === 'N'"
								/>
							</td>
							<p class="txt-validation" v-if="errors.has('password')">
								* {{ errors.first("password") }}
							</p>
						</tr>
						<tr>
							<th>Data Protection</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										value="Y"
										v-model="protectYn"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										value="N"
										v-model="protectYn"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="bottom">
				<button type="button" class="btn-save fright" title="Create" @click.stop="doDataDownload()">
					Create
				</button>
				<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
					Cancel
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectDataCreate",
	created() {
		this.logger.debug(this, "created()");
		this.item.projectNo = this.projectNo;
		this.selectProcFieldList();
	},
	props: {
		projectNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {},
			encryptYn: "N",
			protectYn: "N",
			fieldList: [],
			visitOpenList : [],
			pageOpenList: [],
			procOpenList: [],
			isClicked: {},
			checkedVisitList: [],
			checkedPageList: [],
			checkedProcList: [],
			checkedProcQuestionList: [],
			isAllCheck: false,
		};
	},
	methods: {
		doDataDownload() {
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "doDataDownload(projectNo)");
			this.item.encryptYn = this.encryptYn;
			this.item.protectYn = this.protectYn;
			this.item.procQuestionList = this.checkedProcQuestionList;

			axios
				.post(this.config.contextRoot + "/excel/excelCreateResult.do", this.item, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					
					let today = new Date();
					let year = today.getFullYear();
					let month = ('0' + (today.getMonth() +1)).slice(-2);
					let day = ('0' + (today.getDate())).slice(-2);

					today = year + "-" + month + "-" + day;

					link.setAttribute("download", "ScreeningOutcome_"+today+".xlsx");
					document.body.appendChild(link);
					link.click();

					EventBus.$emit("project-loading", false);
					
					this.$alert("The file has been downloaded.", "Info", "info").then((result) => {
						if (result === true) {
							location.reload();
						}
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		selectProcFieldList(){
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "selectProcFieldList()");
			this.isClicked = {};
			axios
				.post(this.config.contextRoot + "/project/allProcList.do", this.item)
				.then((response) => {
					console.log(response);
					this.fieldList = response.data.fieldList;
					console.log(this.fieldList)
					this.$nextTick(function () {
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel creating the project's data?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.projectDataCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.projectDataCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isDataCreate");
			}.bind(this), 410);
		},
		foldVisit(visitNo) {
			this.logger.debug(this, "foldVisit(visitNo)");
			let index = this.visitOpenList.indexOf(visitNo);
			if(index >= 0)
			{
				this.visitOpenList.splice(index, 1);
			}
			else
			{
				this.visitOpenList.push(visitNo);
			}
		},
		foldPage(visitNo, pageNo) {
			this.logger.debug(this, "foldPage(pageNo)");
			let index = this.pageOpenList.indexOf(visitNo+'/'+pageNo);
			if(index >= 0)
			{
				this.pageOpenList.splice(index, 1);
			}
			else
			{
				this.pageOpenList.push(visitNo+'/'+pageNo);
			}
		},
		foldProc(visitNo, pageNo, procNo) {
			this.logger.debug(this, "foldProc(procNo)");
			let index = this.procOpenList.indexOf(visitNo+'/'+pageNo+'/'+procNo);
			if(index >= 0)
			{
				this.procOpenList.splice(index, 1);
			}
			else
			{
				this.procOpenList.push(visitNo+'/'+pageNo+'/'+procNo);
			}
		},
		allCheck(type, event, visit, page, proc){
			this.logger.debug(this, "allCheck(event)");
			if(type == 'ALL'){
				if (event.target.checked) {
					this.isAllCheck = true;

					for(let i = 0; i < this.fieldList.length; i++){
						if(this.checkedVisitList.indexOf(this.fieldList[i].visitNo) < 0){
							this.checkedVisitList.push(this.fieldList[i].visitNo);
						}
						for(let j = 0; j < this.fieldList[i].pageList.length; j++){	
							if(this.checkedPageList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo) < 0){
								this.checkedPageList.push(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo);
							}
							for(let k = 0; k < this.fieldList[i].pageList[j].procedureList.length; k++){
								if(this.checkedProcList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo) < 0){
									this.checkedProcList.push(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo);
								}
								for(let l = 0; l < this.fieldList[i].pageList[j].procedureList[k].procQuestionList.length; l++){
									if(this.checkedProcQuestionList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].procQuestionNo) < 0){
										this.checkedProcQuestionList.push(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].procQuestionNo);
									}	
								}
							}
						}
					}
				}
				else{
					this.isAllCheck = false;

					for(let i = 0; i < this.fieldList.length; i++){
						if(this.checkedVisitList.indexOf(this.fieldList[i].visitNo) >= 0){
							this.checkedVisitList.splice(this.checkedVisitList.indexOf(this.fieldList[i].visitNo), 1);
						}
						for(let j = 0; j < this.fieldList[i].pageList.length; j++){	
							if(this.checkedPageList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo) >= 0){
								this.checkedPageList.splice(this.checkedPageList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo), 1);
							}
							for(let k = 0; k < this.fieldList[i].pageList[j].procedureList.length; k++){
								if(this.checkedProcList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo) >= 0){
									this.checkedProcList.splice(this.checkedProcList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo), 1);
								}
								for(let l = 0; l < this.fieldList[i].pageList[j].procedureList[k].procQuestionList.length; l++){
									if(this.checkedProcQuestionList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].procQuestionNo) >= 0){
										this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(this.fieldList[i].visitNo + '/' + this.fieldList[i].pageList[j].pageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].procQuestionNo), 1);
									}	
								}
							}
						}
					}
				}
			}
			if(type == 'VISIT'){
				if(this.checkedVisitList.indexOf(visit.visitNo) < 0){
					this.checkedVisitList.push(visit.visitNo);
				}

				if (event.target.checked) {
					for(let i = 0; i < visit.pageList.length; i++){
						if(this.checkedPageList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo) < 0){
							this.checkedPageList.push(visit.visitNo + '/' + visit.pageList[i].pageNo);
						}
						for(let j = 0; j < visit.pageList[i].procedureList.length; j++){
							if(this.checkedProcList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo) < 0){
								this.checkedProcList.push(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo);
							}
							for(let k = 0; k < visit.pageList[i].procedureList[j].procQuestionList.length; k++){
								if(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].procQuestionNo) < 0){
									this.checkedProcQuestionList.push(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].procQuestionNo);
								}
							}
						}
					}
				} 
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedVisitList.indexOf(visit.visitNo) >= 0){
						this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.visitNo), 1);
					}

					for(let i = 0; i < visit.pageList.length; i++){
						if(this.checkedPageList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo) >= 0){
							this.checkedPageList.splice(this.checkedPageList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo), 1);
						}
						for(let j = 0; j < visit.pageList[i].procedureList.length; j++){
							if(this.checkedProcList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo) >= 0){
								this.checkedProcList.splice(this.checkedProcList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo), 1);
							}
							for(let k = 0; k < visit.pageList[i].procedureList[j].procQuestionList.length; k++){
								if(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].procQuestionNo) >= 0){
									this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + visit.pageList[i].pageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].procQuestionNo), 1);
								}
							}
						}
					}
				}
			}

			if(type == 'PAGE'){
				if (event.target.checked) {
					if(this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo) < 0){
						this.checkedPageList.push(visit.visitNo + '/' + page.pageNo);
					}

					for(let i = 0; i < page.procedureList.length; i++){
						if(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo) < 0){
							this.checkedProcList.push(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo);
						}
						for(let j = 0; j < page.procedureList[i].procQuestionList.length; j++){
							if(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].procQuestionNo) < 0){
								this.checkedProcQuestionList.push(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].procQuestionNo);
							}
						}
					}
				} 
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo) >= 0){
						this.checkedPageList.splice(this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo), 1);
					}

					for(let i = 0; i < page.procedureList.length; i++){
						if(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo) >= 0){
							this.checkedProcList.splice(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo), 1);
						}
						for(let j = 0; j < page.procedureList[i].procQuestionList.length; j++){
							if(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].procQuestionNo) >= 0){
								this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].procQuestionNo), 1);
							}
						}
					}
				}
			}

			if(type == 'PROC'){
				if (event.target.checked) {
					if(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo) < 0){
						this.checkedProcList.push(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo);
					}
					for(let i = 0; i < proc.procQuestionList.length; i++){
						let index = this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].procQuestionNo);
						if(index < 0){
							this.checkedProcQuestionList.push(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].procQuestionNo);
						}
					}
				}
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo) >= 0){
						this.checkedProcList.splice(this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo), 1);
					}

					for(let i = 0; i < proc.procQuestionList.length; i++){
						if(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].procQuestionNo) >= 0){
							this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].procQuestionNo), 1);
						}
					}
				}
			}

			if(type == 'PROCQUESTION'){
				if (!event.target.checked) {
					this.unCheckedLoop(type, visit, page, proc);
				}
			}
		},
		unCheckedLoop(type, visit, page, proc){
			if(type == "VISIT"){
				if(this.checkedVisitList.length == 1){
					this.isAllCheck = false;
				}
			}
			if(type == "PAGE"){
				let visitCount = 0;
				for(let i = 0; i < this.checkedPageList.length; i++){
					let split = this.checkedPageList[i].split('/');
					if(split[0] == visit.visitNo){
						visitCount += 1;
					}
				}
	
				if(this.checkedVisitList.indexOf(visit.visitNo) >= 0 && visitCount == 1){
					this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.visitNo), 1);
				}
			}
			if(type == "PROC"){
				let pageCount = 0;
				let visitCount = 0;
				for(let i = 0; i < this.checkedProcList.length; i++){
					let split = this.checkedProcList[i].split('/');
					if(split[0] == visit.visitNo && split[1] == page.pageNo){
						pageCount += 1;
					}
					if(split[0] == visit.visitNo){
						visitCount += 1;
					}
				}
	
				if(this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo) >= 0 && pageCount == 1){
					this.checkedPageList.splice(this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo), 1);
				}
				if(this.checkedVisitList.indexOf(visit.visitNo) >= 0 && visitCount == 1){
					this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.visitNo), 1);
				}
			}
			if(type == "PROCQUESTION"){
				let procCount = 0;
				let pageCount = 0;
				let visitCount = 0;
				for(let i = 0; i < this.checkedProcQuestionList.length; i++){
					let split = this.checkedProcQuestionList[i].split('/');
					if(split[0] == visit.visitNo && split[1] == page.pageNo && split[2] == proc.procNo){
						procCount += 1;
					}
					if(split[0] == visit.visitNo && split[1] == page.pageNo){
						pageCount += 1;
					}
					if(split[0] == visit.visitNo){
						visitCount += 1;
					}
				}
	
				let procindex = this.checkedProcList.indexOf(visit.visitNo + '/' + page.pageNo + '/' + proc.procNo);
				let pageindex = this.checkedPageList.indexOf(visit.visitNo + '/' + page.pageNo);
				let visitindex = this.checkedVisitList.indexOf(visit.visitNo);
				if(procindex >= 0 && procCount == 1){
					this.checkedProcList.splice(procindex, 1);
				}
				if(pageindex >= 0 && pageCount == 1){
					this.checkedPageList.splice(pageindex, 1);
				}
				if(visitindex >= 0 && visitCount == 1){
					this.checkedVisitList.splice(visitindex, 1);
				}
			}
		}
	}
};
</script>

<style scoped>
.radio-crud {
	width: 13%;
}
.btn-cancel {
	margin-right: 10px;
}
.field-flex{
	display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 50px;
}
.title {
    font-weight: 600;
    font-size: 10.5pt;
    color: #999;
	margin-top: 10px;
}
.proc-type{
	margin-left: 40px;
    border-bottom: 1px solid #999;
}
.procQuestion-type{
	margin-left: 60px;
    border-bottom: 1px solid #999;
}
.allIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: #dd574f;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.procIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: #ff9dae;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.procQuestionIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: gray;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.check-label {
    display: inline-flex;
    font-size: 10pt;
    color: inherit;
	position: absolute;
    right: 20px;
}
.check-label > input[type=checkbox] {
    display: none;
}
.check-label>.checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
	cursor: pointer;
}
.check-label > input[type=checkbox]:checked+.checkbox {
    border: 2px solid #2bade0;
}
.check-label > input[type=checkbox]:checked+.checkbox::before {
    content: "\2713";
    position: absolute;
    top: -11px;
    left: 2.5px;
    width: 16px;
    height: 16px;
    font-weight: 600;
    font-size: 13pt;
    color: #2bade0;
}
</style>