<template>
	<div ref="GroupCreate" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Question Group Create
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="33%" />
							<col width="17%" />
							<col width="33%" />
						</colgroup>
						<tbody>
                            <tr>
                                <th>Question Group Name.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        class="input-crud"
                                        name="questionGroupName"
										v-model="item.questionGroupName"
										v-validate="'required'"
										data-vv-as="Question Group Name"
                                     />
									<p class="txt-validation" v-if="errors.has('questionGroupName')">
										* {{ errors.first("questionGroupName") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Sort Order.</th>
                                <td colspan="3">
                                    <input 
                                        type="number"
                                        class="input-crud"
                                        name="questionGroupSortOrder"
										v-model="item.questionGroupSortOrder"
										v-validate="'required'"
										data-vv-as="Sort Order"
                                     />
									<p class="txt-validation" v-if="errors.has('questionGroupSortOrder')">
										* {{ errors.first("questionGroupSortOrder") }}
									</p>
                                </td>
                            </tr>
							<tr>
								<th>Register.</th>
								<td>
									{{firstRegistUserName}} ({{firstRegistUserId}})
								</td>
								<th>
									Regist Date.
								</th>
								<td>
									{{firstRegistDate }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";

export default {
	name: "fieldGroupCreate",
	created() {
		this.logger.debug(this, "created()");
		this.firstRegistDate = moment().format("YYYY-MM-DD");
		this.firstRegistUserId = this.userInfo.userId;
		this.firstRegistUserName = this.userInfo.userName;
		this.selectSortOrder();
	},
	data() {
		return {
			item: {},
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the current Question Group?", "Question", "question").then(() => {
							EventBus.$emit("field-loading", true);
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		selectSortOrder() {
			this.logger.debug(this, "selectSortOrder()");

			axios
				.post(this.config.contextRoot + "/field/selectSortOrder.do", this.item)
				.then((response) => {
					console.log(response);

					this.item = response.data.item;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			axios
				.post(this.config.contextRoot + "/field/insertGroup.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						// location.reload();
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.$parent.selectProjectList();
							this.$parent.selectQuestionGroupList();
							this.doClose();
						});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the site registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.GroupCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.GroupCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("group-cancel", "isGroupCreate");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>