<template>
	<div ref="userAdd" class="dialog-container">
		<div class="dialog" style="max-height: 500px; transform: translate(0, -300px); max-width: 1300px;">
			<form @submit.prevent="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						User Add
					</p>
				</div>
				<div class="center">
					<p class="sign-title" style="margin: 10px 0;">Member Information</p>
					<table class="tb-crud-horizon login-table">
						<caption>
							* Membership information is secured in accordance with the privacy policy and is not disclosed or provided to third parties without your explicit consent.
						</caption>
						<colgroup>
							<col width="15%" />
							<col width="35%" />
							<col width="15%" />
							<col width="35%" />
						</colgroup>
						<tbody>
							<tr>
								<th>
									ID
									<span class="fcolor-red">*</span>
								</th>
								<td>
									<input
										type="text"
										maxlength="15"
										name="userId"
										class="input-crud"
										v-model="item.userId"
										v-validate="'required'"
										data-vv-as="User ID"
										autocomplete="off"
										@keyup="doIdDuplicateCheck()"
									/>
									<p class="txt-validation" v-if="errors.has('userId')">
										* {{ errors.first("userId") }}
									</p>
									<p class="txt-validation" v-if="isIdDuplicate">
										* ID is duplicated.
									</p>
								</td>
								<th>
									Name
									<span class="fcolor-red">*</span>
								</th>
								<td>
									<input
										type="text"
										maxlength="50"
										name="userName"
										class="input-crud"
										v-model="item.userName"
										v-validate="'required'"
										data-vv-as="User Name"
									/>
									<p class="txt-validation" v-if="errors.has('userName')">
										* {{ errors.first("userName") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Password
									<span class="fcolor-red">*</span>
								</th>
								<td>
									<input
										type="password"
										maxlength="30"
										name="password"
										class="input-crud"
										v-model="item.password"
										v-validate="'required'"
										data-vv-as="Password"
										ref="password"
									/>
									<p class="txt-validation" v-if="errors.has('password')">
										* {{ errors.first("password") }}
									</p>
								</td>
								<th>
									Confirm Password
									<span class="fcolor-red">*</span>
								</th>
								<td>
									<input
										type="password"
										maxlength="30"
										name="confirmPassword"
										class="input-crud"
										v-model="item.confirmPassword"
										v-validate="'required|confirmed:password'"
										data-vv-as="Confirm Password"
									/>
									<p class="txt-validation" v-if="errors.has('confirmPassword')">
										* {{ errors.first("confirmPassword") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									E-mail
								</th>
								<td>
									<div class="email-wrap">
										<input type="text" class="input-crud" v-model="item.emailFront" />
										<span>@</span>
										<input type="text" class="input-crud" v-model="item.emailEnd" />
									</div>
								</td>

								<th>
									Phone Number
								</th>
								<td>
									<div class="phone-wrap">
										<input type="text" maxlength="3" class="input-crud" v-model="item.phoneFront" ref="phoneFront" @keyup="phoneNext('phoneFront', 'phoneCenter')" />
										<span>-</span>
										<input type="text" maxlength="4" class="input-crud" v-model="item.phoneCenter" ref="phoneCenter" @keyup="phoneNext('phoneCenter', 'phoneEnd')" />
										<span>-</span>
										<input type="text" maxlength="4" class="input-crud" v-model="item.phoneEnd" ref="phoneEnd" />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="submit" class="btn-save fright" title="Sign Up" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "UserAdd",
	created() {
		this.logger.debug(this, "created()");
	},
	data() {
		return {
			item: {},
			isIdDuplicate: false,
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");

			if(this.item.emailFront && this.item.emailEnd)
			{
				var emailAddress = this.item.emailFront + "@" + this.item.emailEnd;
				this.$set(this.item, "emailAddress", emailAddress);
			}
			else if(this.item.emailFront || this.item.emailEnd)
			{
				var emailEmpty = (!this.item.emailFront ? "Email Front" : "Email End");
				this.$alert(emailEmpty + " Field is Empty!","Warning","warning");
				return ;
			}

			if(this.item.phoneFront && this.item.phoneCenter && this.item.phoneEnd)
			{
				var mobileTelno = this.item.phoneFront + "-" + this.item.phoneCenter + "-" + this.item.phoneEnd;
				this.$set(this.item, "mobileTelno", mobileTelno);
			}
			else if(this.item.phoneFront || this.item.phoneCenter || this.item.phoneEnd)
			{
				var phoneEmpty = (!this.item.phoneFront ? "Phone Front" : (!this.item.phoneCenter ? "Phone Center" : "Phone End"));
				this.$alert(phoneEmpty + " Field is Empty!","Warning","warning");
				return ;
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);

					axios
						.post(this.config.contextRoot + "/user/useUserIdFlag.do", this.item)
						.then((response) => {
							console.log(response);
							
							if(response.data.flag)
							{
								this.$alert("This is the ID you are using.", "Warn", "warning");
								return;
							}
							else{
								if (result){
									this.$confirm("Would you like to submit\nthe membership registration form?", "Question", "question").then(() => {
										EventBus.$emit("project-loading", true);
										this.doInsert();
									});
								}
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");

			axios
				.post(this.config.contextRoot + "/login/signUp.do", this.item)
				.then((response) => {
					if(response.status == 200) {
						this.$alert("Membership registration has been successfully completed!","Success","success");
						location.reload();
					} else {
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$refs.userAdd.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.userAdd.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("userAdd-close", "isUserAddOpen");
			}.bind(this), 410);
		},
		doIdDuplicateCheck() {
			this.logger.debug(this, "doIdDuplicateCheck()");
			
			axios
				.post(this.config.contextRoot + "/login/confirmId.do", this.item)
				.then((response) => {
					if(response.data.count > 0) {
						this.isIdDuplicate = true;
					} else {
						this.isIdDuplicate = false;
					}
				})
					.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		phoneNext(now, next) {

			if(!this.utils.isNull(this.item.phoneFront))
			{
				if(now == "phoneFront" && this.item.phoneFront.length == 3) {
				this.$refs[next].focus();
				}
			}
						
			if(!this.utils.isNull(this.item.phoneCenter))
			{
				if(now == "phoneCenter" && this.item.phoneCenter.length == 4) {
					this.$refs[next].focus();
				}
			}
		}
	}
};
</script>
<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>