<template>
	<div ref="visitEdit" class="full-container">
		<div class="full-top-wrap">
			Visit Edit
		</div>
		<div class="full-center-wrap">
			<form @submit.prevent="doValidation()">
				<p class="txt-crud-title">
					Visit Form
				</p>
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>Visit Name.</th>
							<td colspan="3">
								<input
									type="text"
									name="visitName"
									class="input-crud"
									:class="(errors.has('visitName') || isDuplicate ? 'input-crud-error' : '')"
									:placeholder="(errors.has('visitName') ? '* ' + errors.first('visitName') : 'Please enter the Visit Name.')"
									v-model="item.visitName"
									v-validate="'required'"
									data-vv-as="Visit Name"
									@input="item.visitName = $event.target.value"
									@keyup="doDuplicateCheck()"
								/>
								<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;">
									* Visit Name is duplicated.
								</p>
							</td>
						</tr>
						<tr>
							<th>Sort Order.</th>
							<td>
								<input
									type="number"
									min="1"
									max="100"
									v-model="item.visitSortOrder"
									class="input-crud"
									:class="(errors.has('visitSortOrder') ? 'input-crud-error' : '')"
									name="visitSortOrder"
									v-validate="'required'"
									data-vv-as="Sort Order"
									:placeholder="(errors.has('visitSortOrder') ? '* ' + errors.first('visitSortOrder') : 'Please enter the Sort Order.')"
								/>
							</td>
							<th>
								Site
							</th>
							<td>
								<select
									class="select-crud"
									:class="(errors.has('siteCode') ? 'input-crud-error' : '')"
									name="siteCode"
									v-model="item.siteCode"
									v-validate="'required'"
									data-vv-as="Site"
									style="width: 100%;"
									disabled
								>
									<option value="" selected disabled>--- select ---</option>
									<option v-for="(siteListItem, index) in siteList" :key="index" :value="siteListItem.siteCode">
										{{ siteListItem.siteName }}
									</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>Register.</th>
							<td>{{ item.firstRegistUserId }}</td>
						</tr>
						<tr>
							<th>Register Date.</th>
							<td>
								{{ item.firstRegistDate }}
							</td>
						</tr>
						
						<tr>
							<th>Use Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="Y"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="N"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
						<!-- <tr>
							<th>UnSchedule Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="Y"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="N"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									No
								</label>
							</td>
						</tr> -->
						<tr>
							<th>Page Name.</th>
							<td>
								<div v-for="visitPageItem in visitPageList" :key="visitPageItem.pageNo">
									<label class="checkbox-crud">
										<input
											type="checkbox"
											name="selectVisit"
											:value="visitPageItem.pageNo"
											v-model="visitPageSaveList"
											:disabled="screeningNoList.indexOf(visitPageItem.pageNo) > -1"
											v-validate="'required'"
											data-vv-as="Select visit"
										/><span class="checkbox"></span>
										{{ visitPageItem.pageName }}
									</label>
								</div>
								<p class="txt-validation" v-if="errors.has('selectVisit')">
									* {{ errors.first("selectVisit") }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div class="full-bottom-wrap">
			<button  v-if="workAccessCheck('EDIT') && !(projectStateCd == '4' || projectStateCd == '3')" type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()">
				Modify
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">
				Cancel
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "VisitEdit",
	created() {
		this.logger.debug(this, "created()");
		this.selectSiteList();
		this.getAccessList('VISIT');
		this.getProjectStateCd();
	},
	data() {
		return {
			item: {},
			visitList: [],
			visitPageList: [],
			selectedPageNo: [],
			visitPageItem: [],
			visitPageSaveList: [],
			siteList: [],
			screeningNoList: [],
			isDuplicate: false,
			pageNoDeleteList: [],
			pageNoList: [],
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		select() {
			this.logger.debug(this, "selectList()");
			this.item.projectNo = this.$route.params.projectNo;
			this.item.visitNo = this.$route.params.visitNo;

			axios
				.post(this.config.contextRoot + "/visit/select.do", this.item)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
					this.item = response.data.item;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				this.selectVisitPageList();
		},	
		selectVisitPageList() {
			this.logger.debug(this, "selectVisigPageListEdit()");

			axios
				.post(this.config.contextRoot + "/visit/selectVisigPageListEdit.do", {projectNo: this.$route.params.projectNo, visitNo: this.$route.params.visitNo, unscheduleYn: "N"})
				.then((response) => {
					console.log(response);
					this.visitPageList = response.data.visitPageList;

					for(let index = 0; index < this.visitPageList.length; index++)
					{
						if(this.visitPageList[index].selectedPageNo == 1)
						{
							this.visitPageSaveList.push(this.visitPageList[index].visitPageNo);
						}
						if(this.visitPageList[index].screeningCheck == 1)
						{
							this.screeningNoList.push(this.visitPageList[index].pageNo);
						}
						if(this.visitPageList[index].notScreeningCheck == 1)
						{
							this.pageNoDeleteList.push(this.visitPageList[index].pageNo);
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");
			axios
				.post(this.config.contextRoot + "/site/selectList.do", {})
				.then((response) => {
					console.log(response);
					this.siteList = response.data.siteList;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				this.select();
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if(result && !this.isDuplicate)
					{
						this.$confirm("Do you want to Edit the current Visit?", "Question", "question").then(() => {
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");

			for(let index = 0; index < this.visitPageSaveList.length; index++)	
			{
				if(this.screeningNoList.indexOf(this.visitPageSaveList[index]) == -1)
				{
					this.pageNoList.push(this.visitPageSaveList[index]);
				}
			}

			this.item.projectNo = this.$route.params.projectNo;
			this.item.visitNo = this.$route.params.visitNo;
			this.item.pageNoDeleteList = this.pageNoDeleteList;
			this.item.pageNoList = this.pageNoList;
			this.item.visitPageList = this.visitPageSaveList;
			this.item.screeningNoList = this.screeningNoList;

			axios
				.post(this.config.contextRoot + "/visit/update.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						EventBus.$emit("project-loading", true);
						var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/visit";
						if (this.$route.path !== url)
						{
							this.$router.push(url);
						}
					}
					else
					{
						this.$alert(response, "Error", "error");
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the visit modification?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.visitEdit.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/visit"
				if (this.$route.path !== url)
				{
					EventBus.$emit("project-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 410);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");

			axios
				.post(this.config.contextRoot + "/visit/duplicateCheck.do", this.item)
				.then((response) => {
					console.log(response);
					if(response.data.visitExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>

<style scoped>

.btn-cancel {
	margin-right: 10px;
}
</style>