<template>
	<div class="main-container">
		<LoginLayout v-if="isLoginPath" />
		<LoginLayout v-if="isLoginPathPreRegist" />
		<NoticeLayout v-if="isNoticePath" />
		<ProjectLayout v-if="isProjectPath" />
		<FieldLayout v-if="isFieldPath" />
		<LogLayout v-if="isLogPath" />
		<DataDictionaryLayout v-if="isDataDictionaryPath" />
		<SettingLayout v-if="isSettingPath" />
		<router-view v-if="!isLoginPath && !isLoginPathPreRegist && !isNoticePath && !isProjectPath && !isFieldPath && !isDataDictionaryPath && !isLogPath && !isSettingPath"/>
	</div>
</template>

<script>
import LoginLayout from "@/components/login/LoginLayout.vue";
import NoticeLayout from "@/components/notice/NoticeLayout.vue";
import ProjectLayout from "@/components/project/ProjectLayout.vue";
import FieldLayout from "@/components/field/FieldLayout.vue";
import LogLayout from "@/components/log/LogLayout.vue";
import DataDictionaryLayout from "@/components/dataDictionary/DataDictionaryLayout.vue";
import SettingLayout from "@/components/setting/SettingLayout.vue";

export default {
	name: "App",
	created() {},
	components: {
		LoginLayout,
		NoticeLayout,
		ProjectLayout,
		FieldLayout,
		LogLayout,
		DataDictionaryLayout,
		SettingLayout
	},
	computed: {
		isLoginPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/login") >= 0
				? true
				: false;
		},
		isLoginPathPreRegist() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/preRegistration") >= 0
				? true
				: false;
		},
		isNoticePath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/notice") >= 0
				? true
				: false;
		},
		isProjectPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/project") >= 0
				? true
				: false;
		},
		isFieldPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/field") >= 0
				? (this.$route.path.indexOf("popup") >= 0 ? false : true)
				: false;
		},
		isLogPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/system_log") >= 0
				? true
				: false;
		},
		isDataDictionaryPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/data_dictionary") >= 0
				? true
				: false;
		},
		isSettingPath() {
			return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/setting") >= 0
				? true
				: false;
		},

	},
};
</script>

<style>
@import "assets/css/common/common.css";
</style>
