<template>
	<div ref="ScreeningMutipleCreate" class="dialog-container" @mouseup="dragEnded()">
		<div v-if="!isEMR" class="dialog" style="max-height: 800px; transform: translate(0, -400px)">
			<form>
				<!-- 팝업창 상단 제목 -->
				<div class="top">
					<!-- <button type="button" class="btn-cancel fright btn-history" title="History">History</button> -->
					<!-- <p class="txt-crud-title">
						Data Input
						<button type="button" title="Get EMR" class="btn-cancel btn-history" @click="openEMR()">
							Get EMR
						</button>
					</p> -->
				</div>
				<!-- 팝업창 내용 -->
				<div class="center">
					<!-- 스크리닝 정보 -->
					<div class="dinput-info">
						<div class="item">
							<p class="title">Subject Number</p>
							<p class="content">
								{{ param.screeningName }}
							</p>
						</div>
						<div class="item">
							<p class="title">Page Name</p>
							<p class="content">
								{{ param.pageName }}
							</p>
						</div>
						<div class="item">
							<p class="title">Register</p>
							<p class="content">
								{{ items.userName }} ({{ items.userId }})
							</p>
						</div>
						<div class="item">
							<p class="title">Regist Date</p>
							<p class="content">
								-
							</p>
						</div>
					</div>
					<!-- 스크리닝 정보 -->
					<div class="procedure-input-wrap">
						<template v-for="(procItem, procIndex) in procList">
							<div class="procedure-title" :key="procIndex">
								{{ procItem.procName }}
							</div>
							<template v-for="(procFieldItem, procFieldIndex) in procFieldList">
								<div
									class="procedure-field"
									v-if="procItem.procNo == procFieldItem.procNo"
									:key="procIndex + '-' + procFieldIndex"
								>
									<div class="procedure-question">
										{{ procFieldItem.questionName }}
									</div>

									<div class="procedure-form">
										<div class="procedure-item">
											{{ procFieldItem.prefixName }}
											<div
												:class="procFieldItem.fieldLayoutCd == '1' ? 'vertical' : 'horizon'"
												:style="getMargin(procFieldItem)"
											>
												<template v-if="procFieldItem.fieldTypeCd == '01'">
													<input
														v-if="procFieldItem.dataTypeCd == 1"
														type="text"
														class="input-crud"
														:maxlength="procFieldItem.textSize"
														:style="getLength(procFieldItem)"
														:name="'procFieldNo1' + procFieldItem.procFieldNo"
														v-model="procFieldItem.fieldValue"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo1' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
													/>
													<p class="txt-validation" v-if="errors.has('procFieldNo1' + procFieldItem.procFieldNo)" style="display: table;">
														* {{ errors.first("procFieldNo1" + procFieldItem.procFieldNo) }}
													</p>
													<input
														v-if="procFieldItem.dataTypeCd == 2"
														type="number"
														class="input-crud"
														:min="getLange(procFieldItem, 'min')"
														:max="getLange(procFieldItem, 'max')"
														:style="getLength(procFieldItem)"
														:name="'procFieldNo2' + procFieldItem.procFieldNo"
														v-model="procFieldItem.fieldValue"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo2' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
													/>
													<p class="txt-validation" v-if="errors.has('procFieldNo2' + procFieldItem.procFieldNo)" style="display: table;">
														* {{ errors.first("procFieldNo2" + procFieldItem.procFieldNo) }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">	
														* {{ procFieldItem.errorMessage }}
													</p>
												</template>
												<!-- Type TextArea -->
												<template v-if="procFieldItem.fieldTypeCd == '02'">
													<textarea
														class="textarea-crud"
														:rows="procFieldItem.textareaRows"
														:name="'procFieldNo3' + procFieldItem.procFieldNo"
														v-model="procFieldItem.fieldValue"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo3' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
													/>
													<p class="txt-validation" v-if="errors.has('procFieldNo3' + procFieldItem.procFieldNo)">
														* {{ errors.first("procFieldNo3" + procFieldItem.procFieldNo) }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">	
														* {{ procFieldItem.errorMessage }}
													</p>
												</template>
												<!-- Type Radio -->
												<template v-if="procFieldItem.fieldTypeCd == '03'">
													<div
														class="grid"
														:style="
															getGridRowCol(
																procFieldItem.radioOptionsLabel,
																procFieldItem.radioCols
															)
														"
													>
														<div
															v-for="(
																radioItem, radioIndex
															) in procFieldItem.radioOptionsLabel.split(';')"
															:key="radioIndex"
														>
															<label class="radio-crud">
																<input
																	type="radio"
																	:value="
																		getSplitArrayValue(
																			procFieldItem.radioOptionsValue,
																			radioIndex
																		)
																	"
																	:name="'procFieldNo4' + procFieldItem.procFieldNo"
																	v-model="procFieldItem.fieldValue"
																	v-validate="'required'"
																	:data-vv-as= procFieldItem.questionName
																	:class="(errors.has('procFieldNo4' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
																/><span class="radio"></span>
																{{ radioItem }}
															</label>
														</div>
													</div>
												<p class="txt-validation" v-if="errors.has('procFieldNo4' + procFieldItem.procFieldNo)" style="display: table;">
														* {{ errors.first("procFieldNo4" + procFieldItem.procFieldNo) }}
												</p>
												<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">
														* {{ procFieldItem.errorMessage }}
												</p>
												</template>
												<!-- Type Checkbox -->
												<template v-if="procFieldItem.fieldTypeCd == '04'">
													<div
														class="grid"
														:style="
															getGridRowCol(
																procFieldItem.checkboxOptionsLabel,
																procFieldItem.checkboxCols
															)
														"
													>
														<div
															v-for="(
																checkboxItem, checkboxIndex
															) in procFieldItem.checkboxOptionsLabel.split(';')"
															:key="checkboxIndex"
														>
															<label class="checkbox-crud">
																<input
																	type="checkbox"
																	:value="
																		getSplitArrayValue(
																			procFieldItem.checkboxOptionsValue,
																			checkboxIndex
																		)
																	"
																	:name="'procFieldNo5' + procFieldItem.procFieldNo"
																	v-model="procFieldItem.fieldValue"
																	v-validate="'required'"
																	:data-vv-as= procFieldItem.questionName
																	:class="(errors.has('procFieldNo5' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
																/><span class="checkbox"></span>
																{{ checkboxItem }}
															</label>
														</div>
													</div>
													<p class="txt-validation" v-if="errors.has('procFieldNo5' + procFieldItem.procFieldNo)" style="display: table;">
															* {{ errors.first("procFieldNo5" + procFieldItem.procFieldNo) }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">
														* {{ procFieldItem.errorMessage }}
													</p>
												</template>
												<!-- Type Select -->
												<template v-if="procFieldItem.fieldTypeCd == '05'">
													<select
														class="select-crud"
														:name="'procFieldNo6' + procFieldItem.procFieldNo"
														v-model="procFieldItem.fieldValue"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo6' + procFieldItem.procFieldNo) ? 'input-crud-error' : '')"
													>
														<option value="" disabled>-- Select --</option>
														<option
															v-for="(
																selectItem, selectIndex
															) in procFieldItem.selectOptionsLabel.split(';')"
															:key="selectIndex"
															:value="
																getSplitArrayValue(
																	procFieldItem.selectOptionsValue,
																	selectIndex
																)
															"
														>
															{{ selectItem }}
														</option>
													</select>
													<p class="txt-validation" v-if="errors.has('procFieldNo6' + procFieldItem.procFieldNo)" style="display: table;">
														* {{ errors.first("procFieldNo6" + procFieldItem.procFieldNo) }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">
														* {{ procFieldItem.errorMessage }}
													</p>
												</template>
												<!-- Type Image -->
												<template v-if="procFieldItem.fieldTypeCd == '06'">
													<div v-show="procFieldItem.isLoad">
														<img
															v-if="procFieldItem.imageFileGroupNo"
															:src="
																config.contextRoot +
																'/common/file/printImage.do?fileNo=' +
																procFieldItem.fileNo +
																'&fileGroupNo=' +
																procFieldItem.imageFileGroupNo +
																'&serverFileSaveName=' +
																procFieldItem.serverFileSaveName
															"
															:width="procFieldItem.imageWidth"
															:height="procFieldItem.imageHeight"
															@load="loadedImage(procFieldItem)"
														/>
													</div>
													<div v-if="!procFieldItem.isLoad">
														<svg
															v-if="
																procFieldItem.imageFileGroupNo &&
																procFieldItem.imageWidth
															"
															xmlns="http://www.w3.org/2000/svg"
															:width="procFieldItem.imageWidth"
															:height="procFieldItem.imageHeight"
															:viewBox="
																'0 0 ' +
																procFieldItem.imageWidth +
																' ' +
																procFieldItem.imageHeight
															"
														>
															<rect
																fill="#ddd"
																:width="procFieldItem.imageWidth"
																:height="procFieldItem.imageHeight"
															/>
															<text
																fill="rgba(0,0,0,0.5)"
																font-family="sans-serif"
																font-size="20"
																dy="10.5"
																font-weight="normal"
																x="50%"
																y="50%"
																text-anchor="middle"
															>
																{{ procFieldItem.imageWidth }}x{{
																	procFieldItem.imageHeight
																}}
															</text>
														</svg>
														<p v-else-if="!procFieldItem.imageFileGroupNo">
															There is no image.
														</p>
													</div>
												</template>
												<!-- Type File -->
												<template v-if="procFieldItem.fieldTypeCd == '07'">
													<site-file
														:fileExtension="procFieldItem.fileExtension"
														:fileMaxSize="procFieldItem.fileMaxSize"
														v-model="procFieldItem.siteFileList"
													/>
												</template>
												<!-- Type Date -->
												<template v-if="procFieldItem.fieldTypeCd == '08'">
													<Datepicker
														v-if="
															procFieldItem.dataTypeCd == '3' ||
															procFieldItem.dataTypeCd == '5'
														"
														class="date-picker"
														format="yyyy-MM-dd"
														placeholder="YYYY-MM-DD"
														:name="'procFieldNo7' + procFieldItem.procFieldNo + 'date'"
														v-model="procFieldItem.fieldValueDate"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo7' + procFieldItem.procFieldNo + 'date') ? 'input-crud-error' : '')"
													/>
													<p class="txt-validation" v-if="errors.has('procFieldNo7' + procFieldItem.procFieldNo + 'date')" style="display: table;">
														* {{ errors.first("procFieldNo7" + procFieldItem.procFieldNo + 'date') }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">
														* {{ procFieldItem.errorMessage }}
													</p>
													<VueTimepicker
														v-if="
															procFieldItem.dataTypeCd == '4' ||
															procFieldItem.dataTypeCd == '5'
														"
														class="time-picker"
														format="HH:mm:ss"
														placeholder="HH:mm:ss"
														:name="'procFieldNo8' + procFieldItem.procFieldNo + 'time'"
														v-model="procFieldItem.fieldValueTime"
														v-validate="'required'"
														:data-vv-as= procFieldItem.questionName
														:class="(errors.has('procFieldNo8' + procFieldItem.procFieldNo + 'time') ? 'input-crud-error' : '')"
													/>
													<p class="txt-validation" v-if="errors.has('procFieldNo8' + procFieldItem.procFieldNo + 'time')" style="display: table;">
														* {{ errors.first("procFieldNo8" + procFieldItem.procFieldNo + 'time') }}
													</p>
													<p class="txt-validation" v-if="!utils.isEmpty(procFieldItem.errorMessage)" style="display: table;">
														* {{ procFieldItem.errorMessage }}
													</p>
												</template>
												<!-- Type Calculation -->
												<template v-if="procFieldItem.fieldTypeCd == '09'">
													<input
														type="text"
														class="input-crud"
														readonly
														:name="'procFieldNo' + procFieldItem.procFieldNo"
														v-model="procFieldItem.fieldValue"
														:placeholder="
															procFieldItem.calculate || 'Not Configurated........'
														"
													/>
												</template>
												<!-- tt -->
											</div>
											{{ procFieldItem.suffixName }}
										</div>
									</div>
								</div>
							</template>
						</template>
					</div>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">Close</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import SiteFile from "@/components/common/SiteFile";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
	name: "ScreeningSingleCreate",
	created() {
		this.logger.debug(this, "created()");
		
		this.items.screeningNo = this.param.screeningNo;
		this.items.visitNo = this.param.visitNo;
		this.items.pageNo = this.param.pageNo;
		this.items.projectEntry = this.param.projectEntry;

		this.items.userId = this.userInfo.userId;
		this.items.userName = this.userInfo.userName;
		this.selectVisitScreeningUserInputMultipleList();

		// EMR IMPORT 닫기
		EventBus.$on("emr-close", () => {
			this.logger.debug(this, "[EventBus] emr-cancel");
			this.isEMR = false;
		});
		// EMR IMPORT 기능
		EventBus.$on("emr-import", (resultList) => {
			this.logger.debug(this, "[EventBus] emr-import(resultList)");
			console.log(resultList);
			for(let i = 0 ; i < resultList.length ; i++)
			{
				// FieldNo가 있는지 검사
				if(resultList[i].FIELD_NO !== undefined && resultList[i].FIELD_NO != null && resultList[i].FIELD_NO != "")
				{
					// DEPTH가 2인 경우 (TextBox, TextArea, Date 타입인 경우)
					if(resultList[i].PROCESS_DEPTH == "2")
					{
						// 현재 조사서 탐색
						for(let j = 0 ; j < this.procFieldList.length ; j++)
						{
							// QuestionNo와 FieldNo가 일치 할 때
							if(this.procFieldList[j].questionNo == resultList[i].QUESTION_NO + "" && this.procFieldList[j].fieldNo == resultList[i].FIELD_NO + "")
							{
								// TextBox, TextArea인 경우
								if(this.procFieldList[j].fieldTypeCd == "01" || this.procFieldList[j].fieldTypeCd == "02")
								{
									// Data Type이 String인 경우
									if(this.procFieldList[j].dataTypeCd == "1")
									{
										this.$set(this.procFieldList[j], "fieldValue", resultList[i].DATA_VALUE);
									}
									// Data Type이 Number인 경우
									else if(this.procFieldList[j].dataTypeCd == "2")
									{
										if(!isNaN(Number(resultList[i].DATA_VALUE)))
										{
											this.$set(this.procFieldList[j], "fieldValue", resultList[i].DATA_VALUE);
										}
									}
								}
								// Date인 경우
								else if(this.procFieldList[j].fieldTypeCd == "08")
								{
									// Data Type이 Date인 경우
									if(this.procFieldList[j].dataTypeCd == "3")
									{
										let date = new Date(resultList[i].DATA_VALUE);
										if(!isNaN(date))
										{
											this.$set(this.procFieldList[j], "fieldValueDate", date);
										}
									}
									// Data Type이 Time인 경우
									else if(this.procFieldList[j].dataTypeCd == "4")
									{
										let time = resultList[i].DATA_VALUE.split(":");
										if(time.length == 3)
										{
											if(time[0].length == 2 && time[1].length == 2 && time[3].length == 2)
											{
												this.$set(this.procFieldList[j], "fieldValueTime", resultList[i].DATA_VALUE);
											}
										}
									}
									// Data Type이 Date + Time인 경우
									else if(this.procFieldList[j].dataTypeCd == "5")
									{
										let date = new Date(resultList[i].DATA_VALUE);
										if(!isNaN(date))
										{
											var hour = (date.getHours() >= 10 ? date.getHours() : "0" + date.getHours());
											var minute = (date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes());
											var second = (date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds());
											this.$set(this.procFieldList[j], "fieldValueTime", [hour, minute, second].join(":"));
											this.$set(this.procFieldList[j], "fieldValueDate", date);
										}
									}
								}
							}
						}
					}
					// DEPTH가 3인 경우 (Radio, CheckBox, Select 즉 Item이 있는 경우)
					else if(resultList[i].PROCESS_DEPTH == "3")
					{
						// 현재 조사서 탐색
						for(let j = 0 ; j < this.procFieldList.length ; j++)
						{
							// QuestionNo와 FieldNo가 일치 할 때
							if(this.procFieldList[j].questionNo == resultList[i].QUESTION_NO + "" && this.procFieldList[j].fieldNo == resultList[i].FIELD_NO + "")
							{
								// Radio, Select인 경우 값 설정
								if(this.procFieldList[j].fieldTypeCd == "03" || this.procFieldList[j].fieldTypeCd == "05")
								{
									this.$set(this.procFieldList[j], "fieldValue", resultList[i].PROCESS_VALUE);
								}
								// CheckBox인 경우 값 추가
								else if(this.procFieldList[j].fieldTypeCd == "04")
								{
									if(this.procFieldList[j].fieldValue.indexOf(resultList[i].PROCESS_VALUE) < 0)
									{
										this.procFieldList[j].fieldValue.push(resultList[i].PROCESS_VALUE);
									}
								}
							}
						}
					}
				}
			}
		});
	},
	beforeDestroy() {
		EventBus.$off("emr-close");
	},
	components: {
		SiteFile,
		Datepicker,
		VueTimepicker,
	},
	computed: {},
	props: {
		param: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: {},
			procFieldList: [],
			procList: [],
			isEMR: false
		};
	},
	methods: {
		selectVisitScreeningUserInputMultipleList() {
			this.logger.debug(this, "selectVisitScreeningUserInputMultipleList()");
			axios
				.post(this.config.contextRoot + "/screening/visitScreeningUserInputMultipleList.do", this.items)
				.then((response) => {
					console.log(response);
					this.procFieldList = response.data.procFieldList;

					let usedProcNoList = [];

					this.procFieldList.forEach((obj) => {
						if (usedProcNoList.indexOf(obj.procNo) == -1) {
							usedProcNoList.push(obj.procNo);
							this.procList.push(obj);
						}

						if (obj.fieldTypeCd == "04") {
							if (obj.fieldValue.indexOf(";") >= 0) {
								obj.fieldValue = obj.fieldValue.split(";");
							} else if (obj.fieldValue.length > 0) {
								obj.fieldValue = [obj.fieldValue];
							} else {
								obj.fieldValue = [];
							}
						}
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getMargin(item) {
			let style = {};
			let prefixName = item.prefixName;
			let suffixName = item.suffixName;
			let fieldLayoutCd = item.fieldLayoutCd;

			if (fieldLayoutCd == "1") {
				if (prefixName != "") style.marginTop = "5px";
				if (suffixName != "") style.marginBottom = "5px";
			} else {
				if (prefixName != "") style.marginLeft = "5px";
				if (suffixName != "") style.marginRight = "5px";
			}
			return style;
		},
		getLength(item) {
			let textSize = Number(item.textSize) * 2 + 2;
			return {
				width: textSize + "rem",
			};
		},
		getLange(item, minmax) {
			let textSize = Number(item.textSize);
			let lange = "";
			for (let i = 0; i < textSize; i++) {
				lange = lange + "9";
			}
			lange = Number(lange);

			if (minmax == "min") {
				return -lange;
			} else {
				return lange;
			}
		},
		getSplitArray(label) {
			if (label !== undefined && label != null && label != "") {
				let array = label.split(";");
				return array;
			} else {
				return [];
			}
		},
		getSplitArrayValue(label, index) {
			let array = this.getSplitArray(label);
			return array[index];
		},
		getGridRowCol(label, cols) {
			let array = this.getSplitArray(label);
			let length = array.length;

			let value = parseInt(Number(length) / Number(cols));
			let etc = Number(length) % Number(cols);

			let row = 0;
			let col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(32px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		loadedImage(item) {
			this.$set(item, "isLoad", true);
		},
		leftPad(value) {
			if (value >= 10) {
				return value;
			}
			return "0" + value;
		},
		toStringByFormatting(source, delimiter = "-") {
			const year = source.getFullYear();
			const month = this.leftPad(source.getMonth() + 1);
			const day = this.leftPad(source.getDate());
			return [year, month, day].join(delimiter);
		},
		doValidation(isTemp) {
			this.logger.debug(this, "doValidation()");
			if(isTemp)
			{
				this.$confirm("Do you want to temp save the data?", "Question", "question").then(() => {
					this.doInsert(isTemp);
				});
			}
			else
			{
				this.$validator
					.validateAll()
					.then((result) => {
						this.logger.debug(this, "doValidationResult:" + result);
						if(result) {
							if (!isTemp) {
								this.$confirm("Do you want to save the data?", "Question", "question").then(() => {
									this.doInsert(isTemp);
								});
							} 
						}
					})
					.catch(() => {
						this.logger.debug(this, "-->" + this.errors.all());
						alert(this.errors.all());
						return false;
					});
			}
		},
		doInsert(isTemp) {
			this.logger.debug(this, "doInsert()");

			let param = {
				procFieldList: this.procFieldList,
				isTemp: isTemp,
			};

			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/screening/insertVisitScreeningInput.do", param)
				.then((response) => {
					EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);
						this.$nextTick(function () {
							setTimeout(
								function () {
									EventBus.$emit("project-loading", false);
								}.bind(this),
								500
							);
					});
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);

						if(response.data.valid != 'false')
						{
							this.$alert(response.data.valid, "Error", "error").then(() => {
								this.procFieldList = [];
								this.procList = [];
								this.selectVisitScreeningUserInputMultipleList();
							});
						}
						else if(response.data.valid == 'false')
						{
							this.$alert("Data has been saved successfully.", "Info", "info").then(() => {
								this.doRefreshClose();
							});
						}
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the data input?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ScreeningMutipleCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ScreeningMutipleCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(
				function () {
					EventBus.$emit("entry-create-cancel", "isMutipleCreate");
				}.bind(this),
				410
			);
		},
		doRefreshClose() {
			this.logger.debug(this, "doRefreshClose()");
			this.$refs.ScreeningMutipleCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ScreeningMutipleCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("refresh-data-list", "isMutipleCreate");
			}.bind(this), 410);
		},
		openEMR() {
			this.logger.debug(this, "openEMR()");
			EventBus.$emit("project-loading", true);
			this.isEMR = true;
		},
		dragEnded() {
			this.logger.debug(this, "dragEnd()");
			if(this.isEMR)
			{
				this.$refs.emrList.isDrag = false;
			}
		}
	},
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}

.dinput-info {
	display: flex;
	align-items: center;
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.dinput-info > .item {
	width: 25%;
	border-left: 1px solid #ccc;
}

.dinput-info > .item:first-child {
	border-left: none;
}

.dinput-info > .item > .title {
	padding: 8px 0 8px 15px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
	border-bottom: 1px solid #ccc;
	background-color: #eee;
	text-align: left;
}

.dinput-info > .item > .content {
	padding: 15px 0 15px 15px;
	font-size: 10.5pt;
	color: #999;
	text-align: left;
}

.procedure-input-wrap {
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
}

.procedure-title {
	padding: 8px 0 8px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #e3f7ff;
	font-weight: 600;
	font-size: 10.5pt;
	color: #000;
	text-align: left;
}

.procedure-field {
	display: flex;
}

.procedure-question {
	display: flex;
	align-items: center;
	width: 20%;
	padding: 20px 0 20px 15px;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #333;
	text-align: left;
}

.procedure-form {
	width: 80%;
	padding: 20px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
	font-weight: 400;
	font-size: 10.5pt;
	color: #666;
	text-align: left;
}

.procedure-form > .procedure-item:last-child {
	margin: 0;
}

.procedure-item {
	display: block;
	margin: 0 0 15px 0;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
}

.procedure-item > .vertical {
	display: block;
}

.procedure-item > .horizon {
	display: inline-block;
}

.input-crud {
	max-width: 100%;
}

.textarea-crud {
	height: unset !important;
}

.radio-crud,
.checkbox-crud {
	font-weight: 400;
	font-size: 10pt;
}

.select-crud {
	width: auto !important;
	max-width: 100% !important;
}

.btn-temp,
.btn-history:hover {
	border: 1px solid #2bade0 !important;
	color: #2bade0 !important;
}

.txt-crud-title {
	justify-content: space-between;
}
</style>
