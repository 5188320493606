<template>
	<div class="login-background">
		<!-- <div class="login-container">
			<div class="login-top-wrap flex-center no-drag">
				<h1 class="login-logo"></h1>
				<div class="login-cs-wrap flex-center">
					<img class="login-cs-img" :src="csIcon" alt=""/>
					<p class="login-cs-text">
						Customer Service<br>
						070-800-8000
					</p>
				</div>
			</div>
			<router-view>
			</router-view>
		</div> -->
		<div class="login-left">
			
		</div>
		<div class="login-right">
			<router-view>
			</router-view>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoginLayout',
	data() {
		return {
			csIcon: require('@/assets/images/login/phone_icon_login.png')
		}
	}
}
</script>

<style>
@import '../../assets/css/login/login.css';
</style>
