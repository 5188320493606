<template>
	<div id="edcSystemApp-frame">
		<p class="txt-list-title">Site List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the Site name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button type="button" class="btn-new" title="New Site" @click="doCreate()">
				<img src="@/assets/images/common/btn_new.png" alt="" />
				New Site
			</button>
		</div>
		<table class="tb-list-vertical tb-setting-list">
			<colgroup>
				<col width="5%" />
				<col width="30%" />
				<col width="15%" />
				<col width="15%" />
				<col width="25%" />
				<!-- <col width="10%" /> -->
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Name</th>
					<th>Code</th>
					<th>Manager</th>
					<th>Etc</th>
					<!-- <th>Remove</th> -->
				</tr>
			</thead>
			<tbody>
				<tr v-for="site in siteList" :key="site.siteNo" tabindex="0" @click.stop="doEdit(site)">
					<td>
						{{ site.rowNumber}}
					</td>
					<td class="ellipsis underline" :title="site.siteName">
						{{ site.siteName }}
					</td>
					<td>
						{{ site.siteCode }}
					</td>
					<td>{{ site.managerName }} ({{ site.managerTelno }}) </td>
					<td class="ellipsis" :title="site.siteEtc">
						{{ site.siteEtc }}
					</td>
					<!-- <td>
						<button
							type="button"
							class="btn-remove"
							title="Remove Site"
							@click.stop="doDeleteCheck(site)"
						></button>
					</td> -->
				</tr>
				<tr v-if="siteList.length == 0">
					<td colspan="5" class="no-list">There is no registered site, Please register the site.</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination :data="pagination" :show-disabled="true" :limit="4" @pagination-change-page="doSearchPage">
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<SiteCreate v-if="isCreate" />
		<SiteEdit v-if="isEdit" :siteCode="selectSiteCode" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import SiteCreate from "@/components/setting/site/SiteCreate.vue";
import SiteEdit from "@/components/setting/site/SiteEdit.vue";


export default {
	name: "SiteList",
	props: {},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectSiteList();
	},
	components: {
		SiteCreate,
		SiteEdit
	},
	data() {
		return {
			item: {},
			siteList: [],
			pagination: {},
			searchText: "",
			isCreate: false,
			isEdit: false,
			selectSiteCode: "",
			isTyping: false,
		};
	},
	watch: {
		searchText: function(val) {
			this.item.siteName = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");

			axios
				.post(
					this.config.contextRoot + "/site/selectList.do", this.item)
				.then(response => {
					console.log(response);
					this.siteList = response.data.siteList;
					this.pagination = response.data.pagination;
						this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch(error => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectSiteList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		doEdit(site) {
			this.logger.debug(this, "doEdit(site)");
			this.selectSiteCode = site.siteCode;
			this.isEdit = true;
		},
		doDelete(site) {
			this.logger.debug(this, "doDelete(site)");
			this.$confirm("Are you sure you want to delete " + site.siteName +"?", "Question", "question").then(() => {
				var param = {siteCode: site.siteCode};
				
				axios
					.post(this.config.contextRoot + "/site/delete.do", param)
					.then((response) => {
						console.log(response);
						if(response.data.totCnt > 0)
						{
							this.$alert("The user with the site exists and cannot be deleted.", "Warn", "warning");
						}
						else
						{
							EventBus.$emit("setting-loading", true);
							location.reload();
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.siteList = new Array();
				this.selectSiteList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
	}
};
</script>
