<template>
	<div ref="procedureSort" class="dialog-container">
		<div class="dialog" style="max-height: 500px; transform: translate(0, -250px);">
			<form @submit.stop="doValidation()">
				<div class="top"> 
					<p class="txt-crud-title">
						Procedure Sort
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="20%" />
							<col width="80%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Page</th>
								<td>
									<select
										class="select-crud"
										:class="(errors.has('pageNo') ? 'input-crud-error' : '')"
										name="pageNo"
										v-model="items.pageNo"
										v-validate="'required'"
										data-vv-as="Page"
										@change="selectProcedureList(items.pageNo)"
									>
										<option value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in pageList" :key="index" :value="item.pageNo">
											{{ item.pageSortOrder }}. {{ item.pageName }} - {{ item.pageLabel }}
										</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>Procedure List</th>
								<td>
									<div v-for="(item, index) in procedureList" :key="index" class="sort-row">
										<label>
											Procedure
											<input
												type="text"
												class="input-crud"
												name="procName"
												v-model="procedureList[index].procName"
												readonly
											/>
										</label>
										<label>
											Sort Order
											<input
												type="number"
												class="input-crud"
												:class="(errors.has('procSortOrder' + index) ? 'input-crud-error' : '')"
												style="margin: 5px 0 8px;"
												:name="'procSortOrder' + index"
												v-model="procedureList[index].procSortOrder"
												v-validate="'required'"
												data-vv-as="Sort Order"
												:placeholder="(
													errors.has('procSortOrder' + index) ? 
													errors.first('procSortOrder' + index) : 
													'Please enter the sort order.'
												)"
												min="1"
												max="100"
											/>
										</label>
									</div>
									<div v-if="procedureList.length == 0" class="no-list">
										There is no registered procedure, Please register the procedure.
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProcedureSort",
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.projectNo;
		this.selectPageList();
	},
	props: {
		projectNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			items: {},
			pageList: [],
			procedureList: []
		};
	},
	methods: {
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/page/selectProcSortList.do", {projectNo: this.items.projectNo})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
					if(this.pageList.length > 0)
					{
						let pageNo = this.pageList[0].pageNo;
						this.$set(this.items, "pageNo", pageNo);
						this.selectProcedureList(pageNo);
					}
					else
					{
						this.$set(this.items, "pageNo", "");
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProcedureList(pageNo) {
			this.logger.debug(this, "selectProcedureList(pageNo)");
			axios
				.post(this.config.contextRoot + "/procedure/selectList.do", {pageNo: pageNo})
				.then((response) => {
					console.log(response);
					this.procedureList = response.data.procedureList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if(this.procedureList.length == 0)
					{
						this.$alert("There is no registered procedure.", "Warning", "warning");
						return false;
					}
					if (result)
					{
						this.$confirm("Do you want to modify the current sort?","Question","question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			let param = [];
			for(let i = 0 ; i < this.procedureList.length ; i++)
			{
				let procNo = this.procedureList[i].procNo;
				let procSortOrder = this.procedureList[i].procSortOrder;
				param.push({
					procNo: procNo,
					procSortOrder: procSortOrder
				});
			}
			axios
				.post(this.config.contextRoot + "/procedure/procedureSortOrderUpdate.do", param)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.$parent.selectList();
							this.doClose();
						});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the sort editing?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.procedureSort.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.procedureSort.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isProcedureSort");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.sort-row {
	display: flex;
}

.sort-row label {
	display: inline-block;
	width: 50%;
	font-weight: 600;
	font-size: 10pt;
	color: #666;
}

.sort-row label:first-child {
	padding: 0 10px 0 0;
}

.sort-row label:last-child {
	padding: 0 0 0 10px;
}

.sort-row input[type=text] {
	margin: 5px 0 8px;
}

.no-list {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10.5pt !important;
	color: #aaa !important;
}
.btn-cancel {
	margin-right: 10px;
}
</style>