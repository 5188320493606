<template>
	<div>
		<p class="txt-list-title">
			System Log List
		</p>
		<p class="txt-list-total">
			Total <span >{{ (pagination.total == 0 ? "0" : pagination.total) }}</span> log records remain.
		</p>
		<div class="list-control-wrap">
			<select class="select-crud select-search-option" v-model="searchOption">
				<option value="MAIN_CATEGORY">Main Category</option>
				<option value="MIDDLE_CATEGORY">Middle Category</option>
				<option value="SUB_CATEGORY">Sub Category</option>
			</select>
			<input
				type="text"
				class="input-list-search input-option"
				:placeholder="'Please enter the ' + searchOption.replace(/\_/gi, ' ').toLowerCase() + ' to search.'"
				v-model="searchText"
			/>
		</div>
		<div class="horizontal-overflow">
			<table class="tb-list-vertical tb-log">
				<colgroup>
					<col width="50"/>
					<col width="90"/>
					<col width="140"/>
					<col width="140"/>
					<col width="140"/>
					<col width="auto"/>
					<col width="auto"/>
					<col width="150"/>
					<col width="150"/>
					<col width="150"/>
				</colgroup>
				<thead>
					<tr>
						<th>No</th>
						<th>Action</th>
						<th>Main Category</th>
						<th>Middle Category</th>
						<th>Sub Category</th>
						<th>Previous Value</th>
						<th>Change Value</th>
						<th>Id</th>
						<th>Ip</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(log, index) in logList"
						:key="index"
						:class="openIndex == index ? 'open' : ''"
						@click="doDetail(index)"
					>
						<td>{{ log.rowNumber }}</td>
						<td class="underline"><b>{{ log.logAction }}</b></td>
						<td class="underline">{{ log.mainCategory }}</td>
						<td class="underline">{{ log.middleCategory.length == 0 ? "-" : log.middleCategory }}</td>
						<td class="underline">{{ (log.subCategory.length == 0 ? "-" : log.subCategory) }}</td>
						<td
							class="underline"
							v-html="( openIndex == index ? log.previousValue.replace(/(?:\r\n|\r|\n)/g, '<br />') : log.previousValue)"
						></td>
						<td
							class="underline"
							v-html="( openIndex == index ? log.changeValue.replace(/(?:\r\n|\r|\n)/g, '<br />') : log.changeValue)"
						></td>
						<td><b>{{ log.firstRegistUserId }}</b></td>
						<td>
							<b>{{log.firstRegistUserIp}}</b>
						</td>
						<td>
							{{log.firstRegistDate.substring(0, 19)}}
						</td>
					</tr>
					<tr v-if="logList.length == 0">
						<td colspan="10" class="no-list">
							There is no remained system log.
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "LogList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "LOG",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectLogList();
	},
	data() {
		return {
			item: {},
			logList: [],
			pagination: {},
			searchText: "",
			searchOption: "MAIN_CATEGORY",
			openIndex: -1
		};
	},
	watch: {
		searchText: function(val) {
			this.item.searchText = val;
			this.item.searchCategory = this.searchOption;
			this.item.pageNum = 1;
			this.selectLogList();
		}
	},
	methods: {
		selectLogList() {
			this.logger.debug(this, "selectLogList()");
			axios
				.post(this.config.contextRoot + "/log/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.logList = response.data.logList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						this.openIndex = -1;
						EventBus.$emit('log-loading', false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			EventBus.$emit('log-loading', true);
			this.selectLogList();
		},
		doDetail(index) {
			this.logger.debug(this, "doDetail(index)");
			if(this.openIndex == index)
				this.openIndex = -1;
			else
				this.openIndex = index;
		}
	}
};
</script>