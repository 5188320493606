<template>
	<div ref="roleCreate" class="dialog-container">
		<div class="dialog" style="max-height: 500px; transform: translate(0, -300px); max-width: 1000px;">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Role Create
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="33%" />
							<col width="17%" />
							<col width="33%" />
						</colgroup>
						<tbody>
                            <tr>
                                <th>Role Name.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        name="roleName"
                                        class="input-crud"
										:class="(errors.has('visitName') || isDuplicate ? 'input-crud-error' : '')"
										v-model="item.roleName"
										v-validate="'required'"
										data-vv-as="Role Name"
										@input="item.roleName = $event.target.value"
										@keyup="selectDuplicateCheck()"
									/>
									<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;"> 
										* Role Name is duplicated.
									</p>
									<p class="txt-validation" v-if="errors.has('roleName')">
										* {{ errors.first("roleName") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Role Category.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        class="input-crud"
                                        name="roleCategory"
										v-model="item.roleCategory"
										v-validate="'required'"
										data-vv-as="Role Category"
									/>
									<p class="txt-validation" v-if="errors.has('roleCategory')">
										* {{ errors.first("roleCategory") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Role Description.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        class="input-crud"
                                        name="roleDescription"
										v-model="item.roleDescription"
										v-validate="'required'"
										data-vv-as="Role Description"
									/>
									<p class="txt-validation" v-if="errors.has('roleDescription')">
										* {{ errors.first("roleDescription") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Register.</th>
								<td>
									{{firstRegistUserName}} ({{firstRegistUserId}})
								</td>
								<th>
									Regist Date.
								</th>
								<td>
									{{firstRegistDate }}
								</td>
                            </tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";

export default {
	name: "roleCreate",
	created() {
		this.logger.debug(this, "created()");
        this.firstRegistDate = moment().format("YYYY-MM-DD");
		this.firstRegistUserId = this.userInfo.userId;
		this.firstRegistUserName = this.userInfo.userName;
	},
	data() {
		return {
			item: {},
			isDuplicate: false,
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.selectDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to register the Role?", "Question", "question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
            this.logger.debug(this, "doInsert()");
            this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/role/roleInsert.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Role registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "addClose()");
			this.$refs.roleCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.roleCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("add-close", "isAddOpen");
			}.bind(this), 410);
		},
		selectDuplicateCheck() {
			this.logger.debug(this, "selectDuplicateCheck()");
			this.item.projectNo =  this.$route.params.projectNo;
			axios
				.post(this.config.contextRoot + "/role/selectDuplicateCheck.do", this.item)
				.then((response) => {
					console.log(response);
					if(response.data.duplicate == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>