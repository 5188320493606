<template>
	<div ref="PiSignature" class="dialog-container">
        <div class="dialog" style="max-height: 350px; transform: translate(0, -250px); max-width: 600px;">
			<div class="top">
				<p class="txt-crud-title">
					Pi Signature
				</p>
			</div>
			<div class="center">
				<p class="dynamic-warn">The subjects were under their supervision during the trial period, and the information and data contained in this case report are complete and accurate.</p>
				<p class="txt-form-title" style="margin-top: 20px;">principal investigator Signature or Reason.</p>
				<input name="statusChangeReason" type="text" class="input-crud" v-validate="'required'" v-model="statusChangeReason" maxlength="500"/>
			</div>
			<div class="bottom">
                <button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Save
				</button>
                <button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doClose()">
                    Close
                </button>
            </div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "PiSignature",
	created() {
        this.logger.debug(this, "created()");
		this.firstRegistUserId = this.userInfo.userId;

    },
    props: {
		param: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			item: {},
			statusChangeReason: ""
		};
    },
    watch: {
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.doInsert();
					}
					else{
						this.$alert("ERROR!", "Warning", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.item = {
				firstRegistUserId: this.firstRegistUserId,
				lastModifyUserId: this.firstRegistUserId,
				statusChangeUserId: this.firstRegistUserId,
				statusChangeReason: this.statusChangeReason,
				statusCd: "7",
				screeningNo: this.param.screeningNo,
				testScreeningNo: this.param.testScreeningNo,
				visitNo: this.param.visitNo,
				pageNo: this.param.pageNo
			}
			axios
				.post(this.config.contextRoot + "/screening/piSign.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						this.$alert("PI Signature has been saved successfully.", "Info", "info").then(() => {
							this.doClose();
							location.reload();
						});
					} else {
						alert(response.status);
						console.log(response);
						this.$parent.doCancel();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.PiSignature.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.PiSignature.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("status-close", "isOpenSign");
			}.bind(this), 410);
		}
	}
};
</script>
<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 0;
	font-weight: 600;
	font-size: 11pt;
	color: #999;
}
.dynamic-warn {
    font-size: 9pt;
    color: rgb(224, 91, 91);
	font-weight: 600;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
.user-check{
	font-weight: 400;
    font-size: 10pt;
    color: #999;
    text-align: left;
}
</style>