<template>
	<div class="login-center-wrap">
		<!-- <div id="loginCenterLeft" class="vhalf fleft flex-center">
			<ul class="login-edc-list no-drag">
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div> -->
		<div id="loginCenterRight">
			<form class="login-form pre-login-form" @submit.prevent="doLogin">
				<img class="login-head pre-login-head" src="@/assets/images/preRegistration/pre_end.png" alt=""/>

				<button type="button" class="login-button" @click="doClose()" style="margin-top: 55px;">
					닫기
				</button>

			</form>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import VeeValidator from "vee-validate";
Vue.use(VeeValidator);

export default {
	name: "preRegistration",
	created() {
		this.logger.debug(this, ".created()");
		console.log(this.userInfo.userId);
		// if (!this.utils.isEmpty(this.userInfo.userId)) {
		// 	this.$router.push(this.config.contextRoot + "/notice/list");
		// }
	},
	mounted() {
		this.logger.debug(this, ".mounted()");
	},
	data() {
		return {
			userId: "",
			password: "",
			loginResult: false,
			findId: "N",
			findPass: "N"
		};
	},
	methods: {
		doLogin() {
			this.logger.debug(this, "doLogin");
			this.$router.push(this.$route.query.redirect || this.config.contextRoot + "/preRegistration?ctcProjectId=109&osType=mobile");

			//관리자 계정 부여
			this.userId = 'ctcadmin';
			this.password = 'ctcadmin';

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						return;
					}

					this.$Auth.login(
						this.userId,
						this.password,
						function(loginResult) {
							if (loginResult) {
								this.$router.push(
									this.$route.query.redirect || this.config.contextRoot + "/project/109/preCreate?osType=" + this.$route.query.osType
								);
							} else {
								this.logger.debug(this, "loginResult:" + loginResult);
							}
						}.bind(this)
					);
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					this.$alert(this.errors.all());
					return false;
				});
		},
		doClose(){
			window.close();
			self.close();
			window.opener = window.location.href;
			self.close();
			window.open('about:blank', '_self').close();
		},
		viewPamphlet(){
			this.$router.push(this.$route.query.redirect || this.config.contextRoot + "/prePamphlet")
		}
	},
};
</script>
<style>
@media ( max-width: 1200px) {
	.pre-login-name{
		margin-top: 2vh;
		color: #BBB;
		font-size: 2vh !important;
	}
	.pre-login-sub{
		position: absolute;
		top: 0;
		justify-content: right;
		margin-right: 30px;
		/* text-decoration: underline; */
	}
	.pre-login-form{
		position: relative;
	}
	.pre-login-head{
		margin-top: 4vh;
		width: 25vmax !important;
	}
}
.pamphlet-btn{
	min-width: 74px !important;
	height: 23px !important;
	border-radius: 12px !important;
	background-color: #D4F2F9 !important;
	font-family: Pretendard !important;
	color: #454545 !important;
	font-size: 11px !important;
}
</style>