<template>
	<div class="container">
		<img class="prev" src="@/assets/images/login/left-arrow.png" @click="prevPage();"/>
		<div class="slider">
			<img class="phamphlet-img" src="@/assets/images/preRegistration/001.png" />
			<img class="phamphlet-img" src="@/assets/images/preRegistration/002.png" />
			<img class="phamphlet-img" src="@/assets/images/preRegistration/003.png" />
			<img class="phamphlet-img" src="@/assets/images/preRegistration/004.png" />
			<img class="phamphlet-img" src="@/assets/images/preRegistration/005.png" />
			<img class="phamphlet-img" src="@/assets/images/preRegistration/006.png" />
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import VeeValidator from "vee-validate";
Vue.use(VeeValidator);

export default {
	name: "prePamphlet",
	created() {
		this.logger.debug(this, ".created()");
		console.log(this.userInfo.userId);
	},
	mounted() {
		this.logger.debug(this, ".mounted()");
	},
	data() {
		return {
		};
	},
	methods: {
		prevPage(){
			this.$router.go(-1);
		}
	},
};
</script>
<style>
.container {
    position: relative;
    overflow: hidden;   

    @media only screen and (max-width: 1000px) {
        border-radius: 0;
    }
}

.slider {
    display: flex;
    height: 55rem;
    transition: all .25s ease-in;
    transform: translateX(0);
	overflow-x: scroll;
	@media only screen and (max-width: 1000px) {
		height: 100vh;
	}
}
.prev{
	position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
	filter: opacity(0.5) drop-shadow(0 0 0 black);
	width: 40px;
}
</style>
