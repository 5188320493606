<template>
	<div ref="codeManage" class="dialog-container">
		<div class="dialog" style="max-height: 650px; transform: translate(0, -325px);">
			<form @submit.stop="doValidation()">
				<div class="top"> 
					<p class="txt-crud-title">
						Code Manage
					</p>
				</div>
				<div class="center">
					<input
						type="text"
						class="input-list-search"
						placeholder="Please enter the code id or name to search."
						style="width: 555px; margin: 0;"
					/>
					<div class="scroll-thead-wrap">
						<table class="tb-code-list no-drag">
							<colgroup>
								<col width="50px" />
								<col width="90px" />
								<col width="90px" />
								<col width="100px" />
								<col width="100px" />
								<col width="80px" />
								<col width="45px" />
							</colgroup>
							<thead>
								<tr>
									<th>No</th>
									<th>ID</th>
									<th>NAME</th>
									<th>LABEL</th>
									<th>VALUE</th>
									<th>COUNT</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(code, codeIndex) in codeList"
									:key="codeIndex"
								>
									<td>{{ codeIndex + 1 }}</td>
									<td>{{ code.id }}</td>
									<td>{{ code.name }}</td>
									<td>
										<p
											v-for="(item, itemIndex) in code.item"
											:key="itemIndex"
										>
											{{ item.label }}
										</p>
									</td>
									<td>
										<p
											v-for="(item, itemIndex) in code.item"
											:key="itemIndex"
										>
											{{ item.value }}
										</p>
									</td>
									<td>
										{{ code.item.length }}
									</td>
									<td>
										<button type="button" class="btn-remove" title="remove code"></button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="scroll-tbody-wrap scroll">
						<table class="tb-code-list no-drag">
							<colgroup>
								<col width="50px" />
								<col width="90px" />
								<col width="90px" />
								<col width="100px" />
								<col width="100px" />
								<col width="80px" />
								<col width="45px" />
							</colgroup>
							<thead>
								<tr>
									<th>No</th>
									<th>Id</th>
									<th>Name</th>
									<th>Label</th>
									<th>Value</th>
									<th>Count</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(code, codeIndex) in codeList"
									:key="codeIndex"
									:class="(selectCodeIndex == codeIndex ? 'on' : '')"
								>
									<td>{{ codeIndex + 1 }}</td>
									<td>{{ code.id }}</td>
									<td>{{ code.name }}</td>
									<td>
										<p
											v-for="(item, itemIndex) in code.item"
											:key="itemIndex"
										>
											{{ item.label }}
										</p>
									</td>
									<td>
										<p
											v-for="(item, itemIndex) in code.item"
											:key="itemIndex"
										>
											{{ item.value }}
										</p>
									</td>
									<td>
										{{ code.item.length }}
									</td>
									<td>
										<button type="button" class="btn-remove" title="remove code"></button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<button
						type="button"
						class="btn-new"
						title="New Code"
						@click="mode = 'Create'"
					>
						<img src="@/assets/images/common/btn_new.png" alt="" />
						New Code
					</button>
					<div class="code-detail">
						<p class="mode-title">
							Code {{ mode }}
						</p>
						<div class="code-view scroll">
							<label for="codeId" class="label">
								ID
							</label>
							<input
								type="text"
								id="codeId"
								:name="'codeId'"
								class="input-crud input-crud-margin options"
								v-model="codeForm.fieldCodeGroupId"
								v-validate="'required'"
								data-vv-name="Code Id"
								:placeholder="errors.has('codeId') ? 'Please enter the code id.' : 'id'"
								
							/>
							<label for="codeName" class="label">
								NAME
							</label>
							<input
								type="text"
								id="codeName"
								name="codeName"
								class="input-crud input-crud-margin options"
								:class="errors.has('codeName') ? 'input-crud-error' : ''"
								v-model="codeForm.fieldCodeGroupName"
								v-validate="'required'"
								data-vv-name="Code Name"
								:placeholder="errors.has('codeName') ? 'Please enter the code name.' : 'name'"
								
							/>
							<div
								v-for="(codeItem, codeItemIndex) in codeForm.fieldCodeList"
								:key="codeItemIndex"
								class="input-options"
							>
								<div>
									<label :for="'label' + codeItemIndex" class="label">
										Label
									</label>
									<input
										type="text"
										:id="'label' + codeItemIndex"
										:name="'label' + codeItemIndex"
										class="input-crud options"
										:class="errors.has('label' + codeItemIndex) ? 'input-crud-error' : ''"
										v-model="codeForm.fieldCodeList[codeItemIndex].fieldCodeLabel"
										v-validate="'required'"
										:data-vv-name="'label' + codeItemIndex"
										:placeholder="errors.has('label' + codeItemIndex) ? 'Please enter the label.' : 'label'"
										
									/>
								</div>
								<div>
									<label :for="'value' + codeItemIndex" class="label">
										Value
									</label>
									<input
										type="text"
										:id="'value' + codeItemIndex"
										:name="'value' + codeItemIndex"
										class="input-crud options options-last"
										:class="errors.has('value' + codeItemIndex) ? 'input-crud-error' : ''"
										v-model="codeForm.fieldCodeList[codeItemIndex].fieldCodeValue"
										v-validate="'required'"
										:data-vv-name="'value' + codeItemIndex"
										:placeholder="errors.has('value' + codeItemIndex) ? 'Please enter the value.' : 'value'"
									/>
									<button
										v-if="codeItemIndex == 0"
										type="button"
										class="btn-add-option"
										title="New Options"
										@click.stop="addRow()"
									>
										<img src="@/assets/images/common/btn_new.png" style="width: 10px; height: 10px;"/>
									</button>
									<button
										v-if="codeItemIndex != 0"
										type="button"
										class="btn-remove-options"
										title="Remove Row"
										@click.stop="removeRow(codeItemIndex)"
									>
										<img src="@/assets/images/common/minus-sign.png" style="width: 10px; height: 10px;"/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "CodeManage",
	created() {
		this.logger.debug(this, "created()");
		this.selectCodeList();
	},
	props: {
		index: {
			type: String,
			required: true
		},
	},
	data() {
		return {
			codeForm: {
				fieldCodeGroupId: '',
				fieldCodeGroupName: '',
				fieldCodeList: [
					{
						fieldCodeLabel: '',
						fieldCodeValue: ''
					}
				]
			},
			codeList: [],
			selectCodeIndex: 0,
			mode: "Edit"
		};
	},
	methods: {
		selectCodeList() {
			this.logger.debug(this, "selectCodeList()");
			// axios
			// 	.post(this.config.contextRoot + "/field/select.do", {})
			// 	.then((response) => {
			// 		this.codeList = response.data.codeList;
			// 	})
			// 	.catch((error) => {
			// 		this.exceptionHandler(this, error);
			// 	});
			let codeList = [
				{
					id: 'ALPHABET',
					name: '알파벳',
					item: [
						{label: 'ABC', value: '123'}, {label: 'CDE', value: '456'}, {label: 'FGH', value: '789'}
					]
				},
				{
					id: 'NUMBER',
					name: '번호',
					item: [
						{label: '123', value: '123'}, {label: '456', value: '456'}, {label: '789', value: '789'}
					]
				},
				{
					id: 'KOREAN',
					name: '한국어',
					item: [
						{label: 'ㄱㄴㄷ', value: '123'}, {label: 'faqfafffffffffffffffffffffffffffqfqfqwf', value: '456'}, {label: 'ㅅㅇㅈ', value: '789'}
					]
				},
				{
					id: 'ALPHABEssssssssssssssssssssssssssssssssssssT',
					name: '알파벳',
					item: [
						{label: 'ABC', value: '123'}, {label: 'CDE', value: '456'}, {label: 'FGH', value: '789'}
					]
				},
				{
					id: 'NUMBER',
					name: '번호',
					item: [
						{label: '123', value: '123'}, {label: '456', value: '456'}, {label: '789', value: '789'}
					]
				},
				{
					id: 'KOREAN',
					name: '한국어',
					item: [
						{label: 'ㄱㄴㄷ', value: '123'}, {label: 'ㄹㅁㅂ', value: '456'}, {label: 'ㅅㅇㅈ', value: '789'}
					]
				},
				{
					id: 'KOREAN',
					name: '한국어',
					item: [
						{label: 'ㄱㄴㄷ', value: '123'}, {label: 'ㄹㅁㅂ', value: '456'}, {label: 'ㅅㅇㅈ', value: '789'}
					]
				}
			];
			this.codeList = codeList;
		},
		addRow() {
			this.logger.debug(this, "addRow()");
			let fieldCodeItem = {
				fieldCodeLabel: '',
				fieldCodeValue: ''
			}
			this.codeForm.fieldCodeList.push(fieldCodeItem);
		},
		removeRow(index) {
			this.logger.debug(this, "removeRow(index)");
			this.codeForm.fieldCodeList.splice(index, 1);
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if(result)
					{
						if(this.mode == 'Create')
						{
							this.$confirm("Do you want to register the current code?", "Question", "question").then(() => {
								EventBus.$emit("field-loading", true);
								this.doInsert();
							});
						}
						else if(this.mode == 'Edit')
						{
							this.$confirm("Do you want to save the current code?", "Question", "question").then(() => {
								EventBus.$emit("field-loading", true);
								this.doUpdate();
							});
						}
						else
						{
							this.$alert("Error has occured!", "Warn", "warning");
							return false;
						}
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			axios
				.post(this.config.contextRoot + "/field/insertFieldCodeGroup.do", this.codeForm)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.codeManage.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.codeManage.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("closeCodeManage");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.dialog {
	max-width: 1200px;
}

.dialog-container {
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
}

.btn-cancel {
	margin-right: 10px;
}

.center {
	position: relative;
	overflow: visible;
}

.scroll-thead-wrap {
	position: absolute;
	top: 50px;
	bottom: 0;
	left: 0;
	right: 0;
	width: 555px;
	height: 36px;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: hidden;
	z-index: 10;
}

.scroll-thead-wrap tbody {
	visibility: hidden;
}

.scroll-tbody-wrap {
	position: absolute;
	top: 50px;
	bottom: 0;
	left: 0;
	right: 0;
	width: calc(555px + 18px);
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 5;
}

.scroll-tbody-wrap thead {
	visibility: hidden;
}

.tb-code-list {
	width: 100%;
	margin: 0;
	border-collapse: collapse;
	table-layout: fixed;
}

.tb-code-list tr {
	height: 35px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.tb-code-list tr.on > td {
	background-color: #f8f9fa;
	font-weight: 600;
	color: #666;
}

.tb-code-list tr:hover > td {
	background-color: #f8f9fa;
	cursor: pointer;
}

.tb-code-list th {
	padding: 3px 5px;
	background-color: #eee;
	font-weight: 600;
	font-size: 10pt;
	color: #000;
	text-align: center;
}

.tb-code-list td {
	padding: 3px 5px;
	background-color: #fff;
	font-weight: 400;
	font-size: 9.5pt;
	color: #999;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


.tb-code-list td > p {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.code-detail {
	position: absolute;
	top: 50px;
	right: 0;
	width: calc(100% - 600px);
	height: calc(100% - 50px);
	background-color: #fff;
	border: 2px solid #2bade0;
	border-radius: 5px;
	box-shadow: 1px 1px 3px 0px #666;
	overflow: hidden;
	z-index: 5;
}

.input-options {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	margin: 0 0 5px ;
}

.input-options > div {
	position: relative;
}

.options {
	margin: 3px 0 0;
	border-radius: 5px;
}

.options-last {
	width: calc(100% - 31px);
}

.label {
	font-weight: 600;
	font-size: 9.5pt;
	color: #999;
}

.mode-title {
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	padding: 0 0 0 20px;
	border-bottom: 1px solid #dee2e6;
	background-color: #fafafa;
	font-weight: 600;
	font-size: 11pt;
	color: #666;
}

.code-view {
	position: relative;
	width: 100%;
	height: calc(100% - 45px);
	padding: 10px 20px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.input-crud-margin {
	margin: 3px 0 10px;
}
</style>