<template>
	<div id="fieldLayout" class="layout-container">
		<Menu :menuName="menuName" />
		<Topbar ref="topbar" :menuName="menuName" />
		<div class="contents-container" :class="isLoading ? 'loading' : ''" style="overflow-y: scroll;">
			<router-view ref="router" :class="isLoading ? 'blur' : ''" />
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import Menu from "@/components/left/Menu.vue";
import Topbar from "@/components/left/Topbar.vue";

export default {
	name: "FieldLayout",
	components: {
		Menu,
		Topbar
	},
	created() {
		this.logger.debug(this, "created()");

		// 로딩바 닫기
		EventBus.$on("field-loading", (isLoading) => {
			this.logger.debug(this, "[EventBus] loading(isLoading)");
			this.isLoading = isLoading;
		});
		// CRUD 새 창 닫기
		EventBus.$on("group-cancel", (target) => {
			this.logger.debug(this, "[EventBus] cancel(target)");
			if(target === "isGroupCreate") this.$refs.router.$data.isGroupCreate = false
			else if(target === "isGroupEdit") this.$refs.router.$data.isGroupEdit = false
			else if(target === "isFieldSort") this.$refs.router.$data.isFieldSort = false
		});
		EventBus.$on("page-move", (target) => {
			this.logger.debug(this, "[EventBus] pagemove(target)");
			this.current_page = target;
		});
	},
	beforeDestroy() {
		EventBus.$off('field-loading');
		EventBus.$off('group-cancel');
	},
	data() {
		return {
			menuName: "Field",
			isLoading: true,
			current_page: "",
		};
	}
};
</script>

<style scoped>
@import "../../assets/css/field/field.css";
</style>