<template>
	<div id="noticeLayout" class="layout-container">
		<Menu :menuName="menuName" />
		<Topbar ref="topbar" :menuName="menuName" />
		<div class="contents-container" :class="isLoading ? 'loading' : ''">
			<router-view ref="router" :class="isLoading ? 'blur' : ''" :searchText="searchText"/>
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import Menu from "@/components/left/Menu.vue";
import Topbar from "@/components/left/Topbar.vue";

export default {
	name: "NoticeLayout",
	components: {
		Menu,
		Topbar
	},
	created() {
		this.logger.debug(this, "created()");

		// 검색어 받기
		EventBus.$on("notice-search", (searchText) => {
			this.logger.debug(this, "[EventBus] notice-search(searchText)");
			this.searchText = searchText;
		});
		// 로딩바 닫기
		EventBus.$on("notice-loading", (isLoading) => {
			this.logger.debug(this, "[EventBus] notice-loading(isLoading)"); 
			this.isLoading = isLoading;
		});
		// CRUD 새 창 닫기
		EventBus.$on("notice-cancel", (target) => {
			this.logger.debug(this, "[EventBus] notice-cancel(target)");
			if(target === "isCreate") this.$refs.router.$data.isCreate = false;
			else if(target === "isEdit") this.$refs.router.$data.isEdit = false;
		});
	},
	beforeDestroy() {
		EventBus.$off('notice-search');
		EventBus.$off('notice-loading');
		EventBus.$off('notice-cancel');
	},
	data() {
		return {
			menuName: "Notice",
			searchText: "",
			isLoading: true
		};
	}
};
</script>

<style scoped>
@import "../../assets/css/notice/notice.css";
</style>