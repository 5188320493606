import html2pdf from 'html2pdf.js'; //html2pdf 라이브러리 import
const methods = {
  // location은 pdf영역 tag
  // fileName은 PDF 파일 명
  htmlToPdf: (location, fileName) => {
    html2pdf()
      .set({
        margin: [8, 8, 8, 8],
      	// filename에서 IE11은 .pdf를 자동으로 넣어주지 않아 .pdf를 파일 명에 넣어줘야 한다.
        filename: navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1 ? `${fileName}.pdf` : fileName,
        pagebreak: {  avoid: ['tr', 'td'], mode: ['css']},
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 2,
          dpi: 300,
          letterRendering: true,
          allowTaint: false,
        },
        jsPDF: { orientation: 'portrait', unit: 'mm', format: 'b3'},
      })
      .from(location)
      .save();
  },
};

export default {
  install(Vue) {
    Vue.prototype.$htmlToPdf = methods.htmlToPdf;
  },
};