<template>
	<div ref="screeningStatusHistory" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px); max-width: 1500px;">
			<form @submit.stop="doValidation()">
				<div class="top" style="height: 30px">
					<p class="txt-crud-title">
						Status History
					</p>
				</div>
				<div class="center">
					<table class="tb-list-vertical">
                        <colgroup>
                            <col width="5%" />
                            <col width="17%" />
                            <col width="10%" />
                            <col width="8%" />
                            <col width="auto" />
                            <col width="8%" />
                            <col width="20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    No
                                </th>
                                <th>
                                    Screening No
                                </th>
                                <th>
                                    Visit
                                </th>
                                <th>
                                    Page
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    PI Sign
                                </th>
                                <th>
                                    User
                                </th>
                                <th>
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in screeningStatusHistoryList" :key="index">
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ history.screeningName }}
                                </td>
                                <td>
                                    {{ history.visitName }}
                                </td>
                                <td>
                                    {{ history.pageName }}
                                </td>
                                <td>
                                    {{ getStatusName(history.statusCd)}}
                                </td>
                                <td>
                                    {{ history.statusChangeReason != '' ? history.statusChangeReason : '-'}}
                                </td>
                                <td>
                                    <b>{{ history.statusChangeUserId }}</b>
                                    ({{ history.statusChangeUserName }})<br>
                                    <!-- {{ history.screeningHistoryStatusName }} -->
                                </td>
                                <!-- <td>
                                    {{ history.dataSortationName }}
                                </td>
                                <td>
                                    {{ history.dataSortationValue }}
                                </td> -->
                                <td>
                                    {{ history.firstRegistDate.substring(0, 19) }}
                                </td>
                            </tr>
                            <tr v-if="screeningStatusHistoryList.length == 0">
                                <td colspan="10" class="no-list">
                                    There is no registered page, Please register the page.
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
                    <button type="button" class="btn-cancel fright" title="Cancel" @click="excelDownload()">
						Excel Download
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ScreeningStatusHistory",
	created() {
        this.logger.debug(this, "created()");
        this.item.screeningNo = this.param.screeningNo;
        this.item.testScreeningNo = this.param.testScreeningNo;
        this.item.visitNo = this.param.visitNo;
        this.item.pageNo = this.param.pageNo;

        if(this.param.screeningNo != null){
            this.selectScreeningStatusHistory();
        }
        else{
            this.selectTestScreeningStatusHistory();
        }
        this.selectComCodeList();

    },
    props: {
		param: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
            item: {},
            screeningStatusHistoryList: [],
            codeList: []
		};
    },
	methods: {
		selectScreeningStatusHistory() {
            this.logger.debug(this, "selectScreeningStatusHistory()");

			axios
				.post(this.config.contextRoot + "/screening/selectScreeningStatusHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.screeningStatusHistoryList = response.data.screeningStatusHistoryList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
        },
		selectTestScreeningStatusHistory() {

            this.logger.debug(this, "selectTestScreeningStatusHistory()");

			axios
				.post(this.config.contextRoot + "/testScreening/selectScreeningStatusHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.screeningStatusHistoryList = response.data.screeningStatusHistoryList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
        },
        selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["SCREENING_INPUT_STATUS_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
        excelDownload() {
            EventBus.$emit("project-loading", true);
            this.logger.debug(this, "excelDownload()");

            this.item.screeningStatusHistoryVoList = this.screeningStatusHistoryList;

			axios
				.post(this.config.contextRoot + "/excel/statusHistoryDownload.do", this.item, {responseType: "blob"})
				.then((response) => {
					console.log(response);
                    const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					
					let today = new Date();
					let year = today.getFullYear();
					let month = ('0' + (today.getMonth() +1)).slice(-2);
					let day = ('0' + (today.getDate())).slice(-2);

					today = year + "-" + month + "-" + day;

                    var name = "Status_History_" + this.param.screeningName + "_" + this.param.visitName + "_" + this.param.pageName;

					link.setAttribute("download", name + "_"+today+".xlsx");
					document.body.appendChild(link);
					link.click();

					EventBus.$emit("project-loading", false);
					
					this.$alert("The file has been downloaded.", "Info", "info").then((result) => {
						if (result === true) {
							// location.reload();
						}
					});
				})
				.catch((error) => {
                    this.exceptionHandler(this, error);
				});
                
        },
        getStatusName(code){
            let statusName = '';
            for(let i = 0; i < this.codeList.length; i++){
                if(this.codeList[i].comCode == code){
                    statusName = this.codeList[i].comCodeName;
                }
            }

            return statusName;
        },
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.screeningStatusHistory.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.screeningStatusHistory.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("history-close", "isScreeningStatusHistory");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>