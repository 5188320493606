import Vue from "vue"
import axios from "axios"

export default "Auth"

const methods = {
    login: function(userId, password, result) {
        // console.log("[Auth.js]login(" + userId + "," + password + ")");

        const data = {
            userId: userId,
            userPassword: password
        };

        axios.post(Vue.prototype.config.contextRoot + "/access/login.do", data)
            .then(function(response) {

                sessionStorage.setItem("userId", response.data.message.userId);
                sessionStorage.setItem("userName", response.data.message.userName);
                sessionStorage.setItem("roleCd", response.data.message.roleCd);

                Vue.prototype.userInfo = {
                    userId: sessionStorage.getItem("userId"),
                    userName: sessionStorage.getItem("userName"),
                    roleCd: sessionStorage.getItem("roleCd"),
                    isAdmin: function() {
                        return sessionStorage.getItem("roleCd") !== undefined && sessionStorage.getItem("roleCd").indexOf("ROLE_ADMIN") >= 0 ? true : false;
                    }
                }

                result(true);
            })
            .catch(function(error) {

                let errorTitle = error.response.statusText;
                let message = error.response.data.message;

                alert("[" + errorTitle + "]" + message);
            });
    },
    logout: function(result) {
        console.log("[Auth.js]logout()");

        axios.post(Vue.prototype.config.contextRoot + "/access/logout.do")
            .then(function(response) {
                console.log(response);

                sessionStorage.setItem("userId", "");
                sessionStorage.setItem("userName", "");
                sessionStorage.setItem("roleCd", "");
                Vue.prototype.userInfo = {};
                result(true);
            })
            .catch(function(error) {
                let errorTitle = error.response.statusText;
                let message = error.response.data.message;

                alert("[" + errorTitle + "]" + message);

                result(false);
            });
    },
    info: function() {
        axios
            .post(Vue.prototype.config.contextRoot + "/access/info.do")
            .then(function(response) {
                console.log(response);

                if (response.data.userId != sessionStorage.getItem("userId") || response.data.roleCd != sessionStorage.getItem("roleCd")) {
                    sessionStorage.setItem("userId", response.data.userId);
                    sessionStorage.setItem("userName", response.data.userName);
                    sessionStorage.setItem("roleCd", response.data.roleCd);
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function(error) {
                //console.log(error.response);
                sessionStorage.setItem("userId", "");
                sessionStorage.setItem("userName", "");
                sessionStorage.setItem("roleCd", "");
            });
    }
};

Vue.prototype.$Auth = methods;