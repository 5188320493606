<template>
	<div>
		<!-- 프로젝트 Import 업로더(hidden) -->
		<common-file
			ref="ProjectUploader"
			v-model="projectImport.fileList"
			fileMaxSize="100"
			fileExtension="zip"
			style="display: none;"
		/>
		<!-- 프로젝트 데이터 Import 업로더(hidden) -->
		<site-file
			ref="DataUploader"
			v-model="dataImport.siteFileList"
			fileMaxSize="100"
			fileExtension="zip"
			style="display: none;"
		/>
		<button v-if="isAccessCreate" type="button" class="btn-new" title="New Project" @click="doCreate()">
			<img src="@/assets/images/common/btn_new.png" alt="" />
			New Project
		</button>
		<button v-if="isAccessImport" type="button" class="btn-import" title="Import Project" @click="openUploader('ProjectUploader')">
			Import Project
		</button>
		<!-- <button v-if="isAccessImport" type="button" class="btn-import" title="Privilege Update" @click="privilegeUpdate()">
			Privilege Update
		</button> -->
		<p class="txt-list-title">
			All Projects
		</p>
		<p class="txt-list-total">
			Total <span>{{ projectList.length }}</span> projects are in operation.
			<select 
				class="project-list-select"
				@input="selectValue($event.target.value, 'searchStateCd')"
			>
				<option v-if="!isData" value="" selected disabled>--- select ---</option>
				<option value="0">ALL</option>
				<option v-for="(item, index) in projectStateList" :key="index" :value="item.comCode">
					{{ item.comCodeName }}
				</option>
			</select>
			<!-- <select 
				class="project-list-select"
				style="right: 165px;"
				@input="selectValue($event.target.value, 'searchCtcSite')"
			>
				<option v-if="!isData" value="" selected disabled>--- select ---</option>
				<option value="0">ALL</option>
				<option value="1">CTC</option>
				<option value="2">SITE</option>
			</select> -->
		</p>
		<!-- 그리드 시작 -->
		<div class="list-grid list-grid-small" ref="projectList">
			<!-- 아이템 시작 -->
			<div
				v-for="index in ((openPage * 12) < projectList.length ? openPage * 12 : projectList.length)"
				:key="index"
				class="item"
			>
				<button type="button" class="btn-project-version" @click.stop="dropBalloon($event, index, 'version')">V{{ projectList[index-1].projectVersion }}</button>
				<ul
					v-if="(index - 1) == openVersionBallon && (index - 1) == openProjectIndex"
					class="list-balloon-version"
					tabindex="0"
					@blur="hideBalloon($event, 'version')"
				>
					<li
						v-for="(version, versionIndex) in projectList[index-1].projectVersionList"
						:key="versionIndex"
						@click="versionChange(projectList[index-1], version.projectVersion, $event)"
					>
					V{{ version.projectVersion }}
					</li>
				</ul>
				<!-- 프로젝트 시작 -->
				<div
					class="box-project"
					:class="((index - 1) == openProjectIndex ? 'box-project-clicked' : '')"
					:style="projectList[index - 1].ctcProjectNo == '' && (index - 1) != openProjectIndex ? 'border: 2px solid #777;' : ''"
					tabindex="0"
					@click="doProject(projectList[index - 1].projectNo, projectList[index - 1].ctcProjectNo)"
					@mouseenter="openProject($event, (index - 1))"
					@focus="openProject($event, (index - 1))"
				>
					<!-- 프로젝트 제목 -->
					<p
						class="title ellipsis"
						:class="projectList[index - 1].projectStateNm.toLowerCase()"
						:title="projectList[index - 1].protocolName + projectList[index - 1].projectStudyTitle"
					>
						<span class="protocol">[{{ projectList[index - 1].protocolName }}]</span>
						{{ projectList[index - 1].projectStudyTitle }}
					</p>
					<!-- 프로젝트 내용 -->
					<p
						class="contents"
						v-html="
							utils.isEmpty(projectList[index - 1].projectNote)
							? ''
							: projectList[index - 1].projectNote.replace(/(?:\r\n|\r|\n)/g, '<br />')
						"
					></p>
					<!-- 프로젝트 케이스, 날짜 -->
					<p v-if="(index - 1) != openProjectIndex" class="info">
						<b>{{ projectList[index - 1].caseCount }}</b> case
						<span class="date">{{ projectList[index - 1].lastModifyDate.substring(0, 19) }}</span>
					</p>
					<!-- 프로젝트 컨트롤바 -->
					<div v-if="(index - 1) == openProjectIndex" class="open-info">
						<p class="case">
							<b>{{ projectList[index - 1].caseCount }}</b> case
						</p>
						<p class="date">
							{{ projectList[index - 1].lastModifyDate.substring(0, 19) }}
							<!-- 프로젝트 Export 버튼 -->
							<button :class="(projectList[index - 1].ctcProjectNo == '' && (workAccessCheck('EXPORT') || workAccessCheck('COPY')))? '' : 'hidden-important'" type="button" class="btn-project-info" @click.stop="dropBalloon($event, index, 'info')"
								:style="projectList[index - 1].projectStateCd != '4' ? '' : 'display : none;'"
							></button>
							<ul
								v-if="projectList[index - 1].ctcProjectNo == '' && (index - 1) == openInfoBallon"
								class="list-balloon"
								tabindex="0"
								@blur="hideBalloon($event, 'info')"
							>
								<li v-if="projectList[index - 1].projectStateCd == '3' && workAccessCheck('EXPORT')" @click.stop="exportProject(projectList[index - 1].projectNo)">Project Export</li>
								<li v-if="(projectList[index - 1].projectStateCd == '1' || projectList[index - 1].projectStateCd == '2' || projectList[index - 1].projectStateCd == '3') && workAccessCheck('COPY')" @click.stop="copyProject(projectList[index - 1].projectNo)">New Version</li>
								<!-- <li @click.stop="copyProject(projectList[index - 1].projectNo)">뉴버전</li> -->
								<!-- <li @click.stop="copyProject(projectList[index - 1].projectNo)">project Copy</li> -->
							</ul>
							<!-- 프로젝트 데이터 Export 버튼 -->
							<button :class="(workAccessCheck('DATA IMPORT') || workAccessCheck('DATA DOWNLOAD')) ? '' : 'hidden-important'" type="button" class="btn-project-data" style="projectList[index - 1].ctcProjectNo == '' ? '' : 'right: 0px'" @click.stop="dropBalloon($event, index, 'data')"
								:style="projectList[index - 1].ctcProjectNo == '' ? 'right : 40px;' : 'right : 0;'"
							></button>
							<ul
								v-if="(index - 1) == openDataBallon"
								class="list-balloon"
								tabindex="0"
								@blur="hideBalloon($event, 'data')"
							>
								<li v-if="(projectList[index - 1].projectStateCd == '3' && projectList[index - 1].ctcProjectNo == '') && workAccessCheck('DATA IMPORT')" @click.stop="openUploader('DataUploader', projectList[index - 1].projectNo)">Data Import</li>
								<li v-if="(projectList[index - 1].projectStateCd == '3' && projectList[index - 1].ctcProjectNo != '') && workAccessCheck('DATA EXPORT')" @click.stop="exportDataOpen()">Data Export</li>
								<li v-if="workAccessCheck('DATA DOWNLOAD')" @click.stop="doDataCheck(projectList[index - 1].projectNo)">Data Download</li>
								<li v-if="projectList[index - 1].ctcProjectNo == '' && workAccessCheck('DATA DOWNLOAD')" @click.stop="doTestDataCheck(projectList[index - 1].projectNo)">Test Download</li>
								<li v-if="(projectList[index - 1].projectStateCd == '1' || projectList[index - 1].projectStateCd == '2' || projectList[index - 1].projectStateCd == '3')" @click.stop="migrationOpen(projectList[index - 1].projectNo)">Data Migration</li>
							</ul>
							<!-- 프로젝트 Edit 버튼 -->
							<button v-if="projectList[index - 1].ctcProjectNo == '' && workAccessCheck('EDIT')" type="button" class="btn-project-edit" title="Edit Project" @click.stop="doEdit()"></button>
						</p>
					</div>
				</div>
			</div>
		</div>
		<button v-if="openPage < maxPage" type="button" class="btn-more" title="More View" @click="moreView($event)">
			<img src="@/assets/images/common/btn_more.png" alt="" />
			more view
		</button>
		<ProjectCreate v-if="isCreate" />
		<ProjectEdit v-if="isEdit" :projectNo="projectList[openProjectIndex].projectNo"/>
		<ProjectCopy v-if="isCopy" :projectNo="copyProjectNo"/>
		<ProjectMigration v-if="isMigration" :projectNo="copyProjectNo"/>
		<ProjectDataCreate v-if="isDataCreate" :projectNo="projectList[openProjectIndex].projectNo"/>
		<ProjectTestDataCreate v-if="isTestDataCreate" :projectNo="projectList[openProjectIndex].projectNo"/>
		<ProjectExport v-if="isDataExportOpen" :projectNo="projectList[openProjectIndex].projectNo"/>
		<div v-if="isMethod" class="prompt">
			<div class="wrap">
				<div class="top">
					Select Import Method
					<button type="button" class="close" @click="cancelData()">
						×
					</button>
				</div>
				<div class="bottom">
					<p>Please choose  the import method</p>
					<button type="button" class="white" @click="importData(false)">
						Override
					</button>
					<button type="button" class="blue" @click="importData(true)">
						Add
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import ProjectCreate from "@/components/project/project/ProjectCreate.vue"
import ProjectEdit from "@/components/project/project/ProjectEdit.vue"
import ProjectCopy from "@/components/project/project/ProjectCopy.vue"
import ProjectMigration from "@/components/project/project/ProjectMigration.vue"
import ProjectDataCreate from "@/components/project/project/ProjectDataCreate.vue"
import ProjectTestDataCreate from "@/components/project/project/ProjectTestDataCreate.vue"
import ProjectExport from "@/components/project/project/ProjectExport.vue"
import CommonFile from "@/components/common/CommonFile";
import SiteFile from "@/components/common/SiteFile";

export default {
	name: "ProjectList",
	components: {
		ProjectCreate,
		ProjectEdit,
		ProjectCopy,
		ProjectMigration,
		ProjectDataCreate,
		ProjectTestDataCreate,
		ProjectExport,
		CommonFile,
		SiteFile
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "PROJECT",
			privilegeName: "ACCESS"
		}

		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectProjectList();
		this.selectProjectStateList();
		this.getAccessList('PROJECT');
		this.privilegeUpdate();
	},
	props: {
		searchText: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {},
			projectList: [],
			projectStateList: [],
			openProjectIndex: -1,
			openPage: 1,
			isCreate: false,
			isEdit: false,
			isData: false,
			isDataCreate: false,
			isTestDataCreate: false,
			openInfoBallon: -1,
			openDataBallon: -1,
			openVersionBallon: -1,
			projectImport: {},
			dataImport: {},
			isCopy: false,
			isMigration: false,
			accessList: [],
			isAccessCreate: false,
			isAccessImport: false,
			isMethod: false,
			isDataExportOpen: false,
		};
	},
	computed: {
		maxPage: function() {
			return Math.ceil(this.projectList.length / 12);
		}
	},
	watch: {
		searchText: function() {
			this.selectProjectList();
			this.initHeight();
		},
		projectImport: function() {
			if(this.projectImport.fileList.length == 1)
			{
				var fileExtension = this.projectImport.fileList[0].fileExtensionName;
				if(fileExtension == "zip" || fileExtension == "ZIP" || fileExtension == "Zip")
				{
					this.importProject();
				}
				else
				{
					this.$alert("Please upload the file in ZIP format.","Warning","warning");
					location.reload();
				}
			}
		},
		dataImport: function() {
			if(this.dataImport.siteFileList.length == 1)
			{
				var fileExtension = this.dataImport.siteFileList[0].fileExtensionName;
				if(fileExtension == "zip" || fileExtension == "ZIP" || fileExtension == "Zip")
				{
					this.isMethod = true;
					// this.importData();
				}
				else
				{
					this.$alert("Please upload the file in ZIP format.","Warning","warning");
					location.reload();
				}
			}
		}
	},
	methods: {
		selectProjectList() {
			this.logger.debug(this, "selectProjectList()");
			this.openProjectIndex = -1;
			axios
				.post(this.config.contextRoot + "/project/selectList.do", {searchText: this.searchText})
				.then((response) => {
					console.log(response);
					this.projectList = [];
					for(var i = 0; i < response.data.projectList.length; i++){
						if(response.data.projectList[i].projectVersion == 1 || response.data.projectList[i].ctcProjectNo != ''){
							this.projectList.push(response.data.projectList[i]);
						}
					}
					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("project-loading", false);
						}.bind(this), 400);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectStateList() {
			this.logger.debug(this, "selectStateList()");
			this.openProjectIndex = -1;
			this.$set(this.item, "searchText", this.searchText);
			axios
				.post(this.config.contextRoot + "/project/selectStateList.do", this.item)
				.then((response) => {
					console.log(response);
					this.projectList = response.data.projectList;
					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("project-loading", false);
						}.bind(this), 400);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProjectStateList() {
			this.logger.debug(this, "selectProjectStateList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "PROJECT_STATE"})
				.then((response) => {
					console.log(response);
					this.projectStateList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		openProject(event, index) {
			this.logger.debug(this, "openProject(event, index)");
			// 다른 Notice 클릭
			if(index != this.openProjectIndex) {
				// 이전 Notice Height 원상복귀
				if(this.openProjectIndex != -1) this.initHeight();
				if(this.openInfo != -1) this.openInfo = -1;
				// Open Index 설정
				this.openProjectIndex = index;
				// 전체 Height 적용
				let getPath = this.utils.getEventPath(event);
				let parentIndex = getPath.map(
					(item) => {
						return item.className;
					}
				).indexOf("box-project");
				let totalHeight = getPath[parentIndex].children[1].clientHeight + 130;
				totalHeight = (totalHeight <= 190 ? 190 : totalHeight);
				getPath[parentIndex].style.transition = "height 0.3s ease-in-out";
				getPath[parentIndex].style.height = totalHeight + "px";
				getPath[parentIndex].blur();
			}
		},
		initHeight() {
			this.logger.debug(this, "initHeight()");
			let projectLength = this.$refs.projectList.children.length;
			for(let i = 0 ; i < projectLength ; i++)
			{
				this.$refs.projectList.children[i].children[1].style.transition = "";
				this.$refs.projectList.children[i].children[1].style.height = "";
			}
		},
		moreView(event) {
			this.logger.debug(this, "moreView(event)");
			event.target.blur();
			if(this.openPage < this.maxPage) this.openPage++;
		},
		doEdit() {
			this.logger.debug(this, "doEdit()");
			this.isEdit = true;
		},
		dropBalloon(event, index, type) {
			this.logger.debug(this, "dropBalloon(event, index, type)");
			if(type == "info")
			{
				this.openInfoBallon = index - 1;
				this.openDataBallon = -1;
				this.openVersionBallon = -1;
			}
			else if(type == "version"){
				this.openInfoBallon = -1;
				this.openDataBallon = -1;
				this.openVersionBallon = index - 1;
			}
			else
			{
				this.openInfoBallon = -1;
				this.openDataBallon = index - 1;
				this.openVersionBallon = -1;
			}
			this.$nextTick(function () {
				if(type != 'version'){
					let childrenList = event.target.parentElement.children;
					console.log(childrenList);
					let index = (type == "info" ? 1 : 2);
					childrenList[index].style.top = (event.clientY - event.offsetY - 4) + "px";
					childrenList[index].style.left = (event.clientX - event.offsetX - 140) + "px";
					childrenList[index].focus();
				}
				else{
					let childrenList = event.target.parentElement.children;
					childrenList[1].style.top = (event.clientY - event.offsetY - 7) + "px";
					childrenList[1].style.left = (event.clientX - event.offsetX - 69) + "px";
					childrenList[1].focus();
				}
			});
		},
		hideBalloon(event, type) {
			this.logger.debug(this, "hideBalloon(event, type)");
			event.target.style.animation = "hideBalloon 0.3s ease-out both";
			if(type == "info")
			{
				setTimeout(function() {
					this.openInfoBallon = -1;
				}.bind(this), 310);
			}
			else if(type == "version"){
				setTimeout(function() {
					this.openVersionBallon = -1;
				}.bind(this), 310);
			}
			else
			{
				setTimeout(function() {
					this.openDataBallon = -1;
				}.bind(this), 310);
			}
		},
		doDownload(data) {
			this.logger.debug(this, "doDownload(data)");

			const formData = new FormData();
			formData.append("fileNo", data.fileNo);
			formData.append("fileName", data.fileName);
			formData.append("serverFileSaveName", data.serverFileSaveName);

			axios
				.post(this.config.contextRoot + "/common/file/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSiteDownload(data) {
			this.logger.debug(this, "doDownload(data)");

			const formData = new FormData();
			formData.append("siteFileNo", data.fileNo);
			formData.append("fileName", data.fileName);
			formData.append("serverFileSaveName", data.serverFileSaveName);

			axios
				.post(this.config.contextRoot + "/site/file/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		openUploader(refName, projectNo) {
			this.logger.debug(this, "openUploader()");
			if(refName == 'DataUploader')
			{
				this.dataImport.projectNo = projectNo;
			}
			this.$refs[refName].openUploader();
		},
		exportProject(projectNo) {
			this.logger.debug(this, "exportProject(projectNo)");
			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/project/transfer/exportProject.do", {projectNo: projectNo})
				.then((response) => {
					console.log(response);
					this.doDownload(response.data);
					EventBus.$emit("project-loading", false);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		importProject() {
			this.logger.debug(this, "importProject()");
			this.$confirm("Do you want to import the project?","Question","question").then(() => {
				EventBus.$emit("project-loading", true);
				axios
					.post(this.config.contextRoot + "/project/transfer/importProject.do", this.projectImport)
					.then(() => {
						this.$alert("The project has been successfully imported.","Success","success").then(() => {
							location.reload();
						});
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
						location.reload();
					});
			});
		},
		importData(isAdd) {
			this.logger.debug(this, "importData()");
			this.isMethod = false;
			this.$confirm("Do you want to import project data?","Question","question").then(() => {
				EventBus.$emit("project-loading", true);
				this.dataImport.isAdd = isAdd;
				axios
					.post(this.config.contextRoot + "/project/transfer/importData.do", this.dataImport)
					.then((response) => {
							let result = response.data.result;
							if(result == "success")
							{
								this.$alert("The data has been successfully imported.","Success","success").then(() => {
									location.reload();
								});
							}
							else
							{
								this.$alert("They are different projects or the versions do not match.","Warning","warning").then(() => {
									location.reload();
								});
							}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
						location.reload();
					});
			});
		},
		exportDataOpen(){
			this.logger.debug(this, "exportDataOpen()");
			this.isDataExportOpen = true;
		},
		doProject(projectNo, ctcProjectNo) {
			this.logger.debug(this, "doProject(projectNo)");
			//Project 내의 Access 허용 여부 Check
			axios
				.post(this.config.contextRoot + "/access/selectProjectAccessUrl.do", {projectNo : projectNo, userId : sessionStorage.getItem("userId")})
				.then((response) => {
					console.log(response.data.url);
					if(response.data.url == 'false'){
						this.$alert("You are not authorized.","Warning","warning");
					}
					else{
						// this.$alert(window.location.href ,"Warning","warning");
						let url = this.config.contextRoot + "/project/" + projectNo + this.getProjectName(response.data.url);
						if (this.$route.path !== url)
						{
							EventBus.$emit("project-loading", true);
							// this.$router.push(url);
							location.href = window.location.href + "/" + projectNo + this.getProjectName(response.data.url);
							this.$set(this.$route.params, "ctcProjectNo", ctcProjectNo);
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		getProjectName(obj){
			let url = "";

			if(obj == 'PAGE'){
				url = "/page";
			}
			else if(obj == 'DICTIONARY'){
				url = "/projectDictionary"
			}
			else if(obj == 'VISIT'){
				url = "/visit"
			}
			else if(obj == 'SCHEDULE'){
				url = "/schedule"
			}
			else if(obj == 'VALIDATION'){
				url = "/validation"
			}
			else if(obj == 'CALCULATION'){
				url = "/calculation"
			}
			else if(obj == 'SCREENING'){
				url = "/screening"
			}
			else if(obj == 'TEST SCREENING'){
				url = "/testScreening"
			}
			else if(obj == 'QUERY RESOLUTION'){
				url = "/queryResolution"
			}
			else if(obj == 'ROLE'){
				url = "/role"
			}
			else if(obj == 'USER'){
				url = "/user"
			}
			else if(obj == 'SITE'){
				url = "/site"
			}

			return url;
		},
        doDataCheck(projectNo) {
			this.logger.debug(this, "doDataCheck(projectNo)");
			axios
				.post(this.config.contextRoot + "/excel/selectProcExist.do", {projectNo : projectNo})
				.then((response) => {
					console.log(response);
					if(response.data.procCount > 0)
					{
						this.doDataCreate();
					}else{
						this.$alert("There's No Survey to Download in this Project.","Warning","warning");
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doDataCreate(){
			this.logger.debug(this, "doDataCreate()");
			this.isDataCreate = true;
		},
        doTestDataCheck(projectNo) {
			this.logger.debug(this, "doTestDataCheck(projectNo)");
			axios
				.post(this.config.contextRoot + "/excel/selectProcExist.do", {projectNo : projectNo})
				.then((response) => {
					console.log(response);
					if(response.data.procCount > 0)
					{
						this.doTestDataCreate();
					}else{
						this.$alert("There's No Survey to Download in this Project.","Warning","warning");
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doTestDataCreate(){
			this.logger.debug(this, "doTestDataCreate()");
			this.isTestDataCreate = true;
		},
		copyProject(projectNo)
		{
			this.logger.debug(this, "copyProject()");
			this.copyProjectNo =  projectNo;
			this.isCopy = true;
		},
		migrationOpen(projectNo)
		{
			this.logger.debug(this, "migrationOpen()");
			this.copyProjectNo =  projectNo;
			this.isMigration = true;
		},
		// createVersion(projectNo){
		// 	this.logger.debug(this, "createVersion(projectNo)");
		// 	console.log(projectNo);
		// 	this.$confirm("Do you want to create a new version?", "Question", "question").then(() => {
		// 					EventBus.$emit("field-loading", true);
		// 					this.doCopy(projectNo);
		// 				});
		// },
		versionChange(project, version, event){
			let versionList = JSON.parse(JSON.stringify(project.projectVersionList));
			let projectCopy;
			let pindex;
			this.projectList.forEach((item, index) => {
				if(item.projectNo == project.projectNo){
					pindex = index;
				}
			})
			this.projectList[pindex].projectVersionList.forEach((item) => {
				if(item.projectVersion == version){
					item.projectVersionList = versionList;
					projectCopy = JSON.parse(JSON.stringify(item));
				}
			})

			this.$set(this.projectList, pindex, projectCopy);
			
			event.target.parentElement.style.animation = "hideBalloon 0.3s ease-out both";
			setTimeout(function() {
				this.openVersionBallon = -1;
			}.bind(this), 310);
		},
		// doCopy(projectNo) {
        //     axios
		// 		.post(this.config.contextRoot + "/project/projectVersion.do", {projectNo: projectNo})
		// 		.then((response) => {
		// 			console.log(response);

		// 			this.$alert("The new version has been successfully created.", "Info", "info").then(() => {
		// 				location.reload();
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		})
		// },
		selectValue(value, target, bools) {
			
			this.$set(this.item, target, value);
			if(target == 'searchStateCd')
			{
				if(bools == true)
				{
					this.isData = false;
				}
				else
				{
					this.isData = true;
					if(value=="0"){
						this.selectProjectList();
					}
					else{
						this.selectStateList();
					}
				}
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
			if(obj.privilegeName == 'CREATE' && obj.isAccess == true){
				this.isAccessCreate = true;
			}
			if(obj.privilegeName == 'IMPORT' && obj.isAccess == true){
				this.isAccessImport = true;
			}
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		cancelData() {
			this.isMethod = false;
			this.dataImport = {};
		},
		privilegeUpdate(){
			axios
				.post(this.config.contextRoot + "/project/privilegeUpdate.do", this.item)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}	
};
</script>

<style scoped>
.protocol {
	margin-right: 5px;
	color: #999;
}
.project-list-select{
	position: absolute;
	right: 0;
	top: 10px;
	width: 154px;
	height: 32px;
    padding: 0 15px;
    border: 1px solid #ccc;
    font-size: 10pt;
    color: #999;
}
.prompt {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000066;
	z-index: 1060;
}

.prompt > .wrap {
	display: block;
	width: 512px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 2px 2px 5px 0 #666;
}

.prompt > .wrap > .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 15px 25px;
	border-radius: 10px 10px 0 0;
	background-color: #eee;
	font-weight: 600;
	font-size: 13pt;
	color: #2bade0;
}

.prompt > .wrap > .top > .close {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 5px;
	background: #fff;
	font-family: serif;
	font-weight: 600;
	color: #999;
}

.prompt > .wrap > .top > .close:hover {
	border: 1px solid #666;
	color: #666;
}

.prompt > .wrap > .bottom {
	position: relative;
	padding: 25px;
	border-radius: 0 0 10px 10px;
	background-color: #fff;
	font-weight: 600;
	font-size: 11pt;
	color: #333;
	text-align: center;
}

.prompt > .wrap > .bottom > button {
	width: 120px;
	height: 36px;
	margin: 30px 10px 0 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #979ea5;
}

.prompt > .wrap > .bottom > button:hover {
	border: 1px solid #2bade0;
	background: #2bade0;
	color: #fff;
}
</style>