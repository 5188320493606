<template>
	<div>
		<p class="txt-list-title">User List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the user ID to search."
				v-model="searchText"
			/>
		</div>
		<table class="tb-list-vertical no-cursor">
			<colgroup>
				<col width="5%" />
				<col width="15%" />
				<col width="10%" />
				<col width="20%" />
				<col width="15%" />
				<col width="10%" />
				<col width="10%" />
				<col width="15%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						User ID
					</th>
					<th>
						User Name
					</th>
					<th>
						Email
					</th>
					<th>
						Phone
					</th>
					<th>
						Site
					</th>
					<th>
						Role Name
					</th>
					<th>
						Status
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(user, index) in userList"
					:key="index"
					tabindex="0"
				>
					<td>
						{{ user.rowNumber }}
					</td>
					<td class="underline">
						{{ user.userId }}
					</td>
					<td class="underline">
						{{ user.userName }}
					</td>
					<td>
						{{ user.emailAddress }}
					</td>
					<td>
						{{ user.mobileTelno }}
					</td>
					<td>
						{{ user.siteCode }}
					</td>
					<td>
						<select
							:disabled="workAccessCheck('ROLE CHANGE') == false || user.userId == user.projectCreater"
							:name="'role'+user.userNo"
							class="select-crud"
							v-model="user.roleNo"
							:style="(user.roleNo == '' ? {color: '#ccc'} : {})"
							@change="insertUserRole(user)"
						>
							<option value="" disabled hidden>
								Select Role
							</option>
							<template
								v-for="(role, roleIndex) in roleList"
								>
								<option
									v-if="role.selectedRoleNo == 1"
									:key="roleIndex"
									:value="role.roleNo"
									:title="role.roleName + '-' + role.roleCategory"
								>
									{{ role.roleName }} - {{ role.roleCategory }}
								</option>
							</template>
						</select>
					</td>
					<td>
						<label class="custom-check">
							<input
								:disabled="workAccessCheck('PROJECT PERMISSION') == false || user.userId == user.projectCreater"
								type="checkbox"
								:name="'confirm' + index"
								v-model="userList[index].approvalStatus"
								@click="userChangeApproval(user)"
							/>
							<span></span>
						</label>
					</td>
				</tr>
				<tr v-if="userList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered user.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "UserList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "USER",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectUserList();
		this.selectRoleList();
		this.getAccessList('USER');
	},
	data() {
		return {
			item: {},
			userList: [],
			roleList: [],
			pagination: {},
			searchText: "",
			accessList: [],
		};
	},
	watch: {
		searchText: function(val) {
			this.item.userId = val;
			this.item.pageNum = 1;
			this.selectUserList();
		}
	},
	methods: {
		selectUserList() {
			this.item.projectNo = this.$route.params.projectNo;
			this.logger.debug(this, "selectUserList()");
			axios
				.post(this.config.contextRoot + "/user/selectUserAuthList.do", this.item)
				.then((response) => {
					console.log(response);
					this.userList = response.data.userList;
					this.pagination = response.data.pagination;
					for(let i = 0 ; i < this.userList.length ; i++) {
						if(this.userList[i].projectUserNo != null && this.userList[i].projectUserNo != ''){
							this.userList[i].approvalStatus = true;
						}
						else{
							this.userList[i].approvalStatus = false;
						}
						// this.userList[i].approvalStatus = (this.userList[i].approvalStatus == "1" ? true : false);
					}
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectRoleList() {
			this.logger.debug(this, "selectRoleList()");

			let param = {
				projectNo: this.$route.params.projectNo,
			};

			axios
				.post(this.config.contextRoot + "/role/selectRoleList.do", param)
				.then((response) => {
					console.log(response);
					this.roleList = response.data.roleList;

					for (let role in this.roleList) {
						if (this.roleList[role].selectedRoleNo == 1) {
							this.checkedList.push(this.roleList[role].roleNo);
							this.projectCheckedRoleNoList.push(this.roleList[role].roleNo);
						}
					}

					this.$nextTick(function () {
						setTimeout(
							function () {
								EventBus.$emit("project-loading", false);
							}.bind(this),
							500
						);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage(page)");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectUserList();
		},
		userChangeApproval(user) {
			this.logger.debug(this, "userChangeApproval(index)");
			user.projectNo = this.$route.params.projectNo;
			if(user.approvalStatus == true)
			{
				user.approvalStatus = 0;
			}
			else
			{
				user.approvalStatus = 1;
			}

			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/user/UpdateUserProjectAuth.do", user)
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						location.reload();
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		insertUserRole(user){
			if(user.approvalStatus == '0')
			{
				this.$alert("is not registered user in project", "Error", "error");
				user.roleNo = "";
			}
			else
			{
				user.lastModifyUserId = this.userInfo.userId;
				this.logger.debug(this, "insertUserRole()");
				EventBus.$emit("project-loading", true);
				axios
					.post(this.config.contextRoot + "/user/insertUserRole.do", user)
					.then((response) => {
						console.log(response);
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
							// location.reload();
						}, 300);
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>