<template>
	<div ref="freezingScreening" class="dialog-container">
		<div class="dialog" style="max-height: 720px; transform: translate(0, -360px);">
			
			<button type="button" class="btn-cancel fright" title="Close" @click="doCancel()">
				Close
			</button>
		</div>
		
	</div>
	
</template>

<script>
import EventBus from "@/script/EventBus.js";

export default {
	name: "freezingScreening",

	
	created() {
		this.logger.debug(this, "created()");
		
		this.$nextTick(function () {
			setTimeout(function() {
				// 로딩바 닫기 요청
				EventBus.$emit("project-loading", false);
			}.bind(this), 500);
		});
		
        EventBus.$emit("dictionary-loading", false);
	},
	components: {
	},
	props: {
		paramPageNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {}
		};
	},
	watch: {		
	},
	methods: {		
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.doClose();
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.freezingScreening.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.freezingScreening.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isFreezingScreening");
			}.bind(this), 410);
		}
	}
};
</script>