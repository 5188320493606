<template>
	<div> 
		<p class="txt-list-title">Emr Column List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the column name to search."
				@input="searchColumn($event.target.value)"
			/>
			<div class="btn-wrap"> 
				<button
					type="button"
					class="btn-new"
					title="New Auth"
					@click="doCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New Column
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Upload"
					@click="openUploader('EmrColumnUploader')"
				>
					Upload
				</button>
				<button
					type="button"
					class="btn-grey"
					title="Download Template"
					@click="doDownloadTemplate()"
				>
					Download Template
				</button>
			</div>
		</div>
		<table class="tb-list-vertical tb-setting-list">
			<colgroup>
				<col width="10%" />
				<col width="15%" />
				<col width="20%" />
				<col width="15%" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>No</th>
					<th>Column</th>
					<th>Korean</th>
					<th>English</th>
					<th>Register</th>
					<th>Regist Date</th>
					<th>UseYn</th>
					<th>Delete</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(col, index) in columnList"
					:key="index"
					tabindex="0"
					@click.stop="doEdit(col)"
				>
					<td>
						{{col.rowNumber}}
					</td>
					<td>
						{{ col.columnName }}
					</td>
					<td class="ellipsis">
						{{ col.columnKoreanName }}
					</td>
					<td class="ellipsis">
						{{ col.columnEnglishName }}
					</td>
					<td>
						{{ col.firstRegistUserId }}
					</td>
					<td>
						{{ col.firstRegistDate.substring(0, 10)}}
					</td>
					<td>
						{{ col.useYn }}
					</td>
					<td>
						<button
							type="button"
							class="btn-remove"
							title="Remove Auth"
							@click.stop="doDelete(col)"
						></button>
					</td>
				</tr>
				<tr v-if="columnList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered auth, Please register the auth.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
					:data="pagination"
					:show-disabled="true"
					:limit="4"
					@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<EmrColumnManageCreate v-if="isCreate" />
		<EmrColumnManageEdit v-if="isEdit" :columnNo="columnNo" />
		<common-file
			ref="EmrColumnUploader"
			v-model="uploadColumn.fileList"
			fileMaxSize="500"
			fileExtension="xlsx"
			style="display: none;"
		/>
		<div v-if="isMethod" class="prompt">
			<div class="wrap">
				<div class="top">
					Select Import Method
					<button type="button" class="close" @click="cancelData()">
						×
					</button>
				</div>
				<div class="bottom">
					<p>Please choose  the import method</p>
					<button type="button" class="white" @click="importColumn(false)">
						Override
					</button>
					<button type="button" class="blue" @click="importColumn(true)">
						Add
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import EmrColumnManageCreate from "@/components/setting/emrColumnManage/EmrColumnManageCreate.vue";
import EmrColumnManageEdit from "@/components/setting/emrColumnManage/EmrColumnManageEdit.vue";
import CommonFile from "@/components/common/CommonFile";

export default {
	name: "EmrColumnManageList",
	components: {
		EmrColumnManageCreate,
		EmrColumnManageEdit,
		CommonFile
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "EMR COLUMN MANAGE ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectColumnList();
	},
	data() {
		return {
			item: {},
			columnList: [],
			pagination: {},
			searchText: "",
			isCreate: false,
			isEdit: false,
			columnNo: "",
			uploadColumn: {},
			isMethod: false
		};
	},
	watch: {
		uploadColumn: function() {
			if(this.uploadColumn.fileList.length == 1)
			{
				var fileExtension = this.uploadColumn.fileList[0].fileExtensionName;
				if(fileExtension == "xlsx" || fileExtension == "XLSX")
				{
					// this.isMethod = true;
					this.importColumn(true);
				}
				else
				{
					this.$alert("Please upload the file in Excel format.","Warning","warning").then(() => {
						location.reload();
					});
				}
			}
		}
	},
	methods: {
		selectColumnList() {
			this.logger.debug(this, "selectColumnList()");
			axios
				.post(this.config.contextRoot + "/emr/selectColumnList.do", this.item)
				.then((response) => {
					console.log(response);
					this.columnList = response.data.emrColumnList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		searchColumn(value) {
			this.$set(this.item, "searchText", value);
			this.$set(this.item, "pageNum", 1);
			this.selectColumnList();
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectColumnList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isCreate = true;
		},
		doEdit(col) {
			this.logger.debug(this, "doEdit(agency)");
			this.columnNo = col.columnNo;
			this.isEdit = true;
		},
		doDelete(col) {
			this.logger.debug(this, "doDelete(agency)");
			this.$confirm("Are you sure you want to delete " + col.columnName +"?", "Question", "question").then(() => {
				var param = {columnNo: col.columnNo};
				
				axios.post(this.config.contextRoot + "/emr/deleteColumn.do", param)
				.then((response) => {
					console.log(response);
					EventBus.$emit("setting-loading", true);
					location.reload();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});

				// axios.post(this.config.contextRoot + "/auth/delete.do", param)
				// .then((response) => {
				// 	console.log(response);
				// 	if(response.data.totCnt > 0)
				// 	{
				// 		this.$alert("The user with the auth exists and cannot be deleted.", "Warn", "warning");
				// 	}
				// 	else
				// 	{
				// 		EventBus.$emit("setting-loading", true);
				// 		location.reload();
				// 	}
				// })
				// .catch((error) => {
				// 	this.exceptionHandler(this, error);
				// });
			});
		},
		openUploader(refName) {
			this.logger.debug(this, "openUploader()");
			this.$refs[refName].openUploader();
		},
		cancelData() {
			this.isMethod = false;
			this.uploadColumn = {};
		},
		importColumn(isAdd) {
			this.logger.debug(this, "importColumn()");
			this.isMethod = false;
			this.$confirm("Do you want to import emr column?","Question","question").then(() => {
				axios.post(this.config.contextRoot + "/emr/excelColumnUpload.do", {fileList: this.uploadColumn.fileList, isAdd: isAdd})
				.then((response) => {
					if(response.data.result == "Duplication"){
						this.$alert("This is a duplicate English Name..(" +response.data.dupList+ ")" ,"Warn","warning");
					}
					else if(response.data.result == "Success"){
						this.$alert("The emr column has been successfully imported.","Success","success");
						location.reload();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
					location.reload();
				});
			});
		},
		doDownloadTemplate() {
			this.logger.debug(this, "doDownloadTemplate()");
			this.$confirm("Would you like to download a template?", "Question", "question").then(() => {
				axios({
					method: 'POST',
					url: '../emr/excelTemplateDownload.do',
					responseType: 'blob',
					headers: {
						"Content-Type": "application/json"
					}
				}).then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'columnTemplate.xlsx');
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
					location.reload();
				});
			});
		}
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}
.btn-wrap {
	display: block;
	position: relative;
	float: right;
}
.prompt {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000066;
	z-index: 1060;
}

.prompt > .wrap {
	display: block;
	width: 512px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 2px 2px 5px 0 #666;
}

.prompt > .wrap > .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 15px 25px;
	border-radius: 10px 10px 0 0;
	background-color: #eee;
	font-weight: 600;
	font-size: 13pt;
	color: #2bade0;
}

.prompt > .wrap > .top > .close {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 5px;
	background: #fff;
	font-family: serif;
	font-weight: 600;
	color: #999;
}

.prompt > .wrap > .top > .close:hover {
	border: 1px solid #666;
	color: #666;
}

.prompt > .wrap > .bottom {
	position: relative;
	padding: 25px;
	border-radius: 0 0 10px 10px;
	background-color: #fff;
	font-weight: 600;
	font-size: 11pt;
	color: #333;
	text-align: center;
}

.prompt > .wrap > .bottom > button {
	width: 120px;
	height: 36px;
	margin: 30px 10px 0 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #979ea5;
}

.prompt > .wrap > .bottom > button:hover {
	border: 1px solid #2bade0;
	background: #2bade0;
	color: #fff;
}
</style>