<template>
	<div ref="userEdit" class="full-container">
		<div class="full-top-wrap">User Edit</div>
		<div class="full-center-wrap">
			<form @submit.prevent="doValidation()">
				<p class="txt-crud-title">User Form</p>
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>User Name.</th>
							<td>{{ projectUser.userName }}</td>
							<th>User ID.</th>
							<td>{{ projectUser.userId }}</td>
						</tr>
						<tr>
							<th>Email.</th>
							<td>{{ projectUser.emailAddress }}</td>
							<th>Mobile.</th>
							<td>{{ projectUser.mobileTelno }}</td>
						</tr>
						<tr>
							<th>Role.</th>
							<td colspan="3">
								<div
									v-for="projectUserRoleItem in projectUserRoleList"
									:key="projectUserRoleItem.projectRoleNo"
								>
									<label class="checkbox-crud">
										<input
											type="checkbox"
											name="roleNo"
											v-model="checkedRoleList"
											:value="projectUserRoleItem.roleNo"
											@change="testFunc"
										/><span class="checkbox"></span>
										{{ projectUserRoleItem.roleName }}
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div class="full-bottom-wrap">
			<button
				type="button"
				class="btn-save fright"
				title="Modify"
				@click.stop="doValidation()"
				v-show="item.unscheduleYn == 'N' ? false : true"
			>
				Modify
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">Cancel</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "UserEdit",
	created() {
		this.logger.debug(this, "created()");
		this.selectUser();
	},
	data() {
		return {
			item: {},
			projectUser: {},
			projectUserRoleList: [],
			checkedRoleList: [],
		};
	},
	methods: {
		testFunc() {
			console.log(this.checkedRoleList);
		},
		selectUser() {
			this.logger.debug(this, "selectUser()");
			this.item.projectNo = this.$route.params.projectNo;
			this.item.projectUserNo = this.$route.params.projectUserNo;

			axios
				.post(this.config.contextRoot + "/user/selectProjectUser.do", this.item)
				.then((response) => {
					this.projectUser = response.data.projectUser;
					this.projectUserRoleList = response.data.projectUserRoleList;

					this.projectUserRoleList.forEach((obj) => {
						this.checkedRoleList.push(obj.roleChecked);
					});

					console.log(this.checkedRoleList);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			this.$nextTick(function () {
				setTimeout(
					function () {
						EventBus.$emit("project-loading", false);
					}.bind(this),
					500
				);
			});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result) {
						this.$confirm("Do you want to Edit the current User?", "Question", "question").then(() => {
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");

			this.item.checkedRoleList = this.checkedRoleList;

			console.log(this.item);

			axios
				.post(this.config.contextRoot + "/role/updateProjectUserRole.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						console.log(response);
						EventBus.$emit("project-loading", true);
						var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/user";
						if (this.$route.path !== url) {
							this.$router.push(url);
						}
					} else {
						this.$alert(response, "Error", "error");
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the user modification?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.userEdit.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(
				function () {
					let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/user";
					if (this.$route.path !== url) {
						EventBus.$emit("project-loading", true);
						this.$router.push(url);
					}
				}.bind(this),
				410
			);
		},
	},
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>
