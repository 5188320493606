import { render, staticRenderFns } from "./DefaultValue.vue?vue&type=template&id=526d04a2&scoped=true&"
import script from "./DefaultValue.vue?vue&type=script&lang=js&"
export * from "./DefaultValue.vue?vue&type=script&lang=js&"
import style0 from "./DefaultValue.vue?vue&type=style&index=0&id=526d04a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526d04a2",
  null
  
)

export default component.exports