import Vue from "vue"
import App from "@/App.vue"
import router from "@/router.js"
import Auth from "@/script/Auth.js"
import VueSimpleAlert from "vue-simple-alert";
import AccessAuth from "@/plugins/access.js"
import htmlToPdf from '@/plugins/htmlToPdf';

Vue.config.productionTip = false;

Vue.prototype.global = {}

// 전역필터
Vue.filter("comma", val => {
    if (!val) {
        return 0;
    }
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})
Vue.filter("dt", val => {
    if (val.length != 8) {
        return;
    }
    return val.slice(0, 4) + '-' + val.slice(4, 6) + '-' + val.slice(6, 8);
})
Vue.filter("dateTime", val => {
    if (!val.indexOf(".0") > 0) {
        return;
    }
    return val.slice(0, val.indexOf(".0"));
})
Vue.filter("timeless", val => {
    return val.slice(0, 10);
})

Vue.component("pagination", require("laravel-vue-pagination"));
Vue.use(VueSimpleAlert);
Vue.use(AccessAuth);
Vue.use(htmlToPdf);

new Vue({
    router,
    Auth,
    render: h => h(App),
}).$mount("#app");

