<template>
	<div>
		<p class="txt-list-title">Calculation List</p>
		
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the calculate name to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button
				v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')"
				type="button"
				class="btn-new"
				title="New Calculation"
				@click="doCreate()"
			>
				<img src="@/assets/images/common/btn_new.png" alt="" />
				New Calculation
			</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="35%" />
				<col width="35%" />
				<col width="15%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						Calculate Name
					</th>
					<th>
						Calculate Type
					</th>
					<th>
						Use
					</th>
					<th>
						Delete
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(calculation, index) in calculationList"
					:key="index"
					tabindex="0"
					@click.stop="doEdit(calculation)"
				>
					<td>
						{{ calculation.rowNumber }}
					</td>
					<td class="ellipsis underline" :title="calculation.calculateName">
						{{ calculation.calculateName }}
					</td>
					<td class="ellipsis" :title="calculation.calculateType">
						{{ calculation.calculateType }}
					</td>
					<td>
						{{ calculation.useYn }}
					</td>
					<td>
						<button
							v-if="workAccessCheck('DELETE') && !(projectStateCd == '4' || projectStateCd == '3')"
							type="button"
							class="btn-remove"
							title="Remove Calculation"
							@click.stop="doDelete(calculation)"
						></button>
					</td>
				</tr>
				<tr v-if="calculationList.length == 0">
					<td colspan="5" class="no-list">
						There is no registered calculation, Please register the calculation.
					</td>
				</tr>
			</tbody>
		</table>
		<!--
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		-->
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "CalculationList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "CALCULATION",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.item.projectNo = this.$route.params.projectNo;
		
		this.selectCalculationList();
		this.getAccessList('CALCULATION');
		this.getProjectStateCd();
	},
	data() {
		return {
			item: {},
			calculationList: [],
			pagination: {},
			searchText: "",
			isTyping: false,
			accessList: [],
			projectStateCd: ""
		};
	},
	watch: {
		searchText: function(val) {
			this.item.searchText = val;
			this.item.pageNo = 1;
		}
	},
	methods: {
		selectCalculationList() {
			this.logger.debug(this, "selectCalculationList()");
			axios
				.post(this.config.contextRoot + "/calculate/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.calculationList = response.data.calculationList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNo = page;
			this.selectValidationList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/calculation/create"
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(calculation) {
			this.logger.debug(this, "doEdit(calculation)");
			let url = this.config.contextRoot + "/project/" + this.item.projectNo + "/calculation/edit/" + calculation.calculateNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doDelete(calculation) {
			this.logger.debug(this, "doDelete(calculation)");
			if (!this.utils.isEmpty(this.item.projectNo) && !this.utils.isEmpty(calculation.calculateNo))
			{
				this.$confirm("Are you sure you want to delete the calculation?", "Question", "question").then(() => {
					EventBus.$emit("project-loading", true);
					var param = {projectNo: this.item.projectNo, calculateNo: calculation.calculateNo}
					axios
						.post(this.config.contextRoot + "/calculate/delete.do", param)
						.then((response) => {
							console.log(response);
							location.reload();
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});
				});
			}
			else this.$alert("An error has occurred, Project Number or Validation Number is empty!", "Error", "error");
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.selectValidationList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>
<style scoped>
.tb-list-vertical {
	table-layout: fixed;
}
</style>