import { render, staticRenderFns } from "./preScreeningCreate.vue?vue&type=template&id=0fc38d60&scoped=true&"
import script from "./preScreeningCreate.vue?vue&type=script&lang=js&"
export * from "./preScreeningCreate.vue?vue&type=script&lang=js&"
import style0 from "./preScreeningCreate.vue?vue&type=style&index=0&id=0fc38d60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc38d60",
  null
  
)

export default component.exports