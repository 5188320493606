<template>
	<div>
		<div v-if="visitList.length > 0 && pageList.length > 0 " class="scroll-wrap">
			<div class="scroll-thead-wrap" :style="headRowSet()">
				<table class="schedule-table full-schedule">
					<thead>
						<tr ref="headRow">
							<th rowspan="3" :style="headThSet()">
								<!-- <label>
									<input type="checkbox" name="checkAll" @click="checkAll($event)"/>
									<span class="checkbox"></span>
								</label> -->
								<!-- <div class="pdf-down" @click="pdfDown()">
								</div> -->
								<button v-if="workAccessCheck('PDF DOWNLOAD')" type="button" class="pdf-btn" @click="pdfDown(1)">Blank</button><br>
								<button v-if="workAccessCheck('PDF DOWNLOAD')" type="button" class="pdf-btn" style="margin-top: 5px;" @click="pdfDown(2)">Annotated</button>
							</th>
							<th :colspan="nvCnt"  rowspan="2">
								NV
							</th>
							<th v-if="uvCnt > 0" :colspan="uvCnt" rowspan="2">
								UV
							</th>
						</tr>
						<tr>
							<th v-for="(item, index) in siteList" :key="index">
								{{ item.siteName }}
							</th>
						</tr>
						<tr>
							<th v-for="(item, index) in visitList" :key="index" :style="headTh2Set()">
								{{ item.visitName }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(pageItem, pageIndex) in pageList" :key="pageIndex">
							<th>
								{{ pageItem.pageLabel }}
								<!-- <label v-if="visitUsedCheck(pageItem.pageNo)">
									<input
										type="checkbox"
										name="pageNo"
										v-model="checkedList"
										:value="pageItem.pageNo"
										:checked="checkedList.indexOf(pageItem.pageNo) >= 0 ? true : false"
										@click="check(pageItem.pageNo)"
									/>
									<span class="checkbox"></span>
								</label> -->
							</th>
							<td v-for="(visitItem, visitIndex) in visitList" :key="visitIndex">
								<button
									v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) >= 0 && visitItem.useYn == 'Y'"
									class="ck-blue-btn"
									@click="selectPage(pageItem, visitItem)"
								>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="scroll-tbody-wrap scroll">
				<table class="schedule-table full-schedule">
					<thead>
						<tr>
							<th rowspan="3">
								<button v-if="workAccessCheck('PDF DOWNLOAD')" type="button" class="pdf-btn">Blank</button><br>
								<button v-if="workAccessCheck('PDF DOWNLOAD')" type="button" class="pdf-btn">Annotated</button>
							</th>
							<th :colspan="nvCnt"  rowspan="2">
								NV
							</th>
							<th v-if="uvCnt > 0" :colspan="uvCnt" rowspan="2"> 
								UV
							</th>
						</tr>
						<tr>
							<th v-for="(item, index) in siteList" :key="index">
								{{ item.siteName }}
							</th>
						</tr>
						<tr>
							<th v-for="(item, index) in visitList" :key="index">
								{{ item.visitName }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(pageItem, pageIndex) in pageList" :key="pageIndex">
							<th>
								<!-- <label	v-if="visitUsedCheck(pageItem.pageNo)">
									<input
										type="checkbox"
										name="pageNo"
										v-model="checkedList"
										:value="pageItem.pageNo"
										:checked="checkedList.indexOf(pageItem.pageNo) >= 0 ? true : false"
										@click="check(pageItem.pageNo)"
									/>
									<span class="checkbox"></span>
								</label> -->
								{{ pageItem.pageLabel }}
							</th>
							<td v-for="(visitItem, visitIndex) in visitList" :key="visitIndex">
								<button
									v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) >= 0 && visitItem.useYn == 'Y'"
									class="ck-blue-btn"
									@click="selectPage(pageItem, visitItem)"
								>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<table class="schedule-table" v-if="visitList.length == 0 || pageList.length == 0 "> 
			<thead>
				<tr>
					<th>No Schedule</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="no-list" style="border-bottom: 1px solid #ccc;">
						There is no registered visit, Please register the visit.
					</td>
				</tr>
			</tbody>
		</table>
		<ScheduleDetail v-if="isScheduleDetail" :param="paramSigleCreate"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import ScheduleDetail from "@/components/project/schedule/ScheduleDetail.vue";

export default {
	name: "ScheduleList",
	components: {
		ScheduleDetail
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "SCHEDULE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;

		this.selectDataVisitList();
		this.getAccessList('SCHEDULE');
	},
	updated(){
		this.headRow();
	},
	data() {
		return {
			items: {},
			visitList: [],
			pageList: [],
			siteList: [],
			nvCnt: 0,
			uvCnt: 0,
			isScheduleDetail: false,
			selectPageNo: "",
			checkedList: [],
			height: "",
			accessList: [],
		};
	},
	methods: {
		selectDataVisitList() {
			this.logger.debug(this, "selectDataVisitList()");
			axios
				.post(this.config.contextRoot + "/schedule/dataVisitList.do", this.items)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.dataVisitList;

					if(this.visitList.length > 0)
					{
						this.nvCnt = this.visitList[0].nvCnt;
						this.uvCnt = this.visitList[0].uvCnt;
					}
					
					for(let index = 0; index < this.visitList.length; index++)
					{
						if(this.visitList[index].unscheduleYn == 'Y')
						{
							if(this.visitList[index].siteRank == 1)
							{
								let site = {
									siteName: this.visitList[index].siteName,
									siteCnt: this.visitList[index].siteCnt
								}
								this.siteList.push(site);
							}
						}
					}

					this.selectPageList();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/schedule/selectPageList.do", this.items)
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// doDetail(pageIndex) {
		// 	this.logger.debug(this, "doDetail()");
		// 	this.selectPageNo = this.pageList[pageIndex].pageNo;

		// 	this.isScheduleDetail = true;
		// },
		selectPage(pageItem, visitItem) {
			this.logger.debug(this, "selectPage()");
			this.paramSigleCreate = {
				pageNo: pageItem.pageNo,
				visitNo: visitItem.visitNo,
				pdfYn: 'N',
			};
			this.isScheduleDetail = true;
		},
		check(pageNo) {
			this.logger.debug(this, "check(pageNo)");

			let index = this.checkedList.indexOf(pageNo);
			if (index >= 0) {
				this.checkedList.splice(index, 1);
			} else {
				this.checkedList.push(pageNo);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if (event.target.checked) {
				this.checkedList = [];
				for(let i = 0; i < this.pageList.length; i++){
					if(this.visitUsedCheck(this.pageList[i].pageNo)){
						this.checkedList.push(this.pageList[i].pageNo);
					}
				}
			} else {
				this.checkedList = [];
			}
		},
		visitUsedCheck(pageNo){
			let result = false;
			this.visitList.forEach(item => {
				item.visitPageList.forEach(obj => {
					if(obj == pageNo){
						result = true;
					}
				});
			});
			
			return result;
		},
		pdfDown(type){
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "pdfDown()");

			this.items.pdfType = type;

			axios
				.post(this.config.contextRoot + "/pdf/pdfDown.do", this.items)
				.then((response) => {
					console.log(response);
					this.doDownload(response.data);
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
					}.bind(this), 1000);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doDownload(data) {
			this.logger.debug(this, "doDownload(data)");
			const formData = new FormData();
			formData.append("fileName", data.fileName);

			axios
				.post(this.config.contextRoot + "/pdf/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		headRow(){
			this.height = this.$refs.headRow.scrollHeight;
		},
		headRowSet(){
			if(this.height > 90){
				var height = this.height + 2;
				return "height : " + height + "px";
			}
		},
		headThSet(){
			return "height : " + this.height + "px";
		},
		headTh2Set(){
			return "height : " + this.height-40 + "px";
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		// pdfDown(){
		// 	if(this.checkedList.length > 0){
		// 		EventBus.$emit("project-loading", true);
		// 		let settime = 0;
	
		// 		for(let i = 0; i < this.checkedList.length; i++){
		// 			let pageNo = this.checkedList[i];
		// 			let pageIndex = this.pageList.map((item) => {return item.pageNo}).indexOf(pageNo);
		// 			let pageName = this.pageList[pageIndex].pageName;
	
		// 			// 각 페이지별 첫번째 visitNo 추출
		// 			let visitNo = "";
		// 			this.visitList.forEach(item => {
		// 				item.visitPageList.forEach(obj => {
		// 					if(obj == pageNo){
		// 						visitNo = item.visitNo;
		// 					}
		// 				});
		// 			});
	
		// 			settime += 1400;
		// 			setTimeout(async() =>{
		// 				this.paramSigleCreate = {
		// 					pageNo: pageNo,
		// 					pageName: pageName,
		// 					visitNo: visitNo,
		// 					pdfYn: 'Y',
		// 				};
		// 				if(i == this.checkedList.length-1){
		// 					this.paramSigleCreate = {
		// 						pageNo: pageNo,
		// 						pageName: pageName,
		// 						visitNo: visitNo,
		// 						pdfYn: 'Y',
		// 						pdfEnd : 'Y'
		// 					};	
		// 				}
		// 				this.isScheduleDetail = true;
		// 			}, settime);
		// 		}
		// 	}
		// 	else{
		// 		alert("no select!");
		// 	}
		// }
	}
};
</script>

<style scoped>
.full-schedule>thead>tr:nth-child(1) th {
	height: 40px;
}

.full-schedule>thead>tr:nth-child(2) th {
	height: 45px;
	border-top: none;
}

.full-schedule>thead>tr:nth-child(3) th {
	height: 45px;
	border-top: none;
}

.scroll-wrap {
position: absolute;
	top: 42px;
	left: 42px;
	width: calc(100% - 84px);
	height: calc(100% - 84px);
	border-bottom: 1px solid #ccc;
}

.scroll-thead-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-width: 100%;
	height: 90px;
	background-color: #fff;
	overflow-y: hidden;
	z-index: 10;
}

.scroll-thead-wrap tbody {
	visibility: hidden;
}

.scroll-tbody-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-width: calc(100% + 18px);
	height: 100%;
	overflow-y: scroll;
	z-index: 5;
}

.scroll-tbody-wrap thead {
	visibility: hidden;
}

.schedule-table tbody tr:last-child th, .schedule-table tbody tr:last-child td {
	border-bottom: none;
}
label {
    display: inline-flex;
    align-items: center;
    min-height: 36px;
    font-size: 10pt;
    color: inherit;
}
input[type=checkbox] {
    display: none;
}
label>.checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
}
input[type=checkbox]:checked+.checkbox {
    border: 2px solid #2bade0;
}
input[type=checkbox]:checked+.checkbox::before {
    content: "\2713";
    position: absolute;
    top: -11px;
    left: 0.5px;
    width: 16px;
    height: 16px;
    font-weight: 600;
    font-size: 13pt;
    color: #2bade0;
}
.pdf-down{
	width: 24px;
	height: 24px;
	background-image: url('../../../assets/images/project/pdf.png');
	background-repeat: no-repeat;
}
.pdf-down:hover{
	background-image: url('../../../assets/images/project/pdf_on.png');
}
.pdf-btn{
	min-width: 85px;
	max-width: 200px;
	width: 90%;
    align-items: center;
    min-height: 28px;
    border-radius: 5px;
    background-color: #2bade0;
    font-weight: 600;
    font-size: 10.5pt;
    color: #fff;
	word-break: break-all;
}
</style>