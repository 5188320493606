<template>
	<div ref="queryMsgHistory" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px); max-width: 1500px;">
			<form @submit.stop="doValidation()">
				<div class="top" style="height: 30px">
					<p class="txt-crud-title">
						{{getStatusName(statusCd)}} Query Message History 
					</p>
				</div>
				<div class="center">
					<table class="tb-list-vertical">
                        <colgroup>
                            <col width="5%" />
                            <col width="20%" />
                            <col width="15%" />
                            <col width="12%" />
                            <col width="12%" />
                            <col width="10%" />
                            <col width="auto" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    No
                                </th>
                                <th>
                                    Screening No
                                </th>
                                <th>
                                    Valid Name
                                </th>
                                <th>
                                    Before
                                </th>
                                <th>
                                    After
                                </th>
                                <th>
                                    User
                                </th>
                                <th>
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in queryHistoryList" :key="index">
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ history.screeningName }}
                                </td>
                                <td>
                                    {{ history.validationName }}
                                </td>
                                <td
                                    class="bold"
                                    :class="getStatusName(history.beforeStatus)=='CLOSE' ? 'red' : getStatusName(history.beforeStatus)=='OPEN' ? 'blue' : getStatusName(history.beforeStatus)=='ANSWER' ? 'green' : ''"
                                >
                                    {{ getStatusName(history.beforeStatus) }}
                                </td>
                                <td
                                class="bold"
                                    :class="getStatusName(history.afterStatus)=='CLOSE' ? 'red' : getStatusName(history.afterStatus)=='OPEN' ? 'blue' : getStatusName(history.afterStatus)=='ANSWER' ? 'green' : ''"
                                >
                                    {{ getStatusName(history.afterStatus)}}
                                </td>
                                <td>
                                    <b>{{ history.firstRegistUserId }}</b><br>
                                    ({{ history.userName }})
                                </td>
                                <td>
                                    {{ history.firstRegistDate.substring(0, 19) }}
                                </td>
                            </tr>
                            <tr v-if="queryHistoryList.length == 0">
                                <td colspan="10" class="no-list">
                                    There is no registered history, Please register the history.
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "queryMsgHistory",
	created() {
        this.logger.debug(this, "created()");
        this.item.projectNo = this.$route.params.projectNo;
        this.queryResolution = this.selectQueryResolution;
        this.statusCd = this.selectQueryStatusCd;

        this.selectqueryHistory();
        this.selectComCodeList();
    },
    props: {
		selectQueryResolution: {
			type: Object,
			required: true
		},
		selectQueryStatusCd: {
			type: String,
			required: true
		}
	},
	data() {
		return {
            item: {},
            queryResolution: {},
            statusCd: "",
            queryHistoryList: [],
            codeList: []
		};
    },
	methods: {
		selectqueryHistory() {
            this.logger.debug(this, "selectqueryHistory()");

			axios
				.post(this.config.contextRoot + "/queryResolution/selectqueryHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.queryHistoryList = response.data.queryHistoryList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
        },
        selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["QUERY_STATUS_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
        getStatusName(code){
            let statusName = '';
            for(let i = 0; i < this.codeList.length; i++){
                if(this.codeList[i].comCode == code){
                    statusName = this.codeList[i].comCodeName;
                }
            }

            return statusName;
        },
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.queryMsgHistory.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.queryMsgHistory.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("view-close", "isMsgHistory");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
</style>