<template>
	<div ref="ProcedureCopy" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
					<p class="txt-crud-title">
						Copy Procedure
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="83%" />
						</colgroup>
						<tbody>
							<tr>
								<th>
									Origin Project.
								</th>
								<td>
									<select
										class="select-crud"
										name="originProject"
										v-validate="'required'"
										v-model="item.originProjectNo"
										data-vv-as="Origin Project"
										@change="getPage('origin', item.originProjectNo)"
									>
										<option
											v-for="(project, index) in originProjectList"
											:key="index"
											:value="project.projectNo"
										>
											{{ project.projectStudyTitle }}
										</option>
									</select>
									<p class="txt-validation" v-if="errors.has('originProject')">
										* {{ errors.first("originProject") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Origin Page.
								</th>
								<td>
									<select
										class="select-crud"
										name="originPageNo"
										v-model="item.originPageNo"
										v-validate="'required'"
										data-vv-as="Origin Page"
										@change="getProcedure(item.originPageNo)"
									>
										<option
											v-for="(page, index) in originPageList"
											:key="index"
											:value="page.pageNo"
										>
											{{ page.pageName }}
										</option>
									</select>
									<p class="txt-validation" v-if="errors.has('originPageNo')">
										* {{ errors.first("originPageNo") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Origin Procedure.
								</th>
								<td>
									<select
										class="select-crud"
										name="copyProcedure"
										v-validate="'required'"
										data-vv-as="orogin Procedure"
										v-model="item.originProcNo"
									>
										<option
											v-for="(proc, index) in originProcList"
											:key="index"
											:value="proc.procNo"
										>
											{{ proc.procName }}
										</option>
									</select>
									<p class="txt-validation" v-if="errors.has('copyProcedure')">
										* {{ errors.first("copyProcedure") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Target Project.
								</th>
								<td>
									<select
										class="select-crud"
										name="copyProject"
										v-validate="'required'"
										data-vv-as="Target Project"
										v-model="item.copyProjectNo"
										@change="getPage('copy', item.copyProjectNo)"
									>
										<option
											v-for="(project, index) in copyProjectList"
											:key="index"
											:value="project.projectNo"
										>
											{{ project.projectStudyTitle }}
										</option>
									</select>
									<p class="txt-validation" v-if="errors.has('copyProject')">
										* {{ errors.first("copyProject") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Target Page.
								</th>
								<td>
									<select
										class="select-crud"
										name="copyPage"
										v-validate="'required'"
										v-model="item.copyPageNo"
										data-vv-as="Target Page"
									>
										<option
											v-for="(page, index) in copyPageList"
											:key="index"
											:value="page.pageNo"
										>
											{{ page.pageName }}
										</option>
									</select>
									<p class="txt-validation" v-if="errors.has('copyPage')">
										* {{ errors.first("copyPage") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Target Procedure.
								</th>
								<td>
									<input
										class="select-crud"
										:class="(errors.has('copyProcedureName') || isDuplicate ? 'input-crud-error' : '')"
										name="copyProcedureName"
										type="text"
										v-model="item.copyProcName"
										v-validate="'required'"
										data-vv-as="Target Procedure"
										@input="item.copyProcName = $event.target.value"
										@keyup="doDuplicateCheck()"
									>
									<p class="txt-validation" v-if="errors.has('copyProcedureName')">
										* {{ errors.first("copyProcedureName") }}
									</p>
									<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;">
										* Visit Name is duplicated.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button
						type="button"
						class="btn-save fright"
						title="Save"
						@click="doValidation()"
					>
						Save
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProcedureCopy",
	created() {
		this.logger.debug(this, "created()");
		this.initCopyProcedure();
	},
	components: {
	},
	data() {
		return {
			searchItem:{
				projectNo:"",
				pageNo:"",
				procNo:"",
				procName:"",
			},
			item:{
				originProjectNo: this.$route.params.projectNo,
				copyProjectNo:"",
				originPageNo:"",
				copyPageNo:"",
				originProcNo:"",
				copyProcName:"",
			},
			originProjectList: [],
			copyProjectList:[],
			originPageList:[],
			copyPageList:[],
			originProcList:[],
			isDuplicate: false
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to copy the Procedure", "Question", "question").then(() => {
							EventBus.$emit("field-loading", true);
							this.doCopy();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		initCopyProcedure() {
			axios
				.post(this.config.contextRoot + "/procedure/selectProjectListForCopy.do")
				.then((response) => {
					this.originProjectList = response.data.projectList;
					this.copyProjectList = response.data.projectList;
				
					this.getPage();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		getPage(type, searchProjectNo) {
			
			if(type == 'origin' || type == 'copy')
			{
				this.searchItem.projectNo = searchProjectNo;
			}
			else if(this.utils.isEmpty(type))
			{
				this.searchItem.projectNo = this.$route.params.projectNo;
			}

			axios
				.post(this.config.contextRoot + "/procedure/selectPageListForCopy.do", this.searchItem)
				.then((response) => {
					if(type == 'origin')
					{
						this.originPageList = response.data.pageList;
					}
					else if(type == 'copy')
					{
						this.copyPageList = response.data.pageList;
					}
					else if(this.utils.isEmpty(type))
					{
						this.originPageList = response.data.pageList;
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		getProcedure(searchPageNo) {
			this.searchItem.pageNo = searchPageNo;
			axios
				.post(this.config.contextRoot + "/procedure/selectProcedureListForCopy.do", this.searchItem)
				.then((response) => {
					console.log(response.data.procedureList);
					this.originProcList = response.data.procedureList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Copy data?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ProcedureCopy.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ProcedureCopy.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isProcedureCopy");
			}.bind(this), 410);
		},
		doCopy() {
			this.logger.debug(this, "doCopy()");
			this.searchItem.projectNo = this.item.copyProjectNo;
			this.searchItem.pageNo = this.item.copyPageNo;
			this.searchItem.procNo = this.item.originProcNo;
			this.searchItem.procName = this.item.copyProcName;

			axios
				.post(this.config.contextRoot + "/procedure/insertCopyProcedure.do", this.searchItem)
				.then((response) => {
					if(response.status == 200)
					{					
						this.$alert("The Procedure has been successfully copied.", "Info", "info").then(() => {
							location.reload();
						});				
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
			
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			
			let param = {
				procName: this.item.copyProcName,
				projectNo: this.item.copyProjectNo,
			}
			
			axios
				.post(this.config.contextRoot + "/procedure/duplicateCheck.do", param)
				.then((response) => {
					console.log(response);
					if(response.data.procedureExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>