<template>
	<div ref="noticeEdit" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Notice Edit
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="15%" />
							<col width="85%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Title.</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="noticeName"
										v-model="item.noticeName"
										v-validate="'required'"
										data-vv-as="Notice Name"
									/>
									<p class="txt-validation" v-if="errors.has('noticeName')">
										* {{ errors.first("noticeName") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Regist Date.</th>
								<td>{{ item.firstRegistDate }}</td>
							</tr>
							<tr>
								<th>Register.</th>
								<td>{{ item.firstRegistUserId }}</td>
							</tr>
							<tr>
								<th>Contents.</th>
								<td>
									<textarea
										class="textarea-crud"
										name="noticeContents"
										v-model="item.noticeContents"
										v-validate="'required'"
										data-vv-as="Notice Contents"
									></textarea>
									<p class="txt-validation" v-if="errors.has('noticeContents')">
										* {{ errors.first("noticeContents") }}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()">
						Modify
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "NoticeEdit",
	created() {
		this.logger.debug(this, "created()");
		this.item.noticeNo = this.noticeNo;
		this.doSelect();
	},
	props: {
		noticeNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {}
		};
	},
	methods: {
		doSelect() {
			this.logger.debug(this, "doSelect()");
			axios
				.post(this.config.contextRoot + "/notice/selectNotice.do", this.item)
				.then((response) => {
					console.log(response);
					this.item = response.data.item;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to modify the current notice?","Question","question").then(() => {
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			axios
				.post(this.config.contextRoot + "/notice/updateNotice.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the notice editing?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.noticeEdit.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.noticeEdit.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("notice-cancel", "isEdit");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>