<template>
	<div ref="ConfirmQuery" class="dialog-container">
        <div class="dialog" style="max-height: 750px; transform: translate(0, -390px); max-width: 700px;">
			<div class="top">
				<p class="txt-crud-title">
					Query Confirm
				</p>
			</div>
			<div class="center">

				<p class="txt-form-title">Please select an item for which to answer a query.</p>
				<select
					class="select-crud"
					v-model="queryConfirmNo"
					v-validate="'required'"
					name="querySelect"
					@change="setSendMsg($event)"
					required
					>
					<option value="" disabled hidden>
						Please select the Valid
					</option>
					<option
					v-for="(queryConfirm, index) in queryConfirmList"
					:key="index"
					:value="queryConfirm.queryConfirmNo"
					>
					{{queryConfirm.validationName != '' ? queryConfirm.validationName : 'Required Data or Requested Data'}}
				</option>
			</select>
				<p class="txt-form-title" style="margin-top: 10px;">Query contents.</p>
				<textarea type="text" class="textarea-crud" v-model="sendMsg" disabled></textarea>
				<p class="txt-form-title" style="margin-top: 10px; height: 25px;">Please select the result of query verification.</p>
				<div class="grid">
					<label v-if="workAccessCheck('QUERY ANSWER')">
						<input name="answerType" type="radio" value="3" v-model="queryStatusCd" v-validate="'required'"><span class="radio"></span>
						<span class="txt-form-radio">Query Answer</span>
					</label>
					<span class="txt-form-ex" v-if="workAccessCheck('QUERY ANSWER')">-> Please indicate the reason below and perform query response.</span>
					<label v-if="workAccessCheck('QUERY CLOSE')">
						<input name="answerType" type="radio" value="2" v-model="queryStatusCd" v-validate="'required'"><span class="radio"></span>
						<span class="txt-form-radio">Query Close</span>
					</label>
					<span class="txt-form-ex" v-if="workAccessCheck('QUERY CLOSE')">-> Please provide the reason below and perform Termination of Query.</span>
				</div>
				<textarea type="text" class="textarea-crud" v-model="answerMsg" style="margin-top: 10px;" maxlength="500" v-validate="'required'"></textarea>
				<p class="dynamic-warn" style="margin-top: 20px;">*If some of the input values related to the query conditions are modified after the query response, <br>the query can be opened again.</p>
				<p class="dynamic-warn">*If some of the input values related to the query conditions are modified after the query is finished, <br>the query can be opened again.</p>
			</div>
			<div class="bottom">
                <button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Save
				</button>
                <button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doClose()">
                    Close
                </button>
            </div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ConfirmQuery",
	created() {
        this.logger.debug(this, "created()");
		this.queryConfirmList = this.selectQuery;
		this.field = this.selectField;
		this.lastModifyUserId = this.userInfo.userId;

		this.getAccessList('ENTRY');
    },
    props: {
		selectQuery: {
			type: Array,
			required: true
		},
		selectField: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
            item: {},
            queryConfirmList: [],
			field: {},
			queryConfirmNo: "",
			answerMsg: "",
			queryStatusCd: "",
			sendMsg: "",
			endQueryList: {},
			accessList: [],
		};
    },
    watch: {
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to save query results?", "Question", "question").then(() => {
							if(this.queryStatusCd == '2'){
								if(this.resultValueChk()){
									this.doInsert();
								}
								else{
									this.$confirm("The values are the same as before. Would you like to continue?", "Question", "question").then(() => {
										this.doInsert();
									});
								}
							}
							else{
								this.doInsert();
							}
						});
					}
					else{
						this.$alert("ERROR!", "Warning", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.item = {
				queryConfirmNo: this.queryConfirmNo,
				answerMsg: this.answerMsg,
				queryStatusCd: this.queryStatusCd,
				lastModifyUserId: this.lastModifyUserId,
				procQuestionNo: this.field.procQuestionNo,
				screeningNo: this.field.screeningNo,
				visitNo: this.field.visitNo,
				pageNo: this.field.pageNo,
			}
			axios
				.post(this.config.contextRoot + "/queryResolution/confirm.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						this.$alert("Query has been saved successfully.", "Info", "info").then(() => {
							this.endQueryList = response.data.queryConfirmList;
							this.doCloseEnd();
						});
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ConfirmQuery.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ConfirmQuery.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("query-close", "isOpenQuery");
			}.bind(this), 410);
		},
		doCloseEnd() {
			this.logger.debug(this, "doClose()");
			this.$refs.ConfirmQuery.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ConfirmQuery.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			this.$parent.resetQuery(this.field, this.endQueryList);
			setTimeout(function() {
				EventBus.$emit("query-close", "isOpenQuery");
			}.bind(this), 410);
		},
		resultValueChk(){
			let bool = false;
			for(let i = 0; i < this.queryConfirmList.length; i++){
				if(this.queryConfirmList[i].queryConfirmNo == this.queryConfirmNo){
					if(this.fieldValue != this.queryConfirmList[i].resultValue){
						bool = true;
					}
				}
			}
			return bool;
		},
		setSendMsg(event){
			let msg = "";
			for(let i = 0; i < this.queryConfirmList.length; i++){
				if(this.queryConfirmList[i].queryConfirmNo == event.target.value){
					msg = this.queryConfirmList[i].sendMsg;
				}
			}
			this.sendMsg = msg;
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
	}
};
</script>
<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 0;
	font-weight: 600;
	font-size: 11pt;
	color: #999;
}
.txt-form-radio {
	margin: 0;
	font-weight: 600;
	font-size: 10pt;
	color: #666;
}
.txt-form-ex {
	margin: 0;
	font-weight: 600;
	font-size: 10pt;
	color: #999;
}
.dynamic-warn {
    font-size: 9pt;
    color: rgb(224, 91, 91);
	font-weight: 600;
}
.radio {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #999;
    border-radius: 20px;
    background-color: #fff;
}
label{
	display: inline-flex;
    align-items: center;
    min-height: 36px;
    font-size: 10pt;
    color: inherit;
}
input[type=radio]:disabled+.radio{
	background-color: #eee;
}
input[type=radio]{
	display: none;
}
input[type=radio]:checked+.radio::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #2bade0;
}
input[type=radio]:checked+.radio {
    border: 2px solid #2bade0;
}
.textarea-crud {
    width: 100%;
    height: 100px;
    line-height: 18px;
    padding: 15px;
    border: 1px solid #ccc;
    font-size: 10pt;
    color: #999;
    resize: none;
}
</style>