<template>
	<div ref="dataImport" class="dialog-container">
		<div class="dialog" style="max-height: 300px; transform: translate(0, -150px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
					<p class="txt-crud-title">
						Import Data
					</p>
				</div>
				<div class="center">
					<p class="grey-caption">Data Upload</p>
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="83%" />
						</colgroup>
						<tbody>
							<tr style="display: none;">
								<th>
									Process Method.
								</th>
								<td>
									<label class="radio-crud">
										<input
											type="radio"
											name="processMethod"
											:value="0"
											v-model="item.processMethod"
										/><div class="radio" />
										OVERWRITE
									</label>
								</td>
							</tr>
							<tr>
								<th>
									Select Schedule.
								</th>
								<td>
									<select
										class="select-crud"
										name="selectScheduleNo"
										v-model="selectScheduleNo"
										v-validate="'required'"
										data-vv-as="Schedule"
										style="width: 50%;"
									>
										<option
											v-for="(visitItem, index) in visitList"
											:key="index"
											:value="visitItem.visitNo"
										>
											{{ visitItem.visitName }}
										</option>
									</select>
									<p class="txt-validation" style="display: inline-block; width: 50%; margin: 0; padding: 0 0 0 30px;">
										※ Please select the schedule.
									</p>
								</td>
							</tr>
							<template v-if="item.visitNo !== undefined">
								
								<tr>
									<th>
										File Upload
									</th>
									<td>
										<common-file
											ref="importFile"
											v-model="importFile.fileList"
											fileExtension="xls"
											fileMaxSize="100"
										/>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
					<template v-if="isChecking">
						<p class="grey-caption" style="margin: 30px 0 5px;">Error Check Result</p>
						<table class="tb-crud-horizon">
							<colgroup>
								<col width="17%" />
								<col width="83%" />
							</colgroup>
							<tbody>
								<tr>
									<th>
										Check Result.
									</th>
									<td>
										<p :class="checkResult.result == 0 ? 'fcolor-green' : 'txt-validation'" style="margin: 0; font-weight: 600; font-size: 10pt;">
											{{ checkResult.result == 0 ? 'Success' : 'Error' }}
										</p>
									</td>
								</tr>
								<tr>
									<th>
										Error Spot.
									</th>
									<td>
										<p style="color: #666;">
											{{ checkResult.spot == "" ? '-' : checkResult.spot }}
										</p>
									</td>
								</tr>
								<tr>
									<th>
										Message.
									</th>
									<td>
										<p class="txt-info" style="margin: 0; font-size: 10pt;">
											{{ checkResult.msg }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</template>
				</div>
				<div class="bottom">
					<button
						v-if="isChecking &&  checkResult.result == 0"
						type="button"
						class="btn-save fright"
						title="Save"
						@click.stop="doImportData()"
					>
						Save
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";

export default {
	name: "DataImport",
	created() {
		this.logger.debug(this, "created()");
		this.item.processMethod = 0;
		this.item.spaceCharacter = 0;
		this.selectVisitList();

		EventBus.$on("deleteFile", () => {
			this.importFile = {};
		});
	},
	components: {
		CommonFile
	},
	data() {
		return {
			item: {},
			visitList: [],
			selectScheduleNo: "",
			//selectScheduleDegree: "",
			importFile: {},
			isChecking: false,
			checkResult: {},
			//taskNo: "",
			DegreeList: [],
			selectDegreeNo: "",
		};
	},
	computed: {
		fileName: function() {
			return "project" + this.$route.params.projectNo + "_" + moment(new Date()).format('YYYYMMDDHHmmss') + ".xls"
		}
	},
	watch: {
		selectScheduleNo: function(value) {
			// 화면 로직
			this.$refs.dataImport.children[0].style.transition = "all 0.4s ease-in-out";
			this.$refs.dataImport.children[0].style.maxHeight = "420px";
			this.$refs.dataImport.children[0].style.transform = "translate(0, -220px)";
			setTimeout(function() {

				this.logger.debug(this, "selectVisitList()>>>>>>>>>>>>>>>>>>>>"+value);
				//this.$set(this.item, "scheduleNo", value);
				this.$set(this.item, "visitNo", value);
			}.bind(this), 410);
		},
		importFile: function() {
			if(!this.utils.isEmpty(this.importFile.fileList)) {
				if(this.importFile.fileList[0].fileExtensionName == "xls")
				{
					this.$confirm("Would you like to check the error?", "Question", "question").then(() => {
						this.doErrorCheck();
					});
				}
				else
				{
					this.$alert("It's invalid file extension! Please upload the file again.", "Warn", "warning");
					this.$refs.importFile.deleteFile();
				}
			}
			else
			{
				this.checkResult = {};
				// 화면 로직
				this.isChecking = false;
				this.$refs.dataImport.children[0].style.transition = "all 0.4s ease-in-out";
				this.$refs.dataImport.children[0].style.maxHeight = "420px";
				this.$refs.dataImport.children[0].style.transform = "translate(0, -220px)";
			}
		}
	},
	methods: {
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");
			axios
				.post(this.config.contextRoot + "/screening/selectVisitValueList.do" , {projectNo : this.$route.params.projectNo})
				.then((response) => {
					//console.log(this.visitList);
					this.visitList = response.data.visitList;
				});
		},
		// selectScheduleDegreeList() {
		// 	this.logger.debug(this, "selectScheduleDegreeList()");
		// 	axios
		// 		.post(this.config.contextRoot + "/data/selectScheduleDegreeList.do" , {scheduleNo: this.selectScheduleNo})
		// 		.then((response) => {
		// 			console.log(this.DegreeList);
		// 			this.degreeList = response.data.degreeList;
		// 		});
		// },
		// downloadUploadForm() {
		// 	this.logger.debug(this, "downloadUploadForm()");
		// 	if(this.selectScheduleNo == "")
		// 	{
		// 		this.$alert("Plesase select the schedule.", "Warn", "warning");
		// 	}
		// 	else
		// 	{
		// 		this.$confirm("Do you want download data import form?", "Question", "question").then(() => {
		// 			let param = {
		// 				projectNo: this.$route.params.projectNo,
		// 				visitNo: this.selectVisitNo,
		// 				//scheduleDegree: this.selectScheduleDegree,
		// 			};
		// 			axios
		// 				.post(this.config.contextRoot + "/data/selectImportForm.do", param, {responseType:'blob'})
		// 				.then((response) => {
		// 					const link = document.createElement('a');
		// 					const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type'] }));
		// 					const today = moment(new Date()).format('YYYYMMDDHHmmss');
		// 					link.href = url;
		// 					link.setAttribute('download', 'project' + this.$route.params.projectNo + '_' + today + '.xls');
		// 					document.body.appendChild(link);
		// 					link.click();
		// 				})
		// 				.catch((error) => {
		// 					this.exceptionHandler(this, error);
		// 				});
		// 		});
		// 	}
		// },
		doErrorCheck() {
			this.logger.debug(this, "doErrorCheck()");
			EventBus.$emit("project-loading", true);
			const formData = new FormData();
			formData.append("excel", this.$refs.importFile.$refs.myfile.files[0], this.$refs.importFile.$refs.myfile.files[0].name);
			formData.append("projectNo", this.$route.params.projectNo);
			formData.append("spaceChar" , 2);
			//formData.append("spaceChar" , this.item.spaceCharacter);
			formData.append("visitNo" , this.selectVisitNo);
			formData.append("scheduleNo" , this.selectVisitNo);
			formData.append("scheduleDegree" , "1");

			
			console.log(formData);
			axios
				.post(this.config.contextRoot + "/screening/updateExcelFile.do", formData)
				.then((response) => {
					console.log(response.data);
					if(response.data.result == "0")
					{
						this.checkResult.result = 0; // 성공
						this.checkResult.spot = "";
						this.checkResult.msg = "※ The check result is successful! Please click the Save button.";
						//this.taskNo = response.data.taskNo;
					}
					else
					{
						this.checkResult.result = 1; // 에러
						this.checkResult.spot = response.data.spot;
						this.checkResult.msg = "※ An error occurred during the check! Please delete the file and upload it again.";
					}
					// 화면 로직
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
						this.$refs.dataImport.children[0].style.transition = "all 0.4s ease-in-out";
						this.$refs.dataImport.children[0].style.maxHeight = "660px";
						this.$refs.dataImport.children[0].style.transform = "translate(0, -330px)";
						setTimeout(function() {
							this.isChecking = true;
						}.bind(this), 410);
					}.bind(this), 500);
				});
		},
		doImportData() {
			this.logger.debug(this, "doImportData()");
			if(this.isChecking && this.checkResult.result == 0)
			{
				this.$confirm("Do you want to register the data in Excel?", "Info", "info").then(() => {
					//this.item.taskNo = this.taskNo;
					axios
					.post(this.config.contextRoot + "/screening/importData.do",{userId: this.userInfo.userId})
					.then((response) => {
						console.log(response);
						if(response.data.string == "complate")
						{
							this.$alert("The data has been successfully registered! Please check the data list.", "Info", "info").then(() => {
								EventBus.$emit("refresh-data-list");
								this.doClose();
							});
						}
						else
						{
							this.$alert("An error has occurred! please try it again.", "Error", "error").then(() => {
								EventBus.$emit("refresh-data-list");
								this.doClose();
							});
						}
					})
					.catch((error) => {
						this.$alert("An error has occurred! please try it again.", "Error", "error").then(() => {
							EventBus.$emit("refresh-data-list");
							this.doClose();
							this.exceptionHandler(this, error);
						});
					});
				});
			}
			else
			{
				this.$alert("An error has occurred! please try it again.", "Error", "error").then(() => {
					EventBus.$emit("refresh-data-list");
					this.doClose();
				});
			}
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the import data?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.dataImport.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.dataImport.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isDataImport");
			}.bind(this), 410);
		},
		// selectDegree(event) {
		// 	this.logger.debug(this, "selectDegree()");
			
		// 	this.selectDegreeNo = event.target.value;

		// 	if(this.selectDegreeNo.length > 0) {
		// 		this.selectScheduleDegreeList();
		// 	}
		// }
	}
};
</script>