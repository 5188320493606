<template>
	<div>
		<div v-if="!downloadOnly" class="file-crud">
			<input
				type="file"
				name="myfile"
				ref="myfile"
				v-validate="'required'"
				enctype="multipart/form-data"
				multiple="false"
				hidden
				@change="upload($event.target.name, $event.target.files)"
				@drop="upload($event.target.name, $event.target.files)"
			/>
			<input
				type="text"
				class="file-input-crud"
				:value="'(' + fileMaxSize + 'MB, 확장자: ' + fileExtension + ')'"
				readonly
			/>
			<button type="button" class="file-btn-crud" @click.stop="openUploader()">
				Upload
			</button>
		</div>
		<div
			v-for="(item, index) in value"
			:key="item.serverFileSaveName"
			class="file-list-crud"
			:style="fieldTypeCd != '11' ? {padding: '0 12px', margin: '0'} : getVideoStyle()"
		>
			<img
				v-if="fieldTypeCd != '11'"
				src="data:image/gif;base64,R0lGODlhBwAMAIABAD9tmf///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjI3NzJhYmIwLTI3ZjQtZjg0YS04ZmRiLTFjYWE0Njk0MmZlOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NjVEQzEyNjU4MTExMUU0OTI4MUY5RTJDMkUxRTJCNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NjVEQzEyNTU4MTExMUU0OTI4MUY5RTJDMkUxRTJCNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBNTk4Q0Q0NUVBNTVFNDExOTNCNUY5MERBRDRCMzk2NSIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjcyNWMxN2NiLTU0MmUtMTFlNC1hODdjLWY4YTQ5ZmM2YjA5MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAAAEALAAAAAAHAAwAAAITjIEWoLx9XmKUSlvlctxBDyZBAQA7"
			/>
			<a
				v-if="fieldTypeCd != '11'"
				href="javascript:;"
				@click="download(item.fileGroupNo, item.fileName, item.serverFileSaveName, item.serverFileSavePath)"
			>
				{{ item.fileName }}
			</a>
			<video
				v-if="fieldTypeCd == '11'"
				width="100%"
				height="100%"
				:src="
					config.contextRoot +
					'/common/file/printVideo.do?fileGroupNo=' +
					item.fileGroupNo +
					'&serverFileSaveName=' +
					item.serverFileSaveName
				"
				controls
				preload="none"
			>
			</video>
			<span
				v-if="fieldTypeCd != '11'"
			>
				{{ item.fileSizeName }}
			</span>
			<!-- <button
				v-if="downloadOnly && checkedImageFile(item.fileExtensionName)"
				type="button"
				@click="popupImagePreview(item)"
			>
				Preview
			</button> -->
			<button
				v-if="!downloadOnly"
				type="button"
				class="btn-x"
				@click="deleteFile(index)"
			></button>
			<input type="hidden" name="fileGroupNo" :value="item.fileGroupNo" />
			<input type="hidden" name="fileNo" :value="item.fileNo" />
			<input type="hidden" name="fileName" :value="item.fileName" />
			<input type="hidden" name="fileExtensionName" :value="item.fileExtensionName" />
			<input type="hidden" name="fileType" :value="item.fileType" />
			<input type="hidden" name="fileSize" :value="item.fileSize" />
			<input type="hidden" name="fileSizeName" :value="item.fileSizeName" />
			<input type="hidden" name="serverFileSavePath" :value="item.serverFileSavePath" />
			<input type="hidden" name="serverFileSaveName" :value="item.serverFileSaveName" />
			<input type="hidden" name="imgUrl" :value="imgUrl" />
		</div>
		<ImagePopUp v-if="isPopup && !downloadOnly" v-bind:popup-data="popup" />
	</div>
</template>

<script>
import axios from "axios";
import ImagePopUp from "@/components/common/ImagePopUp.vue";
import EventBus from "@/script/EventBus.js";

export default {
	name: "CommonFile",
	created() {
		// 자식 Event 감지
		EventBus.$on("closeImagePopUp", () => {
			this.logger.debug(this, "[EventBus] closePopUp");
			this.isPopup = false;
		});
	},
	beforeDestroy(){
		EventBus.$off('closeImagePopUp');
	},
	mounted() {},
	data() {
		return {
			isPopup: false,
			popup: {},
			imgUrl: ""
		};
	},
	props: {
		value: {
			type: Array,
			default: function() {
				return [];
			},
		},
		downloadOnly: {
			type: Boolean,
			default: false,
		},
		fileMaxSize: {
			type: String,
		},
		fileExtension: {
			type: String,
		},
		height: {
			type: String
		},
		fieldTypeCd: {
			type: String
		},
	},
	components: {
		ImagePopUp,
	},
	methods: {
		upload(name, files) {
			const formData = new FormData();
			for (let idx = 0; idx < files.length; idx++) {
				//파일 갯수 1개로 제한 Start
				if (idx > 0) {
					break;
				}
				//파일 갯수 1개로 제한 End
				formData.append(name, files[idx], files[idx].name);
			}
			axios.post(this.config.contextRoot + "/common/file/upload.do", formData).then((response) => {
				console.log(response);
				//파일 갯수 1개로 제한 Start
				for (let idx = this.value.length - 1; idx >= 0; idx--) {
					this.value.pop();
				}
				//파일 갯수 1개로 제한 End
				for (let idx = 0; idx < response.data.fileList.length; idx++) {
					this.value.push(response.data.fileList[idx]);
				}
				//이미지 미리보기 생성
				let fileType = this.value[0].fileExtensionName.toLowerCase();
				if(fileType == "jpg" || fileType == "jpeg" || fileType == "png")
				{
					let promise = this.getUrl(this.$refs.myfile.files[0]);
					promise.then(
						function(val) {
							this.$set(this.value[0], "imgUrl", val);
							this.$emit("input", this.value);
						}.bind(this),
						function(reason) {
							console.log(reason);
						}
					);
				}
				else
				{
					this.$emit("input", this.value);
				}
			});
		},
		getUrl(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader();
				reader.onloadend = (e) => {
					let content = e.target.result;
					resolve(content);
				};
				reader.onerror = (e) => {
					reject(e);
				}
				reader.readAsDataURL(file);
			});
		},
		download(fileNo, fileName, serverFileSaveName, serverFileSavePath) {
			this.logger.debug(this, "download()");

			const formData = new FormData();
			formData.append("fileNo", fileNo);
			formData.append("fileName", fileName);
			formData.append("serverFileSaveName", serverFileSaveName);
			formData.append("serverFileSavePath", serverFileSavePath);

			axios
				.post(this.config.contextRoot + "/common/file/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		openVideo(fileNo, fileName, serverFileSaveName){
			console.log(fileNo, fileName, serverFileSaveName);
		},
		deleteFile() {
			this.value.splice(0);
			this.$refs.myfile.value = "";
			EventBus.$emit("deleteFile");
		},
		openUploader() {
			this.logger.debug(this, "openUploader()");
			this.$refs.myfile.click();
		},
		checkedImageFile(fileExtensionName) {
			const extensions = "JPG,JPEG,PNG,BMP,GIF,TIFF,TIF";
			if (extensions.indexOf(fileExtensionName.toUpperCase()) > -1) {
				return true;
			}
			return false;
		},
		popupImagePreview(item) {
			this.logger.debug(this, "popupImagePreview()");
			console.log(item);
			var title = "Image Preview"
			var parameter = {
				dataInputItem: item
			};
			var popup = {
				title: title,
				contentsType: "imagePreview",
				parameter: parameter,
				button: [],
				buttonAction: [],
			};
			this.popup = popup;

			/* 팝업 사용시 : PopupSize Parameters 적용부분 (숫자만 입력)
			   없을경우 선언 안하면 default 값으로 적용 */
			this.popup.top = "5";
			this.popup.left = "25";
			this.popup.width = "1100";
			this.popup.height = "800";

			this.isPopup = true;
		},
		getVideoStyle(){
			return{
				width: "100%",
				height: "100%",
				margin: 0,
				padding: "5%",
				display: "block",
				alignItems: "center",
				position: "relative",
				minHeight: "100%",
				border: "0",
				borderRadius: "0",
				backgroundColor: "transparent"
			}
		}
	},
};
</script>
