<template>
    <div>
        <p class="txt-list-title">Privilege List</p>
        <ul class="sub-menu">
            <li @click="moveMenu('/role')">Role</li>
            <li class="clicked" @click="moveMenu('/role/privilege')">Privilege</li>
            <li @click="moveMenu('/role/rolePrivilege')">Role Privilege</li>
        </ul>
        <div class="list-control-wrap">
            <input
                type="text"
                class="input-list-search"
                placeholder="Please enter the privilege category name to search."
                v-model="searchText"
            />
            <!-- <button
                type="button"
                class="btn-new"
                title="New Data"
                @click="doSvae()"
            >
                Save
			</button> -->
        </div>
        <table class="tb-list-vertical">
			<colgroup>
                <!-- <col width="3%" /> -->
				<col width="5%" />
				<col width="25%" />
				<col width="25%" />
				<col width="auto" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
                    <!-- <th>
                        <input
							type="checkbox"
							name="checkAll"
							@click="checkAll($event)"
						/>
                    </th> -->
					<th>
						No
					</th>
                    <th>
                        Category
                    </th>
					<th>
						Privilege
					</th>
					<th>
						Description
					</th>
					<th>
						Register
					</th>
					<th>
						Regist Date
					</th>
					
				</tr>
			</thead>
				<tbody>
                <tr v-for="privilege in privilegeList" :key="privilege.privilegeNo" tabindex="0">
                    <!-- <td>
                        <input
                        type="checkbox"
                        name="privilegeNo"
                        v-model="checkedList"
                        :value="privilege.privilegeNo"
                        :checked="(checkedList.indexOf(privilege.privilegeNo) >= 0 ? true : false)"
                        @click="check(privilege.privilegeNo)"
                        />
                    </td> -->
                    <td>
                        {{ privilege.rowNumber }}
                    </td>
                    <td>
                        {{ privilege.privilegeCategory }}
                    </td>
                    <td>
                        {{ privilege.privilegeName }}
                    </td>
                    <td>
                        {{ privilege.privilegeDescription }}
                    </td>
                    <td>
                        {{ privilege.firstRegistUserId }}
                    </td>
                    <td>
                        {{ privilege.firstRegistDate.substring(0, 10) }}
                    </td>     
                </tr>
			</tbody>
		</table>
    </div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
    name: "PrivilegeList",
    components: {},
    beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "ROLE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		EventBus.$emit('access-check', item);
	},
    created() {
        this.logger.debug(this, "created()");

        this.selectPrivilegeList();
    },
    data() {
        return {
            item: {},
            privilegeList: [],
            checkedList: [],
            projectCheckedPrivilegeNoList: [],
            searchText: "",
        };
    },
    watch: {
		searchText: function(val) {
			this.item.privilegeCategory = val;
			this.item.pageNum = 1;
			this.selectPrivilegeList();
		}
	},
    methods: {
        selectPrivilegeList() {
            this.logger.debug(this, "selectPrivilegeList()");

            this.item.projectNo = this.$route.params.projectNo;
            this.item.privilegeTypeCd = '2';

            axios
                .post(this.config.contextRoot + "/role/selectPrivilegeList.do", this.item)
                .then((response) => {
                    console.log(response);
                    this.privilegeList = response.data.privilegeList;

                    for(let privilege in this.privilegeList)
                    {
                        if(this.privilegeList[privilege].selectedPrivilegeNo == 1)
                        {
                            this.checkedList.push(this.privilegeList[privilege].privilegeNo);
                            this.projectCheckedPrivilegeNoList.push(this.privilegeList[privilege].privilegeNo);
                        }
                    }
                    
                    this.$nextTick(function () {
                    setTimeout(function() {
                        EventBus.$emit("project-loading", false);
                    }.bind(this), 500);
                    });
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                })
        },
        doSvae() {
            this.logger.debug(this, "doSave()");

            let param = {
                projectNo: this.$route.params.projectNo,
                selectedPrivilegeNoList: this.checkedList,
                projectCheckedPrivilegeNoList: this.projectCheckedPrivilegeNoList,
            }

            this.$confirm("Do you want to save your privilege?", "Question", "question").then(() => {
                axios
                    .post(this.config.contextRoot + "/role/selectedPrivilegeSave.do", param)
                    .then((response) => {
                        if(response.status == 200)
                        {            
                            console.log(response);
                            this.$alert("It's saved.", "Info", "info").then(() => {
								location.reload();
							});               
                        }
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                    })
            });
        },
        moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
            
            EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
        },
        check(privilegeNo) {
			this.logger.debug(this, "check(privilegeNo)");
            
            let index = this.checkedList.indexOf(privilegeNo);
			if(index >= 0)
			{
				this.checkedList.splice(index, 1);
			}
			else
			{
				this.checkedList.push(privilegeNo);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if(event.target.checked)
			{
				this.checkedList = this.privilegeList.map((item)=>{
					return item.privilegeNo;
				});
			}
			else
			{
				this.checkedList = [];
			}
		},
    }
}
</script>

<style>

</style>