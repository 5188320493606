<template>
	<div ref="ScreeningMultipleList" class="dialog-container">
		<div class="dialog" style="max-height: 800px; transform: translate(0, -400px);">
			<form @submit.stop="doValidation()">
				<!-- 팝업창 상단 제목 -->
				<div class="top">
					<p class="txt-crud-title">
						Multiple List
					</p>
				</div>
				<!-- 팝업창 내용 -->
				<div class="center">
					<div class="data-list-top">
						<div class="data-status-wrap">
							<span class="fcolor-blue"> Completion ({{ entryList.length > 0 ? entryList[0].completeCount : 0 }}) </span>
							|
							<span class="fcolor-green"> Partical Completion ({{ entryList.length > 0 ? entryList[0].particialCount : 0 }}) </span>
							|
							<span class="fcolor-red"> Incompletion ({{ entryList.length > 0 ? entryList[0].incompleteCount : 0 }}) </span>
						</div>
					</div>
					<div class="scroll" style="height: calc(100% - 145px); overflow-y: auto;">
						<table class="tb-list-vertical tb-data-list">
							<colgroup>
								<col width="10%" />
								<col width="5%" />
								<col width="5%" />
								<col width="13%" />
								<col width="10%" />
								<col width="10%" />
								<col width="8%" />
							</colgroup>
							<thead>
								<tr>
									<th>Source</th>
									<th>#</th>
									<th>#Status</th>
									<th>#User</th>
									<th>#Date</th>
									<th>#Entry</th>
									<th>#Compare</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, rowIndex) in entryList">
									<tr :key="rowIndex">
										<td
											v-if="rowIndex == 0 || item.projectEntry == 1"
											:rowspan="(item.projectEntry == 1 ? item.screeningMaxIndex : Number(item.screeningMaxIndex) - Number(item.projectEntry) + 1)"
										>
											{{ item.screeningSourceName == "" ? "-" : item.screeningSourceName }}
										</td>
										<td>
											{{ item.projectEntry }}
										</td>
										<td :class="statusColor(item.entryInputStatusCd)">
											{{ item.entryInputStatusName }}
										</td>
										<td>
											{{ item.userName }}
										</td>
										<td>
											{{ item.inputDate }}
										</td>
										<td>
											<button
												type="button"
												class="btn-data-edit"
												title="Start Survey"
												@click.stop="doWriteEntry(item)"
											></button>
										</td>
										<td
											v-if="rowIndex == 0 || item.projectEntry == 1"
											:rowspan="(item.projectEntry == 1 ? item.screeningMaxIndex : Number(item.screeningMaxIndex) - Number(item.projectEntry) + 1)"
										>
											<b
												v-if="item.entryCompareResultName == 'Error'"
												style="color: #dd574f; text-decoration: underline; cursor: pointer;"
												@click.stop="doCompareData(item)"
											>
												{{ item.entryCompareResultName }}
											</b>
											<b v-if="item.entryCompareResultName == 'Success'">
												{{ item.entryCompareResultName }}
											</b>
											<b v-if="item.entryCompareResultName == ''">
												-
											</b>
										</td>
									</tr>
								</template>
							</tbody>
							<template v-if="entryList.length == 0">
								<tbody>
									<tr>
										<td colspan="7" style="height: 100px;">
											There is no registered data, Please register the data.
										</td>
									</tr>
								</tbody>
							</template>
						</table>
					</div>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
				</div>
			</form>
		</div>		
		<ScreeningEntryCreate v-if="isEntryCreate" :param="param"/>
		<ScreeningMultipleCreate v-if="isMutipleCreate" :param="this.paramEntryCreate"/>
		<ScreeningMultipleCompare v-if="isCompare" :param="this.paramCompate"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import ScreeningEntryCreate from "@/components/project/screening/ScreeningEntryCreate.vue"
import ScreeningMultipleCreate from "@/components/project/screening/ScreeningMultipleCreate.vue"
import ScreeningMultipleCompare from "@/components/project/screening/ScreeningMultipleCompare.vue"
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
	name: "ScreeningMultipleList",
	created() {
		this.logger.debug(this, "created()");
		this.selectEntryList();
		
		EventBus.$on("refresh-data-list", (target) => {
			this.logger.debug(this, "[EventBus] refresh-data-list()");
			if (target == "isEntryCreate") {
				this.isEntryCreate = false;
				this.selectEntryList();
			}
		});
		EventBus.$on("refresh-data-list", (target) => {
			this.logger.debug(this, "[EventBus] refresh-data-list()");
			if (target == "isMutipleCreate") {
				this.isMutipleCreate = false;
				this.selectEntryList();
				this.entryCompareResult = this.entryList.map((item) => {
					if(item.projectEntry)
					{
						return item.entryCompareResultName;
					}
				});
			}
		});
		EventBus.$on("refresh-data-list", (target) => {
			this.logger.debug(this, "[EventBus] refresh-data-list()");
			if (target == "isCompare") {
				this.isCompare = false;
				this.selectEntryList();
				this.entryCompareResult = this.entryList.map((item) => {
					if(item.projectEntry)
					{
						return item.entryCompareResultName;
					}
				});
			}
		});
		EventBus.$on("entry-create-cancel", (target)=> {
			if (target == "isEntryCreate") {
				this.isEntryCreate = false;
			}
		});
		EventBus.$on("entry-create-cancel", (target)=> {
			if (target == "isMutipleCreate") {
				this.isMutipleCreate = false;
			}
		});
		EventBus.$on("entry-create-cancel", (target)=> {
			if (target == "isCompare") {
				this.isCompare = false;
			}
		});
	},
	components: {
		ScreeningEntryCreate,
		ScreeningMultipleCreate,
		ScreeningMultipleCompare
	},
	props: {
		param: {
			type: Object,
			required: true
		}
	},
	beforeDestroy() {
		EventBus.$off("refresh-data-list");
	},
	data() {
		return {
			item: {},
			entryList: [],
			isEntryCreate: false,
			isMutipleCreate: false,
			isCompare: false,
			paramEntryCreate: [],
			paramCompate: [],
			entryCompareResult: [],
		};
	},
	methods: {
		selectEntryList() {
			this.logger.debug(this, "selectEntryList()");
			
			this.item.screeningNo = this.param.selectedScreeningNo;
			this.item.visitNo = this.param.visitNo;
			this.item.pageNo = this.param.pageNo;

			axios
				.post(this.config.contextRoot + "/screening/entrySelectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.entryList = response.data.entrySelectList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		statusColor(status) {
			// 1: Incompletion, 2: Partical Completion, 3: Completion
			let resultCssName = "";
			switch (status) {
				case "1":
					resultCssName = "fcolor-red";
					break;
				case "2":
					resultCssName = "fcolor-green";
					break;
				case "3":
					resultCssName = "fcolor-blue";
					break;
			}
			return resultCssName;
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			this.isEntryCreate = true;
		},
		doWriteEntry(data) {
			this.logger.debug(this, "doWriteEntry(data)");
			this.paramEntryCreate = data;			
			this.isMutipleCreate = true;
		},
		doCompareData(data) {
			this.logger.debug(this, "doCompareData(data)");
			this.paramCompate = data;
            this.isCompare = true;
		},	
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the entry input?","Question","question").then(() => {
				if(this.entryCompareResult[0] == "Error" || this.entryCompareResult[0] == "Success")
				{
					this.doRefreshClose();
				}
				else if(this.utils.isEmpty(this.entryCompareResult[0]))
				{
					this.doClose();
				}
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ScreeningMultipleList.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ScreeningMultipleList.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isMultipleList");
			}.bind(this), 410);
		},
		doRefreshClose() {
			this.logger.debug(this, "doRefreshClose()");
			this.$refs.ScreeningMultipleList.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ScreeningMultipleList.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("refresh-data-list", "isMultipleList");
			}.bind(this), 410);
		},
	},
};
</script>

<style scoped>
.data-status-wrap {
	padding-top: 20px;
}
</style>