<template>
	<div class="login-center-wrap">
		<div id="loginCenterRight">
			<div class="login-form">
				<img class="login-head" src="@/assets/images/login/login_head.png" alt=""/>
				<span class="login-name bold">충북형 헬스케어 빅데이터 플랫폼 아이디 찾기</span>
				<!-- <div>
					<p class="account-title">Find ID</p>
					<input
						type="text"
						class="input-login-style sha-focus-666 bmargin-15"
						placeholder="Please enter yout email."
						v-model="item.findIdEmail"
						style="width:calc(70% - 20px); margin: 0 20px 0 0;"
						@keyup="idValidating()"
					/>
					<button type="button" class="button-blue" style="width: 25%;" @click="findId()">Find ID</button>
					<p class="txt-validation" v-if="!idValidate">
						* Email is required
					</p>
				</div> -->
				<input
					type="text"
					class="input-login-style sha-focus-666 bmargin-15"
					placeholder="가입하신 이메일을 입력해주십시오."
					v-model="item.findIdEmail"
					style="margin-top: 4.2vh"
					@keyup="idValidating()"
				/>
				<button type="button" class="login-button" @click="findId()">
					아이디 찾기
				</button>
				<div class="login-sub">
					<button type="button" @click="findUser(2)">비밀번호 찾기</button>
					<div>|</div>
					<button type="button" @click="doCancel()">로그인</button>
				</div>

				<div class="login-call">
					<span>Customer Service</span>
					<span style="font-weight: bold; color:#004098;">043-835-1533</span>
				</div>

				<div class="login-sponser">
					<img style="width: 5.37vmax;" src="@/assets/images/login/agency_img_1.png" alt=""/>
					<img style="width: 4.54vmax;" src="@/assets/images/login/agency_img_3.png" alt=""/>
					<img style="width: 5.94vmax;" src="@/assets/images/login/agency_img_4.png" alt=""/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "FindAccountId",
	created() {
		this.logger.debug(this, "created()");
	},
	data() {
		return {
			item: {},
			idValidate: true,
			psValidate: true
		};
	},
	methods: {
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$router.push(this.config.contextRoot + "/login");
		},
		idValidating() {
			if(this.item.findIdEmail) this.idValidate = true;
		},
		findId() {
			this.logger.debug(this, "findId()");

			if(!this.item.findIdEmail)
			{
				this.idValidate = false;
				return ;
			}
			else this.$set(this.item, "emailAddress", this.item.findIdEmail);

			axios
				.post(this.config.contextRoot + "/login/findId.do", this.item)
				.then((response) => {
					console.log(response);
					this.$alert("Email has been sent.", "Success", "success");
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
					this.$alert("Error.", "Warn","warning");
				});
		},
		findUser(code) {
			this.logger.debug(this, ".findUser()");
			if(code == 1){
				this.$router.push(this.config.contextRoot + "/login/find/account/id");
			}
			else{
				this.$router.push(this.config.contextRoot + "/login/find/account/password");
			}
		},
	},
};
</script>
