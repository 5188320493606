<template>
	<div ref="validCalculationAdd" class="dialog-container" style="width: 100%; left: 0;">
		<div class="dialog" style="max-height: 900px; max-width: 1400px;transform: translate(0, -450px);">
			<div class="full-top-wrap">
				Valid Calculate Create
			</div>
			<div class="full-center-wrap" style="overflow-y: scroll; height: calc(100% - 140px); padding: 10px 30px;">
				<form @submit.prevent="doValidation()">
					<p class="txt-crud-title">
						Valid Calculate Form
					</p>
					<p class="txt-form-title">
						Valid Calculate Info
					</p>
					<!-- Validation Info -->
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="11%" />
							<col width="39%" />
							<col width="11%" />
							<col width="39%" />
						</colgroup>
						<tbody>
							<!-- <tr>
								<th>Select Visit</th>
								<td colspan="2">
									<select
										name="visitNo"
										class="select-crud"
										v-model="calculateForm.visitNo"
										:class="errors.has('visitNo') ? 'input-crud-error' : ''"
										v-validate="'required'"
										data-vv-as="Visit"
										:style="(calculateForm.visitNo == '' ? {color: '#ccc'} : {})"
										@change="selectPageList(calculateForm.visitNo, 'calculate')"
									>
										<option value="" disabled hidden>
											Please select the visit first.
										</option>
										<option
											v-for="(option, index) in visitList"
											:key="'visit'+index"
											:value="option.visitNo"
										>
											{{ option.visitName }}
										</option>
									</select>
								</td>
							</tr> -->
							<!-- <tr>
								<th>Calculation Field</th>
								<td colspan="2">
									<div style="position: relative; width:100%; height: 32px;">
										<div
											class="drop-list"
											:class="errors.has('calculationField') ? 'input-crud-error' : ''"
											:title="
												(
													!utils.isEmpty(calculateForm.calculateName) ?
													calculateForm.calculateName :
													'Please select the field'
												)
											"
											:style="!utils.isEmpty(calculateForm.calculateName) ? {color: '#999'} : {}"
											@click="openDropList('calculationField', $event);"
										>
											{{
												(
													!utils.isEmpty(calculateForm.calculateName) ?
													calculateForm.calculateName :
													"Please select the field"
												)
											}}
										</div>
										<DropList
											v-if="openDropIndex == 'calculationField'"
											v-model="calculateForm.value"
											:pageList="pageList"
											:fieldTypeList="fieldTypeList"
											@input="setMainDropValue(calculateForm)"
										/>
										<input
											type="hidden"
											:name="'calculationField'"
											v-model="calculateForm.value"
											v-validate="'required'"
											data-vv-as="Target Field"
										/>
									</div>
								</td>
							</tr> -->
							
							<tr>
								<th>Register</th>
								<td>
									{{ item.firstRegistUserId }}
								</td>
								<th>Regist Date</th>
								<td>
									{{ item.firstRegistDate }}
								</td>
							</tr>
							<tr>
									<th>Conditional Formula</th>
									<td colspan="3" style="padding: 15px 10px;">
										<!-- Validation Condition Group List -->
										<p class="txt-form-stitle" style="margin-top: 0; padding: 0;">
											Group List
											<button
												type="button"
												title="Add Group"
												class="btn-new"
												style="margin: 0;"
												@click="addCalculateGroupForm(calculateForm.calculateGroupList.length)"
											>
												Add Group
											</button>
										</p>
										<!-- Calculate Condition Group Form -->
										<template v-for="(con, conIndex) in calculateForm.calculateGroupList">
											<template v-if="conIndex != 0">
												<div v-if="conIndex != 0"
													:key="'calculateGroup'+con.groupSortOrder"
													class="con-group-wrap"
												>
													<p class="con-operator-title" style="display: flex; align-items: center; justify-content: space-between; width:35%; margin:0">
														Group Operator
														<select
															v-if="conIndex != 0"
															class="select-cal-crud"
															:name="conIndex + '_operatorCd'"
															:class="errors.has(conIndex + '_operatorCd') ? 'input-crud-error' : ''"
															v-model="calculateForm.calculateGroupList[conIndex-1].groupOperator"
															v-validate="'required'"
															:data-vv-as="conIndex + '_operatorCd'"
															:style="(con.groupOperator == '' ? {width: '250px', color: '#ccc'} : {width: '250px'})"
															@change="setInput()"
														>
															<option value="" disabled hidden>
																Operator
															</option>
															<option value="+"> + </option>
															<option value="-"> - </option>
															<option value="*"> * </option>
															<option value="/"> / </option>
														</select>
													</p>
												</div>
											</template>
											<div
												:key="'conGroup'+conIndex"
												class="con-group-wrap"
											>
												<!-- Validation Condition Detail List -->
												<p class="con-group-title">
													Group {{ conIndex + 1 }}
													<button
														type="button"
														title="Remove Group"
														class="btn-small-remove"
														style="margin: 0;"
														@click="removeCalculateGroupForm(conIndex)"
													></button>
												</p>
												<input
													type="text"
													class="input-crud"
													v-model="con.text"
													readonly
												/>

												<!-- Calculate Condition Detail Form -->
												<template v-for="(conDetail, conDetailIndex) in con.calculateDetailList">
													<div
														:key="'detail'+conDetailIndex"
														class="con-detail-wrap"
													>

														<!-- Validation Condition Detail Operator -->
														
														<!-- <template v-if="conDetail.fieldTypeCd == 'FIELD' ">
															<label class="label">
																<p>
																	Visit
																</p>
																<select
																	:name="conIndex + '_' + conDetailIndex + '_selectVisit'"
																	class="select-cal-crud"
																	:class="errors.has(conIndex + '_' + conDetailIndex + '_selectVisit') ? 'input-crud-error' : ''"
																	v-model="conDetail.visitNo"
																	v-validate="'required'"
																	data-vv-as="Visit"
																	:style="(conDetail.visitNo == '' ? {color: '#ccc', width: '100%'} : {width: '100%'})"
																	@change="selectPageList(conDetail.visitNo, 'nonCalculate')"
																>
																	<option value="" disabled hidden>
																		Please select the visit first.
																	</option>
																	<option
																		v-for="(option, index) in visitList"
																		:key="'detailVisit'+index"
																		:value="option.visitNo"
																	>
																		{{ option.visitName }}
																	</option>
																</select>
															</label>
														</template>
														<template v-if="conDetail.fieldTypeCd == 'VALUE' ">
															<label class="label">
																<p>
																	Visit
																</p>
																<select
																	name="visitNo"
																	class="select-cal-crud"
																	v-model="conDetail.visitNo"
																	data-vv-as="Visit"
																	:style="(conDetail.visitNo == '' ? {color: '#ccc', width: '100%'} : {width: '100%'})"
																	@change="selectPageList(conDetail.visitNo, 'nonCalculate')"
																	disabled
																>
																	<option value="" disabled hidden>
																		Please select the visit first.
																	</option>
																	<option
																		v-for="(option, index) in visitList"
																		:key="'detailVisit'+index"
																		:value="option.visitNo"
																	>
																		{{ option.visitName }}
																	</option>
																</select>
															</label>
														</template> -->

														<!-- Validation Condition Detail Select Field -->
														<div class="label">
															<p>
																<button
																	type="button"
																	class="select-field-value"
																	:class="conDetail.fieldTypeCd == 'FIELD' ? 'selected-field-value' : ''"
																	@click="setFieldTypeCd(conIndex, conDetailIndex, 'FIELD')" 
																>
																	Field
																</button>
																or
																<button
																	type="button"
																	class="select-field-value"
																	:class="conDetail.fieldTypeCd == 'VALUE' ? 'selected-field-value' : ''"
																	@click="setFieldTypeCd(conIndex, conDetailIndex, 'VALUE')"
																>
																	Value
																</button>
																or
																<button
																	type="button"
																	class="select-field-value"
																	:class="conDetail.fieldTypeCd == 'FUNCTION' ? 'selected-field-value' : ''"
																	@click.stop="openFunctionModal(conIndex, conDetailIndex, conDetail.funcParamList)"
																>
																	Function
																</button>
															</p>
															<div 
																v-if="conDetail.fieldTypeCd == 'FIELD'"
																style="position: relative; width:100%; height: 32px;"
															>
																<div
																	class="drop-list"
																	:class="errors.has(conIndex + '_' + conDetailIndex + '_targetField') ? 'input-crud-error' : ''"
																	:title="
																		(
																			!utils.isEmpty(conDetail.calculateDetailName) ?
																			conDetail.calculateDetailName :
																			'Please select the field'
																		)
																	"
																	:style="!utils.isEmpty(conDetail.calculateDetailName) ? {color: '#999'} : {}"
																	@click="openDropList(conIndex + '_' + conDetailIndex + '_targetField', $event);"
																>
																	{{
																		(
																			!utils.isEmpty(conDetail.calculateDetailName) ?
																			conDetail.calculateDetailName :
																			"Please select the field"
																		)
																	}}
																</div>
																<DropList
																	v-if="openDropIndex == conIndex + '_' + conDetailIndex + '_targetField'"
																	v-model="conDetail.value"
																	:validVisitList="validVisitList"
																	:fieldTypeList="fieldTypeList"
																	@input="setDropValue(conDetail)"
																/>
																<input
																	type="hidden"
																	:name="conIndex + '_' + conDetailIndex + '_targetField'"
																	v-model="conDetail.value"
																	v-validate="'required'"
																	data-vv-as="Target Field"
																/>
															</div>
															<input
																v-if="conDetail.fieldTypeCd == 'VALUE'"
																type="number"
																class="input-crud"
																:name="conIndex + '_' + conDetailIndex + '_fieldValue'"
																:class="errors.has(conIndex + '_' + conDetailIndex + '_fieldValue') ? 'input-crud-error' : ''"
																v-model="conDetail.fieldValue"
																v-validate="'required'"
																data-vv-as="Field Value"
																placeholder="Please enter the compare value."
																@change="setInput()"
															/>
															<div
																v-if="conDetail.fieldTypeCd == 'FUNCTION'"
																class="input-crud"
																:name="conIndex + '_' + conDetailIndex + '_functionValue'"
																:class="errors.has(conIndex + '_' + conDetailIndex + '_functionValue') ? 'input-crud-error' : ''"
																data-vv-as="Function Value"
																disabled
																style="display: flex; align-items: center;"
															>
																{{conDetail.calculateDetailName}}
															</div>
														</div>
														<label>
															<p>
																Operator
															</p>
															<select
																v-if="(conDetailIndex + 1) != con.calculateDetailList.length"
																class="select-cal-crud"
																:name="conIndex + '_' + conDetailIndex + '_operatorCd'"
																:class="errors.has(conIndex + '_' + conDetailIndex + '_operatorCd') ? 'input-crud-error' : ''"
																v-model="conDetail.fieldOperator"
																v-validate="'required'"
																:data-vv-as="conIndex + '_' + conDetailIndex + '_operatorCd'"
																:style="(conDetail.fieldOperator == '' ? {color: '#ccc'} : {})"
																@change="setInput()"
															>
																<option value="" disabled hidden>
																	Operator
																</option>
																<option value="+"> + </option>
																<option value="-"> - </option>
																<option value="*"> * </option>
																<option value="/"> / </option>
															</select>
															<select
																v-if="(conDetailIndex + 1) == con.calculateDetailList.length"
																class="select-cal-crud"
																:name="conIndex + '_' + conDetailIndex + '_operatorCd'"
																v-model="conDetail.fieldOperator"
																:data-vv-as="conIndex + '_' + conDetailIndex + '_operatorCd'"
																:style="(conDetail.fieldOperator == '' ? {color: '#ccc'} : {})"
																disabled
															>
																<option value="" disabled hidden>
																	Operator
																</option>
																<option value="+"> + </option>
																<option value="-"> - </option>
																<option value="*"> * </option>
																<option value="/"> / </option>
															</select>
														</label>
														<!-- Validation Condition Detail Target Field -->
														<!-- Validation Condition Detail Logical Operator -->
														
														<!-- Validation Condition Detail Last Row None Logical Operator -->
														<!-- Validation Condition Detail Last Row Add or Remove -->
														<button
															v-if="conDetailIndex == 0"
															type="button"
															class="btn-add-row"
															title="Add Row"
															@click.stop="addCalculateDetailForm(conIndex)"
														></button>
														<button
															v-if="conDetailIndex != 0"
															type="button"
															class="btn-remove-row"
															title="Remove Row"
															@click.stop="removeCalculateDetailForm(conIndex, conDetailIndex)"
														></button>
													</div>
												</template>
											</div>
										</template>
									</td>
								</tr>
								<tr>
									<th>Summary</th>
									<td colspan="2">
										<input
											type="text"
											class="input-crud"
											v-model="calculateForm.allText"
											:title="calculateForm.allText"
											readonly
										/>
									</td>
								</tr>
						</tbody>
					</table>
					<!-- Validation List -->
				</form>
			</div>
			<div class="full-bottom-wrap">
				<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Save
				</button>
				<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">
					Cancel
				</button>
			</div>
		</div>
		<FuncModal :funcModalParam="funcModalParam" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";
import DropList from "@/components/project/validation/DropList.vue";
import FuncModal from "@/components/project/calculation/FuncModal.vue";

export default {
	name: "CalculationCreate",
	created() {
		this.logger.debug(this, "created()");

		this.item.projectNo = this.$route.params.projectNo;
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.firstRegistUserId = sessionStorage.userName + " (" + sessionStorage.userId + ")";

		this.selectVisitList();
		this.selectValidVisitList();
		this.selectFieldTypeCdList();
		this.selectComCodeList();

		// 로딩바 종료
		this.$nextTick(function () {
			setTimeout(function() {
				EventBus.$emit("project-loading", false);
			}.bind(this), 400);
		});

		EventBus.$on("blurDropList", () => {
			this.logger.debug(this, "[EventBus] blurDropList");
			this.openDropIndex = "";
		});
		
		console.log("length : " + Object.keys(this.$attrs.calcTarget).length);
		if(Object.keys(this.$attrs.calcTarget).length != 0){
			this.calculateForm = JSON.parse(JSON.stringify(this.$attrs.calcTarget));
			for(let i=0; i<this.calculateForm.calculateGroupList.length; i++)
			{
				for(let j=0; j<this.calculateForm.calculateGroupList[i].calculateDetailList.length; j++)
				{
					if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FIELD")
					{
						let value = {};
						value.fieldChildName = this.calculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName;
						value.pageNo = this.calculateForm.calculateGroupList[i].calculateDetailList[j].pageNo;
						value.procFieldNo = this.calculateForm.calculateGroupList[i].calculateDetailList[j].procFieldNo;
						value.procNo = this.calculateForm.calculateGroupList[i].calculateDetailList[j].procNo;
						value.procQuestionNo = this.calculateForm.calculateGroupList[i].calculateDetailList[j].procQuestionNo;
						value.valueText = this.calculateForm.calculateGroupList[i].calculateDetailList[j].calculateDetailName;
						this.$set(this.calculateForm.calculateGroupList[i].calculateDetailList[j], "value", value);
						this.selectPageList(this.calculateForm.calculateGroupList[i].calculateDetailList[j].visitNo, "nonCalculate");
					}
				}
			}
		}
	},
	components: {
		DropList,
		FuncModal
	},
	data() {
		return {
			// 조회 및 로드를 위한 데이터
			item: {},
			visitList: [],
			resultTypeList: [],
			validVisitList: [],
			calcVisitList: [],
			operatorList: [],
			groupOperatorList: [],
			pageList: [],
			calculateList: [],
			fieldTypeList: [],
			codeList: [],
			openDropIndex: "",
			funcModalParam: {},
			selectFunc: [],
			// 입력, 수정을 위한 데이터
			calculateForm: {
				projectNo: this.$route.params.projectNo,
				calculateName:"",
				calculateType:"NUMBER",
				visitNo: "",
				pageNo: "",
				procNo: "",
				procQuestionNo: "",
				procFieldNo: "",
				useYn: "Y",
				// Calculate 상세 리스트
				calculateGroupList: [
					{
						groupSortOrder: 1,
						groupOperator: "",
						text: "",
						// 그룹 조건식 상세 리스트
						calculateDetailList: [
							{
								calculateDetailName:"",
								visitNo: "",
								pageNo: "",
								procNo: "",
								procQuestionNo: "",
								procFieldNo: "",
								fieldTypeCd: "FIELD",
								fieldValue: "",
								fieldOperator: "",
								fieldSortOrder: 1,
								fieldChildName:"",
								funcParamList: [],
							}
						]
					}
				]
			},
			calculateGroupForm: {
				groupSortOrder: 1,
				groupOperator: "",
				text: "",
				calculateDetailList: []
			},
			calculateDetailForm: {
				calculateDetailName:"",
				visitNo: "",
				pageNo: "",
				procNo: "",
				procQuestionNo: "",
				procFieldNo: "",
				fieldTypeCd: "FIELD",
				fieldValue: "",
				fieldOperator: "",
				fieldSortOrder: 1,
				fieldChildName:"",
				funcParamList: [],
			}
		};
	},
	watch: {
		// selectPageNo: function(value) {
		// 	this.$set(this.item, "pageNo", value);
		// 	this.selectFieldList();
		// }
	},
	methods: {
		// 초기 조회 기능 시작
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");
			axios
				.post(this.config.contextRoot + "/visit/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectValidVisitList() {
			this.logger.debug(this, "selectValidVisitList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidVisitList.do", this.item)
				.then((response) => {
					console.log(response);
					this.validVisitList = response.data.validVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList(visitNo, category) {
			this.logger.debug(this, "selectPageList()"+visitNo+category);
			axios
				.post(this.config.contextRoot + "/calculate/selectVisitPageList.do", {visitNo: visitNo, searchCategory: category})
				.then((response) => {
					console.log(response);
					if(category == 'calculate')
					{
						this.pageList = response.data.pageList;
					}
					else
					{
						this.calculateList = response.data.calculateList;
					}
					
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldTypeCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_TYPE_CD"})
				.then((response) => {
					console.log(response);
					this.fieldTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["DATA_TYPE_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		setMainDropValue(obj) {
			obj.pageNo = obj.value.pageNo;
			obj.procNo = obj.value.procNo;
			obj.procQuestionNo = obj.value.procQuestionNo;
			obj.procFieldNo = obj.value.procFieldNo;
			obj.calculateName = obj.value.valueText;
		},
		setDropValue(obj) {
			let isRepeat = this.findIsRepeat();

			if(isRepeat == 'true')
			{
				obj.value = {};
				obj.visitNo = "";
				obj.pageNo = "";
				obj.procNo = "";
				obj.procQuestionNo = "";
				obj.procFieldNo = "";
				obj.isRepeat = "";
				this.$alert("Repeated regions cannot be calculated!", "Warning", "warning");
			}
			else
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
				obj.procFieldNo = obj.value.procFieldNo;
				obj.fieldChildName = obj.value.fieldChildName;
				obj.procFieldName = obj.value.fieldChildName;
				obj.calculateDetailName = obj.value.valueText;
				obj.isRepeat = obj.value.isRepeat;
			}
			this.setInput();
		},

		setInput(){
			for(let i=0; i<this.calculateForm.calculateGroupList.length; i++)
			{
				let putData = "";
				for(let j=0; j<this.calculateForm.calculateGroupList[i].calculateDetailList.length; j++)
				{
					if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FIELD")
					{
						if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName == null){
							putData += '';
						}
						else{
							putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName;
						}
						// putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName;
						putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
					}
					else if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "VALUE")
					{
						putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldValue;
						putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
					}
					else if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FUNCTION")
					{
						putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].calculateDetailName;
						putData += this.calculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
					}
				}
				this.calculateForm.calculateGroupList[i].text = putData;
			}

			this.setAllInput();
		},

		setAllInput(){
			let putAllData = "";
			for(let i=0; i<this.calculateForm.calculateGroupList.length; i++)
			{
				putAllData += "("+this.calculateForm.calculateGroupList[i].text+")";
				putAllData += this.calculateForm.calculateGroupList[i].groupOperator;
			}
			this.calculateForm.allText = putAllData;
			this.calculateForm.calculateName = putAllData;
		},
		// 초기 조회 기능 끝
		// Form 추가, 제거 관련 기능 시작
		addCalculateGroupForm(calculateGroupIndex) {
			this.logger.debug(this, "addCalculateGroupForm(calculateGroupIndex)");
			let calculateGroupForm = JSON.parse(JSON.stringify(this.calculateGroupForm));
			let calculateDetailForm = JSON.parse(JSON.stringify(this.calculateDetailForm));

			calculateGroupForm.groupSortOrder = calculateGroupIndex + 1;
			calculateGroupForm.calculateDetailList.push(calculateDetailForm);

			this.calculateForm.calculateGroupList.push(calculateGroupForm);
		},

		removeCalculateGroupForm(calculateGroupIndex) {
			this.logger.debug(this, "removeValidConForm(calculateGroupIndex)");
			if(this.calculateForm.calculateGroupList.length == 1)
			{
				this.$alert("At least one group must exist!", "Warn", "warning");
			}
			else
			{
				this.calculateForm.calculateGroupList.splice(calculateGroupIndex, 1);
			}
		},
		setFieldTypeCd(conIndex, conDetailIndex, value) {
			this.logger.debug(this, "setTargetTypeCd(conIndex, conDetailIndex, value)");
			this.$set(this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex], "fieldTypeCd", value);

			let field = this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex];
			if(value != 'FUNCTION'){
				field.funcParamList = [];
				field.calculateDetailName = "";
			}
			if(value == 'FIELD')
			{
				field.fieldValue = "";
			}
			else
			{
				field.calculateDetailName = "";
				field.fieldChildName = "";
				field.procFieldName = "";
				field.value = {};
				field.visitNo = "";
				field.pageNo = "";
				field.procNo = "";
				field.procQuestionNo = "";
				field.procFieldNo = "";
			}

			this.setInput();
		},
		openDropList(index, event) {
			this.openDropIndex = index;

			this.$nextTick(() => {
				event.target.parentNode.children[1].children[0].focus();
			})
		},
		addCalculateDetailForm(calculateDetailIndex) {
			this.logger.debug(this, "addCalculateDetailForm(calculateDetailIndex)");
			let calculateDetailForm = JSON.parse(JSON.stringify(this.calculateDetailForm));

			calculateDetailForm.fieldSortOrder = this.calculateForm.calculateGroupList[calculateDetailIndex].calculateDetailList.length + 1;

			this.calculateForm.calculateGroupList[calculateDetailIndex].calculateDetailList.push(calculateDetailForm);
		},
		removeCalculateDetailForm(calculateGroupIndex, calculateDetailIndex) {
			this.logger.debug(this, "removeValidConDetailForm(validDetailIndex, validConIndex, validConDetailIndex)");
			if(this.calculateForm.calculateGroupList[calculateGroupIndex].calculateDetailList.length == 1)
			{
				this.$alert("At least one row must exist!", "Warn", "warning");
			}
			else
			{
				if(this.calculateForm.calculateGroupList[calculateGroupIndex].calculateDetailList.length-1 == calculateDetailIndex){
					this.calculateForm.calculateGroupList[calculateGroupIndex].calculateDetailList[calculateDetailIndex-1].fieldOperator = "";
				}
				this.calculateForm.calculateGroupList[calculateGroupIndex].calculateDetailList.splice(calculateDetailIndex, 1);
			}
			this.setInput();
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => { 
					this.logger.debug(this, "doValidationResult:" + result);
					if(result) {
						this.$confirm("Do you want to save the data?", "Question", "question").then(() => {
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.$alert("Calculation has been saved successfully.", "Info", "info").then(() => {
				let isRepeat = this.findIsRepeat();
				this.$parent.setCalcData(this.$attrs.indexList, this.calculateForm, isRepeat);
				this.$refs.validCalculationAdd.style.animation = "fadeOut 0.4s ease-in-out both";
				this.$refs.validCalculationAdd.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
				setTimeout(function() {
					EventBus.$emit("project-cancel", "isCalcAdd");
				}.bind(this), 400);
			});
			// axios
			// 	.post(this.config.contextRoot + "/calculate/insert.do", this.calculateForm)
			// 	.then((response) => {
			// 		if (response.status == 200) {
			// 			this.$alert("Calculation has been saved successfully.", "Info", "info").then(() => {
			// 				this.doClose();
			// 			});
			// 		} else {
			// 			alert(response.status);
			// 			console.log(response);
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		this.exceptionHandler(this, error);
			// 	});
		},
		// Form 추가, 제거 관련 기능 끝
		// 데이터 저장 및 기본 동작 기능 시작
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Calculation registration?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.validCalculationAdd.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.validCalculationAdd.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			if(Object.keys(this.$attrs.calcTarget).length === 0){
				if(this.$attrs.indexList.type == "OG"){
					this.$parent.setOgTypeCd(this.$attrs.indexList.validDetailIndex, this.$attrs.indexList.validConIndex, this.$attrs.indexList.validConDetailIndex, "FIELD");
				}
				else{
					this.$parent.setTargetTypeCd(this.$attrs.indexList.validDetailIndex, this.$attrs.indexList.validConIndex, this.$attrs.indexList.validConDetailIndex, "FIELD");
				}
			}
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isCalcAdd");
			}.bind(this), 400);
		},
		openFunctionModal(conIndex, conDetailIndex, funcParamList) {
			EventBus.$emit("project-loading", true);

			// let field = this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex];
			// field.fieldValue = "";
			// field.calculateDetailName = "";
			// field.fieldChildName = "";
			// field.procFieldName = "";
			// field.value = {};
			// field.visitNo = "";
			// field.pageNo = "";
			// field.procNo = "";
			// field.procQuestionNo = "";
			// field.procFieldNo = "";
			
			let isRepeat = this.findIsRepeat();
			
			this.funcModalParam = {
				isOpen: true,
				conIndex: conIndex,
				conDetailIndex: conDetailIndex,
				selectFunc: funcParamList,
				isRepeat: isRepeat
			};
		},
		findIsRepeat() {
			let isRepeat = "";
			for(let i=0; i<this.calculateForm.calculateGroupList.length; i++)
			{
				for(let j=0; j<this.calculateForm.calculateGroupList[i].calculateDetailList.length; j++)
				{
					if(this.calculateForm.calculateGroupList[i].calculateDetailList[j].isRepeat == "true")
					{
						isRepeat = "true";
					}
				}
			}
			if(this.$attrs.indexList.isRepeat == "true")
			{
				isRepeat = "true";
			}
			return isRepeat;
		},
		closeFunction(){
			this.funcModalParam = {};
		},
		setFunction(FuncParamList, funcName){
			let conIndex = this.funcModalParam.conIndex;
			let conDetailIndex = this.funcModalParam.conDetailIndex;
			let isRepeat = "";
			for(let i = 0; i < FuncParamList.length; i++)
			{
				if(FuncParamList[i].isRepeat == "true")
				{
					isRepeat = "true";
				}
			}

			this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex].fieldTypeCd = "FUNCTION";
			this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex].funcParamList = JSON.parse(JSON.stringify(FuncParamList));
			this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex].calculateDetailName = this.setFuncName(JSON.parse(JSON.stringify(FuncParamList)), JSON.parse(JSON.stringify(funcName)));
			this.calculateForm.calculateGroupList[conIndex].calculateDetailList[conDetailIndex].isRepeat = isRepeat;

			this.setInput();
			this.closeFunction();
		},
		setFuncName(funcList, funcName){
			let name = funcName;
			name += '(';

			if(funcList.length == 1){
				if(funcList[0].funcParamsCnt == "1"){
					if(Object.keys(funcList[0].field).length != 0){
						name += funcList[0].field.fieldChildName;
					}
					else{
						name += '"' + funcList[0].value + '"';
					}
				}
			}
			if(funcList.length > 1){
				for(let i = 0; i < funcList.length; i++){
					if(Object.keys(funcList[i].field).length != 0){
						name += funcList[i].field.fieldChildName;
					}
					else{
						name += '"' + funcList[i].value + '"';
					}
					if(i != (funcList.length - 1)){
						name += ', '
					}
				}	
			}

			name += ')';
			return name;
		}
	}
};
</script>

<style scoped>
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 20px 0 5px;
	padding: 0 10px;
	font-weight: 600;
	font-size: 13pt;
	color: #666;
}

caption, .txt-form-stitle {
	margin: 20px 0 5px;
	padding: 0 20px;
	font-weight: 600 !important;
	font-size: 11pt !important;
}

.txt-form-stitle {
	height: 35px;
	line-height: 35px;
}

.select-crud {
	padding: 0 10px;
}

.con-group-wrap {
	margin: 10px 0;
	padding: 20px;
	border-radius: 10px;
	background-color: #f5f5f5;
}

.con-group-title {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
	border-bottom: 1px solid #2bade0;
    padding: 10px 0;
}

.con-operator-title {
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
}

.btn-small-remove {
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 3px;
	background-color: #fff;
}

.con-detail-wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-top:15px;
}

.btn-add-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #2bade0;
}

.btn-remove-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #c44944;
}

.con-detail-wrap > .label > p {
	margin: 0 0 5px;
	font-weight: 600;
	font-size: 10pt;
}

.select-field-value {
	font-weight: 600;
	font-size: 10pt;
	color: #999;
	text-decoration: underline;
}

.select-field-value:hover {
	color: #666;
}

.selected-field-value {
	color: #2bade0;
}

.con-detail-wrap > .label:nth-child(1) {
	width: 85%;
}

.con-detail-wrap > .label:nth-child(2) {
	width: 45%;
}

.con-detail-wrap > .label:nth-child(3) {
	width: 10%;
}

.con-detail-wrap > .label:nth-child(4) {
	width: 10%;
}

.drop-list {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.btn-cancel {
	margin-right: 10px;
}
</style>