<template>
	<div ref="emrDataEdit" class="dialog-container">
		<div class="dialog" style="max-height: 600px; transform: translate(0, -300px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Emr Data Edit
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="35%" />
							<col width="15%" />
							<col width="25%" />
							<col width="25%" />
						</colgroup>
						<tbody>
							<tr 
								v-for="(col, colIndex) in columnList"
								:key="colIndex"
							>
								<th>
									{{col.columnEnglishName}}
								</th>
								<td colspan="3">
									<input
										v-if="col.dataIndex"
										type="text"
										class="input-crud"
										:name="'COLUMN_'+col.columnNo"
										v-model="dataList[col.dataIndex].dataValue"
										:readonly="(param.dataType == 'EMR' ? true : false)"
									/>
									<input
										v-if="!col.dataIndex"
										type="text"
										class="input-crud"
										:name="'COLUMN_'+col.columnNo"
										:readonly="(param.dataType == 'EMR' ? true : false)"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()" v-if="param.dataType != 'EMR'">
						Modify
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "EmrDataEdit",
	created() {
		this.logger.debug(this, "created()");
		this.selectColumnList();
	},
	props: {
		param: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			item: {},
			columnList : [],
			dataList: [],
			dataModifyList: [],
		};
	},
	methods: {
		selectColumnList() {
			this.logger.debug(this, "selectColumnList()");
			axios
				.post(this.config.contextRoot + "/emr/selectColumnAllList.do", {})
				.then((response) => {
					console.log(response);
					this.columnList = response.data.selectColumnAllList;
					this.selectDataList();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectDataList() {
			this.logger.debug(this, "selectDataList()");
			axios
				.post(this.config.contextRoot + "/emr/selectData.do", {dataRowNo: this.param.dataRowNo})
				.then((response) => {
					console.log(response);
					this.dataList = response.data.itemList;
					
					for(let i = 0 ; i < this.columnList.length ; i++)
					{
						let data = {
							dataColumnNumber: this.columnList[i].columnNo,
							dataValue: "",
							dataType : "TYPING",
						};
						let columnNo = this.columnList[i].columnNo;
						for(let j = 0 ; j < this.dataList.length ; j++)
						{
							let dataColumnNo = this.dataList[j].dataColumnNo;
							if(Number(dataColumnNo) == Number(columnNo))
							{
								this.columnList[i].dataIndex = j;
							}
						}
						this.dataModifyList.push(data);
					}
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
		this.logger.debug(this, "doValidation()");
		this.$validator
			.validateAll()
			.then((result) => {
				this.logger.debug(this, "doValidationResult:" + result);
				if (result)
				{
					this.$confirm("Do you want to modify the data?", "Question", "question").then(() => {
						EventBus.$emit("setting-loading", true);
						this.doUpdate();
					});
				}
			})
			.catch(() => {
				this.logger.debug(this, "-->" + this.errors.all());
				alert(this.errors.all());
				return false;
			});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			axios
				.post(this.config.contextRoot + "/emr/updateData.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the emr data editing?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.emrDataEdit.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.emrDataEdit.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("setting-cancel", "isEmrEdit");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>