<template>
    <div>
        <p class="txt-list-title">Privilege List</p>
        <ul class="sub-menu">
            <li @click="moveMenu('/auth')">Auth</li>
            <li class="clicked" @click="moveMenu('/auth/privilege')">Privilege</li>
            <li @click="moveMenu('/auth/authPrivilege')">Auth Privilege</li>
        </ul>
        <div class="list-control-wrap">
            <input
                type="text"
                class="input-list-search"
                placeholder="Please enter the privilege category name to search."
                v-model="searchText"
                @keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
        </div>
        <table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="25%" />
				<col width="25%" />
				<col width="auto" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
                    <th>
                        Category
                    </th>
					<th>
						Privilege
					</th>
					<th>
						Description
					</th>
					<th>
						Register
					</th>
					<th>
						Regist Date
					</th>
					
				</tr>
			</thead>
				<tbody>
                <tr v-for="privilege in privilegeList" :key="privilege.privilegeNo" tabindex="0">
                    <td>
                        {{ privilege.rowNumber }}
                    </td>
                    <td>
                        {{ privilege.privilegeCategory }}
                    </td>
                    <td>
                        {{ privilege.privilegeName }}
                    </td>
                    <td>
                        {{ privilege.privilegeDescription }}
                    </td>
                    <td>
                        {{ privilege.firstRegistUserId }}
                    </td>
                    <td>
                        {{ privilege.firstRegistDate.substring(0, 10) }}
                    </td>     
                </tr>
			</tbody>
		</table>
    </div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
    name: "AdminPrivilegeList",
    components: {},
    beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
    created() {
        this.logger.debug(this, "created()");

        this.selectPrivilegeList();
    },
    data() {
        return {
            item: {},
            privilegeList: [],
            searchText: "",
            isTyping: false,
        };
    },
    watch: {
		searchText: function(val) {
			this.item.privilegeCategory = val;
			this.item.pageNum = 1;
		}
	},
    methods: {
        selectPrivilegeList() {
            this.logger.debug(this, "selectPrivilegeList()");

            this.item.privilegeTypeCd = '1';

            axios
                .post(this.config.contextRoot + "/role/selectPrivilegeList.do", this.item)
                .then((response) => {
                    console.log(response);
                    this.privilegeList = response.data.privilegeList;

                    this.$nextTick(function () {
                    setTimeout(function() {
                        EventBus.$emit("setting-loading", false);
                    }.bind(this), 500);
                    });
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                })
        },
        moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
            
            EventBus.$emit("setting-loading", true);
			let url = this.config.contextRoot + "/setting" + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
        },
        keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("setting-loading", true);
				this.privilegeList = new Array();
				this.selectPrivilegeList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
    }
}
</script>

<style scoped>
.project-in-balloon {
    display: inline-block;
    position: absolute;
    top: 90px;
    left: 480px;
    width: 120px;
    height: 50px;
    animation: dropBalloon 0.3s ease-out both;
    z-index: 55;
}
</style>