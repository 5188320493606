<template>
	<div>
		<p class="txt-list-title">Role List</p>
		<ul class="sub-menu">
			<li class="clicked" @click="moveMenu('/role')">Role</li>
			<li @click="moveMenu('/role/privilege')">Privilege</li>
			<li @click="moveMenu('/role/rolePrivilege')">Role Privilege</li>
		</ul>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the role name to search."
				v-model="searchText"
			/>
			<button v-if="workAccessCheck('ROLE SAVE')" type="button" class="btn-new" title="New Data" @click="doSave()">Save</button>
			<button v-if="workAccessCheck('ROLE CREATE')" type="button" class="btn-new" title="New Data" @click="openAdd()">Add</button>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="3%" />
				<col width="1%" />
				<col width="25%" />
				<col width="25%" />
				<col width="auto" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						<input type="checkbox" name="checkAll" @click="checkAll($event)" />
					</th>
					<th>No</th>
					<th>Role</th>
					<th>Category</th>
					<th>Description</th>
					<th>Register</th>
					<th>Regist Date</th>
					<th>Function</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="role in roleList" :key="role.roleNo" tabindex="0">
					<td>
						<input
							:disabled="role.roleName == 'ADMIN'"
							type="checkbox"
							name="roleNo"
							v-model="checkedList"
							:value="role.roleNo"
							:checked="checkedList.indexOf(role.roleNo) >= 0 ? true : false"
							@click="check(role.roleNo)"
						/>
					</td>
					<td>
						{{ role.rowNumber }}
					</td>
					<td>
						{{ role.roleName }}
					</td>
					<td>
						{{ role.roleCategory }}
					</td>
					<td>
						{{ role.roleDescription }}
					</td>
					<td>
						{{ role.firstRegistUserId }}
					</td>
					<td>
						{{ role.firstRegistDate.substring(0, 10) }}
					</td>
					<td>
						<button 
							v-if="role.roleName != 'ADMIN'"
							:class="workAccessCheck('ROLE DELETE')"
							type="button" title="Remove Field" class="btn-remove" @click.stop="roleRemove(role.roleNo)">
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		<RoleCreate v-if="isAddOpen"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import RoleCreate from "@/components/project/role/RoleCreate"

export default {
	name: "RoleList",
	components: {
		RoleCreate
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "ROLE",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");

		EventBus.$on("add-close", () => {
			this.logger.debug(this, "[EventBus] add-cancel");
			this.isAddOpen = false;
		});

		this.selectRoleList();
		this.getAccessList('ROLE');
	},
	data() {
		return {
			item: {},
			roleList: [],
			checkedList: [],
			projectCheckedRoleNoList: [],
			searchText: "",
			isAddOpen: false,
			accessList: [],
		};
	},
	watch: {
		searchText: function(val) {
			this.item.roleName = val;
			this.item.pageNum = 1;
			this.selectRoleList();
		}
	},
	methods: {
		selectRoleList() {
			this.logger.debug(this, "selectRoleList()");

			this.item.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/role/selectRoleList.do", this.item)
				.then((response) => {
					console.log(response);
					this.roleList = response.data.roleList;

					for (let role in this.roleList) {
						if (this.roleList[role].selectedRoleNo == 1) {
							this.checkedList.push(this.roleList[role].roleNo);
							this.projectCheckedRoleNoList.push(this.roleList[role].roleNo);
						}
					}

					this.$nextTick(function () {
						setTimeout(
							function () {
								EventBus.$emit("project-loading", false);
							}.bind(this),
							500
						);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSave() {
			this.logger.debug(this, "doSave()");

			let param = {
				projectNo: this.$route.params.projectNo,
				selectedRoleNoList: this.checkedList,
				projectCheckedRoleNoList: this.projectCheckedRoleNoList,
			};

			this.$confirm("Do you want to save your role?", "Question", "question").then(() => {
				axios
					.post(this.config.contextRoot + "/role/selectedRoleSave.do", param)
					.then((response) => {
						if (response.status == 200) {
							console.log(response);
							if(response.data.falseList.length > 0){
								this.$alert("It's used.", "warning", "warning").then(() => {
									location.reload();
								});
							}
							else{
								this.$alert("It's saved.", "Info", "info").then(() => {
									location.reload();
								});
							}
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");

			EventBus.$emit("project-loading", true);
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu;
			if (this.$route.path !== url) this.$router.push(url);
			else location.reload();
		},
		check(roleNo) {
			this.logger.debug(this, "check(roleNo)");

			let index = this.checkedList.indexOf(roleNo);
			if (index >= 0) {
				this.checkedList.splice(index, 1);
			} else {
				this.checkedList.push(roleNo);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			let getAdminNo = "";
			this.roleList.forEach((item) => {
				if(item.roleName == 'ADMIN'){
					getAdminNo = item.roleNo;
				}
			});
			if (event.target.checked) {
				this.checkedList = this.roleList.map((item) => {
					return item.roleNo;
				});
			} else {
				this.checkedList = [];
				this.checkedList.push(getAdminNo);
			}
		},
		openAdd(){
			this.isAddOpen = true;
		},
		roleRemove(roleNo) {
			this.logger.debug(this, "fieldChildRemove()");

			this.item.roleNo = roleNo;
			
			axios
				.post(this.config.contextRoot + "/role/roleDeleteFlag.do", this.item)
				.then((response) => {
					console.log(response);
					
					if(response.data.flag)
					{
						this.$alert("The referenced item exists.", "Warn", "warning");
						return;
					}

					this.$confirm("Do you want to delete the current Role?", "Question", "question").then(() => {
						axios
						.post(this.config.contextRoot + "/role/roleDelete.do", this.item)
						.then((response) => {
							console.log(response);

							if(response.status == 200)
							{
								this.$alert("delete complete.", "Info", "info").then(() => {
									location.reload();
								});
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});		
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	},
};
</script>
