<template>
	<div>
		<p class="txt-list-title">
			Data Dictionary List
		<button
			v-if="workAccessCheck('EXCEL DOWNLOAD')"
			type="button"
			class="btn-grey"
			title="Excel Download"
			style="float: right;"
			@click="doExcelDown()"
		>
			Excel
		</button>
		</p>
		<div class="dictionary-left">
			<!-- 필드 검색 -->
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the field or group name."
				style="margin: 0;"
				@keyup.enter="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="typing-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<!-- 필드 리스트 wrap -->
			<div class="survey-category">
				<p class="title">CRF Configuration</p>
				<div class="prodic-category-list scroll no-drag">
					<div
						v-for="(pageItem, gindex) in pageDicList"
						:key="gindex"
						class="field-group page-question"
						:class="(pageCloseList.indexOf(pageItem.pageNo) >= 0 ? 'group-off' : '')"
					>
						<button
							type="button"
							class="group-name page-type"
							:class="(isClicked.type == 'page' && isClicked.id == pageItem.pageNo ? 'dic-check' : '')"
							:title="pageItem.pageName"
							@click="infoData(pageItem.pageNo, 'page', '', pageItem.pageName)"
						>
							<div class="groupIcon">
								Pa
							</div>
							<div class="proc-dic-list">
								{{ pageItem.pageName}} [Page]
							</div>
							<div
								class="pageDropdown"
								@click.stop="foldPage(pageItem.pageNo)"
							>
								&gt;
							</div>
						</button>
						<div
							v-for="(procedureItem, pIndex) in pageItem.procedureList"
							:key="pIndex"
							class="field-group proc-question"
							:class="(procedureCloseList.indexOf(procedureItem.procNo) >= 0 ? 'group-off' : '')"
						>
							<button
								type="button"
								class="group-name proc-type"
								:class="(isClicked.type == 'procedure' && isClicked.id == procedureItem.procNo ? 'dic-check' : '')"
								:title="procedureItem.procName"
								@click="infoData(procedureItem.procNo, 'procedure', '', (pageItem.pageName + ' > ' + procedureItem.procName))"
							>
								<div class="procIcon">
									Pr
								</div>
								<div class="proc-dic-list">
									{{ procedureItem.procName }} [Procedure]
								</div>
								<div
									class="procDropdown"
									@click="foldProc(procedureItem.procNo)"
								>
									&gt;
								</div>
							</button>
							<div
								v-for="(questionItem, qIndex) in procedureItem.procQuestionList"
								:key="qIndex"
								class="field-group proc-field-question"
								:class="(fieldCloseList.indexOf(questionItem.questionNo) >= 0 ? 'group-off' : '')"
							>
								<button
									type="button"
									class="group-name proc-question-type"
									:class="(isClicked.type == 'field' && isClicked.id == questionItem.questionNo ? 'dic-check' : '')"
									:title="questionItem.questionFieldName"
									@click="infoData(questionItem.questionNo, 'field', '', (pageItem.pageName + ' > ' + procedureItem.procName + ' > ' + questionItem.questionFieldName))"
								>
									<div class="fieldIcon">
										F
									</div>
									<div class="proc-dic-list">
										{{ questionItem.questionFieldName }} [{{ questionItem.fieldList[0].fieldTypeName }}: {{ questionItem.fieldList[0].dataTypeName }}]
									</div>
									<div
										class="procFieldDropdown"
										@click.stop="foldField(questionItem.questionNo)"
									>
										&gt;
									</div>
								</button>
								<template v-if="questionItem.fieldList[0].fieldTypeCd == '03' || questionItem.fieldList[0].fieldTypeCd == '04' || questionItem.fieldList[0].fieldTypeCd == '05' || questionItem.fieldList[0].fieldTypeCd == '12'">
									<div
										v-for="(tag, index) in getLabelList(questionItem.fieldList[0])"
										v-bind:key="index"
										class="field-group proc-item-question"
										:class="(componentCloseList.indexOf(questionItem.fieldList[0].fieldNo) >= 0 ? 'group-off' : '')"
									>
										<button
											type="button"
											class="group-name proc-item-type"
											:class="(isClicked.type == 'item' && isClicked.id == questionItem.fieldList[0].fieldNo && isClicked.tag == tag ? 'dic-check' : '')"
											:title="tag"
											@click="infoData(questionItem.fieldList[0].fieldNo, 'item', tag, (pageItem.pageName + ' > ' + procedureItem.procName + ' > ' + questionItem.questionFieldName + ' > ' + tag))"
										>
											<div class="itemIcon">
												I
											</div>
											<div class="proc-dic-list">
												{{ tag }}
											</div>
										</button>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dictionary-right">
			<div
				v-if="
					pageRowData.selectType == 'PAGE' || procRowData.selectType == 'PROCEDURE' ||
					fieldRowData.selectType == 'FIELD' || itemRowData.selectType == 'ITEM'
				"
			>
				<div
					:class="
						(
							pageRowData.selectType == 'PAGE' ? 'rightPageIcon' :
							procRowData.selectType == 'PROCEDURE' ? 'rightProcIcon' :
							fieldRowData.selectType == 'FIELD' ? 'rightFieldIcon' :
							itemRowData.selectType == 'ITEM' ? 'rightItemIcon' : ''
						)
					"
				>
					{{
						(
							pageRowData.selectType == 'PAGE' ? 'Pa' :
							procRowData.selectType == 'PROCEDURE' ? 'Pr' :
							fieldRowData.selectType == 'FIELD' ? 'F' :
							itemRowData.selectType == 'ITEM' ? 'I' : ''
						)
					}}
				</div>
				<span class="titleSpan">
					{{
						(
							pageRowData.selectType == 'PAGE' ? pageRowData.pageLabel :
							procRowData.selectType == 'PROCEDURE' ? procRowData.procedureName :
							fieldRowData.selectType == 'FIELD' ? fieldRowData.questionFieldName :
							itemRowData.selectType == 'ITEM' && itemRowData.fieldTypeCd != '12' ? itemRowData.itemLabeleName : itemRowData.itemValueName
						)
					}}
				</span>
				<div class="rightVal">
					{{ isRightVal }}
				</div>
				<p class="title">
					Dictionary Info
				</p>
			</div>
			<div class="scroll-dictionary scroll">
				<table class="dic-crud-horizon">
					<colgroup>
						<col width="16%" />
						<col width="84%" />
					</colgroup>
					<tbody v-if="pageRowData.selectType == 'PAGE'">
						<tr>
							<th>Page Name</th>
							<td>
								{{ pageRowData.pageName }}
							</td>
						</tr>
						<tr>
							<th>Page Label</th>
							<td>
								{{ pageRowData.pageLabel }}
							</td>
						</tr>
						<tr>
							<th>Sort Order</th>
							<td>
								{{ pageRowData.pageSortOrder }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ pageRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ pageRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
					<tbody v-if="procRowData.selectType == 'PROCEDURE'">
						<tr>
							<th>Procedure Name</th>
							<td>
								{{ procRowData.procedureName }}
							</td>
						</tr>
						<tr>
							<th>Sort Order</th>
							<td>
								{{ procRowData.procSortOrder }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ procRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ procRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
					<tbody v-if="fieldRowData.selectType == 'FIELD'">
							<tr>
								<th>Field Name</th>
								<td>
									{{ fieldRowData.questionFieldName }}
								</td>
							</tr>
							<tr>
								<th>Field Label</th>
								<td>
									{{ fieldRowData.questionName }}
								</td>
							</tr>
							<tr>
								<th>Field Type</th>
								<td>
									{{ fieldRowData.fieldTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Type</th>
								<td>
									{{ fieldRowData.dataTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Size</th>
								<td>
									{{ (fieldRowData.dataSize == "" ? "-" : fieldRowData.dataSize) }}
								</td>
							</tr>
							<tr>
								<th>Decimal Digit</th>
								<td>
									{{ (fieldRowData.decimalDigit == "" ? "-" : fieldRowData.decimalDigit) }}
								</td>
							</tr>
							<tr>
								<th>Component Layout</th>
								<td>
									{{ fieldRowData.fieldLayoutName }}
								</td>
							</tr>
							<tr>
								<th>Chart Type</th>
								<td>
									{{ fieldRowData.fieldChartName }}
								</td>
							</tr>
							<tr>
								<th>Prefix Label</th>
								<td>
									{{ (fieldRowData.prefixName == "" ? "-" : fieldRowData.prefixName) }}
								</td>
							</tr>
							<tr>
								<th>Suffix Label</th>
								<td>
									{{ (fieldRowData.suffixName == "" ? "-" : fieldRowData.suffixName) }}
								</td>
							</tr>
							<tr>
								<th>Allow Required</th>
								<td>
									{{ (fieldRowData.isRequired == "" ? "-" : fieldRowData.isRequired) }}
								</td>
							</tr>
							<tr v-if="fieldRowData.fieldTypeName == 'Date'">
								<th>Allow UK Type</th>
								<td>
									{{ (fieldRowData.isAllowUk == "" ? "-" : fieldRowData.isAllowUk) }}
								</td>
							</tr>
							<tr>
								<th>Sort Order</th>
								<td>
									{{ fieldRowData.questionSortOrder }}
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ fieldRowData.firstRegistUserId }}
								</td>
							</tr>
							<tr>
								<th>Register Date</th>
								<td>
									{{ fieldRowData.firstRegistDate }}
								</td>
							</tr>
						</tbody>
					<tbody v-if="itemRowData.selectType == 'ITEM'">
						<tr>
							<th>Item Type</th>
							<td>
								{{ itemRowData.fieldTypeName.toUpperCase() }} {{ itemRowData.selectType }}
							</td>
						</tr>
						<tr>
							<th>Item Label</th>
							<td>
								{{ itemRowData.itemLabeleName }}
							</td>
						</tr>
						<tr>
							<th>Item Value</th>
							<td>
								{{ itemRowData.itemValueName }}
							</td>
						</tr>
						<tr>
							<th>Data Type</th>
							<td >
								{{ itemRowData.dataTypeName }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ itemRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ itemRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectDictionaryList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "DICTIONARY",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectFieldList();
		this.getAccessList('DICTIONARY');
	},
	data() {
		return {
			item: {},
			items: {},
			pageDicList: [],

			pageCloseList : [],
			fieldCloseList: [],
			procedureCloseList : [],
			componentCloseList: [],

			pageRowData : {},
			procRowData : {},
			fieldRowData: {},
			itemRowData: {},

			isRightVal: "",
			isClicked: {},
			isTyping: false,
			accessList: [],
		};
	},
	methods: {
		selectFieldList() {
			this.logger.debug(this, "selectFieldList()");
			this.pageCloseList = [];
			this.procedureCloseList = [];
			this.fieldCloseList = [];
			this.componentCloseList = [];

			this.pageRowData = {};
			this.procRowData = {};
			this.fieldRowData = {};
			this.itemRowData = {};

			this.isClicked = {};
			this.item.projectNo = this.$route.params.projectNo;
			axios
				.post(this.config.contextRoot + "/dictionary/projectDictionaryList.do", this.item)
				.then((response) => {
					console.log(response);
					this.pageDicList = response.data;

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.item.projectNo = this.$route.params.projectNo;
			this.item.searchText = event.target.value;
			
			EventBus.$emit("project-loading", true);
			this.selectFieldList();
		},
		getLabelList(qFieldItem) {
			let returnLabelList = [];
			switch (qFieldItem.fieldTypeCd)
			{
				case '03':
					returnLabelList = qFieldItem.radioOptionsLabel.split(';');
					break;
				case '04':
					returnLabelList = qFieldItem.checkboxOptionsLabel.split(';');
					break;
				case '05':
					returnLabelList = qFieldItem.selectOptionsLabel.split(';');
					break;
				case '12':
					returnLabelList = qFieldItem.usedDefaultList.split(';');
					break;
				default:
					alert("error");
					break;
			}
			return returnLabelList;
		},
		foldPage(pageNo) {
			this.logger.debug(this, "foldPage(pageNo)");
			let index = this.pageCloseList.indexOf(pageNo);
			if(index >= 0)
			{
				this.pageCloseList.splice(index, 1);
			}
			else
			{
				this.pageCloseList.push(pageNo);
			}
		},
		foldProc(procedureNo) {
			this.logger.debug(this, "foldProc(procedureNo)");
			let index = this.procedureCloseList.indexOf(procedureNo);
			if(index >= 0)
			{
				this.procedureCloseList.splice(index, 1);
			}
			else
			{
				this.procedureCloseList.push(procedureNo);
			}
		},
		foldField(questionNo){
			this.logger.debug(this, "foldField(questionNo)");
			let index = this.fieldCloseList.indexOf(questionNo);
			if(index >= 0)
			{
				this.fieldCloseList.splice(index, 1);
			}
			else
			{
				this.fieldCloseList.push(questionNo);
			}
		},
		infoData(selectNo, type, itemData, rightNav) {
			this.logger.debug(this, "infoData(selectNo, type, itemData, rightNav)");
			this.isClicked = {};
			this.isClicked.id = selectNo;
			this.isClicked.type = type;
			this.isClicked.tag = itemData;
			this.isRightVal = rightNav;
			
			axios
				.post(this.config.contextRoot + "/dictionary/select.do", {selectNo: selectNo, type: type, itemData: itemData})
				.then((response) => {
					console.log(response);
					this.pageRowData = {};
					this.procRowData = {};
					this.fieldRowData = {};
					this.itemRowData = {};

					if(type == 'page') this.pageRowData = response.data.dictionaryVo;
					else if(type == 'procedure') this.procRowData = response.data.dictionaryVo;
					else if(type == 'field') this.fieldRowData = response.data.dictionaryVo;
					else this.itemRowData = response.data.dictionaryVo;
					
					this.$nextTick(function () {
						EventBus.$emit("dictionary-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		doExcelDown() {
			this.logger.debug(this, "doExcelDown()");

			this.items.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/excel/downloadExcelDictionary.do", this.items, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					if(response.status == 200)
					{						
						console.log(response);
						console.log(response.headers);
						const url = window.URL.createObjectURL(
							new Blob([response.data], {
								type: response.headers["content-type"],
							})
						);						
						
						const link = document.createElement("a");
						link.href = url;
						let today = new Date();
						let year = today.getFullYear();
						let month = ('0' + (today.getMonth() +1)).slice(-2);
						let day = ('0' + (today.getDate())).slice(-2);

						today = year + "-" + month + "-" + day;

						link.setAttribute("download", "DataDictionary_"+today +".xlsx");
						
						document.body.appendChild(link);
						link.click();
						
						this.$alert("The file has been downloaded.", "Info", "info");
					}

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>

<style>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}
</style>