<template>
	<div
		ref="procedureEdit"
		class="full-container"
		@mousemove="dragging($event)"
		@mouseup="draggend($event)"
	>
		<div class="full-top-wrap">
			Procedure Edit
		</div>
		<div class="full-center-wrap">
			<p class="title">
				Procedure
			</p>
			<!-- 좌측 카테고리 영역 -->
			<div class="survey-left">
				<!-- 필드 검색 -->
				<input
					type="text"
					class="input-list-search"
					placeholder="Please enter the field or group name."
					style="margin: 0;"
					@keyup="keyupHandler"
					@focus="infoBalloon"
					@blur="infoBalloon"
				/>
				<div v-if="isTyping" ref="typingBalloon" class="typing-balloon">
					<p>Please press the Enter key. </p>
				</div>
				<!-- 필드 리스트 wrap -->
				<div class="survey-category">
					<p class="title">Field List</p>
					<div class="survey-category-list scroll no-drag">
						<div
							v-for="(groupItem, gindex) in fieldList"
							:key="gindex"
							class="field-group"
							:class="(fieldCloseList.indexOf(groupItem.questionGroupNo) >= 0 ? 'group-off' : '')"
						>
							<button
								type="button"
								class="group-name"
								:title="groupItem.questionGroupName"
								draggable="true"
								@click="foldField(groupItem.questionGroupNo)"
							>
								{{ groupItem.questionGroupName }}
							</button>
							<div
								v-for="(questionItem, qIndex) in groupItem.questionList"
								:key="qIndex + 1000"
								class="field-question"
								:class="(usedQuestionNo.indexOf(questionItem.questionNo) >= 0 ? 'used-category ' + fieldTypeName(questionItem.fieldList[0].fieldTypeCd) : fieldTypeName(questionItem.fieldList[0].fieldTypeCd))"
								:title="questionItem.questionFieldName"
								draggable="true"
								@dragstart.prevent="dragstart($event, questionItem)"
							>
								{{ questionItem.questionFieldName }} - {{questionItem.questionName}}
							</div>
						</div>
						<!-- Drag Template -->
						<div
							v-if="dragData.isDrag"
							class="survey-category-drag no-drag"
							:style="dragData.position"
						>
							{{ dragData.fieldData.questionFieldName }}
						</div>
					</div>
				</div>
			</div>
			<form @submit.prevent="doValidation()">
				<!-- 우측 상단 CRF Name 영역 -->
				<div class="survey-name">
					<div class="survey-name-label">
						Page
					</div>
					<div class="survey-name-input">
						<select
							class="select-crud"
							:class="(errors.has('pageNo') ? 'input-crud-error' : '')"
							name="pageNo"
							v-model="items.pageNo"
							v-validate="'required'"
							data-vv-as="Page"
							@change="selectSortOrder()"
						>
							<option value="" selected disabled>--- select ---</option>
							<option v-for="(item, index) in pageList" :key="index" :value="item.pageNo">
								{{ item.pageName }}
							</option>
						</select>
					</div>
					<div class="survey-name-label">
						Sort Order
					</div>
					<div class="survey-name-input">
						<input
							type="number"
							min="1"
							max="100"
							class="input-crud"
							:class="(errors.has('procSortOrder') ? 'input-crud-error' : '')"
							name="procSortOrder"
							v-model="items.procSortOrder"
							v-validate="'required'"
							data-vv-as="Sort Order"
							:placeholder="(errors.has('procSortOrder') ? '* ' + errors.first('procSortOrder') : 'Please enter the Sort Order.')"
						/>
					</div>
					<div class="survey-name-label">
						Procedure Name
					</div>
					<div class="survey-name-input">
						<input
							type="text"
							name="procName"
							v-model="items.procName"
							v-validate="'required'"
							data-vv-as="Procedure Name"
							class="input-crud"
							:class="(errors.has('procName') || isDuplicate ? 'input-crud-error' : '')"
							:placeholder="(errors.has('procName') ? '* ' + errors.first('procName') : 'Please enter the Procedure Name.')"
							@input="doDuplicateCheck($event.target.value)"
						/>
						<div v-if="isDuplicate" class="duplicate-balloon">
							* Procedure Name is duplicated.
						</div>
					</div>
					<div class="survey-name-label">
						Procedure Label
					</div>
					<div class="survey-name-input">
						<input
							type="text"
							name="procLabel"
							v-model="items.procLabel"
							v-validate="'required'"
							data-vv-as="Procedure Label"
							class="input-crud"
							:class="(errors.has('procLabel') || isDuplicate ? 'input-crud-error' : '')"
							:placeholder="(errors.has('procLabel') ? '* ' + errors.first('procLabel') : 'Please enter the Procedure Label.')"
						/>
						<div v-if="isDuplicate" class="duplicate-balloon">
							* Procedure Label is duplicated.
						</div>
					</div>
					<div class="survey-name-label">
						Table Grid
					</div>
					<div class="survey-name-input table-set" style="grid-column: 2 / 5">
						<input
							type="number"
							min="1"
							max="26"
							name="tableCol"
							v-model.number="tableCol"
							v-validate="'required'"
							data-vv-as="Table Column"
							class="input-crud"
							:class="(errors.has('tableCol') || isDuplicate ? 'input-crud-error' : '')"
							:placeholder="(errors.has('tableCol') ? '* ' + errors.first('tableCol') : 'Column')"
							onKeyDown="return false"
						/>
						<span>×</span>
						<input
							type="number"
							min="1"
							max="1024"
							name="tableRow"
							v-model.number="tableRow"
							v-validate="'required'"
							data-vv-as="Table Row"
							class="input-crud"
							:class="(errors.has('tableRow') || isDuplicate ? 'input-crud-error' : '')"
							:placeholder="(errors.has('tableRow') ? '* ' + errors.first('tableRow') : 'Row')"
							onKeyDown="return false"
						/>
					</div>
				</div>
				<!-- 우측 중앙 Survey 생성 영역 -->
				<div
					id="surveyContainer"
					ref="surveyContainer"
					class="survey-contents-container"
				>
					<table
						ref="procedureForm"
						class="procedure-table"
						tabindex="0"
						@mouseup="gridDraggEnd()"
						@mouseleave.stop="gridDraggEnd()"
						@blur.stop="initialFocus()"
						@keydown="keyMap($event)"
					>
						<thead :style="(dragData.isDrag ? {opacity: '0.5'} : '')">
							<tr>
								<th :colspan="tableCol + 1">
									<div class="control-box">
										<div class="merge" title="merge" @click.stop="mergeCell()">
											Merge
										</div>
										<div class="revert" title="revert" @click.stop="revertCell()">
											Revert
										</div>
										<div class="header" title="header" @click.stop="setHeader()">
											Header
										</div>
										<div class="typing" title="typing" @click.stop="setTyping()">
											Typing
										</div>
										<div class="repeat" title="repeat" @click.stop="setRepeat()">
											Repeat
										</div>
										<div class="addRow" title="addRow" @click.stop="addRow()">
											Add Row
										</div>
										<div class="deleteRow" title="deleteRow" @click.stop="deleteRow()">
											Del Row
										</div>
										<div class="align">
											<div class="align-left" title="Align Left" @click.stop="setAlign('left')"></div>
											<div class="align-center" title="Align Center" @click.stop="setAlign('center')"></div>
											<div class="align-right" title="Align Right" @click.stop="setAlign('right')"></div>
										</div>
									</div>
								</th>
							</tr>
							<tr>
								<th @click.stop="selectAllCell()"></th> 
								<th
									v-for="col in tableCol"
									:key="col"
									class="th-col"
									:data-id="String.fromCharCode(64 + col)"
									@click.stop="selectColumn(String.fromCharCode(64 + col))"
								>
									{{ String.fromCharCode(64 + col) }}
								</th>
							</tr>
						</thead>
						<tbody>
							<div id="repeat-zone"></div>
							<tr
								v-for="row in tableRow"
								:key="row"
							>
								<th
									class="th-row"
									:id="row"
									:ref="row"
									:data-id="row"
									@click.stop="selectRow(row)"
								>
									{{ row }}
								</th>
								<td
									v-for="col in tableCol"
									:key="col"
									:id="String.fromCharCode(64 + col) + row"
									:colspan="tableData[String.fromCharCode(64 + col) + row].expandCol"
									:rowspan="tableData[String.fromCharCode(64 + col) + row].expandRow"
									:style="cellStyle(String.fromCharCode(64 + col) + row)"
									@click.stop="selectCell($event)"
									@dblclick.stop="setTextEdit($event)"
									@mousedown.stop="gridDragStart($event)"
									@mouseenter.stop="tableDrag.isDrag ? gridDragging($event) : (dragData.isDrag ? showPreview(String.fromCharCode(64 + col) + row) : '')"
									@mouseleave.stop="dragData.isDrag ? hidePreview() : ''"
								>
									<!-- repeat -->
									<div
										v-if="tableData[String.fromCharCode(64 + col) + row].repeatRoot == (String.fromCharCode(64 + col) + row)"
										:id="'repeat-zone-'+(String.fromCharCode(64 + col) + row)+'-'+items.procSortOrder"
										:style="makeRepeatDiv(String.fromCharCode(64 + col) + row)"
									>
									</div>
									<!-- cell type -->
									<div
										v-if="(tableData[String.fromCharCode(64 + col) + row].isFocus || dragData.isDrag)"
										class="focus-celltype"
									>
										<span
											v-if="tableData[String.fromCharCode(64 + col) + row].isRepeat"
											class="celltype-repeat"
										>
											REPEAT
										</span>
										<span
											v-if="tableData[String.fromCharCode(64 + col) + row].isHeader && !tableData[String.fromCharCode(64 + col) + row].isRepeat"
											class="celltype-header"
										>
											HEAD
										</span>
										<span
											v-if="tableData[String.fromCharCode(64 + col) + row].isText && !tableData[String.fromCharCode(64 + col) + row].isRepeat"
											class="celltype-text"
										>
											TEXT
										</span>
										<span
											v-if="
												!tableData[String.fromCharCode(64 + col) + row].isHeader &&
												!tableData[String.fromCharCode(64 + col) + row].isText &&
												!tableData[String.fromCharCode(64 + col) + row].isRepeat &&
												Object.keys(tableData[String.fromCharCode(64 + col) + row].field).length !== 0
												
											"
											class="celltype-data"
										>
											{{ tableData[String.fromCharCode(64 + col) + row].field.questionFieldName }}
										</span>
										<span
											v-if="
												!tableData[String.fromCharCode(64 + col) + row].isHeader &&
												!tableData[String.fromCharCode(64 + col) + row].isText &&
												!tableData[String.fromCharCode(64 + col) + row].isRepeat &&
												Object.keys(tableData[String.fromCharCode(64 + col) + row].field).length !== 0 &&
												['7','8', '9', '11', '12', '13'].includes(tableData[String.fromCharCode(64 + col) + row].field.fieldList[0].dataTypeCd)
											"
											class="listIndex-data"
										>
											{{ tableData[String.fromCharCode(64 + col) + row].field.listIndex }}
										</span>
									</div>
									<!-- text input -->
									<input
										v-if="tableData[String.fromCharCode(64 + col) + row].isText && isTypingEdit"
										type="text"
										:id="String.fromCharCode(64 + col) + row + '_text'"
										v-model="tableData[String.fromCharCode(64 + col) + row].text"
										@blur.stop="isTypingEdit = false"
										@keydown.enter="$event.target.blur()"
										@click.stop=""
									/>
									<!-- cell Text -->
									<div
										v-if="tableData[String.fromCharCode(64 + col) + row].isText && !isTypingEdit"
										class="unfocus-text"
									>
										{{ tableData[String.fromCharCode(64 + col) + row].text }}
									</div>
									<!-- cell Data -->
									<div
										v-if="!tableData[String.fromCharCode(64 + col) + row].isText"
										:class="
											tableData[String.fromCharCode(64 + col) + row].isHeader ?
											'table-head' :
											'table-data'
										"
									>
										<!-- cell Header -->
										<template
											v-if="
												tableData[String.fromCharCode(64 + col) + row].isHeader &&
												(
													(preview.isPreview && preview.cellId == String.fromCharCode(64 + col) + row) ||
													Object.keys(tableData[String.fromCharCode(64 + col) + row].field).length !== 0
												)
											"
										>
											<template v-if="(preview.isPreview && preview.cellId == String.fromCharCode(64 + col) + row)">
												{{ dragData.fieldData.questionName }}
											</template>
											<template v-else>
												{{ tableData[String.fromCharCode(64 + col) + row].field.questionName }}
											</template>
										</template>
										<!-- cell Field -->
										<template
											v-if="
												!tableData[String.fromCharCode(64 + col) + row].isHeader &&
												(
													(preview.isPreview && preview.cellId == String.fromCharCode(64 + col) + row) ||
													Object.keys(tableData[String.fromCharCode(64 + col) + row].field).length !== 0
												)
											"
										>
											<ul style="display: block; width: 100%;">
												<li
													v-for="(field, fieldIndex) in getFieldList(String.fromCharCode(64 + col) + row)"
													:key="fieldIndex"
													class="survey-field-list"
												>
													{{ field.prefixName }}
													<div
														:class="field.fieldLayoutCd == '1' ? 'survey-field-vtype' : 'survey-field-htype'"
														:style="getMargin(field)"
													>
														<!-- Text type -->
														<template v-if="field.fieldTypeCd == '01'">
															<input
																type="text"
																:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																style="max-width: 100%;"
																disabled
															/>
														</template>
														<!-- TextArea type -->
														<template v-if="field.fieldTypeCd == '02'">
															<textarea
																:rows="field.textareaRows"
																:cols="field.textareaCols"
																style="max-width: 100%;"
																disabled
															/>
														</template>
														<!-- Radio type -->
														<template v-if="field.fieldTypeCd == '03'">
															<div
																class="grid"
																:style="getGridRowCol(field.radioOptionsLabel.split(';').length, field.radioCols)"
															>
																<label
																	v-for="(text, radioIndex) in field.radioOptionsLabel.split(';')"
																	:key="radioIndex"
																>
																	<input
																		type="radio"
																		:value="text"
																		disabled
																	/><span class="radio"></span>
																	{{ text }}
																</label>
															</div>
														</template>
														<!-- Select type -->
														<template v-if="field.fieldTypeCd == '04'">
															<div
																class="grid"
																:style="getGridRowCol(field.checkboxOptionsLabel.split(';').length, field.checkboxCols)"
															>
																<label
																	v-for="(text, checkboxIndex) in field.checkboxOptionsLabel.split(';')"
																	:key="checkboxIndex"
																>
																	<input
																		type="checkbox"
																		:value="text"
																		disabled
																	/><span class="checkbox"></span>
																	{{ text }}
																</label>
															</div>
														</template>
														<!-- Select type -->
														<template v-if="field.fieldTypeCd == '05'">
															<select disabled>
																<option value="">-- Select --</option>
															</select>
														</template>
														<!-- Image type -->
														<template v-if="field.fieldTypeCd == '06'">
															<div v-show="field.isLoad">
																<img v-if="field.fileList && field.fileList.length > 0"
																	:src="
																		config.contextRoot +
																			'/common/file/printImage.do?fileNo=' +
																			field.fileList[0].fileNo +
																			'&fileGroupNo=' +
																			field.fileList[0].fileGroupNo +
																			'&serverFileSaveName=' +
																			field.fileList[0].serverFileSaveName
																	"
																	:width="field.imageWidth"
																	:height="field.imageHeight"
																	@load="loadedImage(field)"
																/>
															</div>
															<div v-if="!field.isLoad">
																<svg 
																	v-if="field.fileList && field.imageWidth"
																	xmlns="http://www.w3.org/2000/svg"
																	:width="field.imageWidth"
																	:height="field.imageHeight"
																	:viewBox="'0 0 ' + field.imageWidth + ' ' + field.imageHeight">
																	<rect fill="#ddd" 
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																	/>
																	<text fill="#666" font-family="sans-serif" font-size="20" dy="10.5" font-weight="normal" x="50%" y="50%" text-anchor="middle">
																		{{field.imageWidth}}x{{field.imageHeight}}
																	</text>
																</svg>
																<p v-else-if="!field.fileList">
																	There is no image.
																</p>
															</div>
														</template>
														<!-- File Type -->
														<template v-if="field.fieldTypeCd == '07'">
															<div class="file-wrap">
																<input
																	type="file"
																	hidden
																/>
																<input
																	type="text"
																	class="file"
																	:value="
																		'(' + field.fileMaxSize + 'MB, 확장자: ' + field.fileExtension + ')'
																	"
																	disabled
																/>
																<button type="button" class="upload">Upload</button>
															</div>
														</template>
														<!-- Date Start -->
														<template v-if="field.fieldTypeCd == '08'">
															<input
																type="text"
																class="date-picker"
																:placeholder="field.dateFormat"
																disabled
																:style="{minWidth: Number(field.dateFormat.replace(/ /gi, '').length) + 'rem' }"
															/>
														</template>
														<!-- Calc Type -->
														<template v-if="field.fieldTypeCd == '09'">
															<input
																type="text"
																class="calc"
																:name="'calculate' + field.fieldNo"
																placeholder="Calculation"
																:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																style="max-width: 100%;"
																readonly
															/>
														</template>
														<!-- FileDownload Type -->
														<template v-if="field.fieldTypeCd == '10'">
															<common-file
																v-model="field.fileList"
																:fieldTypeCd = field.fieldTypeCd
																:downloadOnly="true"
															/>
														</template>
														<!-- Video Type -->
														<template v-if="field.fieldTypeCd == '11'">
															<common-file
																v-model="field.fileList"
																:fieldTypeCd = field.fieldTypeCd
																:downloadOnly="true"
															/>
														</template>
														<!-- Default Value type -->
														<template v-if="field.fieldTypeCd == '12'">
															<!-- <input
																type="text"
																disabled
																v-model="field.selectDefault"
															/> -->
															{{field.selectDefault}}
														</template>
													</div>
													{{ field.suffixName }}
												</li>
											</ul>
										</template>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</form>
		</div>
		<div class="full-bottom-wrap">
			<button v-if="workAccessCheck('PROCEDURE EDIT') && !(projectStateCd == '4' || projectStateCd == '3')" type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()">
				Modify
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()" style="margin-right: 10px;">
				Cancel
			</button>
		</div>
		<DefaultValue v-if="isDefaultValue" :defaultField="defaultField" :defaultCellId="defaultCellId"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";
import DefaultValue from "@/components/project/procedure/DefaultValue.vue";

export default {
	name: "ProcedureEdit",
	components: {
		CommonFile,
		DefaultValue,
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.items.procNo = this.$route.params.procNo;
		this.select();
		this.selectFieldList();
		this.selectPageList();
		this.getAccessList('PAGE');
		this.getProjectStateCd();
	},
	watch: {
		tableRow: function() {
			this.generateGrid();
		},
		tableCol: function() {
			this.generateGrid();
		}
	},
	data() {
		return {
			// 기본 데이터
			items: {},
			searchForm: {},
			fieldList: [],
			fieldCloseList: [],
			pageList: [],
			surveyItemList: [],
			defaultField: [],
			defaultCellId: [],
			// 엑셀 관련 데이터
			isTypingEdit: false,
			tableRow: 0,
			tableCol: 0,
			tableData: {},
			columnInfo: {},
			rowInfo: {},
			tableDrag: {
				isDrag: false,
				startId: "",
				endId: ""
			},
			clipBoard: {},
			// 필드 Drag&Drop 데이터
			preview: {
				isPreview: false,
				cellId: "",
			},
			dragData: {
				isDrag: false,
				position: {
					top: "",
					left: "",
				},
				mouseX: "",
				offsetY: "",
				fieldData: {},
			},
			// 상태 확인 데이터
			isTyping: false,
			isDuplicate: false,
			isDefaultValue: false,
			accessList: [],
			projectStateCd: "",
			repeatYn: "",
			firstQuestionNo: [],
			deleteQuestionNo: [],
		};
	},
	computed: {
		usedQuestionNo: function() {
			let usedQuestionNo = this.surveyItemList.map(
				(item) => {
					return item.questionNo;
				}
			);
			return usedQuestionNo;
		},
	},
	methods: {
		select() {
			this.logger.debug(this, "select()");
			axios
				.post(this.config.contextRoot + "/procedure/select.do", this.items)
				.then((response) => {
					console.log(response);
					this.tableCol = Number(response.data.item.tableCol);
					this.tableRow = Number(response.data.item.tableRow);
					this.surveyItemList = response.data.item.procQuestionList;
					this.items.procName = response.data.item.procName;
					this.items.procLabel = response.data.item.procLabel;
					this.items.pageNo = response.data.item.pageNo;
					this.items.procSortOrder = response.data.item.procSortOrder;
					this.tableData = response.data.tableData;
					this.columnInfo = response.data.columnInfo;
					this.rowInfo = response.data.rowInfo;

					let tableDataKeys = Object.keys(this.tableData);
					
					for(let i = 0 ; i < tableDataKeys.length ; i++)
					{
						if(this.tableData[tableDataKeys[i]].field == null)
						{
							this.$set(this.tableData[tableDataKeys[i]], "field", {});
						}
						if(this.tableData[tableDataKeys[i]].repeatRoot != ""){
							this.repeatYn = this.tableData[tableDataKeys[i]].repeatRoot;
						}
					}

					for(let j = 0; j < this.surveyItemList.length; j++){
						let item = this.surveyItemList[j];
						this.firstQuestionNo.push(item.questionNo);	
					}
					this.$nextTick(() => {
						this.setDataType();
						this.setColumnStyle();
						this.setRowStyle();
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldList() {
			this.logger.debug(this, "selectFieldList()");
			axios
				.post(this.config.contextRoot + "/procedure/questionGroupList.do", this.searchForm)
				.then((response) => {
					console.log(response);
					this.fieldList = response.data;
					for(let g = 0 ; g < this.fieldList.length ; g++)
					{
						for(let q = 0 ; q < this.fieldList[g].questionList.length ; q++)
						{
							for(let f = 0 ; f < this.fieldList[g].questionList[q].fieldList.length ; f++)
							{
								if(this.fieldList[g].questionList[q].fieldList[f].fieldTypeCd == "09")
								{
									this.fieldList[g].questionList[q].fieldList[f].calculate = "";
								}
							}
						}
					}
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}, 400)
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/page/selectList.do", {projectNo: this.items.projectNo, searchCategory : "page"})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSortOrder() {
			this.logger.debug(this, "selectSortOrder()");
			axios
				.post(this.config.contextRoot + "/procedure/selectSortOrder.do", {pageNo: this.items.pageNo})
				.then((response) => {
					console.log(response);
					this.$set(this.items, "procSortOrder", response.data.procSortOrder);
					this.$nextTick(function () {
						this.$validator.validateAll();
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		setDataType(){
			for(let i = 0; i < this.surveyItemList.length; i++){
				this.surveyItemList[i].listIndex = Number(this.surveyItemList[i].listIndex);
				this.surveyItemList[i].listSize = Number(this.surveyItemList[i].listSize);
			}

			let keys = Object.keys(this.tableData);
			for(let i = 0; i < keys.length; i++){
				if(Object.keys(this.tableData[keys[i]].field).length != 0){
					this.tableData[keys[i]].field.listIndex = Number(this.tableData[keys[i]].field.listIndex);
					this.tableData[keys[i]].field.listSize = Number(this.tableData[keys[i]].field.listSize);
				}
			}
		},
		fieldTypeName(fieldTypeCd){
			if(fieldTypeCd=="01"){
				return "type_01";
			}
			else if(fieldTypeCd=="02"){
				return "type_02";
			}
			else if(fieldTypeCd=="03"){
				return "type_03";
			}
			else if(fieldTypeCd=="04"){
				return "type_04";
			}
			else if(fieldTypeCd=="05"){
				return "type_05";
			}
			else if(fieldTypeCd=="06"){
				return "type_06";
			}
			else if(fieldTypeCd=="07"){
				return "type_07";
			}
			else if(fieldTypeCd=="08"){
				return "type_08";
			}
			else if(fieldTypeCd=="09"){
				return "type_09";
			}
			else if(fieldTypeCd=="10"){
				return "type_10";
			}
			else if(fieldTypeCd=="11"){
				return "type_11";
			}
			else if(fieldTypeCd=="12"){
				return "type_12";
			}
			else {
				return "";
			}
		},
		foldField(questionGroupNo) {
			this.logger.debug(this, "foldField(questionGroupNo)");
			let index = this.fieldCloseList.indexOf(questionGroupNo);
			if(index >= 0)
			{
				this.fieldCloseList.splice(index, 1);
			}
			else
			{
				this.fieldCloseList.push(questionGroupNo);
			}
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.searchForm.searchText = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.selectFieldList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		doDuplicateCheck(procName) {
			this.logger.debug(this, "doDuplicateCheck(procName");
			if(procName != "" && procName != null && procName !== undefined) this.$set(this.items, "procName", procName);
			axios
				.post(this.config.contextRoot + "/procedure/duplicateCheck.do", this.items)
				.then((response) => {
					console.log(response);
					if(response.data.procedureExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		generateGrid() {
			let usedKey = [];
			for(let col = 1 ; col <= this.tableCol ; col++)
			{
				for(let row = 1 ; row <= this.tableRow ; row++)
				{
					// cell Data 생성
					let keys = Object.keys(this.tableData);
					let id = String.fromCharCode(64 + col) + row;
					
					usedKey.push(id);
					if(keys.indexOf(id) < 0)
					{
						let data = {
							isFocus: false,
							isExpand: false,
							expandCol: 1,
							expandRow: 1,
							repeatCol: 1,
							repeatRow: 1,
							expandRoot: "",
							repeatRoot: "",
							isHeader: false,
							isText: false,
							isRepeat: false,
							align: "left",
							text: "",
							field: {}
						};
						this.$set(this.tableData, id, data);
					}
				}
			}
			
			// 미사용 Cell Data 삭제
			let keyArray = Object.keys(this.tableData);
			for(let i = 0 ; i < keyArray.length ; i++)
			{
				if(usedKey.indexOf(keyArray[i]) < 0)
				{
					if(this.tableData[keyArray[i]].isExpand)
					{
						let rootId = this.tableData[keyArray[i]].expandRoot;
						let rootStartCol = Number(rootId.slice(0, 1).charCodeAt(0));
						let rootEndCol = Number(rootStartCol + this.tableData[rootId].expandCol - 1);
						let rootStartRow = Number(rootId.slice(1));
						let rootEndRow = Number(rootStartRow + this.tableData[rootId].expandRow - 1);
						for(let c = rootStartCol ; c <= rootEndCol ; c++)
						{
							for(let r = rootStartRow ; r <= rootEndRow ; r++)
							{
								let id = String.fromCharCode(c) + r;
								this.$set(this.tableData[id], "isFocus", false);
								this.$set(this.tableData[id], "isExpand", false);
								this.$set(this.tableData[id], "expandCol", 1);
								this.$set(this.tableData[id], "expandRow", 1);
								this.$set(this.tableData[id], "expandRoot", "");
								this.$set(this.tableData[id], "isRepeat", false);
								this.$set(this.tableData[id], "repeatCol", 1);
								this.$set(this.tableData[id], "repeatRow", 1);
								this.$set(this.tableData[id], "repeatRoot", "");
							}
						}
					}
					this.$delete(this.tableData, keyArray[i]);
				}
			}
		},
		cellStyle(id) {
			let style = {};
			let cell = this.tableData[id];

			if(cell.isExpand && cell.expandCol < 2 && cell.expandRow < 2)
			{
				style.display = "none";
			}
			if(cell.isHeader)
			{
				style.backgroundColor = "#f5f5f5";
				style.fontWeight = "600";
			}
			if(cell.isFocus)
			{
				style.backgroundColor = "#00579211";
			}
			if(this.dragData.isDrag)
			{
				style.opacity = "0.9";
			}
			if(this.preview.isPreview && this.preview.cellId == id)
			{
				style.backgroundColor = "#eef4f8";
			}

			style.textAlign = cell.align;
			style.justifyContent = cell.align;

			return style;
		},
		setColumnStyle() {
			let tableWidth = this.$refs.procedureForm.offsetWidth - 35;
			let cols = document.getElementsByClassName("th-col");
			
			for(let i = 0 ; i < cols.length ; i++)
			{
				let id = cols[i].dataset.id;
				let width = Number(this.columnInfo[id].width.replace('%', ''));
				let setWidth = tableWidth * (width / 100);
				cols[i].style.width = setWidth + "px";
			}
		},
		setRowStyle() {
			let rows = document.getElementsByClassName("th-row");
			for(let i = 0 ; i < rows.length ; i++)
			{
				let id = rows[i].dataset.id;
				let height = this.rowInfo[id].height;
				rows[i].style.height = height;
			}
		},
		selectCell(event) {
			this.initialFocus();
			let getPath = this.utils.getEventPath(event);
			let nodePath = getPath.map((item) => {
				return item.nodeName;
			});
			let tdIndex = nodePath.indexOf("TD");
			let id = getPath[tdIndex].id;
			if(this.tableData[id].isRepeat){
				let rootId = this.tableData[id].repeatRoot;
				let rootStartCol = Number(rootId.slice(0, 1).charCodeAt(0));
				let rootEndCol = Number(rootStartCol + this.tableData[rootId].repeatCol - 1);
				let rootStartRow = Number(rootId.slice(1));
				let rootEndRow = Number(rootStartRow + this.tableData[rootId].repeatRow - 1);
				for(let c = rootStartCol ; c <= rootEndCol ; c++)
				{
					for(let r = rootStartRow ; r <= rootEndRow ; r++)
					{
						let id = String.fromCharCode(c) + r;
						this.$set(this.tableData[id], "isFocus", true);
					}
				}
			}
			else if(this.tableData[id].isExpand && !this.tableData[id].isRepeat)
			{
				let rootId = this.tableData[id].expandRoot;
				let rootStartCol = Number(rootId.slice(0, 1).charCodeAt(0));
				let rootEndCol = Number(rootStartCol + this.tableData[rootId].expandCol - 1);
				let rootStartRow = Number(rootId.slice(1));
				let rootEndRow = Number(rootStartRow + this.tableData[rootId].expandRow - 1);
				for(let c = rootStartCol ; c <= rootEndCol ; c++)
				{
					for(let r = rootStartRow ; r <= rootEndRow ; r++)
					{
						let id = String.fromCharCode(c) + r;
						this.$set(this.tableData[id], "isFocus", true);
					}
				}
			}
			else
			{
				this.$set(this.tableData[id], "isFocus", true);
			}
		},
		selectColumn(column) {
			this.initialFocus();

			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < Object.keys(this.tableData).length ; i++)
			{
				if(keys[i].slice(0, 1) == column)
				{
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						let rootCol = Number(rootId.slice(0, 1).charCodeAt(0));
						let rootRow = Number(rootId.slice(1));
						let rootColLength = rootCol + Number(this.tableData[rootId].expandCol) - 1;
						let rootRowLength = rootRow + Number(this.tableData[rootId].expandRow) - 1;

						for(let c = rootCol ; c <= rootColLength ; c++)
						{
							for(let r = rootRow ; r <= rootRowLength ; r++)
							{
								let id = String.fromCharCode(c) + r;
								this.$set(this.tableData[id], "isFocus", true);
							}
						}
					}
					else
					{
						this.$set(this.tableData[keys[i]], "isFocus", true);
					}
				}
			}
		},
		selectRow(row) {
			this.initialFocus();
			
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < Object.keys(this.tableData).length ; i++)
			{
				if(keys[i].slice(1) == row)
				{
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						let rootCol = Number(rootId.slice(0, 1).charCodeAt(0));
						let rootRow = Number(rootId.slice(1));
						let rootColLength = rootCol + Number(this.tableData[rootId].expandCol) - 1;
						let rootRowLength = rootRow + Number(this.tableData[rootId].expandRow) - 1;

						for(let c = rootCol ; c <= rootColLength ; c++)
						{
							for(let r = rootRow ; r <= rootRowLength ; r++)
							{
								let id = String.fromCharCode(c) + r;
								this.$set(this.tableData[id], "isFocus", true);
							}
						}
					}
					else
					{
						this.$set(this.tableData[keys[i]], "isFocus", true);
					}
				}
			}
		},
		selectAllCell() {
			for(let col = 1 ; col <= this.tableCol ; col++)
			{
				for(let row = 1 ; row <= this.tableRow ; row++)
				{
					let id = String.fromCharCode(64 + col) + row;
					this.$set(this.tableData[id], "isFocus", true);
				}
			}
		},
		initialFocus() {
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < Object.keys(this.tableData).length ; i++)
			{
				this.$set(this.tableData[keys[i]], "isFocus", false);
			}
		},
		gridDragStart(event) {
			let getPath = this.utils.getEventPath(event);
			let nodePath = getPath.map((item) => {
				return item.nodeName;
			});
			let tdIndex = nodePath.indexOf("TD");
			let startId = getPath[tdIndex].id;
			this.$set(this.tableDrag, "isDrag", true);
			this.$set(this.tableDrag, "startId", startId);
		},
		gridDragging(event) {
			this.initialFocus();

			let getPath = this.utils.getEventPath(event);
			let nodePath = getPath.map((item) => {
				return item.nodeName;
			});
			let tdIndex = nodePath.indexOf("TD");
			let endId = getPath[tdIndex].id;
			this.$set(this.tableDrag, "endId", endId);

			let startCol = Number(this.tableDrag.startId.slice(0, 1).charCodeAt(0));
			let startRow = Number(this.tableDrag.startId.slice(1));
			let endCol = Number(endId.slice(0, 1).charCodeAt(0));
			let endRow = Number(endId.slice(1));
			
			let col = (startCol < endCol ? startCol : endCol);
			let colLength = (startCol < endCol ? endCol : startCol);
			let row = (startRow < endRow ? startRow : endRow);
			let rowLength = (startRow < endRow ? endRow : startRow);

			let minCol = col;
			let maxCol = colLength;
			let minRow = row;
			let maxRow = rowLength;
			let expandCell = [];
			let repeatCell = [];

			let loof = true;
			while(loof)
			{
				for(let c = col ; c <= colLength ; c++)
				{
					for(let r = row ; r <= rowLength ; r++)
					{
						let id = String.fromCharCode(c) + r;
						if(this.tableData[id].isExpand)
						{
							
							let rootId = this.tableData[id].expandRoot;
							let rootStartCol = Number(rootId.slice(0, 1).charCodeAt(0));
							let rootEndCol = Number(rootStartCol + this.tableData[rootId].expandCol - 1);
							let rootStartRow = Number(rootId.slice(1));
							let rootEndRow = Number(rootStartRow + this.tableData[rootId].expandRow - 1);

							if(rootStartCol < minCol) minCol = rootStartCol;
							if(rootEndCol > maxCol) maxCol = rootEndCol;
							if(rootStartRow < minRow) minRow = rootStartRow;
							if(rootEndRow > maxRow) maxRow = rootEndRow;

							if(expandCell.indexOf(rootId) < 0) expandCell.push(rootId);
						}
						if(this.tableData[id].isRepeat)
						{
							
							let rootId = this.tableData[id].repeatRoot;
							let rootStartCol = Number(rootId.slice(0, 1).charCodeAt(0));
							let rootEndCol = Number(rootStartCol + this.tableData[rootId].repeatCol - 1);
							let rootStartRow = Number(rootId.slice(1));
							let rootEndRow = Number(rootStartRow + this.tableData[rootId].repeatRow - 1);

							if(rootStartCol < minCol) minCol = rootStartCol;
							if(rootEndCol > maxCol) maxCol = rootEndCol;
							if(rootStartRow < minRow) minRow = rootStartRow;
							if(rootEndRow > maxRow) maxRow = rootEndRow;

							if(repeatCell.indexOf(rootId) < 0) repeatCell.push(rootId);
						}
					}
				}

				let cancel = false;
				for(let c = minCol ; c <= maxCol ; c++)
				{
					for(let r = minRow ; r <= maxRow ; r++)
					{
						let id = String.fromCharCode(c) + r;
						if(this.tableData[id].isExpand && expandCell.indexOf(this.tableData[id].expandRoot) < 0)
						{
							cancel = true;
							col = minCol;
							colLength = maxCol;
							row = minRow;
							rowLength = maxRow;
							break;
						}
						else
						{
							this.$set(this.tableData[id], "isFocus", true);
						}
					}
					if(cancel) break;
				}
				if(!cancel) loof = false;
			}
		},
		gridDraggEnd() {
			this.$set(this.tableDrag, "isDrag", false);
			this.$set(this.tableDrag, "startId", "");
			this.$set(this.tableDrag, "endId", "");
		},
		mergeCell() {
			this.deleteData();
			let keys = Object.keys(this.tableData);
			let colArray = [];
			let rowArray = [];
			let expandArray = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isRepeat)
					{
						this.$alert("Please release the repeat area.","Warning","warning");
						return false;
					}
					if(this.tableData[keys[i]].isExpand)
					{
						this.revertCell();
						return;
					}
					else
					{
						colArray.push(keys[i].slice(0, 1).charCodeAt(0));
						rowArray.push(Number(keys[i].slice(1)));
						expandArray.push(keys[i]);
					}
				}
			}

			if(colArray.length > 1 && rowArray.length > 1)
			{
				let minCol = Math.min(...colArray);
				let maxCol = Math.max(...colArray);
				let minRow = Math.min(...rowArray);
				let maxRow = Math.max(...rowArray);

				let targetId = String.fromCharCode(minCol) + minRow;
				let expandCol = maxCol - minCol + 1;
				let expandRow = maxRow - minRow + 1;

				this.$set(this.tableData[targetId], "expandCol", expandCol);
				this.$set(this.tableData[targetId], "expandRow", expandRow);
				
				for(let i = 0 ; i < expandArray.length ; i++)
				{
					let usedIndex = -1;
					this.surveyItemList.forEach((item, index)=>{
						if(expandArray[i] == item.cellName){
							usedIndex = index;
						}
					})

					// let usedIndex = this.usedQuestionNo.indexOf(this.tableData[expandArray[i]].field.questionNo);
					if(usedIndex >= 0) this.surveyItemList.splice(usedIndex, 1);
					
					this.$set(this.tableData[expandArray[i]], "isExpand", true);
					this.$set(this.tableData[expandArray[i]], "expandRoot", targetId);
					this.$set(this.tableData[expandArray[i]], "isText", false);
					this.$set(this.tableData[expandArray[i]], "text", "");
					this.$set(this.tableData[expandArray[i]], "field", {});
				}
			}
		},
		revertCell() {
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isRepeat)
					{
						this.$alert("Please release the repeat area.","Warning","warning");
						return false;
					}
					this.$set(this.tableData[keys[i]], "isExpand", false);
					this.$set(this.tableData[keys[i]], "expandCol", 1);
					this.$set(this.tableData[keys[i]], "expandRow", 1);
					this.$set(this.tableData[keys[i]], "expandRoot", "");
				}
			}
		},
		setHeader() {
			let keys = Object.keys(this.tableData);
			let isUnset = true;
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isRepeat)
					{
						this.$alert("Please release the repeat area.","Warning","warning");
						return false;
					}
					if(this.tableData[keys[i]].isHeader)
					{
						if(this.tableData[keys[i]].field.fieldList && ['7','8', '9', '11', '12', '13'].includes(this.tableData[keys[i]].field.fieldList[0].dataTypeCd))
						{
							this.$alert("Unable to release header.\nNot allowed for list type Fields.","Warning","warning");
						}
						else if(this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo) >= 0)
						{
							this.$alert("Unable to release header. Same field is in use.","Warning","warning");
						}
						else{
							isUnset = false;
							break;
						}
					}
				}
			}

			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						this.$set(this.tableData[rootId], "isHeader", isUnset);

						if(Object.keys(this.tableData[rootId].field).length !== 0)
						{
							if(isUnset)
							{
								let usedIndex = this.usedQuestionNo.indexOf(this.tableData[rootId].field.questionNo);
								if(usedIndex >= 0) this.surveyItemList.splice(usedIndex, 1);
							}
							else
							{
								let usedIndex = this.usedQuestionNo.indexOf(this.tableData[rootId].field.questionNo);
								if(usedIndex < 0) this.surveyItemList.push(this.tableData[rootId].field);
							}
						}
					}
					else
					{
						this.$set(this.tableData[keys[i]], "isHeader", isUnset);

						if(Object.keys(this.tableData[keys[i]].field).length !== 0)
						{
							if(isUnset)
							{
								let usedIndex = this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo);
								if(usedIndex >= 0) this.surveyItemList.splice(usedIndex, 1);
							}
							else
							{
								let usedIndex = this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo);
								if(usedIndex < 0) this.surveyItemList.push(this.tableData[keys[i]].field);
							}
						}
					}
				}
			}
		},
		setTyping() {
			this.deleteData();
			let keys = Object.keys(this.tableData);
			let isUnset = true;
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isRepeat)
					{
						this.$alert("Please release the repeat area.","Warning","warning");
						return false;
					}
					if(this.tableData[keys[i]].isText)
					{
						isUnset = false;
						break;
					}
				}
			}

			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						if(!this.tableData[rootId].isHeader && Object.keys(this.tableData[rootId].field).length !== 0)
						{
							let usedIndex = -1;
							this.surveyItemList.forEach((item, index)=>{
								if(rootId == item.cellName){
									usedIndex = index;
								}
							})
							// let usedIndex = this.usedQuestionNo.indexOf(this.tableData[rootId].field.questionNo);
							if(usedIndex >= 0) this.surveyItemList.splice(usedIndex, 1);
						}

						this.$set(this.tableData[rootId], "isText", isUnset);
						this.$set(this.tableData[rootId], "text", "");
						this.$set(this.tableData[rootId], "field", {});
					}
					else
					{
						if(!this.tableData[keys[i]].isHeader && Object.keys(this.tableData[keys[i]].field).length !== 0)
						{
							let usedIndex = -1;
							this.surveyItemList.forEach((item, index)=>{
								if(keys[i] == item.cellName){
									usedIndex = index;
								}
							})
							// let usedIndex = this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo);
							if(usedIndex >= 0) this.surveyItemList.splice(usedIndex, 1);
						}

						this.$set(this.tableData[keys[i]], "isText", isUnset);
						this.$set(this.tableData[keys[i]], "text", "");
						this.$set(this.tableData[keys[i]], "field", {});
					}
				}
			}
		},
		setTextEdit(event) {
			let getPath = this.utils.getEventPath(event);
			let nodePath = getPath.map((item) => {
				return item.nodeName;
			});
			let tdIndex = nodePath.indexOf("TD");
			let id = getPath[tdIndex].id + "_text";
			if(this.tableData[getPath[tdIndex].id].isText)
			{
				this.isTypingEdit = true;
				this.$nextTick(() => {
					document.getElementById(id).focus();
				});
			}
		},
		setAlign(direction) {
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						this.$set(this.tableData[rootId], "align", direction);
					}
					else
					{
						this.$set(this.tableData[keys[i]], "align", direction);
					}
				}
			}
		},
		setRepeat() {
			let keys = Object.keys(this.tableData);
			let minCol = 999;
			let maxCol = 0;
			let rootCount = [];
			let fieldCount = 0;
			this.getWidthHeight();
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					let rootId = keys[i].slice(0,1);
					let col = Number(keys[i].slice(0, 1).charCodeAt(0));
					// let row = Number(keys[i].slice(1));
					if(minCol > col)
					{
						minCol = col;
					}
					if(maxCol < col)
					{
						maxCol = col;	
					}
					rootCount.push(rootId);
					if(Object.keys(this.tableData[keys[i]].field).length > 0 && !this.tableData[keys[i]].isHeader){
						fieldCount += 1;
					}
				}
			}

			// let size = 0;
			let countResult = rootCount.reduce((accu,curr)=> {
				accu.set(curr, (accu.get(curr)||0) +1) ;
				return accu;
			},new Map());

			// for (let [key, value] of countResult.entries()) {
			// 	console.log(key + ' : ' + value);
			// 	size++;
			// }
			// console.log(size);

			let values = new Set(countResult.values());


			if((maxCol-minCol+1) != this.tableCol)
			{
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			if(values.size != 1) {
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			if(fieldCount == 0){
				this.$alert("The cell cannot be repeat\n doesn't have fields","Warning","warning");
				return false;
			}		
			let pageVo = {};
			this.pageList.forEach(item => {
				if(item.pageNo == this.items.pageNo){
					pageVo = item;
				}
			});
			if(pageVo.pageUsedReapetProcNo != this.$route.params.procNo && pageVo.pageUsedReapetProcNo != 0){
				this.$alert("The repeat function is already in use within that page!","Warning","warning");
				return false;
			}
			// 조건 충족시
			if((maxCol-minCol+1) == this.tableCol && values.size == 1)
			{
				let colArray = [];
				let rowArray = [];
				let repeatArray = [];
				for(let i = 0 ; i < keys.length ; i++)
				{
					if(this.tableData[keys[i]].isFocus)
					{
						if(this.tableData[keys[i]].isRepeat)
						{
							this.revertRepeat();
							return;
						}
						else
						{
							colArray.push(keys[i].slice(0, 1).charCodeAt(0));
							rowArray.push(Number(keys[i].slice(1)));
							repeatArray.push(keys[i]);
						}
					}
				}
				if(colArray.length > 1 && rowArray.length > 1 && this.repeatYn == "")
				{
					let minCol = Math.min(...colArray);
					let maxCol = Math.max(...colArray);
					let minRow = Math.min(...rowArray);
					let maxRow = Math.max(...rowArray);

					let targetId = String.fromCharCode(minCol) + minRow;
					let repeatCol = maxCol - minCol + 1;
					let repeatRow = maxRow - minRow + 1;

					this.$set(this.tableData[targetId], "repeatCol", repeatCol);
					this.$set(this.tableData[targetId], "repeatRow", repeatRow);
					
					for(let i = 0 ; i < repeatArray.length ; i++)
					{
						this.$set(this.tableData[repeatArray[i]], "isRepeat", true);
						this.$set(this.tableData[repeatArray[i]], "repeatRoot", targetId);
						this.repeatYn = targetId;
					}

				}
				
			}
		},
		revertRepeat() {
			let keys = Object.keys(this.tableData);
			let minRow = 0;
			let maxRow = 0;
			let countArr = [];
			let rootIdArr = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus && this.tableData[keys[i]].isRepeat)
				{
					let rootId = keys[i].slice(1, 10);
					countArr.push(rootId);
					
					rootIdArr.push(this.tableData[keys[i]].repeatRoot);
					this.$set(this.tableData[keys[i]], "isRepeat", false);
					this.$set(this.tableData[keys[i]], "repeatRoot", "");
					this.$set(this.tableData[keys[i]], "repeatCol", 1);
					this.$set(this.tableData[keys[i]], "repeatRow", 1);
				}
			}
			minRow = Math.min(...countArr);
			maxRow = Math.max(...countArr);
			if(minRow == maxRow)
			{
				document.getElementById(minRow).style.resize = "vertical";
			} 
			else
			{
				for(let i = minRow ; i <= maxRow; i++)

				{
					document.getElementById(i).style.resize = "vertical";
				}
			}
			this.repeatYn = "";
		},
		makeRepeatDiv(targetId){
			let keys = Object.keys(this.tableData);
			let minRow = 0;
			let maxRow = 0;
			let countArr = [];
			let style = {};

			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isRepeat && this.tableData[keys[i]].repeatRoot == targetId)
				{
					let rootId = keys[i].slice(1, 10);
					countArr.push(rootId);
				}
			}
			minRow = Math.min(...countArr);
			maxRow = Math.max(...countArr);
			// 세로

			let totalHeight = 0;
			if(minRow == maxRow)
			{
				this.$nextTick(() => {
					document.getElementById(minRow).style.resize="none";
				});
				totalHeight = Number(this.rowInfo[minRow].height.replace('px', ''));
			} 
			else
			{
				for(let i = minRow ; i <= maxRow; i++)
				{
					this.$nextTick(() => {
						document.getElementById(i).style.resize="none";
					});
					totalHeight += Number(this.rowInfo[i].height.replace('px', ''));
				}
			}

			// 가로
			let tableWidth = this.$refs.procedureForm.offsetWidth - 35;

			style.height = totalHeight + "px";
			style.width = (tableWidth) + "px";
			style.border = "2px solid #2bade0";
			style.backgroundColor = "transparent";
			style.position = "absolute";
			style.top = "0";
			style.zIndex = "20";

			return style;
		},
		addRow() {
			let keys = Object.keys(this.tableData);
			let minCol = 999;
			let maxCol = 0;
			let rootCount = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					let rootId = keys[i].slice(0,1);
					let col = Number(keys[i].slice(0, 1).charCodeAt(0));
					if(minCol > col)
					{
						minCol = col;
					}
					if(maxCol < col)
					{
						maxCol = col;	
					}
					rootCount.push(rootId);
				}
			}
			// let size = 0;
			let countResult = rootCount.reduce((accu,curr)=> {
				accu.set(curr, (accu.get(curr)||0) +1) ;
				return accu;
			},new Map());

			// for (let [key, value] of countResult.entries()) {
			// 	console.log(key + ' : ' + value);
			// 	size++;
			// }
			// console.log(size);

			let values = new Set(countResult.values());


			if((maxCol-minCol+1) != this.tableCol)
			{
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			if(values.size != 1) {
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			// 조건 충족시
			this.tableRow += 1;

			//선택한 행 값 가져오기
			let focusRow = "";
			let focusList = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					focusList.push(keys[i].slice(1));
				}
			}
			focusRow = focusList[0];

			//선택한 행 아래 모든 행 데이터 복사
			let repeatArr = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				let keysnum = parseInt(keys[i].slice(1));
				if(keysnum >= parseInt(focusRow))
				{
					repeatArr.push({cellName : keys[i], data : JSON.parse(JSON.stringify(this.tableData[keys[i]]))})
				}
			}

			//row height 데이터 복사
			let rowArr = [];
			for(let i = 0 ; i < Object.keys(this.rowInfo).length; i++){
				rowArr.push(JSON.parse(JSON.stringify(this.rowInfo[Object.keys(this.rowInfo)[i]])));
			}

			//복사한 데이터 정렬
			repeatArr.sort(function(a,b){
				if(a.row == b.row){
					var x = a.cellName.toLowerCase(), y = b.cellName.toLowerCase();
					return x < y ? -1 : x > y ? 1 : 0;
				}
				return a.row - b.row;
			});

			//복사한 데이터 한줄씩 미뤄서 삽입 및 추가된 행 데이터 제거
			this.$nextTick(function () {
				keys = Object.keys(this.tableData);

				let cellNameList = [];
				let subNameList = [];
				for(let i = 0 ; i < keys.length ; i++)
				{
					for(let j = 0; j < repeatArr.length; j++){
						if(keys[i] == repeatArr[j].cellName){
							let num = parseInt(repeatArr[j].cellName.slice(1)) + 1;
							let subName = keys[i].slice(0, 1) + num.toString();
							this.tableData[subName] = repeatArr[j].data;
							this.tableData[subName].cellName = subName;
							
							for(let t = 0; t < this.surveyItemList.length; t++){
								if(keys[i] == this.surveyItemList[t].cellName){
									cellNameList.push(keys[i]);
								}
							}

							if(this.tableData[subName].isExpand){
								let rootNum = parseInt(this.tableData[subName].expandRoot.slice(1)) + 1;
								this.tableData[subName].expandRoot = this.tableData[subName].expandRoot.slice(0, 1) + rootNum.toString();
							}
							if(this.tableData[subName].isRepeat){
								let reapetNum = parseInt(this.tableData[subName].repeatRoot.slice(1)) + 1;
								this.tableData[subName].repeatRoot = this.tableData[subName].repeatRoot.slice(0, 1) + reapetNum.toString();
							}
						}
					}
				}

				for(let t = 0; t < this.surveyItemList.length; t++){
					for(let k = 0; k < cellNameList.length; k++){
						if(cellNameList[k] == this.surveyItemList[t].cellName){
							subNameList.push(this.surveyItemList[t]);
						}
					}
				}
				for(let i = 0; i < subNameList.length; i++){
					let rowNum = parseInt(subNameList[i].cellName.slice(1)) + 1;
					subNameList[i].cellName = subNameList[i].cellName.slice(0, 1) + rowNum.toString();
				}

				for(let i = 0 ; i < keys.length ; i++)
				{
					if(keys[i].slice(1) == focusRow)
					{
						console.log(keys[i]);
						this.tableData[keys[i]].align = "left";
						this.tableData[keys[i]].expandCol = 1;
						this.tableData[keys[i]].expandRoot = "";
						this.tableData[keys[i]].expandRow = 1;
						this.tableData[keys[i]].field = {};
						this.tableData[keys[i]].isExpand = false;
						this.tableData[keys[i]].isFocus = false;
						this.tableData[keys[i]].isHeader = false;
						this.tableData[keys[i]].isRepeat = false;
						this.tableData[keys[i]].isText = false;
						this.tableData[keys[i]].repeatCol = 1;
						this.tableData[keys[i]].repeatRoot = "";
						this.tableData[keys[i]].repeatRow = 1;
						this.tableData[keys[i]].text = "";
					}
				}
				
				//row height 데이터 셋팅
				this.getWidthHeight();
				for(let i = 0 ; i < Object.keys(this.rowInfo).length; i++){
					if(parseInt(Object.keys(this.rowInfo)[i]) > parseInt(focusRow)){
						this.rowInfo[Object.keys(this.rowInfo)[i]] = rowArr[Object.keys(this.rowInfo)[i]-2];
					}
				}
				this.rowInfo[focusRow].height = "50px";
				this.rowInfo[focusRow].rowRepeat = "";
				this.setRowStyle();
			});
		},
		deleteRow() {
			this.deleteData();
			let keys = Object.keys(this.tableData);
			let minCol = 999;
			let maxCol = 0;
			let rootCount = [];
			this.getWidthHeight();
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{ 
					let rootId = keys[i].slice(0,1);
					let col = Number(keys[i].slice(0, 1).charCodeAt(0));
					if(minCol > col)
					{
						minCol = col;
					}
					if(maxCol < col)
					{
						maxCol = col;	
					}
					rootCount.push(rootId);
				}
			}
			// let size = 0;
			let countResult = rootCount.reduce((accu,curr)=> {
				accu.set(curr, (accu.get(curr)||0) +1) ;
				return accu;
			},new Map());

			// for (let [key, value] of countResult.entries()) {
			// 	console.log(key + ' : ' + value);
			// 	size++;
			// }
			// console.log(size);

			let values = new Set(countResult.values());


			if((maxCol-minCol+1) != this.tableCol)
			{
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			if(values.size != 1) {
				this.$alert("The cell cannot be repeat\n Specify the column as maximum length","Warning","warning");
				return false;
			}
			// 조건 충족시

			//선택한 행 값 및 길이 가져오기
			let focusRow = "";
			let focusList = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(focusList.indexOf(keys[i].slice(1)) < 0){
						focusList.push(keys[i].slice(1));
					}
				}
			}
			focusRow = focusList[focusList.length-1];

			//선택한 행 아래 모든 행 데이터 복사
			let repeatArr = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				let keysnum = parseInt(keys[i].slice(1));
				if(keysnum > parseInt(focusRow))
				{
					repeatArr.push({cellName : keys[i], data : JSON.parse(JSON.stringify(this.tableData[keys[i]]))})
				}
			}

			//row height 데이터 복사
			let rowArr = [];
			for(let i = 0 ; i < Object.keys(this.rowInfo).length; i++){
				rowArr.push(JSON.parse(JSON.stringify(this.rowInfo[Object.keys(this.rowInfo)[i]])));
			}

			//복사한 데이터 정렬
			if(repeatArr.length != 0){
				repeatArr.sort(function(a,b){
					if(a.row == b.row){
						var x = a.cellName.toLowerCase(), y = b.cellName.toLowerCase();
						return x < y ? -1 : x > y ? 1 : 0;
					}
					return a.row - b.row;
				});
			}

			//복사한 데이터 한줄씩 떙겨서 삽입 및 데이터 제거
			// this.deleteData();
			this.$nextTick(function () {
				keys = Object.keys(this.tableData);
				for(let i = 0 ; i < keys.length ; i++)
				{
					if(repeatArr.length != 0){
						for(let j = 0; j < repeatArr.length; j++){
							if(keys[i] == repeatArr[j].cellName){
								let num = parseInt(repeatArr[j].cellName.slice(1)) - focusList.length;
								let subName = keys[i].slice(0, 1) + num.toString();
								this.tableData[subName] = repeatArr[j].data;
								this.tableData[subName].cellName = subName;
								if(Object.keys(this.tableData[keys[i]].field).length != 0){
									this.tableData[subName].field.cellName = subName;
								}

								for(let t = 0; t < this.surveyItemList.length; t++){
									if(keys[i] == this.surveyItemList[t].cellName){
										this.surveyItemList[t].cellName = subName;
									}
								}

								if(this.tableData[subName].isExpand){
									let rootNum = parseInt(this.tableData[subName].expandRoot.slice(1)) - focusList.length;
									this.tableData[subName].expandRoot = this.tableData[subName].expandRoot.slice(0, 1) + rootNum.toString();
								}
								if(this.tableData[subName].isRepeat){
									let reapetNum = parseInt(this.tableData[subName].repeatRoot.slice(1)) - focusList.length;
									this.tableData[subName].repeatRoot = this.tableData[subName].repeatRoot.slice(0, 1) + reapetNum.toString();
								}
							}
						}
					}
				}

				let deleteList = [];
				for(let j = Object.keys(this.rowInfo).length; j > (Object.keys(this.rowInfo).length - focusList.length); j--){
					for(let i = 0; i < keys.length; i++){
						if(parseInt(keys[i].slice(1)) == j){
							deleteList.push(keys[i]);
						}
					}
				}
				
				for(let i = 0; i < deleteList.length; i++){
					this.$delete(this.tableData, deleteList[i]);
				}

				this.tableRow -=  focusList.length;

				//row height 데이터 셋팅
				this.getWidthHeight();
				for(let i = 0 ; i < Object.keys(this.rowInfo).length; i++){
					if(parseInt(Object.keys(this.rowInfo)[i]) > parseInt(focusRow)){
						this.rowInfo[Object.keys(this.rowInfo)[i]-focusList.length] = rowArr[Object.keys(this.rowInfo)[i]-1];
					}
				}

				let delCount = Object.keys(this.rowInfo).length - focusList.length;
				for(let j = Object.keys(this.rowInfo).length; j > delCount; j--){
					this.$delete(this.rowInfo, j);
				}
				this.$nextTick(function () {
					setTimeout(function() {
						this.setRowStyle();
					}.bind(this), 400);
				});
			});
		},
		deleteData() {
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					if(this.tableData[keys[i]].isRepeat)
					{
						this.$alert("Please release the repeat area.","Warning","warning");
						return false;
					}
					if(this.tableData[keys[i]].isExpand)
					{
						let rootId = this.tableData[keys[i]].expandRoot;
						
						let usedIndex = this.usedQuestionNo.indexOf(this.tableData[rootId].field.questionNo);
						if(usedIndex >= 0 && !this.tableData[rootId].isHeader)
						{
							let keyIndex = 0;
							this.surveyItemList.forEach((item, index) => {
								if(rootId == item.cellName){
									keyIndex = index;
								}
							})
							if(keyIndex >= 0){
								let dataTypeCd = this.surveyItemList[usedIndex].fieldList[0].dataTypeCd;
								if(['7','8', '9', '11', '12', '13'].includes(dataTypeCd)){
									this.listIndexReload(this.tableData[keys[i]].field);
								}
								this.surveyItemList.splice(keyIndex, 1);
								if(['7','8', '9', '11', '12', '13'].includes(dataTypeCd)){
									this.listSizeReload(this.tableData[keys[i]].field);
								}
							}
						}

						this.$set(this.tableData[rootId], "field", {});
						this.$set(this.tableData[rootId], "text", "");
					}
					else
					{
						let usedIndex = this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo);
						if(usedIndex >= 0 && !this.tableData[keys[i]].isHeader)
						{
							let keyIndex = 0;
							this.surveyItemList.forEach((item, index) => {
								if(keys[i] == item.cellName){
									keyIndex = index;
								}
							})
							if(keyIndex >= 0){
								let dataTypeCd = this.surveyItemList[usedIndex].fieldList[0].dataTypeCd;
								if(['7','8', '9', '11', '12', '13'].includes(dataTypeCd)){
									this.listIndexReload(this.tableData[keys[i]].field);
								}
								this.surveyItemList.splice(keyIndex, 1);
								if(['7','8', '9', '11', '12', '13'].includes(dataTypeCd)){
									this.listSizeReload(this.tableData[keys[i]].field);
								}
							}
						}

						this.$set(this.tableData[keys[i]], "field", {});
						this.$set(this.tableData[keys[i]], "text", "");
					}
				}
			}
		},
		deleteDefaultData(keys) {
			if(this.tableData[keys].isRepeat)
			{
				this.$alert("Please release the repeat area.","Warning","warning");
				return false;
			}
			if(this.tableData[keys].isExpand)
			{
				let rootId = this.tableData[keys].expandRoot;
				
				let usedIndex = this.usedQuestionNo.indexOf(this.tableData[rootId].field.questionNo);
				if(usedIndex >= 0 && !this.tableData[rootId].isHeader) this.surveyItemList.splice(usedIndex, 1);

				this.$set(this.tableData[rootId], "field", {});
				this.$set(this.tableData[rootId], "text", "");
			}
			else
			{
				let usedIndex = this.usedQuestionNo.indexOf(this.tableData[keys].field.questionNo);
				if(usedIndex >= 0 && !this.tableData[keys].isHeader) this.surveyItemList.splice(usedIndex, 1);

				this.$set(this.tableData[keys], "field", {});
				this.$set(this.tableData[keys], "text", "");
			}
		},
		pasteCell() {
			let insertField = JSON.parse(JSON.stringify(this.clipBoard));
			if(this.usedQuestionNo.indexOf(insertField.questionNo) >= 0 && !['7','8', '9', '11', '12', '13'].includes(insertField.fieldList[0].dataTypeCd))
			{
				this.$alert("Can't insert same Field.", "Warn", "warning");
				return ;
			}

			if(Object.keys(this.clipBoard).length !== 0)
			{
				let keys = Object.keys(this.tableData);
				var focusCnt = 0;
				for(let i = 0 ; i < keys.length ; i++)
				{	
					if(this.tableData[keys[i]].isFocus)
					{
						focusCnt += 1;
						if(focusCnt > 1) {
							this.$alert("Can't insert Field.\nNot allowed for list type Fields.", "Warn", "warning");
							return ;
						}
					}
				}
				for(let i = 0 ; i < keys.length ; i++)
				{
					if(this.tableData[keys[i]].isFocus)
					{
						if(this.usedQuestionNo.indexOf(this.tableData[keys[i]].field.questionNo) >= 0) {
							this.$alert("Can't insert Field.", "Warn", "warning");
							return ;
						}
						if(this.tableData[keys[i]].isExpand)
						{
							let rootId = this.tableData[keys[i]].expandRoot;
							// if(!this.tableData[rootId].isHeader && !this.tableData[rootId].isText && this.usedQuestionNo.indexOf(insertField.questionNo) < 0)
							// {
							// 	insertField.cellName = keys[i];
							// 	this.$set(this.tableData[rootId], "field", insertField);
							// 	this.surveyItemList.push(insertField);
							// }
							if(!this.tableData[rootId].isHeader && !this.tableData[rootId].isText)
							{
								this.defaultField = [];
								let defaultCount = 0;
								let listCount = 0;
								for(let i = 0; i < insertField.fieldList.length; i++)
								{
									// default Value 일 때
									if(insertField.fieldList[i].fieldTypeCd == '12')
									{
										defaultCount += 1;
										// let valueSplit = insertField.fieldList[i].defaultValue.split(";");
										// for(let j = 0; j < valueSplit.length; j++){
										// 	insertField.fieldList[i].defaultValueList.push(valueSplit[j]);
										// }
										this.defaultField.push(insertField.fieldList[i]);
									}
									// list date 일 때
									if(['7','8', '9', '11', '12', '13'].includes(insertField.fieldList[i].dataTypeCd))
									{
										listCount += 1;
									}
								}

								insertField.cellName = rootId;
								this.$set(this.tableData[rootId], "field", insertField);

								// default Value Popup
								if(defaultCount > 0 && !this.tableData[rootId].isHeader)
								{
									this.defaultCellId = rootId;
									this.isDefaultValue = true;
								}
								
								if(this.tableData[rootId].isHeader)
								{
									this.clipBoard = insertField;
								}
								else
								{
									this.surveyItemList.push(insertField);
									// this.clipBoard = {};
								}

								if(listCount > 0 && !this.tableData[rootId].isHeader)
								{
									this.listIndexSet(insertField);
								}
							}
							return ;
						}
						else
						{
							// if(!this.tableData[keys[i]].isHeader && !this.tableData[keys[i]].isText && this.usedQuestionNo.indexOf(insertField.questionNo) < 0)
							// {
							// 	insertField.cellName = keys[i];
							// 	this.$set(this.tableData[keys[i]], "field", insertField);
							// 	this.surveyItemList.push(insertField);
							// }
							if(!this.tableData[keys[i]].isHeader && !this.tableData[keys[i]].isText)
							{
								this.defaultField = [];
								let defaultCount = 0;
								let listCount = 0;
								for(let i = 0; i < insertField.fieldList.length; i++)
								{
									// default Value 일 때
									if(insertField.fieldList[i].fieldTypeCd == '12')
									{
										defaultCount += 1;
										// let valueSplit = insertField.fieldList[i].defaultValue.split(";");
										// for(let j = 0; j < valueSplit.length; j++){
										// 	insertField.fieldList[i].defaultValueList.push(valueSplit[j]);
										// }
										this.defaultField.push(insertField.fieldList[i]);
									}
									// list date 일 때
									if(['7','8', '9', '11', '12', '13'].includes(insertField.fieldList[i].dataTypeCd))
									{
										listCount += 1;
									}
								}

								insertField.cellName = keys[i];
								this.$set(this.tableData[keys[i]], "field", insertField);

								// default Value Popup
								if(defaultCount > 0 && !this.tableData[keys[i]].isHeader)
								{
									this.defaultCellId = keys[i];
									this.isDefaultValue = true;
								}
								
								if(this.tableData[keys[i]].isHeader)
								{
									this.clipBoard = insertField;
								}
								else
								{
									this.surveyItemList.push(insertField);
									// this.clipBoard = {};
								}

								if(listCount > 0 && !this.tableData[keys[i]].isHeader)
								{
									this.listIndexSet(insertField);
								}
							}
						}
					}
				}
			}
		},
		keyMap(event) {
			let isFocusing = false;
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isFocus)
				{
					isFocusing = true;
					break;
				}
			}
			console.log(event)
			if(isFocusing)
			{
				if(event.key == 'm' || event.key == 'M') this.mergeCell();
				else if(event.key == 'r' || event.key == 'R') this.revertCell();
				else if(event.key == 'h' || event.key == 'H') this.setHeader();
				else if(event.key == 't' || event.key == 'T') this.setTyping();
				else if(event.key == 'p' || event.key == 'P') this.setRepeat();
				else if(event.key == 'Backspace' || event.key == 'Delete') this.deleteData();
				else if(event.key == 'Enter' || event.code == 'Space') this.pasteCell();
				else if(event.key == 'a' || event.code == 'A') this.addRow();
				else if(event.key == 'd' || event.code == 'D') this.deleteRow();
			}
		},
		dragstart(event, questionItem) {
			this.logger.debug(this, "dragstart()");
			var positionX = event.target.getBoundingClientRect().left + window.pageXOffset;
			var positionY = event.target.getBoundingClientRect().top + window.pageYOffset;

			this.dragData.position.top = positionY + "px";
			this.dragData.position.left = positionX + "px";
			this.dragData.position.width = event.target.offsetWidth + "px";

			this.dragData.offsetX = event.offsetX;
			this.dragData.offsetY = event.offsetY;

			this.dragData.fieldData = questionItem;
			this.dragData.isDrag = true;
		},
		dragging(event) {
			if (this.dragData.isDrag) {
				this.dragData.position.top = event.clientY - this.dragData.offsetY + "px";
				this.dragData.position.left = event.clientX - this.dragData.offsetX + "px";
			}
		},
		draggend() {
			if (this.dragData.isDrag) {
				this.logger.debug(this, "draggend()");

				var getId = this.preview.cellId;
				if(this.preview.isPreview && !this.tableData[getId].isText)
				{
					var insertField = JSON.parse(JSON.stringify(this.dragData.fieldData));
					this.$set(insertField, "cellName", getId);
					if(this.usedQuestionNo.indexOf(insertField.questionNo) >= 0 && !this.tableData[getId].isHeader && !['7','8', '9', '11', '12', '13'].includes(insertField.fieldList[0].dataTypeCd))
					{
						this.$alert("Can't insert same Field.", "Warn", "warning");
					}
					else
					{
						if(this.usedQuestionNo.indexOf(this.tableData[getId].field.questionNo) >= 0 && !this.tableData[getId].isHeader){
							this.$alert("Can't insert Field.", "Warn", "warning");	
						}
						else{
							this.defaultField = [];
							let defaultCount = 0;
							let listCount = 0;
							for(let i = 0; i < insertField.fieldList.length; i++)
							{
								// default Value 일 때
								if(insertField.fieldList[i].fieldTypeCd == '12')
								{
									defaultCount += 1;
									let valueSplit = insertField.fieldList[i].defaultValue.split(";");
									for(let j = 0; j < valueSplit.length; j++){
										insertField.fieldList[i].defaultValueList.push(valueSplit[j]);
									}
									this.defaultField.push(insertField.fieldList[i]);
								}
								// list date 일 때
								if(['7','8', '9', '11', '12', '13'].includes(insertField.fieldList[i].dataTypeCd))
								{
									listCount += 1;
								}
							}

							this.$set(this.tableData[getId], "field", insertField);
	
							// default Value Popup
							if(defaultCount > 0 && !this.tableData[getId].isHeader)
							{
								this.defaultCellId = getId;
								this.isDefaultValue = true;
							}

							if(this.tableData[getId].isHeader)
							{
								this.clipBoard = insertField;
							}
							else
							{
								this.surveyItemList.push(insertField);
								this.clipBoard = {};
							}

							if(listCount > 0 && !this.tableData[getId].isHeader)
							{
								this.listIndexSet(insertField);
							}
						}
					}
				}
				// DragData 초기화
				this.dragData = {
					isDrag: false,
					position: {
						top: "",
						left: "",
					},
					mouseX: "",
					offsetY: "",
					fieldData: {}
				};
				// preview 초기화
				this.hidePreview();
			}
		},
		listIndexSet(field){
			this.logger.debug(this, "listIndexSet()");
			let totalSize = 0;
			for(let i = 0; i < this.surveyItemList.length; i++){
				if(this.surveyItemList[i].questionNo == field.questionNo){
					for(let j = 0; j < this.surveyItemList[i].fieldList.length; j++){
						if(this.surveyItemList[i].fieldList[j].dataTypeCd == field.fieldList[0].dataTypeCd && this.surveyItemList[i].fieldList[j].fieldNo == field.fieldList[0].fieldNo){
							totalSize += 1;
						}
					}
				}
			}
			for(let i = 0; i < this.surveyItemList.length; i++){
				if(this.surveyItemList[i].questionNo == field.questionNo){
					for(let j = 0; j < this.surveyItemList[i].fieldList.length; j++){
						if(this.surveyItemList[i].fieldList[j].dataTypeCd == field.fieldList[0].dataTypeCd && this.surveyItemList[i].fieldList[j].fieldNo == field.fieldList[0].fieldNo){
							this.$set(this.surveyItemList[i], "listSize", totalSize);
						}
					}
				}
			}
			this.$set(field, "listIndex", totalSize);
		},
		listIndexReload(field){
			this.logger.debug(this, "listIndexReload()");
			for(let i = 0; i < this.surveyItemList.length; i++){
				if(this.surveyItemList[i].questionNo == field.questionNo){
					for(let j = 0; j < this.surveyItemList[i].fieldList.length; j++){
						if(this.surveyItemList[i].fieldList[j].dataTypeCd == field.fieldList[0].dataTypeCd && this.surveyItemList[i].fieldList[j].fieldNo == field.fieldList[0].fieldNo){
							if(this.surveyItemList[i].listIndex > field.listIndex){
								let listIndex = JSON.parse(JSON.stringify(this.surveyItemList[i].listIndex))-1;
								this.surveyItemList[i].listIndex = listIndex;
								this.tableData[this.surveyItemList[i].cellName].field.listIndex = listIndex;
							}
						}
					}
				}
			}
		},
		listSizeReload(field){
			this.logger.debug(this, "lisSizeReload()");
			let totalSize = 0;
			for(let i = 0; i < this.surveyItemList.length; i++){
				if(this.surveyItemList[i].questionNo == field.questionNo){
					for(let j = 0; j < this.surveyItemList[i].fieldList.length; j++){
						if(this.surveyItemList[i].fieldList[j].dataTypeCd == field.fieldList[0].dataTypeCd && this.surveyItemList[i].fieldList[j].fieldNo == field.fieldList[0].fieldNo){
							totalSize += 1;
						}
					}
				}
			}
			for(let i = 0; i < this.surveyItemList.length; i++){
				if(this.surveyItemList[i].questionNo == field.questionNo){
					for(let j = 0; j < this.surveyItemList[i].fieldList.length; j++){
						if(this.surveyItemList[i].fieldList[j].dataTypeCd == field.fieldList[0].dataTypeCd && this.surveyItemList[i].fieldList[j].fieldNo == field.fieldList[0].fieldNo){
							this.surveyItemList[i].listSize = totalSize;
						}
					}
				}
			}
		},
		setDefaultField(field, cellId){
			for(let i = 0; i < this.surveyItemList.length; i++){
				for(let j = 0; j < field.length; j++){
					if(this.surveyItemList[i].questionNo == field[j].questionNo && this.tableData[cellId].field.listIndex == this.surveyItemList[i].listIndex)
					{
						for(let l = 0; l < this.surveyItemList[i].fieldList.length; l++){
							if(this.surveyItemList[i].fieldList[l].fieldNo == field[j].fieldNo)
							{
								this.surveyItemList[i].fieldList[l].selectDefault = field[j].selectDefault;
							}
						}
					}
				}
			}
		},
		showPreview(id) {
			this.preview.isPreview = true;
			this.preview.cellId = id;
		},
		hidePreview() {
			this.preview.isPreview = false;
			this.preview.cellId = "";
		},
		getFieldList(id) {
			if(this.preview.isPreview && this.preview.cellId == id)
			{
				return this.dragData.fieldData.fieldList;
			}
			else if(Object.keys(this.tableData[id].field).length !== 0)
			{
				return this.tableData[id].field.fieldList;
			}
			else
			{
				return [];
			}
		},
		getMargin(field) {
			var prefixName = field.prefixName;
			var suffixName = field.suffixName;
			var fieldLayout = field.fieldLayoutCd;

			var topMargin = 0;
			var bottomMargin = 0;
			var leftMargin = 0;
			var rightMargin = 0;

			if (fieldLayout == "1")
			{
				if (prefixName != null && prefixName != "") topMargin = 5;
				if (suffixName != null && suffixName != "") bottomMargin = 5;
			}
			else
			{
				if (prefixName != null && prefixName != "") leftMargin = 10;
				if (suffixName != null && suffixName != "") rightMargin = 10;
			}

			return {
				marginTop: topMargin + "px",
				marginBottom: bottomMargin + "px",
				marginLeft: leftMargin + "px",
				marginRight: rightMargin + "px",
			};
		},
		getGridRowCol(length, cols) {
			var value = parseInt(Number(length) / Number(cols));
			var etc = Number(length) % Number(cols);

			var row = 0;
			var col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(36px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		loadedImage(field) {
			this.$set(field, 'isLoad', true);
		},
		getWidthHeight() {
			let keys = Object.keys(this.tableData);
			let cols = document.getElementsByClassName("th-col");
			let totalWidth = 0;
			cols.forEach((element) => {
				totalWidth = totalWidth + element.offsetWidth;
			});
			cols.forEach((element) => {
				let columnId = element.dataset.id;
				let percentWidth = Math.round((element.offsetWidth / totalWidth) * 100) + "%";
				this.$set(this.columnInfo, columnId, {width: percentWidth});
			});

			let rootArr = [];
			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableData[keys[i]].isRepeat){
					rootArr.push(this.tableData[keys[i]].repeatRoot);
				}
			}
			let setArr = new Set(rootArr);
			let rowRepeat = [...setArr];

			let rows = document.getElementsByClassName("th-row");
			rows.forEach((element) => {
				let rowId = element.dataset.id;
				console.log("rowId : " + rowId);
				let pixelHeight = element.offsetHeight + "px";
				this.$set(this.rowInfo, rowId, {height: pixelHeight, rowRepeat: ""});
				for(let i=0; i < rowRepeat.length; i++){
					if(rowRepeat[i].slice(1) == rowId){
						this.$set(this.rowInfo, rowId, {height: pixelHeight, rowRepeat: rowRepeat[i]});
					}
				}
			});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if(result && !this.isDuplicate)
					{
						//제거된 questionNo 검사
						this.deleteQuestionNo = [];
						for(let j = 0; j < this.firstQuestionNo.length; j++){
							if(!this.usedQuestionNo.includes(this.firstQuestionNo[j])){
								this.deleteQuestionNo.push(this.firstQuestionNo[j]);
							}
						}
						// Calculation 조건 입력했는지 검사 해야함
						this.isSavedValid().then((isSavedValid) => {
							if(isSavedValid)
							{
								this.$alert("The procedure cannot be modified\n for which validations have already been registered.","Warning","warning");
								return false;
							}
							else{
								this.isSavedCalc().then((isSavedCalc) => {
									if(isSavedCalc)
									{
										this.$alert("The procedure cannot be modified\n for which calculations have already been registered.","Warning","warning");
										return false;
									}
									else{
										this.isUserTestInput().then((isUserTestInput) => {
											if(isUserTestInput)
											{
												this.$alert("The procedure cannot be modified\n for which data has already been entered.","Warning","warning");
												return false;
											}
											else{
												this.isUserInput().then((isUserInput) => {
													if(this.surveyItemList.length < 1)
													{
														this.$alert("At least 1 field must be included!","Warning","warning");
														return false;
													}
													else if(isUserInput)
													{
														this.$alert("The procedure cannot be modified\n for which data has already been entered.","Warning","warning");
														return false;
													}
													else
													{
														this.$confirm("Do you want to register the current procedure?","Question","question").then(() => {
															EventBus.$emit("project-loading", true);
															this.doUpdate();
														});
													}
												});
											}
										});
									}
								});
							}
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");

			this.surveyItemList = [];
			let keys = Object.keys(this.tableData);
			for(let i = 0 ; i < keys.length ; i++)
			{	
				if(!this.tableData[keys[i]].isHeader){
					console.log(Object.keys(this.tableData[keys[i]].field).length);
					if(Object.keys(this.tableData[keys[i]].field).length != 0){
						this.surveyItemList.push(this.tableData[keys[i]].field);
					}
				}
			}

			// Sort Order 입력
			for(let i = 0 ; i < this.surveyItemList.length ; i++)
			{
				this.surveyItemList[i].procQuestionSortOrder = i + 1;
				for(let j = 0 ; j < this.surveyItemList[i].fieldList.length ; j++)
				{
					this.surveyItemList[i].fieldList[j].fieldSortOrder = j + 1;
				}
			}

			// width, height set
			this.getWidthHeight();

			// update 데이터 전송
			this.items.procQuestionList = this.surveyItemList;
			this.items.tableCol = this.tableCol;
			this.items.tableRow = this.tableRow;

			axios
				.post(this.config.contextRoot + "/procedure/update.do", this.items)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						let sendData = {
							procedureNo: response.data.procedureNo,
							columnInfo: this.columnInfo,
							rowInfo: this.rowInfo,
							tableData: this.tableData
						};
						axios
							.post(this.config.contextRoot + "/procedure/updateTable.do", sendData)
							.then((response) => {
								if (response.status == 200)
								{
									console.log(response);
									// var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/procedure";
									var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/page";
									if (this.$route.path !== url)
									{
										EventBus.$emit("page-move", this.$route.query.current_page);
										this.$router.push(url);
									}
								}
								else
								{
									alert(response);
									console.log(response);
								}
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the procedure modification?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.procedureEdit.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				// let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/procedure"
				let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/page"
				if (this.$route.path !== url)
				{
					EventBus.$emit("project-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 410);
		},
		async isUserInput() {
			this.logger.debug(this, "isUserInput()");
			const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertScreening.do", {pageNo: this.items.pageNo})
			if(Number(response.data.count > 0))
			{
				return true;
			}
			else
			{
				return false;
			}
		},
		async isUserTestInput() {
			this.logger.debug(this, "isUserInput()");
			const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertTestScreening.do", {pageNo: this.items.pageNo})
			if(Number(response.data.count > 0))
			{
				return true;
			}
			else
			{
				return false;
			}
		},
		async isSavedCalc() {
			this.logger.debug(this, "isCalcExists()");
			const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertCalculation.do", {pageNo: this.items.pageNo})
			if(Number(response.data.count > 0))
			{
				return true;
			}
			else
			{
				return false;
			}
		},
		async isSavedValid() {
			this.logger.debug(this, "isValidExists()");
			this.$set(this.items, "deleteQuestionNo", this.deleteQuestionNo);
			const response = await axios.post(this.config.contextRoot + "/procedure/checkInsertValidation.do", this.items)
			if(Number(response.data.count > 0))
			{
				return true;
			}
			else
			{
				return false;
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>

<style scoped>
.survey-contents-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: auto;
    row-gap: 0;
    position: absolute;
    top: 171px;
    left: calc(19% + 155px);
    width: calc(81% - 260px);
    height: calc(100% - 171px);
    padding: 10px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 50;
} 

.control-box {
	display: flex;
	align-items: center;
	width: 100%;
	height: 35px;
	padding: 0 0 0 34px;
	background-color: #eee;
}

.table-set > input[type=number] {
	width: calc(50% - 15px);
}

.table-set > span {
	width: 30px;
	text-align: center;
}

.procedure-table {
	width: 100%;
	border-collapse: collapse;
}

.procedure-table:focus {
	outline: none;
}

.procedure-table tr {
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead th {
	position: relative;
	min-width: 30px;
	height: 35px;
	overflow-x: auto;
	resize: horizontal;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead tr th:first-child {
	min-width: 35px;
	max-width: 35px;
	resize: none;
}

.procedure-table tbody th {
	width: 35px;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table th {
	border: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10pt;
	color: #000;
}

.procedure-table tbody th {
	position: relative;
	height: 50px;
	min-height: 50px;
	overflow-y: auto;
	resize: vertical;
}

.procedure-table td {
	position: relative;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	word-break: keep-all;
}

.procedure-table td > input[type=text] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	border: none;
	background-color: unset;
	font-weight: inherit;
	font-size: inherit;
	text-align: inherit;
}

.procedure-table .focus-celltype {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	font-family: cursive;
	font-weight: 500;
	font-size: 9pt;
	text-align: right;
	overflow: hidden;
	z-index: 5;
	opacity: 0.6;
}

.procedure-table .focus-celltype .celltype-repeat {
	display: inline-block;
	margin: 0 5px 0 0;
	padding: 2px 5px;
	background-color: #fc9e12;
	border-radius: 3px;
	color: rgb(255, 255, 255);
}

.procedure-table .focus-celltype .celltype-header {
	display: inline-block;
	margin: 0 5px 0 0;
	padding: 2px 5px;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-text {
	display: inline-block;
	padding: 2px 5px;
	background-color: #9ecc5a;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-data {
	display: inline-block;
	padding: 2px 5px;
	background-color: #cc5a5a;
	border-radius: 3px;
	color: #fff;
}
.procedure-table .focus-celltype .listIndex-data {
	display: inline-block;
	padding: 2px 5px;
	background-color: #8808af;
	margin-left: 3px;
	border-radius: 3px;
	color: #fff;
}

.unfocus-text,
.table-head {
	display: flex;
	justify-content: inherit;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
	z-index: 15;
}

.table-data {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
	z-index: 1;
}

.survey-field-list {
	width: 100%;
	font-weight: 600 !important;
	font-size: 10pt !important;
	color: #666;
	overflow-y: visible;
}

.survey-field-vtype,
.survey-field-htype {
    max-width: 100%;
	font-weight: 400 !important;
}

.input-crud-error::placeholder {
	color: rgb(224, 91, 91);
}
.date-picker{
	position: relative;
}
</style>import { includes } from "core-js/core/array";
