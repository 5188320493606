<template>
	<div ref="ScreeningEntryCreate" class="dialog-container">
		<div class="dialog" style="max-width: 400px; max-height: 250px; transform: translate(0, -400px);">
			<form>
				<!-- 팝업창 상단 제목 -->
				<div class="top">
					<p class="txt-crud-title">
						Entry Create
					</p>
				</div>
				<!-- 팝업창 내용 -->
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="40%" />
							<col width="auto" />
						</colgroup>
						<tbody>
							<tr>
								<th>Head Count</th>
								<td>
									<label
										class="radio-crud"
										v-for="(count, index) in headCountList" :key="index"
									>
										<input
											type="radio"
											:value="count"
											v-model="headCount"
											disabled
										/><div class="radio" />
										{{ count }}
									</label>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doSave()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ScreeningEntryCreate",
	created() {
		this.logger.debug(this, "created()");
		this.selectProjectHeadCount();
	},
	props: {
		param: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			items: {},
			visitScreeningUserInputList: [],
			headCount: "",
			headCountList: ["2", "3", "4"]
		};
	},
	methods: {
		doSave() {
			this.logger.debug(this, "doSave()");
			this.$confirm("ㄱㄱ?","Question","question").then(() => {
				this.doInsert();
			});
		},
		doInsert() {
			this.logger.debug(this, "insertEntry()");
			axios
				.post(this.config.contextRoot + "/screening/insertEntry.do", {
					visitScreeningUserInputCount: this.headCount
				})
				.then((response) => {
					if (response.status == 200) {
						return;
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProjectHeadCount() {
			this.logger.debug(this, "selectProjectHeadCount()");

			axios
				.post(this.config.contextRoot + "/screening/selectProjectHeadCount.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.headCount = response.data.headCount;
                    console.log("🔥 ~ this.headCount", this.headCount)
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the data input?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ScreeningEntryCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ScreeningEntryCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("entry-create-cancel", "isEntryCreate");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.center > div:first-child {
	float:left;
}
.center > div:last-child {
	float:right;
}

</style>