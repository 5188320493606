<template>
	<div ref="ProjectCopy" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Project New Version
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
						</colgroup>
						<tbody>
                            <tr>
                                <th>Origin Project.</th>
                                <td>
                                    <input 
                                        type="text"
                                        class="input-crud"
										v-model="projectInfo.projectStudyTitle"
                                        disabled
                                        >
                                </td>
                            </tr>
							<tr>
                                <th>Target Protocol.</th>
                                <td>
                                    <input 
                                        type="text"
                                        class="input-crud"
										name="protocolName"
										v-model="projectInfo.protocolName"
										v-validate="'required'"
										data-vv-as="Protocol"
										@input="projectInfo.protocolName = $event.target.value"
										@keyup="doDuplicateCheck()"
                                        >
										<p class="txt-validation" v-if="errors.has('protocolName')">
											* {{ errors.first("protocolName") }}
										</p>
										<p class="txt-validation" v-if="isDuplicate">
											* Protocol is duplicated.
										</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Target Project.</th>
                                <td>
                                    <input 
                                        type="text"
                                        class="input-crud"
										name="targetProject"
										v-validate="'required'"
										data-vv-as="Target Project"
										v-model="item.projectStudyTitle"
                                        >
									<p class="txt-validation" v-if="errors.has('targetProject')">
										* {{ errors.first("targetProject") }}
									</p>
                                </td>
                            </tr>
							<!-- <tr>
								<th>Validation Dependent.</th>
								<td>
									<label
									class="radio-crud"
									>
										<input
											type="radio"
											name="ExcludeFlag"
											v-model="item.copyType"
											:value="false"
											v-validate="'required'"
											data-vv-as="Entry Unit"
										/><div class="radio" />
										Exclude
									</label>
                                    <label
										class="radio-crud"
										v-if="vaildCheck == 0"
									>
										<input
											type="radio"
											name="ExcludeFlag"
											v-model="item.copyType"
											:value="true"
											v-validate="'required'"
											data-vv-as="Entry Unit"
										/><div class="radio" />
										Include
									</label>
									<label v-else></label>
								</td>
							</tr> -->
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectCopy",
	created() {
		this.logger.debug(this, "created()");
		this.selectProject();
    },
    props: {
        projectNo: {
			type: String,
			required: true
		}
    },
	data() {
		return {
			item: {
				copyType:false,
			},
            projectInfo: {},
			isDuplicate: false,
			vaildCheck: 0,
			projectVo: {
				projectNo: '',
				protocolName: '',
				projectStudyTitle: ''
			}
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to create a new version?", "Question", "question").then(() => {
							EventBus.$emit("field-loading", true);
							this.doCopy();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		selectProject() {
			this.logger.debug(this, "selectProject()");

			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.projectNo})
				.then((response) => {
					console.log(response);
                    this.projectInfo = response.data.item;
					this.item.projectStudyTitle = this.projectInfo.projectStudyTitle;
					this.item.projectNo = this.projectInfo.projectNo;
					this.projectInfo.protocolName = "";
					this.vaildCheck = response.data.vaildCount;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doCopy() {
			EventBus.$emit("project-loading", true);
			this.projectVo = {
				projectNo: this.projectNo,
				projectStudyTitle: this.item.projectStudyTitle,
				protocolName: this.projectInfo.protocolName
			}

			axios
				.post(this.config.contextRoot + "/project/projectVersion.do", this.projectVo)
				.then((response) => {
					console.log(response);

					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("project-loading", false);
						}.bind(this), 400);
					});

					this.$alert("The new version has been successfully created.", "Info", "info").then(() => {
						location.reload();
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the site registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ProjectCopy.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ProjectCopy.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isCopy");
			}.bind(this), 410);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/project/duplicateCheck.do", {protocolName: this.projectInfo.protocolName})
				.then((response) => {
					console.log(response);
					if(response.data.projectExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>