<template>
	<div ref="QueryValidView" class="dialog-container">
		<div ref="dialog" class="dialog" style="max-height: 800px; transform: translate(0, -400px)">
			<form>
				<!-- 팝업창 상단 제목 -->
				<div class="top">
					<!-- <button type="button" class="btn-cancel fright btn-history" title="History" @click="openStatusHistory()">Status History</button>
					<button type="button" class="btn-cancel fright btn-history" title="History" @click="openHistory()">Data History</button> -->
					<!-- <button type="button" class="btn-cancel fright btn-history" title="History">History</button> -->
					<p class="txt-crud-title">
						VIEW
					</p>
				</div>
				<!-- 팝업창 내용 -->
				<div class="center">
					<!-- 스크리닝 정보 -->
					<div class="dinput-info">
						<div class="item">
							<p class="title">Subject Number</p>
							<p class="content">
								{{ selectViewSubject }}
							</p>
						</div>
						<div class="item">
							<p class="title">Page Name</p>
							<p class="content">
								{{ selectViewPageName }}
							</p>
						</div>
						<div class="item">
							<p class="title">Register</p>
							<p class="content">
								{{ items.lastModifyUserId ? items.lastModifyUserId : "-"  }}
							</p>
						</div>
						<div class="item">
							<p class="title">Regist Date</p>
							<p class="content">
								{{ items.lastModifyDate ? items.lastModifyDate : "-" }}
							</p>
						</div>
					</div>
					<div class="valid-event-sub">
						<div class="valid-event-part">
							<div class="open-query" style="cursor: auto;">1</div>
							<span>Open Query</span>
						</div>
						<div class="valid-event-part">
							<div class="valid-goto" style="cursor: auto;"></div>
							<span>Page Link</span>
						</div>
						<div class="valid-event-part">
							<div class="valid-msg" style="cursor: auto;"></div>
							<span>Message</span>
						</div>
					</div>
					<div class="screening-contents-container">
						<table
							v-for="(table, index) in surveyItemList"
							:key="index"
							class="procedure-table"
							tabindex="0"
						>
							<colgroup>
								<col
									v-for="col in Number(table.tableCol)"
									:key="col"
									:width="setColumnStyle(index, String.fromCharCode(64 + col))"
								/>
							</colgroup>
							<thead>
								<tr>
									<th 
										:colspan="Number(table.tableCol + 1)"
										class="th-col"
									>
										{{ table.procName }}
									</th> 
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="row in Number(table.tableRow)"
									:key="row"
									:style="setRowStyle(index, row, table)"
								>
									<td
										v-for="col in Number(table.tableCol)"
										:key="col"
										:id="'td_' + index + '_' + (String.fromCharCode(64 + col) + row)"
										class="th-col"
										:data-id="String.fromCharCode(64 + col)"
										:colspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandCol"
										:rowspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandRow"
										:style="cellStyle(index, String.fromCharCode(64 + col)+ row)"
									>
										<!-- repeat button -->
										<!-- <div
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot == (String.fromCharCode(64 + col) + row)"
											:id="'repeat-zone-'+(String.fromCharCode(64 + col) + row)+'-'+table.procSortOrder"
											:style="makeRepeatDiv((String.fromCharCode(64 + col) + row), table.procSortOrder)"
										>
										</div> -->
										<button
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot == tableDataList[index][String.fromCharCode(64 + col) + row].cellName
													&& !tableDataList[index][String.fromCharCode(64 + col) + row].isCopy"
											class="repeat-Btn"
											type="button"
											@click="repeat(tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot, table.procSortOrder)"
										>
											+
										</button>
										<button
											v-else-if="tableDataList[index][String.fromCharCode(64 + col) + row].isCopy && col==1 
											&& tableDataList[index][tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot].repeatRow == tableDataList[index][String.fromCharCode(64 + col) + row].repeatRow
											&& tableDataList[index][tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot].repeatCol == tableDataList[index][String.fromCharCode(64 + col) + row].repeatCol"
											class="repeat-Btn"
											style="backgroundColor: #dd574f;"
											type="button"
											@click="repeatDelete(tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot, tableDataList[index][String.fromCharCode(64 + col) + row].cellName, table.procSortOrder)"
										>
											-
										</button>

										<!-- cell Text -->
										<div
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											class="unfocus-text"
										>
											{{ tableDataList[index][String.fromCharCode(64 + col) + row].text }}
										</div>
										<!-- cell Data -->
										<div
											v-if="!tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											:id="'tableHD_' + index + '_' + (String.fromCharCode(64 + col) + row)"
											:class="
												tableDataList[index][String.fromCharCode(64 + col) + row].isHeader ?
												'table-head' :
												'table-data'
											"
										>
											<!-- cell Header -->
											<template
												v-if="
													tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													)
												"
											>
												
												{{ tableDataList[index][String.fromCharCode(64 + col) + row].field.questionName }}
												
											</template>
											<!-- cell Field -->
											<template
												v-if="
													(!tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													))
												"
											>
												<ul style="display: block; width: 100%;">
													<li
														v-for="(field, fieldIndex) in getFieldList(index, String.fromCharCode(64 + col) + row)"
														:key="fieldIndex"
														:id="'surveyFieldList_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex"
														class="survey-field-list"
														:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'hide'? 'hidden' : ''"
													>
														{{ field.prefixName }}
														<div class="valid-event-zone">
															<!-- Open Query -->
															<template v-if="field.queryConfirmList.length != 0">
																<div 
																	class="open-query blinking"
																	:title="field.queryConfirmList.length + ' OPEN QUERY'"
																>
																	{{field.queryConfirmList.length}}
																</div>
															</template>
															<!-- gotoPage -->
															<div
																v-for="(gotoVo, gotoIndex) in validAction('GOTO', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd)"
																:key="'goto'+gotoIndex"
																class="valid-goto blinking"
																:title="gotoVo.printMsg"
															>
															</div>
															<!-- dynamincPage -->
															<div
																v-for="(dynamicVo, dynamicIndex) in validAction('DYNAMIC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd)"
																:key="'dynamic'+dynamicIndex"
																class="valid-goto blinking"
																:title="dynamicVo.printMsg"
															>
															</div>
															<!-- printMsg -->
															<div
																v-if="validAction('MSG', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd).length > 0"
																class="valid-msg"
															>
																<div v-if="field.openMsgYn == 'Y'" class="entry-balloon"
																	@mouseenter="msgHover($event, 'over')"
																	@mouseleave="msgHover($event, 'leave')"
																>
																	<p>
																		<span
																			v-for="(msgVo, msgIndex) in validAction('MSG', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd)"
																			:key="'msg'+msgIndex"
																			:title="msgVo.printMsg"
																		>
																			#{{msgVo.printMsg }}
																		</span>
																	</p>
																</div>
															</div>
														</div>
														<div
															:class="field.fieldLayoutCd == '1' ? 'survey-field-vtype' : 'survey-field-htype'"
															:style="getMargin(field)"
														>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '01'">
																<input
																	type='text'
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	:name="'procFieldNo1' + field.fieldNo + row + col"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo1' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex) ? '' : 'input-crud-error')"
																	:disabled="true"
																	@input="validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex)"
																	@change="doValidCalc(true)"
																/>
															</template>
															<!-- TextArea type -->
															<template v-if="field.fieldTypeCd == '02'">
																<textarea
																	:rows="field.textareaRows"
																	:cols="field.textareaCols"
																	:name="'procFieldNo2' + field.fieldNo + row + col"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	style="max-width: 100%; width: 41rem;"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo2' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex) ? '' : 'input-crud-error')"
																	:disabled="true"
																	@input="validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex)"
																	@change="doValidCalc(true)"
																/>
															</template>
															<!-- Radio type -->
															<template v-if="field.fieldTypeCd == '03'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.radioOptionsLabel.split(';').length, field.radioCols)"
																>
																	<label
																		v-for="(text, radioIndex) in field.radioOptionsLabel.split(';')"
																		:key="radioIndex"
																		@dblclick="resetRadio(index, (String.fromCharCode(64 + col) + row), fieldIndex, validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd), entryStatus)"
																	>
																		<input
																			type="radio"
																			:name="'procFieldNo3' + field.fieldNo + row + col"
																			v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																			:value="field.radioOptionsValue.split(';')[radioIndex]"
																			:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo3' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																			:disabled="true"
																			@change="doValidCalc(true)"
																		/><span class="radio" :class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo3' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Checkbox type -->
															<template v-if="field.fieldTypeCd == '04'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.checkboxOptionsLabel.split(';').length, field.checkboxCols)"
																>
																	<label
																		v-for="(text, checkboxIndex) in field.checkboxOptionsLabel.split(';')"
																		:key="checkboxIndex"
																	>
																		<input
																			type="checkbox"
																			:name="'procFieldNo4' + field.fieldNo + row + col"
																			v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																			:value="field.checkboxOptionsValue.split(';')[checkboxIndex]"
																			:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo4' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																			:disabled="true"
																			@change="doValidCalc(true)"
																		/><span class="checkbox" :class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo4' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Select type -->
															<template v-if="field.fieldTypeCd == '05'">
																<select
																	class="select-crud"
																	:name="'procFieldNo5' + field.fieldNo + row + col"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo5' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:disabled="true"
																	@change="doValidCalc(true)"
																>
																	<option value="">-- Select --</option>
																	<option
																		v-for="(selectItem, selectIndex) in field.selectOptionsLabel.split(';')"
																		:key="selectIndex"
																		:value="field.selectOptionsValue.split(';')[selectIndex]"
																	>
																			{{ selectItem }}
																	</option>
																</select>
															</template>
															<!-- Image type -->
															<template v-if="field.fieldTypeCd == '06'">
																<div v-show="field.isLoad">
																	<img v-if="field.fileList && field.fileList.length > 0"
																		:src="
																			config.contextRoot +
																				'/common/file/printImage.do?fileNo=' +
																				field.fileList[0].fileNo +
																				'&fileGroupNo=' +
																				field.fileList[0].fileGroupNo +
																				'&serverFileSaveName=' +
																				field.fileList[0].serverFileSaveName
																		"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																		@load="loadedImage(field)"
																	/>
																</div>
																<div v-if="!field.isLoad">
																	<svg 
																		v-if="field.fileList && field.imageWidth"
																		xmlns="http://www.w3.org/2000/svg"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																		:viewBox="'0 0 ' + field.imageWidth + ' ' + field.imageHeight">
																		<rect fill="#ddd" 
																			:width="field.imageWidth"
																			:height="field.imageHeight"
																		/>
																		<text fill="#666" font-family="sans-serif" font-size="20" dy="10.5" font-weight="normal" x="50%" y="50%" text-anchor="middle">
																			{{field.imageWidth}}x{{field.imageHeight}}
																		</text>
																	</svg>
																	<p v-else-if="!field.fileList">
																		There is no image.
																	</p>
																</div>
															</template>
															<!-- File Type -->
															<template v-if="field.fieldTypeCd == '07'">
																<!-- <div class="file-wrap">
																	<input
																		type="file"
																		hidden
																	/>
																	<input
																		type="text"
																		class="file"
																		:value="
																			'(' + field.fileMaxSize + 'MB, 확장자: ' + field.fileExtension + ')'
																		"
																		disabled
																	/>
																	<button type="button" class="upload">Upload</button>
																</div> -->
																<site-file
																	:fileExtension="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileExtension"
																	:fileMaxSize="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileMaxSize"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fileList"
																/>
															</template>
															<!-- Date Start -->
															<template v-if="field.fieldTypeCd == '08'">
																<label 
																	class="label-date-picker label-date-picker-disabled"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo8' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:style="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? 'background-color : #eee; color: #ccc;' : utils.isEmpty(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'color: #ccc' : 'color: #999'"
																>
																	{{
																		(
																			getDateValue(index, String.fromCharCode(64 + col) + row, fieldIndex, 'date-picker_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex)
																		) 
																	}}
																	<date-picker
																		:ref="'date-picker_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex"
																		:name="'procFieldNo8' + field.fieldNo + row + col"
																		:type="getViewOption(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dataTypeCd, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat)"
																		v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValueTemp"
																		value-type="format"
																		:format="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.replace('hh', 'HH')"
																		:lang="lang"
																		style="width: 0px; height: 0px; overflow: hidden;"
																		confirm
																		:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? 'hidden-important' : ''"
																		@change="doValidCalc(true)"
																		:disabled="true"
																	>
																		<template 
																			v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isAllowUk == 'Y'" 
																			v-slot:sidebar="{ }"
																		> 
																			<div class="sidebar-select-uk" style="padding: 8px">
																				<p>Select UK</p>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('YYYY') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="YYYY" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkYYYY"> 
																					<span class="checkbox"></span>
																					YYYY
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('MM') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="MM" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkMM">
																					<span class="checkbox"></span>
																					MM
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('DD') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="DD" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkDD">
																					<span class="checkbox"></span>
																					DD
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('hh') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="hh" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkhh">
																					<span class="checkbox"></span>
																					hh
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('mm') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="mm" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkmm">
																					<span class="checkbox"></span>
																					mm
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('ss') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="ss" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkss">
																					<span class="checkbox"></span>
																					ss
																				</label>
																			</div>
																		</template>
																	</date-picker>
																</label>
															</template>
															<!-- Calc Type -->
															<template v-if="field.fieldTypeCd == '09'">
																<input
																	type="text"
																	class="calc"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? '' : (errors.has('calculate' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:name="'calculate' + field.fieldNo"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	readonly
																	:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) || entryStatus == '8'"
																	@change="doValidCalc(true)"
																/>
																<button
																	type="button"
																	class="btn-calc"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd) == 'disabled' || entryStatus == '8' ? 'hidden-important' : ''"
																	title="calculate"
																	@click.stop="doCalculate(table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)], fieldIndex, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList, index, (String.fromCharCode(64 + col) + row))"
																>
																	Calculate
																</button>
															</template>
															<!-- FileDownload Type -->
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																	style="display: inline-block; margin: 0 10px 0 0;"
																/>
																<button
																	v-if="['pdf', 'PDF'].includes(field.fileList[0].fileExtensionName)"
																	type="button"
																	class="download-preview"
																	title="Preview"
																	@click="showPreview(field.fileList)"
																>
																	Preview
																</button>
															</template>
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length < 1">
																<p class="txt-validation">
																	File is not exist!
																</p>
															</template>
															<!-- FileDownload Type -->
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																/>
															</template>
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length < 1">
																<p class="txt-validation">
																	Video is not exist!
																</p>
															</template>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '12'">
																{{tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].selectDefault}}
															</template>
														</div>
														{{ field.suffixName }}
														<!-- update Reason -->
														<template v-if="workAccessCheck('EDIT REASON') && (entryStatus == '3')">
															<select
																class="select-crud"
																style="position: absolute; min-width: 96%; max-width: 96% !important; height: 30px; margin-top: 10px; left: 2%; bottom: 5px;"
																:style="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason=='4'? 'min-width: 38%; max-width:38% !important' :''"
																:name="'procFieldNo5' + field.fieldNo + row + col"
																v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason"
																v-validate="'required'"
																:class="errors.has('procFieldNo5' + field.fieldNo + row + col) ? 'input-crud-error' : ''"
															>
																<option value="">-- Select Entry Update Reason --</option>
																<option
																	v-for="(comcode, index) in codeList"
																	:key="index"
																	:value="comcode.comCode"
																>
																	{{  comcode.comCodeName }}
																</option>
															</select>
															<input 
																v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason == '4'"
																type="text"
																placeholder="Please enter other reasons"
																style="position: absolute; min-width: 57%; max-width: 57% !important; height: 30px; margin-top: 10px; right: 2%; bottom: 5px;"
																v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].etcReason"
															>
														</template>
													</li>
												</ul>
											</template>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">Close</button>
				</div>
			</form>
		</div>
		<button
			v-if="isViewer"
			type="button" class="btn-preview-close" title="close" @click="closePreview()">Close</button>
		<PDFViewer
			v-if="isViewer"
			:source=viewerUrl
			:controls=viewerControl
			style="position:fixed; top:0; left:0; width: 50vw; height: 100vh; border-radius: 10px;"
		>
		</PDFViewer>
		<ScreeningHistory v-if="isScreeningHistory" :param="items" />
		<ScreeningStatusHistory v-if="isScreeningStatusHistory" :param="items" />
		<ConfirmTestQuery v-if="isOpenQuery" :selectQuery="selectQuery" :selectField="selectField"/>
		<PiSignature v-if="isOpenSign" :param="items"/>
		<TestRequestEdit v-if="isRequestEdit" :procFieldList = "procFieldList"/>
		<RepeatField v-if="isRepeatField" :calcRepeatList="calcRepeatList"/>
	</div>
</template>


<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "QueryValidView",
	created() {
		this.logger.debug(this, "created()");

		this.items.projectNo = this.$route.params.projectNo;
		this.items.validNo = this.selectViewValidNo;
		this.items.visitNo = this.selectViewVisitNo;
		this.items.pageNo = this.selectViewPageNo;
		this.items.userId = this.userInfo.userId;
		
		if(this.selectViewScreeningNo != ''){
			this.items.screeningNo = this.selectViewScreeningNo;
			this.select();
		}
		else{
			this.items.testScreeningNo = this.selectViewTestScreeningNo;
			this.selectTest();
		}

		this.selectComCodeList();
		
		// 로딩바 종료
		this.$nextTick(function () {
			setTimeout(function() {
				EventBus.$emit("project-loading", false);
			}.bind(this), 400);
		});

	},
	beforeDestroy(){
		EventBus.$off('blurDropList');
	},
    props: {
		selectViewValidNo: {
			type: String,
			required: true
		},
		selectViewScreeningNo: {
			type: String,
			required: true
		},
		selectViewTestScreeningNo: {
			type: String,
			required: true
		},
		selectViewVisitNo: {
			type: String,
			required: true
		},
		selectViewPageNo: {
			type: String,
			required: true
		},
		selectViewSubject: {
			type: String,
			required: true
		},
		selectViewPageName: {
			type: String,
			required: true
		}
	},
	components: {
		DatePicker
	},
	data() {
		return {
			items: {},
			accessList: [],
			procFieldList: [],
			surveyItemList: [],
			repeatList: [],
			tableRow: 0,
			tableCol: 0,
			tableDataList: [],
			columnInfoList: [],
			rowInfoList: [],
			isViewer : false,
			viewerUrl : "",
			viewerControl : ['print','rotate','zoom','catalog','switchPage'],
			deleteRepeatList: [],
			validCount : 0,
			editDateInfo: {
				index: "",
				cell: "",
				fieldIndex: ""
			},
			lang: {
				formatLocale: {
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			isScreeningHistory: false,
			isScreeningStatusHistory: false,
			isOpenQuery: false,
			isOpenSign: false,
			isRequestEdit: false,
			selectQuery: [],
			codeList: [],
			entryStatus: "",
			selectField: "",
			//Valid Result List
			applyValidVoList: [],
			disableList: [],
			msgList: [],
			gotoList: [],
			hideList: [],
			isSingleCreate: false,
			paramSigleCreate: {},
			isRepeatField: false,
			calcRepeatList: [],
			calcParam: {
				fieldList: [],
				calcGpLst: [],
				index: "",
				cellId: "",
				fieldIndex: ""
			},
			calcVo: {},
			calculateVoList: [],
			projectStateCd: ""
		};
	},
	methods: {
		// 초기 조회 기능 시작 (visit-부터 쭉 test)
		selectValidVisitList(mode) {
			this.logger.debug(this, "selectValidVisitList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidVisitList.do", this.item)
				.then((response) => {
					console.log(response);
					this.validVisitList = response.data.validVisitList;
					if(mode == "INIT")
					{
						this.setSelectValue();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// 초기 조회 기능 시작
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");
			axios
				.post(this.config.contextRoot + "/visit/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectResultTypeList() {
			this.logger.debug(this, "selectResultTypeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "VALIDATION_RESULT_CD"})
				.then((response) => {
					console.log(response);
					this.resultTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectOperatorCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_OPERATOR_CD"})
				.then((response) => {
					console.log(response);
					this.operatorList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectGroupOperatorCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "GROUP_OPERATOR_CD"})
				.then((response) => {
					console.log(response);
					this.groupOperatorList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldTypeCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_TYPE_CD"})
				.then((response) => {
					console.log(response);
					this.fieldTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["DATA_TYPE_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList(mode) {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/valid/selectVisitPageList.do", {visitNo: this.validForm.visitNo})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
					if(mode == "INIT")
					{
						this.setSelectValue();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		select() {
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "select()");
			axios
				.post(this.config.contextRoot + "/screening/selectProcList.do", this.items)
				.then((response) => {
					console.log(response);
					this.items.lastModifyUserId = response.data.registerVo.lastModifyUserId;
					this.items.lastModifyDate = response.data.registerVo.lastModifyDate;
					this.surveyItemList = response.data.itemList;
					this.tableDataList = response.data.tableDataList;
					this.columnInfoList = response.data.columnInfoList;
					this.rowInfoList = response.data.rowInfoList;

					for(let i = 0; i < this.tableDataList.length; i++) {
						let tableDataKeys = Object.keys(this.tableDataList[i]);
						for(let j = 0 ; j < tableDataKeys.length ; j++)
						{
							this.$set(this.tableDataList[i][tableDataKeys[j]], "isCopy", false);
							if(this.tableDataList[i][tableDataKeys[j]].field == null)
							{
								this.$set(this.tableDataList[i][tableDataKeys[j]], "field", {});
							}
							else{
								for(let k = 0; k < this.tableDataList[i][tableDataKeys[j]].field.fieldList.length; k++){
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldTypeCd == "04" && this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue == ""){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue = [];
									}
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason == ''){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason = '5';
									}
								}
							}
						}
					}
					for(let i = 0; i < this.surveyItemList.length; i++)
					{
						for(let j = 0; j < this.surveyItemList[i].procQuestionList.length; j++)
						{
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [];
									}
								}
							}
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldRepeatList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [];
									}
								}
							}
						}
					}
					this.$nextTick(function(){
						this.setRepeat();
						this.setData();
						this.validApply();
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				
		},
		selectTest() {
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "select()");
			axios
				.post(this.config.contextRoot + "/testScreening/selectProcList.do", this.items)
				.then((response) => {
					console.log(response);
					this.items.lastModifyUserId = response.data.registerVo.lastModifyUserId;
					this.items.lastModifyDate = response.data.registerVo.lastModifyDate;
					this.surveyItemList = response.data.itemList;
					this.tableDataList = response.data.tableDataList;
					this.columnInfoList = response.data.columnInfoList;
					this.rowInfoList = response.data.rowInfoList;

					for(let i = 0; i < this.tableDataList.length; i++) {
						let tableDataKeys = Object.keys(this.tableDataList[i]);
						for(let j = 0 ; j < tableDataKeys.length ; j++)
						{
							this.$set(this.tableDataList[i][tableDataKeys[j]], "isCopy", false);
							if(this.tableDataList[i][tableDataKeys[j]].field == null)
							{
								this.$set(this.tableDataList[i][tableDataKeys[j]], "field", {});
							}
							else{
								for(let k = 0; k < this.tableDataList[i][tableDataKeys[j]].field.fieldList.length; k++){
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldTypeCd == "04" && this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue == ""){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue = [];
									}
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason == ''){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason = '5';
									}
								}
							}
						}
					}
					for(let i = 0; i < this.surveyItemList.length; i++)
					{
						for(let j = 0; j < this.surveyItemList[i].procQuestionList.length; j++)
						{
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [];
									}
								}
							}
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldRepeatList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [];
									}
								}
							}
						}
					}
					this.$nextTick(function(){
						this.setRepeat();
						this.setData();
						this.validApplyTest();
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				
		},
		doCalculate(calculateGroupList) {
			let calcStr = "";
			for(let i = 0 ; i < calculateGroupList.length ; i++)
			{
				calcStr += "(";
				calcStr += this.doGroupCalculate(calculateGroupList[i].calculateDetailList);
				calcStr += ")" + calculateGroupList[i].groupOperator;
			}
			console.warn(calcStr);
			return eval(calcStr);
		},
		doGroupCalculate(calculateDetailList){
			let calcStr = "";
			for(let i = 0 ; i < calculateDetailList.length ; i++)
			{
				let fieldValue = "";
				if(calculateDetailList[i].fieldTypeCd == "FUNCTION")
				{
					fieldValue = this.executeFunction(calculateDetailList[i].funcParamList);
				}
				else
				{
					fieldValue = calculateDetailList[i].fieldValue;
				}
				calcStr += fieldValue;
				calcStr += calculateDetailList[i].fieldOperator;
			}
			return eval(calcStr);
		},
		executeFunction(funcPrmLst) {
			var prmLst = [];
			for(let i = 0 ; i < funcPrmLst.length ; i++)
			{
				let prm = funcPrmLst[i].value;
				if(prm != "")
				{
					prmLst.push('"' + prm + '"');
				}
			}
			var funcDefCode = funcPrmLst[0].funcDefCode;
			var executeCode = funcDefCode.slice(funcDefCode.indexOf(" ") + 1, funcDefCode.indexOf("("));
			var prmLstStr = prmLst.join(", ");
			executeCode += "(" + prmLstStr + ")";
			var totalCode = funcDefCode + executeCode;
			this.evalInContext(totalCode, this, true);
			return this.evalInContext(totalCode, this, true);
		},
		evalInContext(js, context, isValid){
			console.log(isValid);
			return function(){ return eval(js);}.call(context);
		},
		setSelectValue() {
			this.logger.debug(this, "setSelectValue()");
			
			for(let i = 0 ; i < this.validForm.validDetailList.length ; i++)
			{
				let validDetail = this.validForm.validDetailList[i];
				// set Type
				this.$set(this.validForm.validDetailList[i], "printMsgYn", (validDetail.printMsgYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "disableVisitYn", (validDetail.disableVisitYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "disablePageYn", (validDetail.disablePageYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "disableProcYn", (validDetail.disableProcYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "disableQuestionYn", (validDetail.disableQuestionYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "hideQuestionYn", (validDetail.hideQuestionYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "gotoPageYn", (validDetail.gotoPageYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "dynamicYn", (validDetail.dynamicYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "systemPopupYn", (validDetail.systemPopupYn == 'Y' ? true : false));
				this.$set(this.validForm.validDetailList[i], "dataLinkYn", (validDetail.dataLinkYn == 'Y' ? true : false));
				
				// set data link
				if(validDetail.dataLinkYn)
				{
					for(let j = 0; j< validDetail.dataLinkDsa.dataLinkDsaDetailList.length; j++){
						let goPage = {};
						goPage.visitNo = validDetail.dataLinkDsa.dataLinkDsaDetailList[j].visitNo;
						goPage.pageNo = validDetail.dataLinkDsa.dataLinkDsaDetailList[j].pageNo;
						goPage.procNo = validDetail.dataLinkDsa.dataLinkDsaDetailList[j].procNo;
						goPage.procQuestionNo = validDetail.dataLinkDsa.dataLinkDsaDetailList[j].procQuestionNo;
						goPage.procFieldNo = validDetail.dataLinkDsa.dataLinkDsaDetailList[j].procFieldNo;
						goPage.valueText = this.getValueText(goPage.visitNo, goPage.pageNo, goPage.procNo, goPage.procQuestionNo, goPage.procFieldNo, "LIST");

						let copyList = [];
						copyList = (JSON.parse(JSON.stringify(this.validVisitList)));
						goPage.visitSortList = copyList;

						this.$set(this.validForm.validDetailList[i].dataLinkDsa.dataLinkDsaDetailList[j], "value", goPage);
					}
				}
				else{
					this.$set(this.validForm.validDetailList[i], "dataLinkDsa", this.dataLinkDsaForm);
				}

				// set Go to Page
				if(validDetail.gotoPageYn)
				{
					let gotoPageDsa = validDetail.gotoPageDsa;
					let goPage = {};
					goPage.goVisitNo = gotoPageDsa.goVisitNo;
					goPage.goPageNo = gotoPageDsa.goPageNo;
					goPage.valueText = this.getValueText(goPage.goVisitNo, goPage.goPageNo);

					let underQuestion = {};
					underQuestion.visitNo = gotoPageDsa.visitNo;
					underQuestion.pageNo = gotoPageDsa.pageNo;
					underQuestion.procNo = gotoPageDsa.procNo;
					underQuestion.procQuestionNo = gotoPageDsa.procQuestionNo;
					underQuestion.procFieldNo = gotoPageDsa.procFieldNo;
					underQuestion.valueText = this.getValueText(underQuestion.visitNo, underQuestion.pageNo, underQuestion.procNo, underQuestion.procQuestionNo, underQuestion.procFieldNo);
					this.$set(this.validForm.validDetailList[i].gotoPageDsa, "goPage", goPage);
					this.$set(this.validForm.validDetailList[i].gotoPageDsa, "underQuestion", underQuestion);
				}
				else
				{
					let gotoPageDsa = {
						pageTypeCd: 'GOTO',
						conditionYn: 'Y',
						printMsg: "",
					};
					this.$set(this.validForm.validDetailList[i], "gotoPageDsa", gotoPageDsa);
				}
				
				// set Dynaminc Page
				if(validDetail.dynamicYn)
				{
					let dynamicDsa = validDetail.dynamicDsa;
					let goPage = {};
					goPage.goVisitNo = dynamicDsa.goVisitNo;
					goPage.goPageNo = dynamicDsa.goPageNo;
					goPage.valueText = this.getValueText(goPage.goVisitNo, goPage.goPageNo);

					let underQuestion = {};
					underQuestion.visitNo = dynamicDsa.visitNo;
					underQuestion.pageNo = dynamicDsa.pageNo;
					underQuestion.procNo = dynamicDsa.procNo;
					underQuestion.procQuestionNo = dynamicDsa.procQuestionNo;
					underQuestion.procFieldNo = dynamicDsa.procFieldNo;
					underQuestion.valueText = this.getValueText(underQuestion.visitNo, underQuestion.pageNo, underQuestion.procNo, underQuestion.procQuestionNo, underQuestion.procFieldNo);
					this.$set(this.validForm.validDetailList[i].dynamicDsa, "goPage", goPage);
					this.$set(this.validForm.validDetailList[i].dynamicDsa, "underQuestion", underQuestion);
				}
				else
				{
					let dynamicDsa = {
						pageTypeCd: 'DYNAMIC',
						conditionYn: 'Y',
						printMsg: "",
					};
					this.$set(this.validForm.validDetailList[i], "dynamicDsa", dynamicDsa);
				}
				// set Print Msg
				if(validDetail.printMsgYn)
				{
					let validResultMsg = validDetail.validResultMsg;
					let value = {};
					value.visitNo = validResultMsg.visitNo;
					value.pageNo = validResultMsg.pageNo;
					value.procNo = validResultMsg.procNo;
					value.procQuestionNo = validResultMsg.procQuestionNo;
					value.procFieldNo = validResultMsg.procFieldNo;
					value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo);
					this.$set(this.validForm.validDetailList[i].validResultMsg, "value", value);
				}
				else
				{
					let validResultMsg = {
						conditionYn: "Y",
						printTypeCd: 'MESSAGE',
						dataRemoveYn: '',
						pageNo: "",
						printMsg: "",
						procNo: "",
						procQuestionNo: "",
						visitNo: ""
					};
					this.$set(this.validForm.validDetailList[i], "validResultMsg", validResultMsg);
				}

				// set system Popup
				if(!validDetail.systemPopupYn)
				{
					let validResultPopup = {
						conditionYn: "Y",
						printTypeCd: 'POPUP',
						dataRemoveYn: '',
						pageNo: "",
						printMsg: "",
						procNo: "",
						procQuestionNo: "",
						visitNo: ""
					};
					this.$set(this.validForm.validDetailList[i], "validResultPopup", validResultPopup);
				}
				
				// set Dsa
				for(let dsa = 0 ; dsa < 4 ; dsa++)
				{
					let dsaYnType = ["disablePageYn", "disableProcYn", "disableQuestionYn", "disableVisitYn"];
					let dsaType = ["PAGE", "PROC", "QUESTION", "VISIT"];
					let setType = ["pageDsa", "procDsa", "questionDsa", "visitDsa"];
					if(validDetail[dsaYnType[dsa]])
					{
						let index = validDetail.validResultDsaList.map((item) => {return item.disabledTypeCd}).indexOf(dsaType[dsa]);
						for(let j = 0 ; j < validDetail.validResultDsaList[index].validResultDsaDetailList.length ; j++)
						{
							let value = {};
							let type = "";
							if(dsa == 0 || dsa == 1 || dsa == 2 || dsa == 3) value.visitNo = validDetail.validResultDsaList[index].validResultDsaDetailList[j].visitNo;
							if(dsa == 0 || dsa == 1 || dsa == 2) value.pageNo = validDetail.validResultDsaList[index].validResultDsaDetailList[j].pageNo;
							if(dsa == 1 || dsa == 2) value.procNo = validDetail.validResultDsaList[index].validResultDsaDetailList[j].procNo;
							if(dsa == 2) value.procQuestionNo = validDetail.validResultDsaList[index].validResultDsaDetailList[j].procQuestionNo;
							if(dsa == 2) value.procFieldNo = validDetail.validResultDsaList[index].validResultDsaDetailList[j].procFieldNo;
							if(dsa == 2) type = "LIST";
							value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo, type);
							validDetail.validResultDsaList[index].validResultDsaDetailList[j].value = value;
						}
						this.$set(this.validForm.validDetailList[i], setType[dsa], validDetail.validResultDsaList[index]);
					}
				}

				// set Hide
				for(let dsa = 0 ; dsa < 1 ; dsa++)
				{
					let hideYnType = ["hideQuestionYn"];
					let hideType = ["QUESTION"];
					let setType = ["questionHide"];
					if(validDetail[hideYnType[dsa]])
					{
						let index = validDetail.validResultHideList.map((item) => {return item.hideTypeCd}).indexOf(hideType[dsa]);
						for(let j = 0 ; j < validDetail.validResultHideList[index].validResultHideDetailList.length ; j++)
						{
							let value = {};
							if(dsa == 0) value.visitNo = validDetail.validResultHideList[index].validResultHideDetailList[j].visitNo;
							if(dsa == 0) value.pageNo = validDetail.validResultHideList[index].validResultHideDetailList[j].pageNo;
							if(dsa == 0) value.procNo = validDetail.validResultHideList[index].validResultHideDetailList[j].procNo;
							if(dsa == 0) value.procQuestionNo = validDetail.validResultHideList[index].validResultHideDetailList[j].procQuestionNo;
							if(dsa == 0) value.procFieldNo = validDetail.validResultHideList[index].validResultHideDetailList[j].procFieldNo;
							value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo);
							validDetail.validResultHideList[index].validResultHideDetailList[j].value = value;
						}
						this.$set(this.validForm.validDetailList[i], setType[dsa], validDetail.validResultHideList[index]);
					}
				}
				
				// Set Condition
				for(let j = 0 ; j < validDetail.validConList.length ; j++)
				{
					let validCon = validDetail.validConList[j];
					for(let k = 0 ; k < validCon.validConDetailList.length ; k++)
					{
						let validConDetail = validCon.validConDetailList[k];

						if(validConDetail.ogTypeCd == 'FIELD'){
							let value = {};
							value.visitNo = validConDetail.visitNo;
							value.pageNo = validConDetail.pageNo;
							value.procNo = validConDetail.procNo;
							value.procQuestionNo = validConDetail.procQuestionNo;
							value.procFieldNo = validConDetail.procFieldNo;
							value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo, "LIST");
							
							let copyList = [];
							copyList = (JSON.parse(JSON.stringify(this.validVisitList)));
							value.visitSortList = copyList;

							this.$set(this.validForm.validDetailList[i].validConList[j].validConDetailList[k], "value", value);
						} else {
							let value = {};
							this.$set(this.validForm.validDetailList[i].validConList[j].validConDetailList[k], "value", value);
						}

						if(validConDetail.targetTypeCd == 'FIELD'){
							let value = {};
							value.visitNo = validConDetail.targetVisitNo;
							value.pageNo = validConDetail.targetPageNo;
							value.procNo = validConDetail.targetProcNo;
							value.procQuestionNo = validConDetail.targetProcQuestionNo;
							value.procFieldNo = validConDetail.targetProcFieldNo;
							value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo, "LIST");

							let copyList = [];
							copyList = (JSON.parse(JSON.stringify(this.validVisitList)));
							value.visitSortList = copyList;

							this.$set(this.validForm.validDetailList[i].validConList[j].validConDetailList[k], "target", value);
						}else{
							let value = {};
							this.$set(this.validForm.validDetailList[i].validConList[j].validConDetailList[k], "target", value);
						}

						// og calc form
						for(let i=0; i<validConDetail.ogCalculateForm.calculateGroupList.length; i++)
						{
							let putData = "";
							for(let j=0; j<validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList.length; j++)
							{
								if(validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FIELD")
								{
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName;
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
								}
								else if(validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "VALUE")
								{
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldValue;
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
								}
								else if(validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FUNCTION")
								{
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].calculateDetailName;
									putData += validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
									let fieldValue = this.executeFunction(validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].funcParamList);
									validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldValue = fieldValue;
								}
							}
							validConDetail.ogCalculateForm.calculateGroupList[i].text = putData;
							let value = this.doGroupCalculate(validConDetail.ogCalculateForm.calculateGroupList[i].calculateDetailList);
							validConDetail.ogCalculateForm.calculateGroupList[i].value = value;
						}

						let putAllData = "";
						putAllData += this.doCalculate(validConDetail.ogCalculateForm.calculateGroupList);
						putAllData += " (";
						for(let i=0; i<validConDetail.ogCalculateForm.calculateGroupList.length; i++)
						{
							putAllData += " ("+validConDetail.ogCalculateForm.calculateGroupList[i].text+") ";
							putAllData += validConDetail.ogCalculateForm.calculateGroupList[i].groupOperator;
						}
						putAllData += ")";
						validConDetail.ogCalculateForm.allText = putAllData;

						// target calc form
						for(let i=0; i<validConDetail.targetCalculateForm.calculateGroupList.length; i++)
						{
							let putData = "";
							for(let j=0; j<validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList.length; j++)
							{
								if(validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FIELD")
								{
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].procFieldName;
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
								}
								else if(validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "VALUE")
								{
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldValue;
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
								}
								else if(validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldTypeCd == "FUNCTION")
								{
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].calculateDetailName;
									putData += validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldOperator;
									let fieldValue = this.executeFunction(validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].funcParamList);
									validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList[j].fieldValue = fieldValue;
								}
							}
							validConDetail.targetCalculateForm.calculateGroupList[i].text = putData;
							let value = this.doGroupCalculate(validConDetail.targetCalculateForm.calculateGroupList[i].calculateDetailList);
							validConDetail.targetCalculateForm.calculateGroupList[i].value = value;
						}

						let putAllData2 = "";
						putAllData2 += this.doCalculate(validConDetail.targetCalculateForm.calculateGroupList);
						putAllData2 += " (";
						for(let i=0; i<validConDetail.targetCalculateForm.calculateGroupList.length; i++)
						{
							putAllData2 += " ("+validConDetail.targetCalculateForm.calculateGroupList[i].text+") ";
							putAllData2 += validConDetail.targetCalculateForm.calculateGroupList[i].groupOperator;
						}
						putAllData2 += ")";
						validConDetail.targetCalculateForm.allText = putAllData2;


						// for(let l = 0 ; l < 2 ; l++)
						// {
						// 	if(l == 1 && validConDetail.targetTypeCd == 'VALUE')
						// 	{
						// 		break;
						// 	}
						// 	else
						// 	{
						// 		let value = {};
						// 		value.visitNo = (l == 0 ? validConDetail.visitNo : validConDetail.targetVisitNo);
						// 		value.pageNo = (l == 0 ? validConDetail.pageNo : validConDetail.targetPageNo);
						// 		value.procNo = (l == 0 ? validConDetail.procNo : validConDetail.targetProcNo);
						// 		value.procQuestionNo = (l == 0 ? validConDetail.procQuestionNo : validConDetail.targetProcQuestionNo);
						// 		value.procFieldNo = (l == 0 ? validConDetail.procFieldNo : validConDetail.targetProcFieldNo);
						// 		value.valueText = this.getValueText(value.visitNo, value.pageNo, value.procNo, value.procQuestionNo, value.procFieldNo);
						// 		this.$set(this.validForm.validDetailList[i].validConList[j].validConDetailList[k], (l == 0 ? "value" : "target"), value);
						// 	}
						// }
					}
				}
			}
		},
		getValueText(visitNo, pageNo, procNo, procQuestionNo, procFieldNo, type) {
			let valueText = "";
			// let pageIndex = this.pageList.map((item) => {return item.pageNo}).indexOf(pageNo);
			// let page = this.pageList[pageIndex];
			// valueText = valueText + page.pageName;
			let visitIndex = this.validVisitList.map((item) => {return item.visitNo}).indexOf(visitNo);
			let visit = this.validVisitList[visitIndex];
			valueText = valueText + visit.visitName;
			if(visitIndex >= 0 && pageNo !== undefined && pageNo != null){
				let pageIndex = visit.projectDictionaryList.map((item) => {return item.pageNo}).indexOf(pageNo);
				let page = visit.projectDictionaryList[pageIndex];
				valueText = valueText + " > " + page.pageName;
				if(pageIndex >= 0 && procNo !== undefined && procNo != null)
				{
					let procIndex = page.procedureList.map((item) => {return item.procNo}).indexOf(procNo);
					let proc = page.procedureList[procIndex];
					valueText = valueText + " > " + proc.procName;

					if(procIndex >= 0 && procQuestionNo !== undefined && procQuestionNo != null)
					{
						let questionIndex = proc.procQuestionList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
						
						if(questionIndex >= 0 && procFieldNo !== undefined && procFieldNo != null)
						{
							let question = proc.procQuestionList[questionIndex];
							valueText = valueText + " > " + question.questionFieldName;

							let fieldIndex = question.fieldList.map((item) => {return item.procFieldNo}).indexOf(procFieldNo);
							let field = question.fieldList[fieldIndex];
							valueText = valueText + " | " + question.questionName + " | " +this.getFieldTypeName(field.fieldTypeCd) + " | " + this.dataTypeName(field.dataTypeCd);
							if(type == "LIST" && question.listIndex != 0){
								valueText += " | " + question.listIndex;
							}
						}
						else{
							for(let i = 0; i < proc.procQuestionList.length; i++)
							{
								let questionIndex2 = proc.procQuestionList[i].listTypeList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
								if(questionIndex2 >= 0 && procFieldNo !== undefined && procFieldNo != null)
								{
									let question2 = proc.procQuestionList[i].listTypeList[questionIndex2];
									valueText = valueText + " > " + question2.questionFieldName;

									let fieldIndex = question2.fieldList.map((item) => {return item.procFieldNo}).indexOf(procFieldNo);
									let field = question2.fieldList[fieldIndex];
									valueText = valueText + " | " + question2.questionName + " | " +this.getFieldTypeName(field.fieldTypeCd) + " | " + this.dataTypeName(field.dataTypeCd) + " | " + question2.listIndex;
								}
							}
						}
					}
				}
			}
			return valueText;
		},
		getFieldTypeName(comCode) {
			let comCodeList = this.fieldTypeList.map((item) => {
				return item.comCode;
			});
			let getIndex = comCodeList.indexOf(comCode);

			return this.fieldTypeList[getIndex].comCodeName;
		},
		dataTypeName(dataTypeCd){
			let dataTypeName = "";
			this.codeList.forEach((element) => {
				if(dataTypeCd == element.comCode){
					dataTypeName = element.comCodeName;
				}
			});
			return dataTypeName;
		},
		// 초기 조회 기능 끝
		// Form 관련 기능 시작
		addValidDetailForm() {
			this.logger.debug(this, "addValidDetailForm()");
			let getDetailForm = JSON.parse(JSON.stringify(this.validDetailForm));
			let validConForm = JSON.parse(JSON.stringify(this.validConForm));
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			getDetailForm.sortOrder = this.validForm.validDetailList.length + 1;
			getDetailForm.validConList.push(validConForm);
			getDetailForm.validConList[0].validConDetailList.push(validConDetailForm);

			this.validForm.validDetailList.push(getDetailForm);
		},
		removeValidDetailForm(validIndex) {
			this.logger.debug(this, "removeValidDetailForm(validIndex)");
			if(this.validForm.validDetailList.length == 1)
			{
				this.$alert("At least one validation must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList.splice(validIndex, 1);
			}
		},
		addValidConForm(validDetailIndex) {
			this.logger.debug(this, "addValidConForm(validIndex)");
			let validConForm = JSON.parse(JSON.stringify(this.validConForm));
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			validConForm.sortOrder = this.validForm.validDetailList[validDetailIndex].validConList.length + 1;
			validConForm.validConDetailList.push(validConDetailForm);

			this.validForm.validDetailList[validDetailIndex].validConList.push(validConForm);
		},
		removeValidConForm(validDetailIndex, conIndex) {
			this.logger.debug(this, "removeValidConForm(validIndex, conIndex)");
			if(this.validForm.validDetailList[validDetailIndex].validConList.length == 1)
			{
				this.$alert("At least one group must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList[validDetailIndex].validConList.splice(conIndex, 1);
			}
		},
		addValidConDetailForm(validDetailIndex, validConIndex) {
			this.logger.debug(this, "addValidConDetailForm(validDetailIndex, validConIndex)");
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			validConDetailForm.sortOrder = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.length + 1;

			this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.push(validConDetailForm);
		},
		removeValidConDetailForm(validDetailIndex, validConIndex, validConDetailIndex) {
			this.logger.debug(this, "removeValidConDetailForm(validDetailIndex, validConIndex, validConDetailIndex)");
			if(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.length == 1)
			{
				this.$alert("At least one row must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.splice(validConDetailIndex, 1);
			}
		},
		setOgTypeCd(validDetailIndex, validConIndex, validConDetailIndex, value) {
			this.logger.debug(this, "setOgTypeCd(validDetailIndex, validConIndex, validConDetailIndex, 'FIELD')");
			this.$set(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex], "ogTypeCd", value);

			let target = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex];
			if(value == 'FIELD')
			{
				this.isCalcView = false;
				target.calculateNo = "";
				target.ogCalculateForm = {};
			}
			else
			{
				if(target.ogCalculateForm.calculateNo != ""){
					this.calcTarget = target.ogCalculateForm;
				}
				else{
					this.calcTarget = {};
				}
				this.$set(this.indexList, "validDetailIndex", validDetailIndex);
				this.$set(this.indexList, "validConIndex", validConIndex);
				this.$set(this.indexList, "validConDetailIndex", validConDetailIndex);
				this.$set(this.indexList, "type", "OG");
				this.isCalcView = true;
				target.value = {};
				target.visitNo = "";
				target.pageNo = "";
				target.procNo = "";
				target.procQuestionNo = "";
				target.procFieldNo = "";
			}
			this.openFieldIndex = "";
		},
		setCalcData(indexList, calclationForm){
			if(indexList.type == "OG"){
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].ogCalculateForm = calclationForm;
			}
			else{
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].targetCalculateForm = calclationForm;
			}
		},
		setTargetTypeCd(validDetailIndex, validConIndex, validConDetailIndex, value) {
			this.logger.debug(this, "setTargetTypeCd(validDetailIndex, validConIndex, validConDetailIndex, 'FIELD')");
			this.$set(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex], "targetTypeCd", value);

			let target = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex];
			if(value == 'FIELD')
			{
				this.isCalcView = false;
				target.targetValue = "";
				target.targetCalculateForm = {};
			}
			else if(value == 'VALUE')
			{
				this.isCalcView = false;
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
				target.targetCalculateForm = {};
			}
			else{
				if(target.targetCalculateForm.calculateNo != ""){
					this.calcTarget = target.targetCalculateForm;
				}
				else{
					this.calcTarget = {};
				}
				this.$set(this.indexList, "validDetailIndex", validDetailIndex);
				this.$set(this.indexList, "validConIndex", validConIndex);
				this.$set(this.indexList, "validConDetailIndex", validConDetailIndex);
				this.$set(this.indexList, "type", "TARGET");
				this.isCalcView = true;
				target.targetValue = "";
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
			}
			this.openFieldIndex = "";
		},
		setDsa(valid, comCode) {
			this.logger.debug(this, "setDsa(valid, comCode)");
			let dsaType = "";
			let disabledTypeCd = "";
			let dataRemoveYn = "";

			if(comCode == "disableVisitYn")
			{
				dsaType = "visitDsa";
				disabledTypeCd = "VISIT";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disablePageYn")
			{
				dsaType = "pageDsa";
				disabledTypeCd = "PAGE";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disableProcYn")
			{
				dsaType = "procDsa";
				disabledTypeCd = "PROC";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disableQuestionYn")
			{
				dsaType = "questionDsa";
				disabledTypeCd = "QUESTION";
				dataRemoveYn = "Y";
			}
			else if(comCode == "hideQuestionYn")
			{
				dsaType = "questionHide";
				disabledTypeCd = "QUESTION";
				dataRemoveYn = "Y";
			}
			else if(comCode == "dataLinkYn")
			{
				dsaType = "dataLinkDsa";
			}

			if(valid[comCode] && comCode != "dataLinkYn" && comCode != "hideQuestionYn")
			{
				let validResultDsaForm = JSON.parse(JSON.stringify(this.validResultDsaForm));
				validResultDsaForm.disabledTypeCd = disabledTypeCd;
				validResultDsaForm.dataRemoveYn = dataRemoveYn;
				valid[dsaType] = validResultDsaForm;
			}
			else if(valid[comCode] && comCode == "dataLinkYn"){
				let dataLinkDsaForm = JSON.parse(JSON.stringify(this.dataLinkDsaForm));
				valid[dsaType] = dataLinkDsaForm;
			}
			else if(valid[comCode] && comCode == "hideQuestionYn")
			{
				let validResultHideForm = JSON.parse(JSON.stringify(this.validResultHideForm));
				validResultHideForm.hideTypeCd = disabledTypeCd;
				validResultHideForm.dataRemoveYn = dataRemoveYn;
				valid[dsaType] = validResultHideForm;
			}
			else
			{
				valid[dsaType] = {};
			}
		},
		setSelectBox(conDetail){
			if(conDetail.value == null){
				conDetail.value = {};
			}
			let value = conDetail.value;
			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '03' || fieldTypeCd == '04' || fieldTypeCd == '05'){
															return true;
														}
														else{
															return false;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}else{
				return false;
			}
		},
		setDateBox(conDetail){
			let value = conDetail.value;
			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '08'){
															return true;
														}
														else{
															return false;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}else{
				return false;
			}
		},
		optionList(value){
			let optionList = [];
			for(let i = 0; i < value.visitSortList.length; i++){
				if(value.visitSortList[i].visitNo == value.visitNo){
					for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
						if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
							for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
								if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
									for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
										if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
											for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
												if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
													let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
													if(fieldTypeCd == '03'){
														let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsLabel;
														let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsValue;
														let labelList = optionLabel.split(';');
														let valueList = optionValue.split(';');
														for(let y = 0; y < labelList.length; y++){
															optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
														}
														return optionList;
													}
													else if(fieldTypeCd == '04'){
														let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsLabel;
														let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsValue;
														let labelList = optionLabel.split(';');
														let valueList = optionValue.split(';');
														for(let y = 0; y < labelList.length; y++){
															optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
														}
														return optionList;
													}
													else if(fieldTypeCd == '05'){
														let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsLabel;
														let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsValue;
														let labelList = optionLabel.split(';');
														let valueList = optionValue.split(';');
														for(let y = 0; y < labelList.length; y++){
															optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
														}
														return optionList;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		addDsaDetail(detailList) {
			this.logger.debug(this, "addDsaDetail(detailList)");
			let sortOrder = detailList.length + 1;

			let validResultDsaDetailForm = JSON.parse(JSON.stringify(this.validResultDsaDetailForm));
			validResultDsaDetailForm.sortOrder = sortOrder;

			detailList.push(validResultDsaDetailForm);
		},
		removeDsaDetail(detailList, detailIndex) {
			this.logger.debug(this, "removeDsaDetail(detailList, detailIndex)");
			detailList.splice(detailIndex, 1);
		}, 
		addLinkDsaDetail(detailList) {
			this.logger.debug(this, "addLinkDsaDetail(detailList)");
			let sortOrder = detailList.length + 1;

			let dataLinkDsaDetailForm = JSON.parse(JSON.stringify(this.dataLinkDsaDetailForm));
			dataLinkDsaDetailForm.sortOrder = sortOrder;

			detailList.push(dataLinkDsaDetailForm);
		},
		// Form 관련 기능 끝
		// Drop List 관련 기능 시작
		openPageList(index, event) {
			this.$set(this, "openFieldIndex", index);
			this.$nextTick(() => {
				event.target.parentNode.children[1].children[0].focus();
			});
		},
		setDropValue(obj, target, validIndex) {
			if(target == 'conField')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
				obj.procFieldNo = obj.value.procFieldNo;
				this.visitSortList[validIndex] = obj.value.visitSortList;
			}
			else if(target == 'conTargetField')
			{
				obj.targetVisitNo = obj.target.visitNo;
				obj.targetPageNo = obj.target.pageNo;
				obj.targetProcNo = obj.target.procNo;
				obj.targetProcQuestionNo = obj.target.procQuestionNo;
				obj.targetProcFieldNo = obj.target.procFieldNo;
			}
			else if(target == 'resultMsg')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
			}
			else if(target == 'resultPopup')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
			}
			else if(target == 'dsaVisit')
			{
				obj.visitNo = obj.value.visitNo;
			}
			else if(target == 'dsaPage')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
			}
			else if(target == 'dsaProc')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
			}
			else if(target == 'dsaQuestion')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
				obj.procFieldNo = obj.value.procFieldNo;
			}
			else if(target == 'dsaDataLink')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
				obj.procFieldNo = obj.value.procFieldNo;
			}
			else if(target == 'gotoPage')
			{
				obj.goVisitNo = obj.goPage.visitNo;
				obj.goPageNo = obj.goPage.pageNo;
			}
			else if(target == 'gotoUnder')
			{
				obj.visitNo = obj.underQuestion.visitNo;
				obj.pageNo = obj.underQuestion.pageNo;
				obj.procNo = obj.underQuestion.procNo;
				obj.procQuestionNo = obj.underQuestion.procQuestionNo;
			}
		},
		getDateTypeList(){
			this.logger.debug(this, "getDateTypeList()");
			axios
				.post(this.config.contextRoot + "/valid/getDateTypeList.do", this.item)
				.then((response) => {
					console.log(response);
					this.dateTypeList = response.data.validVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// Drop List 관련 기능 끝
		// 데이터 저장 및 기본 동작 기능 시작
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						// type check 최소 한개 이상 여부 검사
						let isTypeCheck = true;
						for(let i = 0 ; i < this.validForm.validDetailList.length ; i++)
						{
							let validDetail = this.validForm.validDetailList[i];
							if(!validDetail.printMsgYn && !validDetail.disablePageYn && !validDetail.disableProcYn && !validDetail.disableQuestionYn && !validDetail.disableVisitYn && !validDetail.gotoPageYn && !validDetail.systemPopupYn && !validDetail.hideQuestionYn)
							{
								isTypeCheck = false;
								break;
							}
						}
						if(isTypeCheck)
						{
							this.$confirm("Do you want to register the current validation?", "Question", "question").then(() => {
								this.doUpdate();
							});
						}
						else
						{
							this.$alert("At least one result type must be checked!", "Warning", "warning");
							return false;
						}
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.QueryValidView.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.QueryValidView.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("view-close", "QueryValidView");
			}.bind(this), 410);
		},
		// 데이터 저장 및 기본 동작 기능 끝
		setRepeat(){
			this.logger.debug(this, "setRepeat()");
			this.repeatList = JSON.parse(JSON.stringify(this.surveyItemList));
			let repeatArr = [];
			// let value = [];
			// let repeatCount = 0; 
			for(let i = 0; i < this.tableDataList.length; i++){
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++){
					if(Object.keys(this.tableDataList[i][keys[j]].field).length > 0 && this.tableDataList[i][keys[j]].repeatRoot != "" && !this.tableDataList[i][keys[j]].isHeader){
						for(let k = 0; k < this.repeatList[i].procQuestionList.length; k++){
							for(let l = 0; l < this.repeatList[i].procQuestionList[k].fieldRepeatList.length; l++){
								for(let t = 0; t < this.tableDataList[i][keys[j]].field.fieldList.length; t++){
									if(this.tableDataList[i][keys[j]].field.fieldList[t].procFieldNo == this.repeatList[i].procQuestionList[k].fieldRepeatList[l].procFieldNo && this.repeatList[i].procQuestionList[k].fieldRepeatList[l].repeatSortOrder != 1){
										// this.repeat(this.tableDataList[i][keys[j]].cellName, i+1, this.repeatList[i].procQuestionList[k].fieldRepeatList[0].fieldValue);
										repeatArr.push({cellName : this.tableDataList[i][keys[j]].repeatRoot, value : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].fieldValue, procFieldNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].procFieldNo, screeningInputNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].screeningInputNo, screeningEntryNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].screeningEntryNo, sortOrder : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].repeatSortOrder, row : i});
										// value.push(this.repeatList[i].procQuestionList[k].fieldRepeatList[0].fieldValue);
									}
								}
							}
						}
					}
				}
			}
			console.log(repeatArr);
			repeatArr.sort(function(a,b){
				if(a.row == b.row){
					var x = a.cellName.toLowerCase(), y = b.cellName.toLowerCase();
					if(x == y){
						var k = a.sortOrder.toLowerCase(), l = b.sortOrder.toLowerCase();

						return k < l ? -1 : k > l ? 1 : 0;
					}
					else
					{
						var xCol = x.slice(0,1), yCol = y.slice(0,1);
						console.log(xCol, yCol);
						if(xCol < yCol) return -1;
						if(xCol > yCol) return 1;
						else
						{
							var xRow = x.slice(1), yRow = y.slice(1);
							console.log(xRow, yRow);
							return xRow - yRow;
						}
					}
				}
				return a.row - b.row;
			});
			console.log(repeatArr);
			let repeatRow = 0;
			let repeatCount = 0;
			let endName = "";
			for(let i = 0; i < repeatArr.length; i++){
				if(repeatArr[i+1] != null && typeof repeatArr[i+1] != "undefined")
				{
					if(repeatArr[i].cellName != repeatArr[i+1].cellName || repeatArr[i].row != repeatArr[i+1].row)
					{
						for(let j = 1; j < repeatArr[i].sortOrder; j++)
						{
							if(j==1){
								let colName = repeatArr[i].cellName.slice(0,1);
								let rowName = repeatArr[i].cellName.slice(1,10);
								let addRowName = Number(rowName) + Number(repeatRow);
								endName = colName + addRowName;
							}
							this.repeat(endName, repeatArr[i].row+1);
							let keys = Object.keys(this.tableDataList[repeatArr[i].row]);

							for(let k =0; k < keys.length; k++){
								if(this.tableDataList[repeatArr[i].row][keys[k]].repeatRoot == endName && !this.tableDataList[repeatArr[i].row][keys[k]].isCopy){
									repeatCount += 1;
								}
							}
							repeatRow += repeatCount/this.surveyItemList[repeatArr[i].row].tableCol;
							repeatCount = 0;
						}
					}
					if(repeatArr[i].row != repeatArr[i+1].row)
					{
						endName = "";
						repeatRow = 0;
					}
				}
				else 
				{
					for(let j = 1; j < repeatArr[i].sortOrder; j++)
					{
						if(j==1){
								let colName = repeatArr[i].cellName.slice(0,1);
								let rowName = repeatArr[i].cellName.slice(1,10);
								let addRowName = Number(rowName) + Number(repeatRow);
								endName = colName + addRowName;
						}
						console.log("repeatName : " + endName);
						console.log("row : " + (Number(repeatArr[i].row)+1));
						this.repeat(endName, Number(repeatArr[i].row)+1);
					}
				}
			}
		},
		setData(){
			this.logger.debug(this, "setData()");
			let dataList = [];
			console.log(this.repeatList);
			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(Object.keys(this.tableDataList[i][keys[j]].field).length > 0 && !this.tableDataList[i][keys[j]].isHeader)
					{
						for(let k = 0; k < this.tableDataList[i][keys[j]].field.fieldList.length; k++)
						{
							if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == 1)
							{
								for(let l = 0; l < this.repeatList[i].procQuestionList.length; l++)
								{
									for(let t = 0; t < this.repeatList[i].procQuestionList[l].fieldList.length; t++)
									{
										if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == this.repeatList[i].procQuestionList[l].fieldList[t].repeatSortOrder)
										{
											if(this.tableDataList[i][keys[j]].field.fieldList[k].procFieldNo == this.repeatList[i].procQuestionList[l].fieldList[t].procFieldNo)
											{
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "beforeFieldValue", this.repeatList[i].procQuestionList[l].fieldList[t].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fieldValue", this.repeatList[i].procQuestionList[l].fieldList[t].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningInputNo", this.repeatList[i].procQuestionList[l].fieldList[t].testScreeningInputNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningEntryNo", this.repeatList[i].procQuestionList[l].fieldList[t].testScreeningEntryNo);
											}
										}
									}

								}
							}
							else{
								for(let a = 0; a < this.repeatList[i].procQuestionList.length; a++)
								{
									for(let s = 0; s < this.repeatList[i].procQuestionList[a].fieldRepeatList.length; s++)
									{
										if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == this.repeatList[i].procQuestionList[a].fieldRepeatList[s].repeatSortOrder)
										{
											if(this.tableDataList[i][keys[j]].field.fieldList[k].procFieldNo == this.repeatList[i].procQuestionList[a].fieldRepeatList[s].procFieldNo)
											{
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "beforeFieldValue", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fieldValue", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningInputNo", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].testScreeningInputNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningEntryNo", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].testScreeningEntryNo);
											}
										}
									}

								}
							}
						}
					}
				}
			}
			console.log(dataList);
		},
		getDateValue(index, cellName, fieldIndex, refName) {
			var fieldValueTemp = this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValueTemp;
			var fieldValue = this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValue;

			if(this.utils.isEmpty(fieldValueTemp))
			{
				if(this.utils.isEmpty(fieldValue))
				{
					return this.tableDataList[index][cellName].field.fieldList[fieldIndex].dateFormat;
				}
				else
				{
					return fieldValue;
				}
			}
			else
			{
				var isUkYYYY = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkYYYY;
				var isUkMM = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkMM;
				var isUkDD = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkDD;
				var isUkhh = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkhh;
				var isUkmm = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkmm;
				var isUkss = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkss;

				if(isUkYYYY || isUkMM || isUkDD || isUkhh || isUkmm || isUkss)
				{
					var date = new Date(this.$refs[refName][0].$data.currentValue);
					var dateFormat = this.tableDataList[index][cellName].field.fieldList[fieldIndex].dateFormat;
					
					if(isUkYYYY) dateFormat = dateFormat.replace('YYYY', 'UKUK');
					else dateFormat = dateFormat.replace('YYYY', date.getFullYear());
					if(isUkMM) dateFormat = dateFormat.replace('MM', 'UK');
					else dateFormat = dateFormat.replace('MM', ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)));
					if(isUkDD) dateFormat = dateFormat.replace('DD', 'UK');
					else dateFormat = dateFormat.replace('DD', (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()));
					if(isUkhh) dateFormat = dateFormat.replace('hh', 'UK');
					else dateFormat = dateFormat.replace('hh', (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()));
					if(isUkmm) dateFormat = dateFormat.replace('mm', 'UK');
					else dateFormat = dateFormat.replace('mm', (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()));
					if(isUkss) dateFormat = dateFormat.replace('ss', 'UK');
					else dateFormat = dateFormat.replace('ss', (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()));

					this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", dateFormat);
				}
				else
				{
					this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", fieldValueTemp);
				}
				return this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValue;
			}
		},
		setRowStyle(index, row, table){
			let rowInfo = this.rowInfoList[index];
			let height = rowInfo[row].height;
			let rowName = rowInfo[row].rowName;
			let count = 0;
			let resultHeight = height;

			if(this.workAccessCheck('EDIT REASON') && (this.entryStatus == '3')){
				let keys = Object.keys(this.tableDataList[index]);
	
				for(let j = 0; j < keys.length; j++){
					if(this.tableDataList[index][keys[j]].field != null){
						if(this.tableDataList[index][keys[j]].field.procNo == table.procNo && this.tableDataList[index][keys[j]].isHeader == false){
							let keyRow = keys[j].slice(1, 10);
							if(parseInt(keyRow) == parseInt(row)){
								count += 1;	
							}
						}
					}
				}
				if(count > 0){
					let splitHeight = height.split('px');
					
					resultHeight = parseInt(splitHeight) + 100;
					resultHeight += 'px';
				}
			}

			return {
				height: resultHeight,
				rowName : rowName
			};
		},
		setColumnStyle(index, col){
			let columnInfo = this.columnInfoList[index];
			let width = columnInfo[col].width;
			return width;
		},
		makeRepeatDiv(targetId, sortOrder){
			let keys = Object.keys(this.tableDataList[sortOrder-1]);
			let minRow = 0;
			let maxRow = 0;
			let countArr = [];
			let style = {};

			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableDataList[sortOrder-1][keys[i]].isRepeat && this.tableDataList[sortOrder-1][keys[i]].repeatRoot == targetId)
				{
					let rootId = keys[i].slice(1, 10);
					countArr.push(rootId);
				}
			}
			minRow = Math.min(...countArr);
			maxRow = Math.max(...countArr);
			console.log("minRow, maxRow");
			console.log(minRow, maxRow);
			// 세로
			let totalHeight = 0;
			if(minRow == maxRow)
			{
				totalHeight = Number(this.rowInfoList[sortOrder-1][minRow].height.replace('px', ''));
			} 
			else
			{
				for(let i = minRow ; i <= maxRow; i++)
				{
					totalHeight += Number(this.rowInfoList[sortOrder-1][i].height.replace('px', ''));
				}
			}
			style.height = totalHeight + "px";
			style.width = "1208.340px";
			style.border = "2px solid #2bade0";
			style.backgroundColor = "transparent";
			style.position = "absolute";
			style.top = "0";
			style.zIndex = "15";

			return style;
		},
		validApply(){
			this.logger.debug(this, "validApply()");
			
			let param = {
				screeningNo : this.selectViewScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.selectViewPageNo,
				visitNo : this.selectViewVisitNo,
			};

			axios
				.post(this.config.contextRoot + "/screening/validApply.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.selectViewTestScreeningNo);
						// this.validCount = (this.disableList.validCnt + this.msgList.validCnt + this.gotoList.validCnt + this.hideList.validCnt);
						if(response.data.applyValidVoList.length > 0){
							this.applyValidVoList = response.data.applyValidVoList;
							this.$nextTick(function(){
								this.validAction('LINK');
								this.validAction('POPUP');
							});
						}
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		validApplyTest(){
			this.logger.debug(this, "validApply()");
			
			let param = {
				testScreeningNo : this.selectViewTestScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.selectViewPageNo,
				visitNo : this.selectViewVisitNo,
			};

			axios
				.post(this.config.contextRoot + "/testScreening/validApply.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.selectViewTestScreeningNo);
						// this.validCount = (this.disableList.validCnt + this.msgList.validCnt + this.gotoList.validCnt + this.hideList.validCnt);
						if(response.data.applyValidVoList.length > 0){
							this.applyValidVoList = response.data.applyValidVoList;
							this.$nextTick(function(){
								this.validAction('LINK');
								this.validAction('POPUP');
							});
						}
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getViewOption(type, format) {
			var result;
			if(type == 3 || type == 11){
				if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") < 0 && format.indexOf("DD") < 0) 
					result = "year";
				else if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0 || format.indexOf("YYYY") < 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0) 
					result = "month";
				else result = "day";
			} else if (type == 4 || type == 12){
				result = "time";
			} else {
				result = "datetime";
			}
			return result;
		},
		validAction(type, procNo, procQuestionNo, index, cellName, fieldIndex, fieldTypeCd){
			if(type == 'PROC'){
				// proc 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultDsaList.length; j++){
						let dsaVo = detailVo.validResultDsaList[j];
						if(dsaVo.disabledTypeCd == 'PROC'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
	
								if(this.selectViewVisitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == procNo){
									if(dsaVo.dataRemoveYn == 'Y'){
										switch(fieldTypeCd) {
											case "04":
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", []);
												break;
											case "08":
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValueTemp", "");
												// eslint-disable-next-line no-fallthrough
											default:
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", "");
										}
									}
									return 'disabled'
								}
							}
						}
					}
				}
				// question 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultDsaList.length; j++){
						let dsaVo = detailVo.validResultDsaList[j];
						if(dsaVo.disabledTypeCd == 'QUESTION'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
	
								if(this.selectViewVisitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == procNo && dsaDetailVo.procQuestionNo == procQuestionNo){
									if(dsaVo.dataRemoveYn == 'Y'){
										switch(fieldTypeCd) {
											case "04":
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", []);
												break;
											case "08":
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValueTemp", "");
												// eslint-disable-next-line no-fallthrough
											default:
												this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", "");
										}
									}
									return 'disabled'
								}
							}
						}
					}
				}
				// hide question 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultHideList.length; j++){
						let hideVo = detailVo.validResultHideList[j];
						if(hideVo.hideTypeCd == 'QUESTION'){
							for(let k = 0; k < hideVo.validResultHideDetailList.length; k++){
								let hideDetailVo = hideVo.validResultHideDetailList[k];
	
								if(this.selectViewVisitNo == hideDetailVo.visitNo && hideDetailVo.procNo == procNo && hideDetailVo.procQuestionNo == procQuestionNo){
									return 'hide'
								}
							}
						}
					}
				}
			}
			else if(type == 'MSG'){
				// message type일시 출력 데이터 리턴
				let msgList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultMsgList.length; j++){
						let msgVo = detailVo.validResultMsgList[j];
						if(msgVo.printTypeCd == 'MESSAGE'){
							if(this.selectViewVisitNo == msgVo.visitNo && msgVo.procNo == procNo && msgVo.procQuestionNo == procQuestionNo){
								msgList.push(msgVo);
							}
						}
					}
				}
				return msgList;
			}
			else if(type == 'POPUP'){
				// popup type일시 출력 데이터 리턴
				let cnt = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultMsgList.length; j++){
						let msgVo = detailVo.validResultMsgList[j];
						if(msgVo.printTypeCd == 'POPUP' && msgVo.visitNo == this.selectViewVisitNo && msgVo.pageNo == this.param.pageNo){
							cnt.push(msgVo.printMsg);
						}
					}
				}
				for(let i = 0 ; i < cnt.length; i++){
					alert(cnt[i], "Warning", "warning");
				}
			}
			else if(type == 'GOTO'){
				let gotoList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultGotoList.length; j++){
						let gotoVo = detailVo.validResultGotoList[j];
						if(gotoVo.pageTypeCd == 'GOTO'){
							if(this.selectViewVisitNo == gotoVo.visitNo && gotoVo.procNo == procNo && gotoVo.procQuestionNo == procQuestionNo){
								gotoList.push(gotoVo)
							}
						}
					}
				}
				return gotoList;
			}
			else if(type == 'DYNAMIC'){
				let dynamicList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultGotoList.length; j++){
						let dynamicVo = detailVo.validResultGotoList[j];
						if(dynamicVo.pageTypeCd == 'DYNAMIC'){
							if(this.selectViewVisitNo == dynamicVo.visitNo && dynamicVo.procNo == procNo && dynamicVo.procQuestionNo == procQuestionNo){
								dynamicList.push(dynamicVo)
							}
						}
					}
				}
				return dynamicList;
			}
			else if(type == 'LINK'){
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validDataLinkList.length; j++){
						let linkVo = detailVo.validDataLinkList[j];
						
						for(let l = 0; l < this.tableDataList.length; l++)
						{
							let keys = Object.keys(this.tableDataList[l]);
							for(let t = 0; t < keys.length; t++)
							{
								if(!this.tableDataList[l][keys[t]].isHeader && Object.keys(this.tableDataList[l][keys[t]].field).length > 0)
								{
									let fieldNewList = this.tableDataList[l][keys[t]].field.fieldList;
									for(let k = 0; k < fieldNewList.length; k++)
									{
										if(this.param.pageNo == linkVo.pageNo && this.selectViewVisitNo == linkVo.visitNo){
											if(linkVo.procQuestionNo == fieldNewList[k].procQuestionNo && linkVo.procFieldNo == fieldNewList[k].procFieldNo){
												fieldNewList[k].fieldValue = linkVo.fieldValue;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		getMargin(field) {
			var prefixName = field.prefixName;
			var suffixName = field.suffixName;
			var fieldLayout = field.fieldLayoutCd;

			var topMargin = 0;
			var bottomMargin = 0;
			var leftMargin = 0;
			var rightMargin = 0;

			if (fieldLayout == "1")
			{
				if (prefixName != null && prefixName != "") topMargin = 5;
				if (suffixName != null && suffixName != "") bottomMargin = 5;
			}
			else
			{
				if (prefixName != null && prefixName != "") leftMargin = 10;
				if (suffixName != null && suffixName != "") rightMargin = 10;
			}

			return {
				marginTop: topMargin + "px",
				marginBottom: bottomMargin + "px",
				marginLeft: leftMargin + "px",
				marginRight: rightMargin + "px",
			};
		},
		getGridRowCol(length, cols) {
			var value = parseInt(Number(length) / Number(cols));
			var etc = Number(length) % Number(cols);

			var row = 0;
			var col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(36px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		loadedImage(field) {
			this.$set(field, 'isLoad', true);
		},
		isNumeric(v) {
			return /^\d+$/.test(v);
		},
		dateCheck(event, length) {
			console.log(event);
			let value = event.target.value;
			if(!(value == "UK") && !this.isNumeric(value))
			{
				event.target.value = "";
			}
			if(value != "UK" && value.length != length)
			{
				event.target.value = "";
			}
		},
		getProcQuestionIndex(index, cellId) {
			let tableData = this.tableDataList[index];
			let procQuestionNo = tableData[cellId].field.procQuestionNo;
			// let repeatSortOrder = tableData[cellId].field.fieldList[0].repeatSortOrder;
			let procQuestionList = this.surveyItemList[index].procQuestionList.map((obj) => {
				let endname = obj.procQuestionNo;
				return endname;
			});
			let getIndex = procQuestionList.indexOf(procQuestionNo);
			console.log("cellId = " + cellId + ", getIndex : " + getIndex);
			return Number(getIndex);
		},
		clearDate(index, cellName, fieldIndex) {
			this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValueTemp", "");
			this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", "");
			setTimeout(function() {
				this.doValidCalc(true);
				let popup = document.getElementsByClassName("mx-datepicker-popup")[0];
				popup.style.display = "none";
			}.bind(this), 0);
		},
		validateInput(index, cellId, fieldIndex) {
			let dataTypeCd = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataTypeCd;
			let value = this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue;
			let pattern = "";
			let num = 0;
			let digit = 0;
			
			if(dataTypeCd == '1' || dataTypeCd == '7')
			{
				let dataSize = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataSize;
				value = value.slice(0, dataSize);
				this.$set(this.tableDataList[index][cellId].field.fieldList[fieldIndex], "fieldValue", value);
				return true;
			}
			else if(dataTypeCd == '2' || dataTypeCd == '8')
			{
				let num = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataSize;
				pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				if(!pattern.test(value) ) return false;
				else return true;
			}
			else if(dataTypeCd == '6' || dataTypeCd == '9')
			{
				let decimalDigit = this.tableDataList[index][cellId].field.fieldList[fieldIndex].decimalDigit;
				let sep = decimalDigit.split(".");
				if(sep.length == 1)
				{
					num = sep[0];
					pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				}
				else if(sep.length == 2)
				{
					num = sep[0];
					digit = sep[1];
					pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				}
				if(!pattern.test(value)) return false;
				else return true;
			}
			else return true;
		},
		msgPopup(field, event){
			if(field.openMsgYn == 'Y'){
				event.target.children[0].style.animation = "hideTopBalloon 0.3s ease-out both";
				field.openMsgYn = 'N';
			}
			else{
				field.openMsgYn = 'Y';
			}
		},
		msgHover(event, type){
			if(type == 'over'){
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.overflow = 'visible';
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.zIndex = '500';
			}
			if(type == 'leave'){
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.overflow = 'hidden'
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.zIndex = '0';
			}
		},
		missingQueryCheck(field, fieldValue){
			let result = false;
			if(field.queryResultList.length > 0){
				let valueCount = [];
				for(let i = 0; i < field.queryResultList.length; i++){
					if(field.queryResultList[i].resultValue != '' || fieldValue != ''){
						valueCount.push(field.queryResultList[i].resultValue);
					}
				}
				if(valueCount.length <= 0){
					result = true;
				}
			}
			return result; 
		},
		cellStyle(index, id) {
			let style = {};
			let cell = this.tableDataList[index][id];

			if(cell.isExpand && cell.expandCol < 2 && cell.expandRow < 2)
			{
				style.display = "none";
			}
			if(cell.isHeader)
			{
				style.backgroundColor = "#f5f5f5";
				style.fontWeight = "600";
			}

			style.textAlign = cell.align;
			style.justifyContent = cell.align;

			return style;
		},
		getFieldList(index, id) {
			if(Object.keys(this.tableDataList[index][id].field).length !== 0)
			{
				return this.tableDataList[index][id].field.fieldList;
			}
			else
			{
				return [];
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
	}
};
</script>

<style scoped>
.screening-contents-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 25px;
    position: absolute;
    width: 95%;
    height: calc(100% - 300px);
    padding: 10px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 50;
}

@media ( max-width: 1200px) {
    .screening-contents-container {
        grid-template-columns: 100% !important;
        left: calc(35% + 60px) !important;
        width: calc(65% - 90px) !important;
        transition: left 0.4s linear !important;
    }
}

.screening-contents-container::-webkit-scrollbar {
    width: 18px;
}

.screening-contents-container::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to top, #9ecc5a, #2bade0);
    background-clip: padding-box;
}

.screening-contents-container::-webkit-scrollbar-track {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 20px;
    background: #eee;
    background-clip: padding-box;
}

.screening-contents-container::after {
    content: "";
    display: block;
    width: 100%;
    min-height: 1px;
}

.unfocus-text,
.table-head {
	display: flex;
	justify-content: inherit;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
	z-index: 15;
}

.table-data {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
}

.survey-field-list {
	width: 100%;
	font-weight: 600 !important;
	font-size: 10pt !important;
	color: #666;
	overflow-y: visible;
}

.survey-field-vtype,
.survey-field-htype {
    max-width: 100%;
	font-weight: 400 !important;
}

.input-crud-error::placeholder {
	color: rgb(224, 91, 91);
}

.btn-cancel {
	margin-right: 10px;
}

.dinput-info {
	display: flex;
	align-items: center;
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.dinput-info > .item {
	width: 25%;
	border-left: 1px solid #ccc;
}

.dinput-info > .item:first-child {
	border-left: none;
}

.dinput-info > .item > .title {
	padding: 8px 0 8px 15px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
	border-bottom: 1px solid #ccc;
	background-color: #eee;
	text-align: left;
}

.dinput-info > .item > .content {
	padding: 15px 0 15px 15px;
	font-size: 10.5pt;
	color: #999;
	text-align: left;
}

.procedure-input-wrap {
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
}

.procedure-title {
	padding: 8px 0 8px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #e3f7ff;
	font-weight: 600;
	font-size: 10.5pt;
	color: #000;
	text-align: left;
}

.procedure-field {
	display: flex;
}

.procedure-question {
	display: flex;
	align-items: center;
	width: 20%;
	padding: 20px 0 20px 15px;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #333;
	text-align: left;
}

.procedure-form {
	width: 80%;
	padding: 20px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
	font-weight: 400;
	font-size: 10.5pt;
	color: #666;
	text-align: left;
}

.procedure-form > .procedure-item:last-child {
	margin: 0;
}

.procedure-item {
	display: block;
	margin: 0 0 15px 0;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
}

.procedure-item > .vertical {
	display: block;
}

.procedure-item > .horizon {
	display: inline-block;
}

.input-crud {
	max-width: 100%;
}

.textarea-crud {
	height: unset !important;
}

.radio-crud,
.checkbox-crud {
	font-weight: 400;
	font-size: 10pt;
}

.select-crud {
	width: auto !important;
	max-width: 100% !important;
}

.btn-temp,
.btn-history:hover {
	border: 1px solid #2bade0 !important;
	color: #2bade0 !important;
}

.txt-crud-title {
	justify-content: space-between;
}


.procedure-table {
	width: 100%;
	border-collapse: collapse;
}

.procedure-table:focus {
	outline: none;
}

.procedure-table tr {
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead th {
	position: relative;
	min-width: 30px;
	height: 35px;
	overflow-x: auto;
	resize: horizontal;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead tr th:first-child {
	width: 35px;
	resize: none;
}

.procedure-table th {
	border: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10pt;
	color: #000;
}

.procedure-table td {
	position: relative;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	word-break: keep-all;
}

.procedure-table td > input[type=text] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	border: none;
	background-color: unset;
	font-weight: inherit;
	font-size: inherit;
	text-align: inherit;
}

.procedure-table .focus-celltype {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	font-family: cursive;
	font-weight: 500;
	font-size: 9pt;
	text-align: right;
	overflow: hidden;
	z-index: 5;
	opacity: 0.6;
}

.repeat-Btn {
	width: 20px;
	height: 20px;
	font-size: 20px;
	position: absolute;
	margin: 5px 5px;
	display: flex;
    align-items: center;
    justify-content: center;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
	z-index: 20;
	top: 0;
	left: 1175px;
}

.procedure-table .focus-celltype .celltype-header {
	display: inline-block;
	margin: 0 5px;
	padding: 2px 5px;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-text {
	display: inline-block;
	padding: 2px 5px;
	background-color: #9ecc5a;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-data {
	display: inline-block;
	padding: 2px 5px;
	background-color: #cc5a5a;
	border-radius: 3px;
	color: #fff;
}

.download-preview {
	color: rgb(0, 162, 255);
}

.download-preview:hover {
	text-decoration: underline;
}

.btn-preview-close {
    display: inline-block;
    position: fixed;
    top: 10px;
    left: calc(50% - 140px);
    width: 36px;
    height: 36px;
    border-radius: 36px;
    z-index: 5555;
    font-weight: 600;
    font-size: 10pt;
    color: #fff;
}

.btn-preview-close:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.btn-calc {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 10px;
	padding: 3px 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	font-weight: 600;
	font-size: 9.5pt;
	color: #999;
}

.btn-calc:hover {
	border: 1px solid #aaa;
	color: #666;
}
.valid-msg{
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #cc5a5a;
	cursor: pointer;
}
.open-query{
	cursor: pointer;
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #fff;
	border: 1px solid;
	color: rgb(224, 91, 91);
	text-align: center;
	font-size: 7pt;
	font-weight: bold;
}
.valid-goto{
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #2bade0;
	cursor: pointer;
}

.valid-event-zone{
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	margin: 5px 5px 0 0;
	display: flex;
    column-gap: 5px;
}

.valid-event-sub{
	display: flex;
	column-gap: 20px;
	font-size: 10.5pt;
    color: #999;
	margin-bottom: 5px;
}

.valid-event-part{
	display: flex;
    align-items: center;
    column-gap: 5px;
}

.blinking{
	-webkit-animation: blink 0.5s ease-in-out infinite alternate;
	-moz-animation: blink 0.5s ease-in-out infinite alternate;
	animation: blink 0.9s cubic-bezier(0, 0, 0.05, 1.0) infinite alternate
}

@-webkit-keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-moz-keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}
</style>