<template>
	<div>
		<p class="txt-list-title">Procedure List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the procedure name to search."
				@input="searchProcedure($event.target.value)"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<div class="btn-wrap">
				<button
					type="button"
					class="btn-grey"
					title="Sort"
					@click="procedureSort()"
				>
					Sort
				</button>
				<!-- <button
					type="button"
					class="btn-grey"
					title="Copy"
					@click="procedureCopy()"
				>
					Copy
				</button> -->
				<button
					type="button"
					class="btn-new"
					title="New Procedure"
					@click="doCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New Procedure
				</button>
			</div>
		</div>
		<table class="tb-list-vertical">
			<colgroup>
				<col width="5%" />
				<col width="18%" />
				<col width="auto" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						Page
					</th>
					<th>
						Procedure
					</th>
					<th>
						Field Count
					</th>
					<th>
						Sort Order
					</th>
					<th>
						Delete
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in procedureList"
					:key="index"
					tabindex="0"
					@click.stop="doEdit(item)"
				>
					<td>
						{{ item.rowNumber }}
					</td>
					<td class="ellipsis underline" :title="item.pageName">
						{{ item.pageName }}
					</td>
					<td>
						{{ item.procName }}
					</td>
					<td>
						{{ item.fieldCount }}
					</td>
					<td>
						{{ item.procSortOrder }}
					</td>
					<td>
						<button
							type="button"
							class="btn-remove"
							title="Remove Procedure"
							@click.stop="selectVisit(item)"
						></button>
					</td>
				</tr>
				<tr v-if="procedureList.length == 0">
					<td colspan="6" class="no-list">
						There is no registered procedure, Please register the procedure.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<ProcedureSort v-if="isProcedureSort" :projectNo="items.projectNo" />
		<ProcedureCopy v-if="isProcedureCopy" :projectNo="items.projectNo" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import ProcedureSort from "@/components/project/procedure/ProcedureSort.vue"
import ProcedureCopy from "@/components/project/procedure/ProcedureCopy.vue"

export default {
	name: "ProcedureList",
	components: {
		ProcedureSort,
		ProcedureCopy
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.selectProcedureList();
	},
	data() {
		return {
			items: {},
			procedureList: [],
			pagination: {},
			isProcedureSort: false,
			isProcedurePDF: false,
			isProcedureCopy: false,
			pdfParam: {},
			isTyping: false,
		};
	},
	watch: { },
	methods: {
		searchProcedure(val) {
			this.items.procName = val;
			this.items.pageNum = 1;
		},
		selectProcedureList() {
			this.logger.debug(this, "selectProcedureList()");
			axios
				.post(this.config.contextRoot + "/procedure/selectList.do", this.items)
				.then((response) => {
					console.log(response);
					this.procedureList = response.data.procedureList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.items.pageNum = page;
			this.selectProcedureList();
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/procedure/create"
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(procedure) {
			this.logger.debug(this, "doEdit(procedure)");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/procedure/edit/" + procedure.procNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		selectVisit(item) {
			this.logger.debug(this, "selectVisit()");

			axios
				.post(this.config.contextRoot + "/procedure/selectVisit.do", {pageNo: item.pageNo})
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
					if(this.visitList.length > 0)
					{
						this.$alert("I can't delete the data because there's a visit.", "Warn", "warning");
						return;
					}
					else if(this.visitList.length == 0)
					{
						this.doDelete(item);
					}

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doDelete(procedure) {
			this.logger.debug(this, "doDelete(notice)");
			this.$confirm("Are you sure you want to delete the procedure?","Question","question").then(() => {
				axios
					.post(this.config.contextRoot + "/procedure/delete.do", {procNo: procedure.procNo})
					.then((response) => {
						console.log(response);
						location.reload();
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
		procedureCopy() {
			this.logger.debug(this, "procedureCopy()");
			this.isProcedureCopy = true;
		},
		procedureSort() {
			this.logger.debug(this, "procedureSort()");
			this.isProcedureSort = true;
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.selectProcedureList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		}
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}

.btn-wrap {
	display: inline-block;
	position: relative;
	float: right;
}
</style>