<template>
	<div ref="emrColumnManageCreate" class="dialog-container">
		<div class="dialog" style="max-height: 500px; transform: translate(0, -250px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Emr Column Create
					</p> 
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="33%" />
							<col width="17%" />
							<col width="33%" />
						</colgroup>
						<tbody>
							<tr>
								<th>
									No
								</th>
								<td colspan="3">
									<p>Auto Increment</p>
								</td>
							</tr>
							<tr>
								<th>
									Korean Column
								</th>
								<td colspan="3">
									<input
										type="text"
										class="input-crud"
										name="columnKoreanName"
										v-model="item.columnKoreanName"
										v-validate="'required'"
										data-vv-as="Korean Name"
										@input="doDuplicateCheck()"
									/>
									<p class="txt-validation" v-if="errors.has('columnKoreanName')">
										* {{ errors.first("columnKoreanName") }}
									</p>
									<p class="txt-validation" v-if="isDuplicate">
										* The column is duplicated.
									</p>
								</td>
							</tr>
							<tr>
								<th>
									English Column
								</th>
								<td colspan="3">
									<input
										type="text"
										class="input-crud"
										name="columnEnglishName"
										v-model="item.columnEnglishName"
										v-validate="'required'"
										data-vv-as="English Name"
										@input="doDuplicateCheck()"
									/>
									<p class="txt-validation" v-if="errors.has('columnEnglishName')">
										* {{ errors.first("columnEnglishName") }}
									</p>
									<p class="txt-validation" v-if="isDuplicate">
										* The column is duplicated.
									</p>
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ item.firstRegistUserName }} ({{ item.firstRegistUserId }})
								</td>
								<th>
									Regist Date
								</th>
								<td>
									{{ item.firstRegistDate }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";

export default {
	name: "EmrColumnManageCreate",
	created() {
		this.logger.debug(this, "created()");
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.firstRegistUserId = this.userInfo.userId;
		this.item.firstRegistUserName = this.userInfo.userName;
	},
	data() {
		return {
			item: {},
			isDuplicate: false
		};
	},
	methods: {
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			if(this.item.columnKoreanName != "" && this.item.columnEnglishName != "")
			{
				axios
				.post(this.config.contextRoot + "/emr/duplicateColumnCheck.do", this.item)
				.then((response) => {
					console.log(response);
					this.isDuplicate = response.data.isDuplicate;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			}
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to register the Data Column?", "Question", "question").then(() => {
							EventBus.$emit("setting-loading", true);
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			this.item.columnType = "Typing";
			axios
				.post(this.config.contextRoot + "/emr/insertColumn.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the emr registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.emrColumnManageCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.emrColumnManageCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("setting-cancel", "isEmrColumnManageCreate");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>