<template>
	<div class="dialog" style="max-height: 800px; transform: translate(0, -1200px)" @mouseup="dragEnd()">
		<div class="top">
			<p class="txt-crud-title">
				EMR Data List
			</p>
		</div>
		<div class="center">
			<input type="text" class="input-list-search" placeholder="Please enter the search text." style="margin: 0 0 10px 0;" v-model="searchText" @keyup.enter="selectSearchText()">
			<div ref="scrollWrap" class="scroll-wrap scroll">
				<div class="scroll-thead-wrap">
					<table class="tb-list-vertical">
						<thead>
							<tr>
								<th></th>
								<th>No</th>
								<th v-for="(col, colIndex) in columnList" :key="colIndex">
									{{col.columnEnglishName}}
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(data, dataIndex) in dataList">
								<tr v-if="showRow[dataIndex] == true" :key="dataIndex">
									<td>
										<input
											type="checkbox"
											class="emr-check"
											v-model="dataList[dataIndex].isChecked"
										/>
									</td>
									<td>
										{{ dataIndex + 1 }}
									</td>
									<template v-for="(col, colIndex) in columnList">
										<td
											v-if="data[col.columnName] !== undefined"
											:key="colIndex"
											:class="(showRow[dataIndex] == true && data[col.columnName].indexOf(searchText) >= 0 && searchText != '' ? 'yellow' : '')"
										>
											{{ data[col.columnName] }}
										</td>
										<td
											v-if="data[col.columnName] === undefined"
											:key="colIndex"
											style="color: #ccc;"
										>
											(EMPTY)
										</td>
									</template>
								</tr>
							</template>
							<tr v-if="dataList.length == 0">
								<td :colspan="columnList.length + 1" class="no-list">
									There is no registered data, Please register the data.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="scroll-tbody-wrap" @scroll="scrollPaging($event)">
					<table class="tb-list-vertical no-drag">
						<thead>
							<tr>
								<th></th>
								<th>No</th>
								<th
									v-for="(col, colIndex) in columnList"
									:key="colIndex"
								>
									{{col.columnEnglishName}}
								</th>
							</tr>
						</thead>
						<!-- <tbody @mousedown="dragStart($event)" @mousemove="dragging($event)"> -->
						<tbody>
							<template v-for="(data, dataIndex) in dataList">
								<tr v-if="showRow[dataIndex] == true" :key="dataIndex">
									<td>
										<input
											type="checkbox"
											class="emr-check"
											v-model="dataList[dataIndex].isChecked"
										/>
									</td>
									<td>
										{{ dataIndex + 1 }}
									</td>
									<template v-for="(col, colIndex) in columnList">
										<td
											v-if="data[col.columnName] !== undefined"
											:key="colIndex"
											:class="(showRow[dataIndex] == true && data[col.columnName].indexOf(searchText) >= 0 && searchText != '' ? 'yellow' : '')"
										>
											{{ data[col.columnName] }}
										</td>
										<td
											v-if="data[col.columnName] === undefined"
											:key="colIndex"
											style="color: #ccc;"
										>
											(EMPTY)
										</td>
									</template>
								</tr>
							</template>
							<tr v-if="dataList.length == 0">
								<td :colspan="columnList.length + 2" class="no-list">
									There is no registered data, Please register the data.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="bottom" style="position: relative;">
			<div v-if="isLoading" class="row-loading">
				<img src="@/assets/images/common/row-loading.png" alt="" />
				Row Loading
			</div>
			<button type="button" class="btn-save fright" title="Import" @click="doImport()">
				Get
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
				Cancel
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "screeningEmrList",
	components: {},
	created() {
		this.logger.debug(this, "created()");
		this.dataPagination.currentPage = 1;
		this.dataPagination.perPage = 100;
		this.selectColumnList();
	},
	data() {
		return {
			item: {},
			columnList: [],
			dataList: [],
			dataPagination: {},
			showRow: {},
			searchText: "",
			isDrag: false,
			dragData: {},
			isLoading: false,
		};
	},
	methods: {
		selectSearchText() {
			EventBus.$emit("project-loading", true);
			this.dataPagination.searchText = this.searchText;
			this.dataPagination.currentPage = 0;
			this.selectDataList();
			for(let i = 0 ; i < this.dataList.length ; i++)
			{
				let equalCount = 0;
				for(let j = 0 ; j < this.columnList.length ; j++)
				{
					if(typeof this.dataList[i][this.columnList[j].columnName] !== "undefined")
					{
						if(this.dataList[i][this.columnList[j].columnName].indexOf(this.searchText) >= 0) {
							equalCount++;
						}
					}
				}
				if(equalCount > 0)
				{
					this.showRow[i] = true;
				}
				else
				{
					this.showRow[i] = false;
				}
			}
		},
		selectColumnList() {
			this.logger.debug(this, "selectColumnList()");
			axios
				.post(this.config.contextRoot + "/emr/selectColumnOneList.do", this.item)
				.then((response) => {
					console.log(response);
					this.columnList = response.data.emrColumnOneList;
					this.selectDataList();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectDataList() {
			this.logger.debug(this, "selectDataList()");
			axios
				.post(this.config.contextRoot + "/emr/selectDataList.do", this.dataPagination)
				.then((response) => {
					console.log(response);
					this.$set(this, "dataList", response.data.emrDataList);

					this.showRow = new Object();
					for(let i = 0 ; i < this.dataList.length ; i++)
					{
						this.showRow[i] = true;
					}

					this.$nextTick(function () {
						this.isLoading = false;
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doImport() {
			this.logger.debug(this, "selectDataList()");
			let checkedList = this.dataList.filter((item) => {
				return item.isChecked !== undefined && item.isChecked == true
			});
			axios
				.post(this.config.contextRoot + "/emr/selectConvertedEmrData.do", checkedList)
				.then((response) => {
					console.log(response);

					let resultList = response.data.resultList;
					EventBus.$emit("emr-import", resultList);

					this.doClose();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the import EMR data?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			EventBus.$emit("emr-close");
		},
		dragStart(event) {
			this.logger.debug(this, "dragStart(event)");
			this.isDrag = true;
			this.dragData.startX = event.clientX;
		},
		dragEnd() {
			this.logger.debug(this, "dragEnd()");
			this.isDrag = false;
		},
		dragging(event) {
			if(this.isDrag)
			{
				let classList = event.path.map(item => {
					return item.className;
				});
				let index = classList.indexOf("scroll-wrap");

				let dragX = event.clientX;
				let direct = dragX - this.dragData.startX;
				let scollLeft = event.path[index].scrollLeft;
				
				event.path[index].scrollLeft = scollLeft - direct;
				this.dragData.startX = event.clientX;
			}
		},
		scrollPaging(event) {
			let clientHeight = event.target.clientHeight;
			let scrollHeight = event.target.scrollHeight;
			let scrollTop = event.target.scrollTop;
			if(clientHeight == (scrollHeight - scrollTop) && this.isLoading == false)
			{
				this.logger.debug(this, "scrollPaging(event)");
				this.dataPagination.currentPage = this.dataPagination.currentPage + 1;
				this.isLoading = true;
				this.selectDataList();
			}
		},
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}

.dialog .center {
	overflow: hidden;
}

.scroll-wrap {
	position: relative;
	width: 100%;
	height: calc(100% - 43px);
	overflow-x: scroll;
}

.tb-list-vertical th,
.tb-list-vertical td {
	padding: 0 20px;
	white-space: nowrap;
}

.tb-list-vertical tbody tr:focus>td, .tb-list-vertical tbody tr:hover>td {
	cursor: initial;
}

.scroll-thead-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: max-content;
	height: 54px;
	overflow-y: hidden;
	z-index: 10;
}

.scroll-thead-wrap tbody {
	visibility: hidden;
}

.scroll-tbody-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: max-content;
	height: 100%;
	overflow-y: scroll;
	z-index: 5;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.scroll-tbody-wrap::-webkit-scrollbar {
	display: none;
}

.scroll-tbody-wrap thead {
	visibility: hidden;
}

.emr-check {
	margin: 0 10px;
}

.yellow {
	background-color: yellow !important;
}

.row-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	font-weight: 600;
	font-size: 11pt;
	color: #999;
}

.row-loading img {
	width: 16px;
	height: 16px;
	margin: 0 10px 0 0;
	animation: loading 1.2s linear infinite both;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>