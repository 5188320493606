<template>
	<div ref="screeningHistory" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px); max-width: 1500px;">
			<form @submit.stop="doValidation()">
				<div class="top" style="height: 70px">
					<p class="txt-crud-title">
						Data History
					</p>
                    <select
                        class="select-crud"
                        style="width: 130px;"
                        v-model="selectedOption"
                    >
                        <option
                            v-for="(option, selectIndex) in selectOption" :key="selectIndex"
                        >
                            {{ option }}
                        </option>
                    </select>
                    <input
                        type="text"
                        class="input-list-search"
                        style="margin-left: 10px;"
                        :placeholder="'Please the' + ' ' + selectedOption + ' ' +'name to search.'"
                        v-model="searchText"
                        @input="searchText = $event.target.value"
                    />
				</div>
				<div class="center" style="height: calc(100% - 115px)">
					<table class="tb-list-vertical">
                        <colgroup>
                            <col width="3%" />
                            <col width="12%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="auto" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="8%" />
                            <!-- <col width="8%" />
                                <col width="5%" /> -->
                            <col width="14%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    No
                                </th>
                                <th>
                                    Screening No
                                </th>
                                <th>
                                    Visit
                                </th>
                                <th>
                                    Page
                                </th>
                                <th>
                                    Procedure
                                </th>
                                <th>
                                    Question
                                </th>
                                <th>
                                    Before
                                </th>
                                <th>
                                    After
                                </th>
                                <th>
                                    Reason
                                </th>
                                <th>
                                    User
                                </th>
                                <!-- <th>
                                    Sortation
                                </th>
                                <th>
                                    Sortation Value
                                </th> -->
                                <th>
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in screeningHistoryList" :key="index">
                                <td>
                                    {{ history.rowNumber }}
                                </td>
                                <td>
                                    {{ history.screeningName }}
                                </td>
                                <td>
                                    {{ history.visitName }}
                                </td>
                                <td>
                                    {{ history.pageName }}
                                </td>
                                <td>
                                    {{ history.procName }}
                                </td>
                                <td>
                                    {{ history.questionName }}
                                </td>
                                <td>
                                    {{ history.beforeFieldValue == '' ? '-' : history.beforeFieldValue}}
                                </td>
                                <td>
                                    {{ history.afterFieldValue == '' ? '-' : history.afterFieldValue }}
                                </td>
                                <td>
                                    {{ getComCodeName(history.updateReason) == '' ? '-' : history.updateReason == '4' ? history.etcReason : getComCodeName(history.updateReason)}}
                                </td>
                                <td>
                                    <b>{{ history.firstRegistUserId }}</b><br>
                                    ({{ history.userName }})
                                    <!-- {{ history.screeningHistoryStatusName }} -->
                                </td>
                                <!-- <td>
                                    {{ history.dataSortationName }}
                                </td>
                                <td>
                                    {{ history.dataSortationValue }}
                                </td> -->
                                <td>
                                    {{ history.firstRegistDate.substring(0, 19) }}
                                </td>
                            </tr>
                            <tr v-if="screeningHistoryList.length == 0">
                                <td colspan="11" class="no-list">
                                    There is no registered page, Please register the page.
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="excelDownload()">
						Excel Download
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ScreeningHistory",
	created() {
        this.logger.debug(this, "created()");
        this.item.screeningNo = this.param.screeningNo;
        this.item.testScreeningNo = this.param.testScreeningNo;
        this.item.visitNo = this.param.visitNo;
        this.item.pageNo = this.param.pageNo;

        this.selectComCodeList();
        if(this.param.screeningNo != null){
            this.selectScreeningHistory();
        }
        else{
            this.selectTestScreeningHistory();
        }

    },
    props: {
		param: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
            item: {},
            searchText: "",
            screeningHistoryList: [],
            selectOption: ["visit", "page", "question", "procedure"],
            selectedOption: "visit",
            codeList: []
		};
    },
    watch: {
		searchText: function(val) {
			this.item.pageName = val;
            this.item.pageNum = 1;
            this.item.selectedOption = this.selectedOption;
            
			this.selectScreeningHistory();
        },
        selectedOption: function(val) {
            this.selectedOption = val;
        }
	},
	methods: {
        selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["UPDATE_REASON"]})
				.then((response) => {
					this.codeList = response.data.codeList;

					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("field-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectScreeningHistory() {
            EventBus.$emit("project-loading", true);
            this.logger.debug(this, "selectScreeningHistory()");

			axios
				.post(this.config.contextRoot + "/screening/selectScreeningHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.screeningHistoryList = response.data.screeningHistoryList;
                    EventBus.$emit("project-loading", false);
				})
				.catch((error) => {
                    this.exceptionHandler(this, error);
				});
                
        },
        excelDownload() {
            EventBus.$emit("project-loading", true);
            this.logger.debug(this, "excelDownload()");

            this.item.screeningHistoryVoList = this.screeningHistoryList;

			axios
				.post(this.config.contextRoot + "/excel/dataHistoryDownload.do", this.item, {responseType: "blob"})
				.then((response) => {
					console.log(response);
                    const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					
					let today = new Date();
					let year = today.getFullYear();
					let month = ('0' + (today.getMonth() +1)).slice(-2);
					let day = ('0' + (today.getDate())).slice(-2);

					today = year + "-" + month + "-" + day;

                    var name = "Data_History_" + this.param.screeningName + "_" + this.param.visitName + "_" + this.param.pageName;

					link.setAttribute("download", name + "_"+today+".xlsx");
					document.body.appendChild(link);
					link.click();

					EventBus.$emit("project-loading", false);
					
					this.$alert("The file has been downloaded.", "Info", "info").then((result) => {
						if (result === true) {
							// location.reload();
						}
					});
				})
				.catch((error) => {
                    this.exceptionHandler(this, error);
				});
                
        },
		selectTestScreeningHistory() {
            EventBus.$emit("project-loading", true);
            this.logger.debug(this, "selectTestScreeningHistory()");

			axios
				.post(this.config.contextRoot + "/testScreening/selectTestScreeningHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.screeningHistoryList = response.data.screeningHistoryList;
                    EventBus.$emit("project-loading", false);
				})
				.catch((error) => {
                    this.exceptionHandler(this, error);
				});
                
        },
        doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined") {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectScreeningHistory();
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.screeningHistory.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.screeningHistory.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("history-close", "isScreeningHistory");
			}.bind(this), 410);
		},
        getComCodeName(code){
            let name = '';
            this.codeList.forEach(item => {
                if(item.comCode == code){
                    name = item.comCodeName;
                }
            })
            return name;
        }
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>