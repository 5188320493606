<template>
	<div ref="siteCreate" class="dialog-container">
		<div class="dialog" style="max-height: 600px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Site Create
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="33%" />
							<col width="17%" />
							<col width="33%" />
						</colgroup>
						<tbody>
							<tr>
								<th>
									Site Code.
								</th>
								<td colspan="3">
									<input
										type="text"
										class="input-crud"
										name="siteCode"
										v-model="item.siteCode"
										v-validate="'required'"
										data-vv-as="Site Code"
									/>
									<p class="txt-validation" v-if="errors.has('siteCode')">
										* {{ errors.first("siteCode") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Site Name.
								</th>
								<td colspan="3">
									<input
										type="text"
										class="input-crud"
										name="siteName"
										v-model="item.siteName"
										v-validate="'required'"
										data-vv-as="Site Name"
									/>
									<p class="txt-validation" v-if="errors.has('siteName')">
										* {{ errors.first("siteName") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Manager Name.
								</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="managerName"
										v-model="item.managerName"
										v-validate="'required'"
										data-vv-as="Manager Name"
									/>
									<p class="txt-validation" v-if="errors.has('managerName')">
										* {{ errors.first("managerName") }}
									</p>
								</td>
								<th>
									Manager Phone.
								</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="managerTelno"
										v-model="item.managerTelno"
										v-validate="'required'"
										data-vv-as="Manager Phone"
									/>
									<p class="txt-validation" v-if="errors.has('managerTelno')">
										* {{ errors.first("managerTelno") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Register.</th>
								<td>
									{{item.firstRegistUserName}} ({{item.firstRegistUserId}})
								</td>
								<th>
									Regist Date.
								</th>
								<td>
									{{item.firstRegistDate}}
								</td>
							</tr>
							<tr>
								<th>Etc.</th>
								<td colspan="3">
									<textarea
										class="textarea-crud"
										name="siteEtc"
										v-model="item.siteEtc"
									></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";

export default {
	name: "SiteCreate",
	created() {
		this.logger.debug(this, "created()");
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.firstRegistUserId = this.userInfo.userId;
		this.item.firstRegistUserName = this.userInfo.userName;
	},
	data() {
		return {
			item: {}
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);

					axios
						.post(this.config.contextRoot + "/site/siteCodeFlag.do", this.item)
						.then((response) => {
							console.log(response);
							
							if(response.data.flag)
							{
								this.$alert("This is the site code in use.", "Warn", "warning");
								return;
							}
							else{
								if (result)
								{
									this.$confirm("Do you want to register the current site?", "Question", "question").then(() => {
										EventBus.$emit("setting-loading", true);
										this.doInsert();
									});
								}
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});
					
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			axios
				.post(this.config.contextRoot + "/site/insert.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the site registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.siteCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.siteCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("setting-cancel", "isSiteCreate");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>