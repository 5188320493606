<template>
	<div ref="pageEdit" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Page Edit
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="33%" />
							<col width="17%" />
							<col width="33%" />
						</colgroup>
						<tbody>
                            <tr>
                                <th>Page Name.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        class="input-crud"
										:class="(errors.has('visitName') || isDuplicate ? 'input-crud-error' : '')"
										:placeholder="(errors.has('pageName') ? '* ' + errors.first('pageName') : 'The Page Name field is required.')"
                                        name="pageName"
										v-model="item.pageName"
										v-validate="'required'"
										data-vv-as="Page Name"
										@input="item.pageName = $event.target.value"
										@keyup="selectDuplicateCheck()"
                                    />
									<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;"> 
										* Page Name is duplicated.
									</p>
									<p class="txt-validation" v-if="errors.has('pageName')">
										* {{ errors.first("pageName") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Page Label.</th>
                                <td colspan="3">
                                    <input 
                                        type="text"
                                        class="input-crud"
                                        name="pageLabel"
										v-model="item.pageLabel"
										v-validate="'required'"
										data-vv-as="Page Label"
                                    />
									<p class="txt-validation" v-if="errors.has('pageLabel')">
										* {{ errors.first("pageLabel") }}
									</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Sort Order.</th>
                                <td colspan="3">
                                    <input 
                                        type="number"
                                        class="input-crud"
                                        name="pageSortOrder"
										v-model="item.pageSortOrder"
										v-validate="'required'"
										data-vv-as="Page Sort Order"
                                    />
									<p class="txt-validation" v-if="errors.has('pageSortOrder')">
										* {{ errors.first("pageSortOrder") }}
									</p>
                                </td>
                            </tr>
							<tr>
                                <th>Use UV Page</th>
                                <td colspan="3">
									<label class="radio-crud">
										<input
											type="radio"
											name="useUvYn"
											value="Y"
											v-validate="'required'"
											v-model="item.useUvYn"
											data-vv-as="Use UV Page"
										/><div class="radio" />
										Yes
									</label>
									<label class="radio-crud">
										<input
											type="radio"
											name="useUvYn"
											value="N"
											v-validate="'required'"
											v-model="item.useUvYn"
											data-vv-as="Use UV Page"
										/><div class="radio" />
										No
									</label>
                                </td>
                            </tr>
                            <tr>
                                <th>Register.</th>
								<td>
									{{ item.firstRegistUserId }}
								</td>
								<th>
									Regist Date.
								</th>
								<td>
									{{ item.firstRegistDate }}
								</td>
                            </tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button v-if="workAccessCheck('EDIT') && !(projectStateCd == '4' || projectStateCd == '3')" type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "pageCreate",
	created() {
        this.logger.debug(this, "created()");
        this.select();
		this.getAccessList('PAGE');
		this.getProjectStateCd();
    },
    props: {
        pageNo: {
            type: String,
            required: true
        }
    },
	data() {
		return {
			item: {},
			isDuplicate: false,
			initName: "",
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.selectDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to register the current Question Group?", "Question", "question").then(() => {
							if(this.slashCheck()){
								this.$alert("You are used '/' in page name.","Warning","warning");
							}
							else{
								EventBus.$emit("project-loading", true);
								this.doUpdate();
							}
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
        },
        select() {
            this.logger.debug(this, "select()");

			axios
				.post(this.config.contextRoot + "/page/select.do", { pageNo: this.pageNo })
				.then((response) => {
					console.log(response);

                    this.item = response.data.item;
					this.initName = response.data.item.pageName
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
        },
		slashCheck(){
			var result = false;
			if(this.item.pageName.includes('/')){
				result = true;
			}
			// if(this.item.pageLabel.includes('/')){
			// 	result = true;
			// }
			return result;
		},
		doUpdate() {
            this.logger.debug(this, "doUpdate()");
            this.item.pageNo = this.pageNo;

			axios
				.post(this.config.contextRoot + "/page/update.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.$parent.selectList();
							this.doClose();
						});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the page registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.pageEdit.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.pageEdit.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isEdit");
			}.bind(this), 410);
		},
		selectDuplicateCheck() {
			this.logger.debug(this, "selectDuplicateCheck()");
			this.item.projectNo =  this.$route.params.projectNo;
			axios
				.post(this.config.contextRoot + "/page/selectDuplicateCheck.do", this.item)
				.then((response) => {
					console.log(response);
					if(this.item.pageName == this.initName || response.data.duplicate == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>