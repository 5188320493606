import { render, staticRenderFns } from "./ScreeningMultipleCreate.vue?vue&type=template&id=7fa97878&scoped=true&"
import script from "./ScreeningMultipleCreate.vue?vue&type=script&lang=js&"
export * from "./ScreeningMultipleCreate.vue?vue&type=script&lang=js&"
import style0 from "./ScreeningMultipleCreate.vue?vue&type=style&index=0&id=7fa97878&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa97878",
  null
  
)

export default component.exports