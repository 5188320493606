<template>
	<div
		v-if="funcModalParam.isOpen"
		ref="functionModal"
		class="dialog-container"
	>
		<div class="dialog" style="max-height: 800px; transform: translate(0, -400px); overflow: unset;">
			<div class="top"> 
				<p class="txt-crud-title">
					Function List
				</p>
			</div>
			<div class="center" style="overflow: unset;">
				<div class="split-scrn">
					<div>
						<p class="txt-form-title">
							Select Function
						</p>
						<div class="split-scrn-box">
							<select
								class="scrn-box-sel"
								name="funcClTypCd"
								v-model="slctClTyp"
							>
								<option
									v-for="(fct, fctIndex) in FuncClTypList" 
									:key="fctIndex"
									:value="fct.funcClTypCd"
								>
									{{ fct.funcClTypNm }}
								</option>
							</select>
							<table class="func-ls-tbl">
								<colgroup>
									<col width="130" />
									<col width="100" />
									<col width="auto" />
								</colgroup>
								<thead>
									<tr>
										<th>Name</th>
										<th>Return Type</th>
										<th>Parameter</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(func, funcIndex) in FuncList"
										:key="funcIndex"
									>
										<td class="t-center">
											{{ func.funcNm }}
										</td>
										<td class="t-center">
											{{ func.funcRtnTypNm }}
										</td>
										<td>
											<template v-for="(funcDt, funcDtIndex) in func.funcDetailList">
												<span :key="'0_' + funcDtIndex">
													(
												</span>
												<a
													v-if="funcDt.funcParamsTypNm == ''"
													:key="'1_' + funcDtIndex"
													href="javascript:;"
													@click="selectDetailNo(funcDt)"
												>
													Null
												</a>
												<a
													v-else
													:key="'1_' + funcDtIndex"
													href="javascript:;"
													@click="selectDetailNo(funcDt)"
												>
													{{ funcDt.funcParamsTypNm.split('|').join(', ') }}
												</a>
												<span :key="'2_' + funcDtIndex">
													)
												</span>
												<span
													v-if="funcDtIndex < (func.funcDetailList.length - 1)"
													:key="'3_' + funcDtIndex"
												>
													|
												</span>
											</template>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div>
						<p class="txt-form-title">
							<span v-if="Object.keys(slctFuncDt).length == 0">
								Detail
							</span>
							<span v-else v-html="slctFuncNm">
							</span>
						</p>
						<div class="split-scrn-box-half" style="overflow: unset;">
							<div class="scrn-box-top">
								Parameter
							</div>
							<div v-if="Object.keys(slctFuncDt).length != 0" class="txt-box-desc" style="overflow: unset;">
								<div
									v-for="(paramsVarNm, pvnIndex) in (slctFuncDt.funcParamsVarNm.split('|'))"
									:key="pvnIndex"
									class="param-list"
								>
									<label :for="'param_' + paramsVarNm + '_' + pvnIndex">
										{{ paramsVarNm }}
									</label>
									<template v-if="slctFuncDt.funcParamsTypNm.split('|')[pvnIndex] == 'Typing'">
										<input type="text" :id="'param_' + paramsVarNm + '_' + pvnIndex" :name="'param_' + paramsVarNm" v-model="FuncParamList[pvnIndex].value"/>
									</template>
									<template v-else>
										<div class="drop-list-wrap">
											<div
												class="drop-list"
												@click="openDropList(slctFuncDt, $event)"
												:title="
													(
														!utils.isEmpty(FuncParamList[pvnIndex].valueText) ?
														FuncParamList[pvnIndex].valueText :
														'Please select the field'
													)
												"
												:style="Object.keys(FuncParamList[pvnIndex].field).length != 0 ? {color: '#999'} : {}"
											>
												{{
													!utils.isEmpty(FuncParamList[pvnIndex].valueText) ?
													FuncParamList[pvnIndex].valueText :
													(slctFuncDt.funcParamsCnt != 0 ? "Please select the field" : "Null")
												}}
											</div>
											<DropList
												v-if="slctDropLstNo == slctFuncDt.funcDetNo && !['1', '2', '21'].includes(slctDropLstNo) && (['3', '11'].includes(slctFuncDt.funcParamsTypCd) || ['3', '11'].includes(slctFuncDt.funcParamsTypCd.split('|')[pvnIndex]))"
												v-model="FuncParamList[pvnIndex].field"
												:validVisitList="dateTypeList"
												:fieldTypeList="fieldTypeList"
												@input="setDropValue(FuncParamList[pvnIndex])"
											/>
											<DropList
												v-if="slctDropLstNo == slctFuncDt.funcDetNo && !['1', '2', '21'].includes(slctDropLstNo) && (['5', '13'].includes(slctFuncDt.funcParamsTypCd) || ['5', '13'].includes(slctFuncDt.funcParamsTypCd.split('|')[pvnIndex]))"
												v-model="FuncParamList[pvnIndex].field"
												:validVisitList="dateTimeTypeList"
												:fieldTypeList="fieldTypeList"
												@input="setDropValue(FuncParamList[pvnIndex])"
											/>
											<DropList
												v-if="slctDropLstNo == slctFuncDt.funcDetNo && !['1', '2', '21'].includes(slctDropLstNo) && (['4', '12'].includes(slctFuncDt.funcParamsTypCd) || ['4', '12'].includes(slctFuncDt.funcParamsTypCd.split('|')[pvnIndex]))"
												v-model="FuncParamList[pvnIndex].field"
												:validVisitList="timeTypeList"
												:fieldTypeList="fieldTypeList"
												@input="setDropValue(FuncParamList[pvnIndex])"
											/>
										</div>
									</template>
									<template v-if="slctFuncDt.funcParamsFuncDetNo.split(';')[pvnIndex] != '0'">
										<label
											v-for="(funcDetName, funcDetIndex) in (slctFuncDt.funcParamsFuncDetNo.split(';')[pvnIndex].split('|'))"
											:key="funcDetIndex"
											@dblclick="resetRadio(pvnIndex)"
											@click.stop="setRadioFunc(pvnIndex, getFuncDtNm(funcDetName))"
										>
											<input type="radio" :name="'FuncRadio_' + pvnIndex" :value="funcDetName" v-model="FuncParamList[pvnIndex].funcParamsFuncDetNo"/>
											{{getFuncDtNm(funcDetName)}}
										</label>
									</template>
								</div>
							</div>
							<p v-else class="txt-box-desc flex-vh-center">
								Please select the function.
							</p>
						</div>
						<div class="split-scrn-box-half" style="margin: 50px 0 0;">
							<div class="scrn-box-top">
								Description
							</div>
							<p
								v-if="Object.keys(slctFuncDt).length != 0"
								class="txt-box-desc"
								v-html="slctFuncDt.funcDefDesc"
							>
							</p>
							<p v-else class="txt-box-desc flex-vh-center">
								Please select the function.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Save
				</button>
				<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">
					Cancel
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import DropList from "@/components/project/validation/DropList.vue";

export default {
	name: "FuncModal",
	components: {
		DropList
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectFieldTypeCdList();
		this.selectComCodeList();
		this.selectValidVisitList();
		this.selectFuncVisitList();

		EventBus.$on("blurDropList", () => {
			this.slctDropLstNo = "";
		});
	},
	props: {
		funcModalParam: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			codeList: [],
			item: {},
			FuncClTypList: [],
			FuncList: [],
			FuncParamList: [],
			FuncParamVo: {},
			slctClTyp: "1",
			slctFuncNm: "",
			nowFuncNm: "",
			slctFuncDt: {},
			slctDropLstNo: "",
			fieldTypeList: [],
			dateTypeList: [],
			dateTimeTypeList: [],
			timeTypeList: [],
			validVisitList: []
		};
	},
	watch: {
		funcModalParam: {
			deep: true,
			handler(param) {
				if(param.isOpen) {
					this.item.funcClTypCd = "1";
					this.selectFuncList();
					if(param.selectFunc.length > 0){
						setTimeout(function() {
							this.setEditFunc();
						}.bind(this), 100);
					}
				}
			}
		},
		slctClTyp: {
			handler(newVal, prevVal) {
				if(newVal != prevVal) {
					this.slctFuncDt = {};
					this.$set(this.item, "funcClTypCd", newVal);
					this.selectFuncList();
				}
			}
		},
	},
	methods: {
		setInit() {
			this.item = {};
			this.FuncClTypList = {};
			this.FuncList = {};
			this.slctClTyp = "1";
			this.slctFuncNm = "";
			this.slctFuncDt = {};
			this.FuncParamList = [];
			this.FuncParamVo = {};
		},
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["DATA_TYPE_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldTypeCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_TYPE_CD"})
				.then((response) => {
					this.fieldTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectValidVisitList() {
			this.logger.debug(this, "selectValidVisitList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidVisitList.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					this.validVisitList = response.data.validVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFuncVisitList() {
			this.logger.debug(this, "selectCalcVisitList()");
			axios
				.post(this.config.contextRoot + "/calculate/getDateTypeList.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					this.dateTypeList = response.data.funcVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			axios
				.post(this.config.contextRoot + "/calculate/getDateTimeTypeList.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					this.dateTimeTypeList = response.data.funcVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			axios
				.post(this.config.contextRoot + "/calculate/getTimeTypeList.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					this.timeTypeList = response.data.funcVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFuncList() {
			this.logger.debug(this, "selectCalculationList()");
			axios
				.post(this.config.contextRoot + "/func/selectFuncList.do", this.item)
				.then((response) => {
					this.FuncClTypList = response.data.FuncClTypList;
					this.FuncList = response.data.FuncList;
					this.FuncParamVo = response.data.FuncParamVo;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 100);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		setEditFunc(){
			this.logger.debug(this, "setEditFunc()");

			let funcPrm = this.funcModalParam.selectFunc;
			axios
				.post(this.config.contextRoot + "/func/selectFuncClTyp.do", funcPrm[0])
				.then((response) => {
					let funcVo = response.data.funcVo;
					this.$set(this.item, "slctClTyp", funcVo.funcClTypCd);
					this.$set(this.item, "funcClTypCd", funcVo.funcClTypCd);
					this.selectFuncList();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});

			setTimeout(function() {
				this.setEditFuncDetail();
				this.setEditFuncParam();
			}.bind(this), 100);
		},
		setEditFuncDetail() {
			let funcPrm = this.funcModalParam.selectFunc;

			let funcNo = funcPrm[0].funcNo;
			let funcIndex = this.FuncList.map((item) => {return item.funcNo}).indexOf(funcNo);
			let func = this.FuncList[funcIndex];
			let funcDetNo = funcPrm[0].funcDetNo;
			if(funcIndex >= 0 && funcDetNo != undefined && funcDetNo != null){
				let funcDetIndex = func.funcDetailList.map((item) => {return item.funcDetNo}).indexOf(funcDetNo);
				if(funcDetIndex >= 0){
					let funcDet = func.funcDetailList[funcDetIndex];
					this.selectDetailNo(funcDet);
				}
			}
		},
		setEditFuncParam(){
			let funcPrm = this.funcModalParam.selectFunc;

			for(let i = 0; i < parseInt(funcPrm[0].funcParamsCnt); i++)
			{
				if(funcPrm[i].funcParamTypNm == "Typing") 
				{
					if(funcPrm[i].value == "TODAY()")
					{
						this.$set(this.FuncParamList[i], "funcParamsFuncDetNo", "1");
					}
					else if(funcPrm[i].value == "NOW()")
					{
						this.$set(this.FuncParamList[i], "funcParamsFuncDetNo", "2");
					}
					this.$set(this.FuncParamList[i], "value", funcPrm[i].value);
				}
				else
				{
					let field = {};
					field.visitNo = funcPrm[i].visitNo;
					field.pageNo = funcPrm[i].pageNo;
					field.procNo = funcPrm[i].procNo;
					field.procQuestionNo = funcPrm[i].procQuestionNo;
					field.valueText = this.getValueText(field.visitNo, field.pageNo, field.procNo, field.procQuestionNo);
					field.fieldChildName = this.getFieldChildName(field.visitNo, field.pageNo, field.procNo, field.procQuestionNo);
					this.$set(this.FuncParamList[i], "field", field);
	
					this.setDropValue(this.FuncParamList[i]);
				}
			}
		},
		selectDetailNo(funcDt) {
			this.logger.debug(this, "selectDetailNo(funcDt)");
			this.FuncParamList = [];

			if(funcDt.funcParamsCnt == "1" || funcDt.funcParamsCnt == "0"){
				let paramVo = JSON.parse(JSON.stringify(this.FuncParamVo));

				paramVo.funcDetNo = funcDt.funcDetNo;
				paramVo.funcNo = funcDt.funcNo;
				paramVo.funcParamsCnt = funcDt.funcParamsCnt;
				paramVo.funcParamTypCd = funcDt.funcParamsTypCd;
				paramVo.funcParamTypNm = funcDt.funcParamsTypNm;
				paramVo.funcParamVarNm = funcDt.funcParamsVarNm;
				paramVo.funcParamsFuncDetNo = "";
				this.$set(paramVo, "field", {});
				paramVo.projectNo = this.$route.params.projectNo;
				paramVo.visitNo = "";
				paramVo.pageNo = "";
				paramVo.procNo = "";
				paramVo.procQuestionNo = "";
				paramVo.value = "";
				paramVo.funcParamsSrt = "1";
				this.FuncParamList.push(paramVo);
			}
			else{
				for(let i = 0; i < parseInt(funcDt.funcParamsCnt); i++){
					let paramVo = JSON.parse(JSON.stringify(this.FuncParamVo));
					let TypCd = funcDt.funcParamsTypCd.split('|');
					let TypNm = funcDt.funcParamsTypNm.split('|');
					let VarNm = funcDt.funcParamsVarNm.split('|');

					paramVo.funcDetNo = funcDt.funcDetNo;
					paramVo.funcNo = funcDt.funcNo;
					paramVo.funcParamsCnt = funcDt.funcParamsCnt;
					paramVo.funcParamTypCd = TypCd[i];
					paramVo.funcParamTypNm = TypNm[i];
					paramVo.funcParamVarNm = VarNm[i];
					paramVo.funcParamsFuncDetNo = "";
					this.$set(paramVo, "field", {});
					paramVo.projectNo = this.$route.params.projectNo;
					paramVo.visitNo = "";
					paramVo.pageNo = "";
					paramVo.procNo = "";
					paramVo.procQuestionNo = "";
					paramVo.value = "";
					let srt = i + 1
					paramVo.funcParamsSrt = srt.toString();
					this.FuncParamList.push(paramVo);
				}
			}

			this.slctFuncDt = funcDt;
			this.setFuncDtNm(funcDt);
		},
		setFuncDtNm(funcDt) {
			this.logger.debug(this, "setFuncDtNm(funcDt)");
			let funcNo = funcDt.funcNo;
			let funcNm = "";

			for(let i = 0 ; i < this.FuncList.length ; i++)
			{
				let cmpFuncNo = this.FuncList[i].funcNo;
				if(funcNo == cmpFuncNo)
				{
					funcNm = this.FuncList[i].funcNm + "(<span style='color: rgb(46, 99, 243);'>" + funcDt.funcParamsTypNm.split('|').join(', ') + "</span>)";
					this.nowFuncNm = this.FuncList[i].funcNm;
					break;
				}
			}

			this.slctFuncNm = funcNm;
		},
		getFuncDtNm(funcNo){
			let funcNm = "";

			for(let i = 0 ; i < this.FuncList.length ; i++)
			{
				let cmpFuncNo = this.FuncList[i].funcNo;
				if(funcNo == cmpFuncNo)
				{
					funcNm = this.FuncList[i].funcNm;
					break;
				}
			}
			return funcNm;
		},
		getValueText(visitNo, pageNo, procNo, procQuestionNo) {
			let valueText = "";
			let visitIndex = this.validVisitList.map((item) => {return item.visitNo}).indexOf(visitNo);
			let visit = this.validVisitList[visitIndex];
			valueText = valueText + visit.visitName;
			if(visitIndex >= 0 && pageNo !== undefined && pageNo != null){
				let pageIndex = visit.projectDictionaryList.map((item) => {return item.pageNo}).indexOf(pageNo);
				let page = visit.projectDictionaryList[pageIndex];
				valueText = valueText + " > " + page.pageName;
				if(pageIndex >= 0 && procNo !== undefined && procNo != null)
				{
					let procIndex = page.procedureList.map((item) => {return item.procNo}).indexOf(procNo);
					let proc = page.procedureList[procIndex];
					valueText = valueText + " > " + proc.procName;

					if(procIndex >= 0 && procQuestionNo !== undefined && procQuestionNo != null)
					{
						let questionIndex = proc.procQuestionList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
						
						if(questionIndex >= 0)
						{
							let question = proc.procQuestionList[questionIndex];
							valueText = valueText + " > " + question.questionFieldName;

							let field = question.fieldList[0];
							valueText = valueText + " | " + question.questionName + " | " +this.getFieldTypeName(field.fieldTypeCd) + " | " + this.dataTypeName(field.dataTypeCd);
						}
						else{
							for(let i = 0; i < proc.procQuestionList.length; i++)
							{
								let questionIndex2 = proc.procQuestionList[i].listTypeList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
								if(questionIndex2 >= 0)
								{
									let question2 = proc.procQuestionList[i].listTypeList[questionIndex2];
									valueText = valueText + " > " + question2.questionFieldName;

									let field = question2.fieldList[0];
									valueText = valueText + " | " + question2.questionName + " | " +this.getFieldTypeName(field.fieldTypeCd) + " | " + this.dataTypeName(field.dataTypeCd) + " | " + question2.listIndex;
								}
							}
						}
					}
				}
			}
			return valueText;
		},
		getFieldChildName(visitNo, pageNo, procNo, procQuestionNo) {
			let fieldChildName = "";
			let visitIndex = this.validVisitList.map((item) => {return item.visitNo}).indexOf(visitNo);
			let visit = this.validVisitList[visitIndex];
			if(visitIndex >= 0 && pageNo !== undefined && pageNo != null){
				let pageIndex = visit.projectDictionaryList.map((item) => {return item.pageNo}).indexOf(pageNo);
				let page = visit.projectDictionaryList[pageIndex];
				if(pageIndex >= 0 && procNo !== undefined && procNo != null)
				{
					let procIndex = page.procedureList.map((item) => {return item.procNo}).indexOf(procNo);
					let proc = page.procedureList[procIndex];

					if(procIndex >= 0 && procQuestionNo !== undefined && procQuestionNo != null)
					{
						let questionIndex = proc.procQuestionList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
						
						if(questionIndex >= 0)
						{
							let question = proc.procQuestionList[questionIndex];
							fieldChildName = question.questionFieldName;
						}
						else{
							for(let i = 0; i < proc.procQuestionList.length; i++)
							{
								let questionIndex2 = proc.procQuestionList[i].listTypeList.map((item) => {return item.procQuestionNo}).indexOf(procQuestionNo);
								if(questionIndex2 >= 0)
								{
									let question2 = proc.procQuestionList[i].listTypeList[questionIndex2];
									fieldChildName = question2.questionFieldName;
								}
							}
						}
					}
				}
			}
			return fieldChildName;
		},
		getFieldTypeName(comCode) {
			let comCodeList = this.fieldTypeList.map((item) => {
				return item.comCode;
			});
			let getIndex = comCodeList.indexOf(comCode);

			return this.fieldTypeList[getIndex].comCodeName;
		},
		dataTypeName(dataTypeCd){
			let dataTypeName = "";
			this.codeList.forEach((element) => {
				if(dataTypeCd == element.comCode){
					dataTypeName = element.comCodeName;
				}
			});
			return dataTypeName;
		},
		resetRadio(index) {
			this.$set(this.FuncParamList[index], "funcParamsFuncDetNo", "");
			this.$set(this.FuncParamList[index], "value", "");
		},
		setRadioFunc(index, FuncNm){
			this.$set(this.FuncParamList[index], "value", FuncNm+'()');
		},
		openDropList(slctFuncDt, event) {
			this.slctDropLstNo = slctFuncDt.funcDetNo;

			this.$nextTick(() => {
				event.target.parentNode.children[1].children[0].focus();
			});
		},
		setDropValue(obj){
			if(this.funcModalParam.isRepeat == "true")
			{
				obj.field = {};
				obj.valueText = "";
				obj.visitNo = "";
				obj.pageNo = "";
				obj.procNo = "";
				obj.procQuestionNo = "";
				obj.isRepeat = "";
				this.$alert("Repeated regions cannot be calculated!", "Warning", "warning");
			}
			else
			{
				obj.valueText = obj.field.valueText;
				obj.visitNo = obj.field.visitNo;
				obj.pageNo = obj.field.pageNo;
				obj.procNo = obj.field.procNo;
				obj.procQuestionNo = obj.field.procQuestionNo;
				obj.isRepeat = obj.field.isRepeat;
			}
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the function creation?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.functionModal.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.functionModal.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				this.$parent.closeFunction();
				this.setInit();
			}.bind(this), 410);
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => { 
					this.logger.debug(this, "doValidationResult:" + result);
					if(result) {
						this.$confirm("Do you want to add the Function?", "Question", "question").then(() => {
							this.doAdd();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doAdd(){
			this.$parent.setFunction(this.FuncParamList, this.nowFuncNm);
			this.setInit();
		}
	}
};
</script>

<style scoped>
.dialog-container {
	left: 0;
	width: 100%;
}

.btn-cancel {
	margin-right: 10px;
}

.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 0;
	padding: 0 10px;
	font-weight: 600;
	font-size: 13pt;
	color: #666;
}

.scrn-box-sel,
.scrn-box-top {
	position: relative;
	width: 100%;
	height: 40px;
	line-height: 38px;
	padding: 0 20px;
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 10px 10px 0 0;
	outline: none;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAABHNCSVQICAgIfAhkiAAAAIRJREFUCFtjnDlz5gwGBoZ0IMYHZjICFbL+//9/MyMjozs2lUC5nUA5X0aQ5KRJk/jY2dlPA5lqaIpv/fz50zQvL+8TWCEIzJo1S+3fv3+ngbr5QHygSZ+YmJhM09LSboH4cIVQxe4gZ4AlgNYBFe2EGYSiECQ4Y8aMDBCdkZEB8iQcAAAeZy46HjWYuQAAAABJRU5ErkJggg==');
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) center;
	background-color: #f5f5f5;
	font-weight: 600;
	font-size: 14px;
	color: #999;
	appearance:none;
	-webkit-appearance: none;
}

.scrn-box-top {
	background-image: none;
	background-repeat: unset;
	background-position: unset;
}

.split-scrn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
}

.split-scrn > div {
	width: calc(50% - 25px);
	height: 100%;
}

.split-scrn-box {
	width: 100%;
	height: calc(100% - 50px);
	margin: 5px 0 0;
	border: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.split-scrn-box-half {
	width: 100%;
	height: calc(50% - 50px);
	margin: 5px 0 0;
	border: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.func-ls-tbl {
	width: 100%;
	border-collapse: collapse;
}

.func-ls-tbl thead th {
	height: 40px;
	border-bottom: 1px solid #ccc;
	background-color: #f5f5f5;
	font-weight: 600;
	font-size: 12.5px;
	color: #666;
	text-align: center;
}

.func-ls-tbl tbody td {
	height: 39px;
	padding: 0 10px;
	border-bottom: 1px solid #ccc;
	font-weight: 400;
	font-size: 12.5px;
	color: #666;
}

.func-ls-tbl a {
	font-weight: 500;
	font-size: 12.5px;
	color: rgb(46, 99, 243);
	text-decoration: underline;
}

.t-center {
	text-align: center;
}

.txt-box-desc {
	width: 100%;
	height: calc(100% - 40px);
	padding: 20px;
	border-radius: 10px;
	font-weight: 400;
	font-size: 13.5px;
	color: #666;
	overflow-y: auto;
}

.param-list {
	width: 100%;
	margin: 0 0 8px;
}

.param-list > label {
	font-weight: 600;
	font-size: 10pt;
	color: #666;
}

.param-list > input {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	margin: 5px 0 0;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #999;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.drop-list-wrap {
	position: relative;
	width:100%;
	height: 32px;
	margin: 5px 0 0;
}

.drop-list {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>