<template>
<article class="field-right">
	<div ref="fieldPopup" class="dialog-container1">
		<h1 class="title" style="align-items: center; width: 100%; height: 45px; padding: 0 20px; border-bottom: 1px solid #dee2e6;
								background-color: #fafafa; font-weight: 600; font-size: 10.5pt;color: #2bade0;">
			PREVIEW
		</h1>
		<div class="dialog1 preview scroll" style="max-height: 100%; font-weight: 600; color: #666">
			<div>
					<p v-if="item.questionFieldName" class="field">
						{{ item.questionFieldName }}
					</p>
					<p v-if="item.questionName" class="question">
						<span>ㆍ</span>
						{{ item.questionName }}
					</p>
					<div class="item-wrap no-drag">
						<div
							v-for="(field, index) in fieldList"
							:key="index"
							class="item"
						>	
							{{ field.prefixName }}
							<div
								:class="field.fieldLayoutCd == '1' ? 'field-vtype' : 'field-htype'"
								style="font-size: inherit; color: inherit;"
							>
								<!-- Text Start -->
								<template v-if="field.fieldTypeCd == '01' || field.fieldTypeCd == '08' ||field.fieldTypeCd == '09'">
									<input
										type="text"
										:style="'width:' + (field.textSize) + 'em;'"
										style="max-width: 100%;"
										disabled
									/>
								</template>
								<!-- Textarea Start -->
								<template v-if="field.fieldTypeCd == '02'">
									<textarea
										:rows="field.textareaRows"
										:style="'width:' + (field.textareaCols) + 'em;'"
										style="max-width: 100%;"
										disabled
									/>
								</template>
								<!-- Radio Start -->
								<template v-if="field.fieldTypeCd == '03'">
									<div
										class="grid"
										
									>
										<label
											v-for="(option, radioIndex) in field.radioOptionList"
											:key="radioIndex"
											class="radio-crud"
										>
											<input
												type="radio"
												:value="option"
												disabled
											/><span class="radio"></span>
											{{ option.radioOptionsLabel }}
										</label>
									</div>
								</template>
								<!-- Checkbox Start -->
								<template v-if="field.fieldTypeCd == '04'">
									<div
										class="grid"
										
									>
										<label
											v-for="(option, checkboxIndex) in field.checkOptionList"
											:key="checkboxIndex"
											class="checkbox-crud"
										>
											<input
												type="checkbox"
												:value="option"
												disabled
											/><span class="checkbox"></span>
											{{ option.checkboxOptionsLabel }}
										</label>
									</div>
								</template>
								<!-- Select Start -->
								<template v-if="field.fieldTypeCd == '05'">
									<div
										class="select-grid"
										
									>
										<select
											style="max-width: 100%;"
											disabled
										>
										</select>
										<p
											v-for="(option, selectIndex) in field.selectOptionList"
											:key="selectIndex"
										>
											{{ option.selectOptionsLabel }}
										</p>
									</div>
								</template>
								<!-- Image Start -->
								<template v-if="field.fieldTypeCd == '06'">
									<div
										:class="field.fileList.length == 0 ? 'img-wrap-no' : 'img-wrap'"
										:style="'width: ' + (field.imageWidth) + 'px; height: ' + (field.imageHeight) + 'px;'"
									>
										<template v-if="field.fileList.length > 0">
											<img
												v-if="
													field.fileList[0].fileExtensionName == 'png' ||
													field.fileList[0].fileExtensionName == 'jpeg' ||
													field.fileList[0].fileExtensionName == 'jpg'
												"
												:src="
													field.fileList[0].imgUrl === undefined ?
													(
														config.contextRoot +
														'/common/file/printImage.do?fileNo=' +
														field.fileList[0].fileNo +
														'&fileGroupNo=' +
														field.fileList[0].fileGroupNo +
														'&serverFileSaveName=' +
														field.fileList[0].serverFileSaveName
													) :
													field.fileList[0].imgUrl
												"
												style="width: 100%; height: 100%;"
											/>
										</template>
										<template v-if="field.fileList.length == 0">
											<span>Image</span>
										</template>
									</div>
								</template>
								<!-- File Start -->
								<template v-if="field.fieldTypeCd == '07'">
									<common-file
										:fileExtension="field.fileExtension"
										:fileMaxSize="field.fileMaxSize"
									/>
									<!-- <p class="print-file" style="font-size: inherit;">→ Please attach files separately</p> -->
								</template>
							</div>
							{{ field.suffixName }}
						</div>
					</div>
				</div>
		</div>
	</div>
	</article>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import axios from "axios";

export default {
	name: "FieldPopup",
	created() {
		this.logger.debug(this, "created()");
		const previewData = JSON.parse(localStorage.previewData);
		this.fieldList = previewData.fieldList;
		this.item.questionName = previewData.questionName;
		this.item.questionFieldName = previewData.questionFieldName;
		this.selectVisitList();
	},
	components: {
	},
	data() {
		return {
			item: {
				questionName: "",
				questionFieldName: "",
			},
			fieldList: [],
			initNameList: [],
			fieldTypeList: [],
			fieldLayoutList: [],
			fieldCharTypeLsit: [],
			fieldDataTypeList: [],
			fieldTimeTypeList: [],
		};
	},
	computed: {
	},
	watch: {
	},
	methods: {
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["FIELD_TYPE_CD", "FIELD_LAYOUT_CD", "FIELD_CHART_TYPE_CD", "DATA_TYPE_CD"]})
				.then((response) => {
					let codeList = response.data.codeList;
					for (let index = 0 ; index < codeList.length ; index++)
					{
						if(codeList[index].classCode == "FIELD_TYPE_CD")
						{
							this.fieldTypeList.push(codeList[index]);
						}
						if(codeList[index].classCode == "FIELD_LAYOUT_CD")
						{
							this.fieldLayoutList.push(codeList[index]);
						}
						if(codeList[index].classCode == "FIELD_CHART_TYPE_CD")
						{
							this.fieldCharTypeLsit.push(codeList[index]);
						}
						if(codeList[index].classCode == "DATA_TYPE_CD")
						{
							if(codeList[index].comCode == '1' || codeList[index].comCode == '2')
							{
								this.fieldDataTypeList.push(codeList[index]);
							}
							else if(codeList[index].comCode == '3' || codeList[index].comCode == '4' || codeList[index].comCode == '5')
							{
								this.fieldTimeTypeList.push(codeList[index]);
							}
						}
					}
						this.$nextTick(function () {
							setTimeout(function() {
								// 로딩바 닫기 요청
								EventBus.$emit("field-loading", false);
							}.bind(this), 500);
						});
					})
					.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		select() {
			for(let field of this.fieldList)
			{
				this.initNameList.push({fieldNo : field.fieldNo, fieldChildName: field.fieldChildName});
			}

		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the import data?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.fieldPopup.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.fieldPopup.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isFieldPopup");
			}.bind(this), 410);
		},
		test(){
			this.logger.debug(this, 'test()');
			console.log(this.fieldList);
		},
	}
};
</script>

<style scoped>

.btn-cancel {
	margin-right: 10px;
}

.select-grid {
    display: inline-grid;
    position: relative;
}

.select-grid select {
    width: 100%;
    border-radius: 0px;
}

.select-grid p {
    padding: 0 30px 0 10px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #f5f5f5;
}

.select-grid p:last-child {
    border-bottom: 1px solid #ddd;
}




.field-right {
	border-radius: 0;
	height: 100%;
}
</style>