<template>
	<div ref="fieldSort" class="dialog-container">
		<div class="dialog" style="max-height: 500px; transform: translate(0, -250px);">
			<form @submit.stop="doValidation()">
				<div class="top"> 
					<p class="txt-crud-title">
						Procedure Sort
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="20%" />
							<col width="80%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Question Group</th>
								<td>
									<select
										class="select-crud"
										:class="(errors.has('questionGroupNo') ? 'input-crud-error' : '')"
										name="questionGroupNo"
										v-model="selectQuestionGroupNo"
										v-validate="'required'"
										data-vv-as="Question Group"
									>
										<option value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in fieldGroupList" :key="index" :value="item.questionGroupNo">
											{{ item.questionGroupName }}
										</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>Field List</th>
								<td>
									<div v-for="(item, index) in fieldList" :key="index" class="sort-row">
										<label>
											Field
											<input
												type="text"
												class="input-crud"
												name="procName"
												v-model="fieldList[index].questionFieldName"
												readonly
											/>
										</label>
										<label>
											Sort Order
											<input
												type="number"
												class="input-crud"
												:class="(errors.has('procSortOrder' + index) ? 'input-crud-error' : '')"
												:name="'procSortOrder' + index"
												v-validate="'required'"
												data-vv-as="Sort Order"
												v-model="fieldList[index].questionSortOrder"
												:placeholder="(
													errors.has('procSortOrder' + index) ? 
													errors.first('procSortOrder' + index) : 
													'Please enter the sort order.'
												)"
												min="1"
												max="100"
											/>
										</label>
									</div>
									<div v-if="fieldList.length == 0" class="no-list">
										There is no registered procedure, Please register the procedure.
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProcedureSort",
	created() {
		this.logger.debug(this, "created()");
		this.selectList();
	},
	data() {
		return {
			item: {},
			fieldList: [],
			fieldGroupList: [],
			selectQuestionGroupNo: "",
		};
	},
	watch: {
		selectQuestionGroupNo: function(val) {
			this.$set(this.item, "questionGroupNo", val);
			this.selectFieldList();
		},
	},
	methods: {
		selectList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/field/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.fieldGroupList = response.data.questionGroupSortList;
					
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldList() {
			this.logger.debug(this, "selectFieldList()");

			console.log(this.item);
				axios
				.post(this.config.contextRoot + "/field/selectFieldList.do", this.item)
				.then((response) => {
					console.log(response);
					this.fieldList = response.data.questionList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if(this.fieldList.length == 0)
					{
						this.$alert("There is no registered field.", "Warning", "warning");
						return false;
					}
					if (result)
					{
						this.$confirm("Do you want to modify the current sort?","Question","question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			let param = [];
			for(let i = 0 ; i < this.fieldList.length ; i++)
			{
				let questionNo = this.fieldList[i].questionNo;
				let questionSortOrder = this.fieldList[i].questionSortOrder;
				param.push({
					questionNo: questionNo,
					questionSortOrder: questionSortOrder,
				});
			}

			axios
				.post(this.config.contextRoot + "/field/questionSortUpdate.do", param)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						// location.reload();
						this.$alert("Complete", "Success", "success").then(() => {
							// location.reload();
							this.$parent.selectProjectList();
							this.$parent.selectQuestionGroupList();
							this.doClose();
						});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the sort editing?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.fieldSort.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.fieldSort.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("group-cancel", "isFieldSort");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.sort-row {
	display: flex;
}

.sort-row label {
	display: inline-block;
	width: 50%;
	font-weight: 600;
	font-size: 10pt;
	color: #666;
}

.sort-row label:first-child {
	padding: 0 10px 0 0;
}

.sort-row label:last-child {
	padding: 0 0 0 10px;
}

.sort-row input[type=text] {
	margin: 5px 0 8px;
}

.no-list {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10.5pt !important;
	color: #aaa !important;
}
.btn-cancel {
	margin-right: 10px;
}
</style>