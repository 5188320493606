<template>
	<div ref="defaultValue" class="dialog-container" style="width: 100%; left: 0px; z-index: 100;">
		<div class="dialog" style="max-height: 600px; max-width: 600px;transform: translate(0, -360px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Default Values
					</p>
				</div>
				<div class="center">
					<table 
						v-for="field in defaultField" :key="field.fieldNo"
						class="tb-crud-horizon"
						style="margin-bottom: 25px;"
					>
					<colgroup>
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>
								Variable Name
							</th>
							<td colspan="3">
									<input class="input-crud" style="font-weight: 600;" type="text" :value="field.fieldChildName" disabled>
							</td>
						</tr>
						<tr>
							<th>Value List.</th>
							<td>
								<div v-for="valueName in field.defaultValueList" :key="valueName">
									<label class="radio-crud" style="height: auto; align-items: flex-start; margin: 0 25px 12px 0;">
										<input
											type="radio"
											:name="'selectDefault_' + field.fieldNo"
											:value="valueName"
											v-validate="'required'"
											v-model="field.selectDefault"
										/>
										<span class="radio" style="margin: 2.5pt 10px 0 0;"></span>
										<span style="margin-top: 1px;">{{ valueName }}</span>
									</label>
								</div>
								<p class="txt-validation" v-if="errors.has('selectVisit')">
									* {{ errors.first("selectVisit") }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Confirm
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import EventBus from "@/script/EventBus.js";

export default {
    name: "DefaultValue",
    created () {
		this.logger.debug(this, "created()");
		// this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		// this.item.useYn = "Y";
		// this.item.unscheduleYn = "Y";
		// this.item.projectNo = this.$route.params.projectNo;
		// this.item.siteCode = this.siteCode;

		// this.selectVisitList();
		// this.selectSiteList();
		// this.selectVisitPageList();
    },
    data() {
        return {
			items: {},
			visitList: [],
			isDuplicate: false,
			item: {},
			visitPageList: [],
			selectedPageNo: [],
			visitPageItem: [],
			visitSortOrderVO: [],
			siteList: [],
		};
    },
	
	props: {
		defaultField: {
			type: Array,
			required: true
		},
		defaultCellId: {
			type: String,
			required: true
		}
	},
    methods: {
		changeVisit(event)
		{
			console.log("changeVisit()");

			this.item.afterVisitNo   = event.target.value;
		},
		// selectVisitList() {
		// 	this.logger.debug(this, "selectVisitList()");

		// 	this.items.projectNo = this.$route.params.projectNo;
		// 	this.items.siteCode = this.siteCode;
		// 	this.item.screeningNo = this.$attrs.screeningNo;

		// 	axios
		// 		.post(this.config.contextRoot + "/screening/selectVisitList.do", this.items)
		// 		.then((response) => {
		// 			console.log(response);
		// 			this.visitList = response.data.visitList;				
		// 			this.$nextTick(function () {
		// 				setTimeout(function() {
		// 					EventBus.$emit("project-loading", false);
		// 				}.bind(this), 500);
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		});
		// },
		// selectSiteList() {
		// 	this.logger.debug(this, "selectSiteList()");
		// 	axios
		// 		.post(this.config.contextRoot + "/site/selectList.do", {})
		// 		.then((response) => {
		// 			console.log(response);
		// 			this.siteList = response.data.siteList;					
		// 			this.$nextTick(function () {
		// 				setTimeout(function() {
		// 					EventBus.$emit("project-loading", false);
		// 				}.bind(this), 500);
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		});
		// },
		// selectVisitPageList() {
		// 	this.logger.debug(this, "selectVisitPageList()");

		// 	axios
		// 		.post(this.config.contextRoot + "/visit/selectVisigPageList.do", {projectNo: this.$route.params.projectNo, visittNo: this.$route.params.visittNo})
		// 		.then((response) => {
		// 			console.log(response);
		// 			this.visitPageList = response.data.visitPageList;

		// 			this.$nextTick(function () {
		// 				setTimeout(function() {
		// 					EventBus.$emit("project-loading", false);
		// 				}.bind(this), 500);
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			this.exceptionHandler(this, error);
		// 		});
		// },
        doValidation() {
			this.logger.debug(this, "doValidation()");

			if(this.isDuplicate == true)
			{
				return false;
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the current Default Value?", "Question", "question").then(() => {
							EventBus.$emit("project-loading", true);
							this.$parent.setDefaultField(this.defaultField, this.defaultCellId);

							this.$refs.defaultValue.style.animation = "fadeOut 0.4s ease-in-out both";
							this.$refs.defaultValue.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
							setTimeout(function() {
								EventBus.$emit("project-cancel", "isDefaultValue");
								EventBus.$emit("project-loading", false);
							}.bind(this), 410);
						});
					}
					else{
						this.$alert("Please select a value!", "Warn", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
        },
        doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Default Value registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.defaultValue.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.defaultValue.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isDefaultValue");
				this.$parent.deleteDefaultData(this.defaultCellId);
			}.bind(this), 410);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/visit/duplicateCheck.do", this.item)
				.then((response) => {
					console.log(response);
					if(response.data.visitExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
    },
}
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>