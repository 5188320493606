<template>
	<div>
		<p class="txt-list-title">User List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the user ID to search."
				v-model="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="project-in-balloon">
				<p>Please press the Enter key. </p>
			</div>


			<button type="button" class="btn-new" title="New Data" @click="openAdd()">Add</button>
		</div>
		<table class="tb-list-vertical no-cursor">
			<colgroup>
				<col width="5%" />
				<col width="15%" />
				<col width="10%" />
				<col width="20%" />
				<col width="20%" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
			</colgroup>
			<thead>
				<tr>
					<th>
						No
					</th>
					<th>
						User ID
					</th>
					<th>
						User Name
					</th>
					<th>
						Email
					</th>
					<th>
						Phone
					</th>
					<th>
						Site
					</th>
					<th>
						Auth
					</th>
					<th>
						Confirm
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(user, index) in userList"
					:key="index"
					tabindex="0"
				>
					<td>
						{{ user.rowNumber }}
					</td>
					<td class="underline">
						{{ user.userId }}
					</td>
					<td class="underline">
						{{ user.userName }}
					</td>
					<td>
						{{ user.emailAddress }}
					</td>
					<td>
						{{ user.mobileTelno }}
					</td>
					<td>
						<select
							:name="'site'+user.userNo"
							class="select-crud"
							v-model="user.siteCode"
							:style="(user.siteCode == '' ? {color: '#ccc'} : {})"
							@change="updateUserSite(user)"
						>
							<option value="" disabled hidden>
								Site Change
							</option>
							<option
								v-for="(site, siteIndex) in siteList"
								:key="siteIndex"
								:value="site.siteCode"
								:title="site.siteName + '-' + site.managerName"
							>
							{{site.siteCode}} ({{ site.siteName }} - {{ site.managerName }})
							</option>
						</select>
					</td>
					<td>
						<select
							:name="'auth'+user.userNo"
							class="select-crud"
							v-model="user.authNo"
							:style="(user.authNo == '' ? {color: '#ccc'} : {})"
							@change="updateUserAuth(user)"
						>
							<option value="" disabled hidden>
								Auth Change
							</option>
							<option
								v-for="(auth, authIndex) in authList"
								:key="authIndex"
								:value="auth.authNo"
							>
							{{ auth.authName }}
							</option>
						</select>
					</td>
					<td>
						<label class="radio-crud">
							<input
								type="radio"
								:name="'confirmYn' + user.userNo"
								value="Y"
								v-model="user.confirmYn"
								@change="updateConfirmYn(user)"
							/><div class="radio"></div>
							Yes
						</label>
						<label class="radio-crud">
							<input
								type="radio"
								:name="'confirmYn' + user.userNo"
								value="N"
								v-model="user.confirmYn"
								@change="updateConfirmYn(user)"
							/><div class="radio"></div>
							No
						</label>
					</td>
				</tr>
				<tr v-if="userList.length == 0">
					<td colspan="8" class="no-list">
						There is no registered user.
					</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div>
		<UserAdd v-if="isUserAddOpen" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import UserAdd from "@/components/setting/userAuth/UserAdd"

export default {
	name: "UserAuthList",
	components: {
		UserAdd
	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "SETTING",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectUserList();
		this.selectAuthList();
		this.selectSiteList();

		EventBus.$on("userAdd-close", () => {
			this.isUserAddOpen = false;
		});
	},
	data() {
		return {
			item: {},
			userList: [],
			authList: [],
			siteList: [],
			pagination: {},
			searchText: "",
			isUserAddOpen: false,
			isTyping: false,
		};
	},
	watch: {
		searchText: function(val) {
			this.item.userId = val;
			this.item.pageNum = 1;
		}
	},
	methods: {
		selectUserList() {
			this.logger.debug(this, "selectUserList()");
			axios
				.post(this.config.contextRoot + "/user/selectUserAuthList.do", this.item)
				.then((response) => {
					console.log(response);
					this.userList = response.data.userList
					this.pagination = response.data.pagination;
					// for(let i = 0 ; i < this.userList.length ; i++) {
					// 	this.userList[i].approvalStatus = (this.userList[i].approvalStatus == "1" ? true : false);
					// }
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectAuthList() {
			this.logger.debug(this, "selectAuthList()");
			axios
				.post(this.config.contextRoot + "/auth/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.authList = response.data.authList;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");

			axios
				.post(
					this.config.contextRoot + "/site/selectList.do", this.item)
				.then(response => {
					console.log(response);
					this.siteList = response.data.siteList;
						this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("setting-loading", false);
						}.bind(this), 500);
					});
				})
				.catch(error => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage(page)");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.item.pageNum = page;
			this.selectUserList();
		},
		userChangeApproval(index) {
			this.logger.debug(this, "userChangeApproval(index)");
			this.userList[index].projectNo = this.$route.params.projectNo;
			if(this.userList[index].approvalStatus == true)
			{
				this.userList[index].approvalStatus = 0;
			}
			else
			{
				this.userList[index].approvalStatus = 1;
			}

			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/user/UpateUserProjectAuth.do", this.userList[index])
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		updateConfirmYn(user){
			user.lastModifyUserId = this.userInfo.userId;
			this.logger.debug(this, "updateConfirmYn()");
			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/user/updateConfrimYn.do", user)
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
						location.reload();
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		updateUserAuth(user){
			user.lastModifyUserId = this.userInfo.userId;
			this.logger.debug(this, "updateUserAuth()");
			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/user/updateUserAuth.do", user)
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
						location.reload();
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		updateUserSite(user){
			user.lastModifyUserId = this.userInfo.userId;
			this.logger.debug(this, "updateUserSite()");
			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/user/updateUserSite.do", user)
				.then((response) => {
					console.log(response);
					setTimeout(function() {
						EventBus.$emit("project-loading", false);
						location.reload();
					}, 300);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		openAdd(){
			this.isUserAddOpen = true;
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			// this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				EventBus.$emit("project-loading", true);
				this.userList = new Array();
				this.selectUserList();
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
	}
};
</script>