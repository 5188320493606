<template>
	<div id="noticeLayout" class="layout-container">
		<Menu :menuName="menuName" />
		<Topbar ref="topbar" :menuName="menuName" />
		<div v-if="!isProject" class="contents-container" :class="isLoading ? 'loading' : ''">
			<router-view ref="router" :class="isLoading ? 'blur' : ''" :searchText="searchText"/>
		</div>
		<div v-if="isProject" class="project-contents-container" :class="isLoading ? 'loading' : ''" style="overflow-y: scroll;">
			<router-view ref="router" :class="isLoading ? 'blur' : ''"/>
		</div>
	</div>
</template>

<script>
import EventBus from "@/script/EventBus.js";
import Menu from "@/components/left/Menu.vue";
import Topbar from "@/components/left/Topbar.vue";

export default {
	name: "ProjectLayout",
	components: {
		Menu,
		Topbar
	},
	created() {
		this.logger.debug(this, "created()");

		// 검색어 받기
		EventBus.$on("project-search", (searchText) => {
			this.logger.debug(this, "[EventBus] search(searchText)");
			this.searchText = searchText;
		});
		// 로딩바 닫기
		EventBus.$on("project-loading", (isLoading) => {
			this.logger.debug(this, "[EventBus] loading(isLoading)");
			this.isLoading = isLoading;
		});
		// CRUD 새 창 닫기
		EventBus.$on("project-cancel", (target) => {
			this.logger.debug(this, "[EventBus] cancel(target)");
			if(target === "isCreate") this.$refs.router.$data.isCreate = false;
			else if(target === "isEdit") this.$refs.router.$data.isEdit = false;
			else if(target === "isDataCreate") this.$refs.router.$data.isDataCreate = false;
			else if(target === "isTestDataCreate") this.$refs.router.$data.isTestDataCreate = false;
			else if(target === "isProcedureSort") this.$refs.router.$data.isProcedureSort = false;
			else if(target === "isProcedureCopy") this.$refs.router.$data.isProcedureCopy = false;
			else if(target === "isSingleCreate") this.$refs.router.$data.isSingleCreate = false;
			else if(target === "isPreSingleCreate") this.$refs.router.$data.isPreSingleCreate = false;
			else if(target === "isSetFormula") this.$refs.router.$data.isSetFormula = false;
			else if(target === "isMultipleList") this.$refs.router.$data.isMultipleList = false;
			else if(target === "isCopy") this.$refs.router.$data.isCopy = false;
			else if(target === "isMigration") this.$refs.router.$data.isMigration = false;
			else if(target === "isProcedurePDF") this.$refs.router.$data.isProcedurePDF = false;
			else if(target === "isScheduleDetail") this.$refs.router.$data.isScheduleDetail = false;
			else if(target === "isFreezingScreening") this.$refs.router.$data.isFreezingScreening = false;
			else if(target === "isProcedureCopy") this.$refs.router.$data.isProcedureCopy = false;
			else if(target === "isMutipleCreate") this.$refs.router.$data.isMutipleCreate = false;
			else if(target === "isCompare") this.$refs.router.$data.isCompare = false;
			else if(target === "isUnAdd") this.$refs.router.$data.isUnAdd = false;
			else if(target === "isUnUpdate") this.$refs.router.$data.isUnUpdate = false;
			else if(target === "isDefaultValue") this.$refs.router.$data.isDefaultValue = false;
			else if(target === "isCalcAdd") this.$refs.router.$data.isCalcAdd = false;
			else if(target === "isDataExportOpen") this.$refs.router.$data.isDataExportOpen = false;
		});

		EventBus.$on("page-move", (target) => {
			this.logger.debug(this, "[EventBus] pagemove(target)");
			this.current_page = target;
		});
	},
	beforeDestroy() {
		EventBus.$off('project-search');
		EventBus.$off('project-loading');
		EventBus.$off('project-cancel');
	},
	data() {
		return {
			menuName: "Project",
			searchText: "",
			isLoading: true,
			current_page: "",
		};
	},
	computed: {
		isProject: function() {
			if(this.$route.params.projectNo === undefined || this.$route.params.projectNo == "") return false;
			else return true;
		},
	},
};
</script>

<style scoped>
@import "../../assets/css/project/project.css";
</style>