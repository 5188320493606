<template>
	<div>
		<p class="txt-list-title">Entry List</p>
		<div class="list-control-wrap">
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the numbering to search."
				@input="searchData($event.target.value)"
			/>
			<div class="btn-wrap">
				<button
					v-if="workAccessCheck('CREATE') && !(projectStateCd == '4')"
					type="button"
					class="btn-new"
					title="New Data"
					@click="doCreate()"
				>
					<img src="@/assets/images/common/btn_new.png" alt="" />
					New Entry
				</button>
				<button
					v-if="workAccessCheck('PDF DOWNLOAD')"
					type="button"
					class="btn-new"
					title="New Visit"
					@click="pdfDown(3)"
				>
					Completion PDF
				</button>
			</div>
		</div>
		<table class="tb-list-vertical no-cursor">
			<colgroup>
				<col width="80px" />
				<col width="5%" />
				<col width="10%" />
				<col width="auto" />
				<col width="15%" />
				<col width="10%" />
				<col width="10%" />
				<col width="10%" />
				<col width="150px" />
			</colgroup>
			<thead>
				<tr>
					<th>
						<input
							type="checkbox"
							name="checkAll"
							@click="checkAll($event)"
						/>
					</th>
					<th>
						No
					</th>
					<th>
						Site
					</th>
					<th>
						Subject
					</th>
					<th>
						Consent Date
					</th>
					<th>
						Regist Date
					</th>
					<th>
						Last Visit
					</th>
					<th>
						PI Sign
					</th>
					<th>
						Visit
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in screeningList"
					:key="index"
					tabindex="0"
				>
					<td>
						<input
							type="checkbox"
							name="screeningNo"
							:checked="(checkedList.indexOf(item.screeningNo) >= 0 ? true : false)"
							@click="check(item.screeningNo)"
						/>
					</td>
					<td>
						{{ item.rowNumber }}
					</td>
					<td>
						{{ item.siteCodeName }}
					</td>
					<td>
						<span class="underline" @click="doEdit(item)">
							{{ item.screeningName }}
						</span>
					</td>
					<td>
						{{ item.informedConsentDt }}
					</td>
					<td>
						{{ item.firstRegistDate.substring(0, 10) }}
					</td>
					<td>
						{{item.lastVisitName}}
					</td>
					<td>
						<!-- <button class="btn-table" title="Pi Sign" @click.stop="endMonitoringCheck(item.screeningNo)">
							Pi Sign
						</button> -->
						-
					</td>
					<td>
						<button v-if="workAccessCheck('VISIT ACCESS')" class="btn-table" title="Visit" @click="doVisit(item)">
							Visit
						</button>
					</td>
				</tr>
				<tr v-if="screeningList.length == 0">
					<td colspan="9" class="no-list">
						There is no registered data, Please register the data.
					</td>
				</tr>
			</tbody>
		</table>
		<!-- <div class="pagination-wrap">
			<pagination
				:data="pagination"
				:show-disabled="true"
				:limit="4"
				@pagination-change-page="doSearchPage"
			>
				<button type="button" slot="prev-nav" class="page-prev">&lt;</button>
				<button type="button" slot="next-nav" class="page-next">&gt;</button>
			</pagination>
		</div> -->
		<DataImport v-if="isImportData" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import DataImport from "@/components/project/screening/DataImport.vue";

export default {
	name: "ScreeningList",
	components: {
		DataImport,

	},
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "ENTRY",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.items.centralEdcProjectNo = this.$route.params.projectNo;
		this.selectScreeningList();
		this.getAccessList('ENTRY');
		this.getProjectStateCd();
	},
	data() {
		return {
			items: {},
			screeningList: [],
			pagination: {},
			checkedList: [],
			DataImport,
			isImportData: false,
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		searchData(val) {
			this.items.screeningName = val;
			this.items.pageNum = 1;
			this.selectScreeningList();
		},
		selectScreeningList() {
			this.logger.debug(this, "selectScreeningList()");
			axios
				.post(this.config.contextRoot + "/screening/selectList.do", this.items)
				.then((response) => {
					console.log(response);
					this.screeningList = response.data.screeningList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(page) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof page === "undefined" || page === 0) {
				page = 1;
			}
			this.items.pageNum = page;
			this.selectScreeningList();
		},
		check(screeningNo) {
			this.logger.debug(this, "check(screeningNo)");
			let index = this.checkedList.indexOf(screeningNo);
			if(index >= 0)
			{
				this.checkedList.splice(index, 1);
			}
			else
			{
				this.checkedList.push(screeningNo);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if(event.target.checked)
			{
				this.checkedList = this.screeningList.map((item)=>{
					return item.screeningNo;
				});
			}
			else
			{
				this.checkedList = [];
			}
		},
		doCreate() {
			this.logger.debug(this, "doCreate()");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/screening/create"
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doEdit(screening) {
			this.logger.debug(this, "doEdit(screening)");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/screening/edit/" + screening.screeningNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doVisit(screening) {
			this.logger.debug(this, "doVisit(screening)");
			let url = this.config.contextRoot + "/project/" + this.items.projectNo + "/screening/" + screening.screeningNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
			}
		},
		doExcelDown() {
			this.logger.debug(this, "doExcelDown()");
			EventBus.$emit("project-loading", true);

			this.items.projectNo = this.$route.params.projectNo;
			axios
				.post(this.config.contextRoot + "/excel/downloadExcelForm.do", this.items, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					if(response.status == 200)
					{
						console.log(response);
						console.log(response.headers);
						const url = window.URL.createObjectURL(
							new Blob([response.data], {
								type: response.headers["content-type"],
							})
						);
						
						const link = document.createElement("a");
						link.href = url;

						let today = new Date();
						let year = today.getFullYear();
						let month = ('0' + (today.getMonth() +1)).slice(-2);
						let day = ('0' + (today.getDate())).slice(-2);
						today = year + "-" + month + "-" + day;

						link.setAttribute("download", "DownloadForm_"+today +".xlsx");
						document.body.appendChild(link);

						EventBus.$emit("project-loading", false);

						link.click();
						this.$alert("The file has been downloaded.", "Info", "info");
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doImportData() {
			this.logger.debug(this, "doImportData()");
			this.isImportData = true;
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		endMonitoringCheck(screeningNo){
			axios
				.post(this.config.contextRoot + "/screening/screeningEndCheck.do", {screeningNo: screeningNo, projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					let visitPageList = response.data.visitPageList;
					let userInputList = response.data.userInputList;
					if(visitPageList.length != userInputList.length){
						this.$alert("All data should be in monitoring end state.", "Warning", "warning");
					}
					else{
						let count = 0;
						for(let i = 0; i < visitPageList.length; i++){
							for(let j = 0; j < userInputList.length; j++){
								if(visitPageList[i].visitNo == userInputList[j].visitNo && visitPageList[i].pageNo == userInputList[j].pageNo && (userInputList[j].screeningInputStatusCd == '6' || userInputList[j].screeningInputStatusCd == '7')){
									count += 1;
								}
							}
						}
						if(count == visitPageList.length){
							this.allPiSign(screeningNo);
						}
						else{
							this.$alert("All data should be in monitoring end state.", "Warning", "warning");
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		allPiSign(screeningNo){
			axios
				.post(this.config.contextRoot + "/screening/allPiSign.do", {screeningNo: screeningNo})
				.then((response) => {
					console.log(response);
					this.$alert("The status change is complete.", "Success", "success");
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		pdfDown(type){
			this.logger.debug(this, "pdfDown()");

			if(this.checkedList.length <= 0){
				this.$alert("Please select at least one.", "Warning", "warning");
			}
			else{
				EventBus.$emit("project-loading", true);

				this.items.pdfType = type;
				this.items.screeningNoList = this.checkedList;

				axios
					.post(this.config.contextRoot + "/pdf/pdfDownList.do", this.items)
					.then((response) => {
						console.log(response);
						this.doDownload(response.data);
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
		},
		doDownload(data) {
			this.logger.debug(this, "doDownload(data)");
			const formData = new FormData();
			formData.append("fileName", data.fileName);

			axios
				.post(this.config.contextRoot + "/pdf/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();

					setTimeout(function() {
						EventBus.$emit("project-loading", false);
					}.bind(this), 1000);
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	}
};
</script>

<style scoped>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}

.btn-grey:hover, .btn-grey:focus {
	border: 1px solid #2bade0;
	background-color: #fff;
	color: #2bade0;
}

.btn-wrap {
	display: inline-block;
	position: relative;
	float: right;
}
</style>